import { IParkingLot, IParkingLotOwnership } from '../../model/api';
import { getApi } from './api';

const BASE_URL = '/parkingLot';

const get = (id: number): Promise<IParkingLot> => {
  return getApi()
    .get<IParkingLot>(`${BASE_URL}/${id}`)
    .then((response) => response.data);
};

const getCount = (): Promise<number> => {
  return getApi()
    .get<{ Count: number }>(`${BASE_URL}/count`)
    .then((response) => response.data.Count);
};

const getOwnerships = async (): Promise<Array<IParkingLotOwnership>> => {
  return await getApi()
    .get<Array<IParkingLotOwnership>>(`${BASE_URL}/ownerships`)
    .then((response) => response.data || []);
};

export const parkingLots = {
  get,
  getCount,
  getOwnerships,
};
