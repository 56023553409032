/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react';

import { selectedCamerasActions } from '../../../../../features';
import { useOpenPopupDispatch } from '../../../../../hooks';
import { ICameraState } from '../../../../../model';
import { dateUtils } from '../../../../../utils';
import { SecureImg } from '../../../../common';
import { CameraStatusText } from '../camera-status-text/CameraStatusText';
import { NavigationSource, PopupType, amplitudeService } from '../../../../../services';

import styles from './CameraThumbnail.module.scss';

const defaultWidth = 65;
const defaultHeight = 40;

interface Props {
  state: ICameraState;
  thumbnailWidth?: number;
  thumbnailHeight?: number;
}

export const CameraThumbnail: FC<Props> = ({ state, thumbnailWidth = defaultWidth, thumbnailHeight = defaultHeight }) => {
  const popupDispatch = useOpenPopupDispatch();

  const openCamera = () => {
    popupDispatch(selectedCamerasActions.loadCamera({ id: state.CameraId, position: null }));
    amplitudeService.trackPopupOpen(PopupType.Cameras, NavigationSource.Navigation);
  };

  return (
    <div className={styles.cameraThumbnail}>
      <div
        className={styles.imageContainer}
        onClick={openCamera}
        style={{ width: thumbnailWidth, height: thumbnailHeight }}
        role='button'
        tabIndex={0}
      >
        <SecureImg src={`/ParkingEvent/snapshot/by-camera?id=${state.CameraId}`} alt='camera' showPlaceholder={true}></SecureImg>
      </div>
      <div className={styles.picInfo}>
        <CameraStatusText status={state.Status} />
      </div>
      <div className={styles.picInfo}>{dateUtils.toLocalUiString(state.LastUpdated, false, true)}</div>
    </div>
  );
};
