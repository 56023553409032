import { FunctionComponent, useCallback } from 'react';
import { Layer, MapLayerMouseEvent, Source } from 'react-map-gl';

import { AnyLayout, AnyPaint } from 'mapbox-gl';
import { selectAreaGeo, selectedAreaActions } from '../../../../features';
import { useMapLayerHover, useMapLayerPopup, useOpenPopupDispatch } from '../../../../hooks';
import { useAppSelector } from '../../../../store/hooks';
import { AreaPopup, PopupItem } from './AreaPopup';

const areasLayout: AnyLayout = {
  visibility: 'visible',
};
const labelsLayout: AnyLayout = {
  visibility: 'visible',
  'text-field': ['get', 'name'],
  'text-size': 14,
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
};

const areasLines: AnyPaint = {
  'line-color': 'white',
  'line-width': 2,
};
const areasFill: AnyPaint = {
  'fill-color': '#8a61ba',
  'fill-opacity': 0.4,
};
const areasLabels: AnyPaint = {
  'text-color': 'white',
};

export const AreaLayer: FunctionComponent = () => {
  const popupDispatch = useOpenPopupDispatch();
  const geojson = useAppSelector(selectAreaGeo);

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      const feature = evt.features ? evt.features[0] : null;
      if (!feature || !feature.properties) return;

      const areaId = feature.properties.id;
      const position = [evt.lngLat.lng, evt.lngLat.lat];
      popupDispatch(
        selectedAreaActions.loadArea({
          id: areaId,
          position: position,
        }),
      );
    },
    [popupDispatch],
  );

  useMapLayerPopup(handleLayerClick, 'area-fill', 'area-lines');

  useMapLayerHover(['area-fill', 'area-lines']);

  return (
    <>
      <Source id='areas' type='geojson' data={geojson} generateId={true}>
        <Layer {...{ id: 'area-fill', type: 'fill', layout: areasLayout, paint: areasFill }} />
        <Layer {...{ id: 'area-lines', type: 'line', layout: areasLayout, paint: areasLines }} />
        <Layer {...{ id: 'area-labels', type: 'symbol', layout: labelsLayout, paint: areasLabels }} />
      </Source>

      <AreaPopup />
    </>
  );
};
