import { FC } from 'react';

import {
  selectedBlockfacesActions,
  selectedOffstreetZonesActions,
  selectedStudyAreasActions,
  selectedZonesActions,
} from '../../../../features';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { IOffstreetZoneRevenueReportItem, IZoneRevenueReportItem } from '../../../../model';
import { HeatmapPeriod } from '../../../../model/api/heatmap-period';
import { useAppDispatch } from '../../../../store/hooks';
import { ReportPeriodSelector } from '../report-period-selector/ReportPeriodSelector';
import { RevenuePopupTab } from './RevenuePopupTab';

interface IProps {
  zoneId?: number;
  offstreetZoneId?: number;
  blockfaceId?: number;
  studyAreaId?: number;
  report: IZoneRevenueReportItem | IOffstreetZoneRevenueReportItem | null | undefined;
  onChangeFilter?: (change: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => void;
}

export const RevenuePeriodSelector: FC<IProps> = ({ zoneId, offstreetZoneId, blockfaceId, studyAreaId, report, onChangeFilter }) => {
  const localization = useExtendedLocalization();
  const dispatch = useAppDispatch();

  const onChangeFilterHandler = (filter: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => {
    if (onChangeFilter) {
      onChangeFilter(filter);
    } else {
      if (zoneId !== undefined) {
        dispatch(
          selectedZonesActions.fetchRevenueReport({
            zoneId: zoneId,
            filter: filter.heatmapPeriod.calcReportFilter(filter.period),
          }),
        );
      } else if (offstreetZoneId !== undefined) {
        dispatch(
          selectedOffstreetZonesActions.fetchRevenueReport({
            zoneId: offstreetZoneId,
            filter: filter.heatmapPeriod.calcReportFilter(filter.period),
          }),
        );
      } else if (blockfaceId !== undefined) {
        dispatch(
          selectedBlockfacesActions.fetchRevenueReport({
            blockfaceId: blockfaceId,
            filter: filter.heatmapPeriod.calcReportFilter(filter.period),
          }),
        );
      } else if (studyAreaId !== undefined) {
        dispatch(
          selectedStudyAreasActions.fetchRevenueReport({
            studyAreaId: studyAreaId,
            filter: filter.heatmapPeriod.calcReportFilter(filter.period),
          }),
        );
      }
    }
  };

  return (
    <>
      <ReportPeriodSelector onChangeFilter={onChangeFilterHandler} />
      {report ? <RevenuePopupTab revenueItem={report} /> : localization.toLanguageStringF('common.noDataToDisplay')}
    </>
  );
};
