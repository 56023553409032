import { FC } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';

interface Props {
  address?: string;
  curbSpacesCount?: number;
  className?: string;
}

export const ZoneReportSubtitle: FC<Props> = ({ address, curbSpacesCount, className }) => {
  const localization = useExtendedLocalization();

  const count =
    (curbSpacesCount || 0) > 0
      ? curbSpacesCount === 1
        ? `1 ${localization.toLanguageStringF('common.curbSpace')}`
        : `${curbSpacesCount} ${localization.toLanguageStringF('common.curbSpaces')}`
      : '';

  return <div className={className || ''}>{[address || '', count].filter((x) => x).join(' | ')}</div>;
};
