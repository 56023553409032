import { Feature, GeoJsonProperties, Geometry, Point, Position } from 'geojson';
import * as pointOnFeature from '@turf/point-on-feature';
import * as midpoint from '@turf/midpoint';

const findCenter = (feature: Feature<Geometry, GeoJsonProperties>): Feature<Point, GeoJsonProperties> => {
  const type = feature?.geometry?.type;
  switch (type) {
    case 'Point':
      return feature as Feature<Point, GeoJsonProperties>;
    case 'LineString':
      return midpoint.default(feature.geometry.coordinates[0], feature.geometry.coordinates[1]);
    case 'Polygon':
    case 'MultiLineString':
      return pointOnFeature.default(feature.geometry);
  }

  throw new Error(`Unknown feature type: ${feature.geometry.type}`);
};

const toUiString = (value: number[] | null | undefined) => {
  return value ? `${value[0].toFixed(4)}, ${value[1].toFixed(4)}` : null;
};

const toGeometry = (positions: Array<Array<Position>>): Geometry => {
  return positions.length === 1
    ? positions[0].length === 1
      ? {
          type: 'Point',
          coordinates: positions[0][0],
        }
      : {
          type: 'LineString',
          coordinates: positions[0],
        }
    : {
        type: 'MultiLineString',
        coordinates: positions,
      };
};

export const geoUtils = {
  findCenter,
  toUiString,
  toGeometry,
};
