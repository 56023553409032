/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react';
import classNames from 'classnames/bind';

import { ISignState, SignStatus } from '../../../../../model';
import { dateUtils } from '../../../../../utils';
import { SecureImg } from '../../../../common';
import { useOpenPopupDispatch } from '../../../../../hooks';
import { selectedSignsActions } from '../../../../../features';
import { useExtendedLocalization } from '../../../../../hooks/use-extended-localization-service';

import styles from './SignThumbnail.module.scss';

const cx = classNames.bind(styles);
const defaultWidth = 75;

interface Props {
  state: ISignState;
  thumbnailWidth?: number;
  className?: string;
}

export const SignThumbnail: FC<Props> = ({ state, thumbnailWidth = defaultWidth, className }) => {
  const popupDispatch = useOpenPopupDispatch();
  const localization = useExtendedLocalization();

  const openSign = () => popupDispatch(selectedSignsActions.loadSign({ id: state.SignId, position: null }));

  const status = (
    <span style={{ color: state.Status === SignStatus.Active ? 'green' : 'gray' }}>
      {state.Status === SignStatus.Active
        ? localization.toLanguageStringF(`sign.status.${SignStatus[SignStatus.Active]}`)
        : localization.toLanguageStringF(`sign.status.${SignStatus[SignStatus.Inactive]}`)}
    </span>
  );

  return (
    <div className={cx('signThumbnail', className)}>
      <div className={styles.imageContainer} onClick={openSign} style={{ width: thumbnailWidth }} role='button' tabIndex={0}>
        {state?.ActiveScreen && (
          <SecureImg src={`/sign/screen/${state.ActiveScreen.Id}/image`} alt='sign' showPlaceholder={true}></SecureImg>
        )}
      </div>
      <div className={styles.picInfo}>{status}</div>
      <div className={styles.picInfo}>{dateUtils.toLocalUiString(state.LastUpdated, false, true)}</div>
    </div>
  );
};
