/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { FunctionComponent } from 'react';
import classNames from 'classnames';

import { SearchItemProps } from '../Search';
import { SensorIcon } from '../../../../map/icons';
import { useExtendedLocalization } from '../../../../../hooks/use-extended-localization-service';

export const SensorItem: FunctionComponent<SearchItemProps> = ({ model, onItemSelected }) => {
  const localization = useExtendedLocalization();

  return (
    <li className={classNames('item', { disabled: !model.enabled })} title={model.displayName} onClick={() => onItemSelected(model)}>
      <SensorIcon className='item-icon' />
      <div className='item-info'>
        <div className='item-info-row'>
          <label>{`${localization.toLanguageStringF('common.groundSensor')}: `}</label>
          <span className='value' dangerouslySetInnerHTML={{ __html: model.item.Name }}></span>
        </div>
      </div>
    </li>
  );
};
