import { combineReducers } from 'redux';

import { appSettingsReducer, citiesReducer, globalizationReducer, userProfileReducer } from '../features/common';
import {
  areaGeoReducer,
  areaLayerReducer,
  areaTypesReducer,
  camerasGeoReducer,
  camerasLayerReducer,
  curbSpacesGeoReducer,
  curbSpacesLayerReducer,
  customLayersReducer,
  keyMetricsReducer,
  mapStateReducer,
  metersEditingReducer,
  metersGeoReducer,
  metersLayerReducer,
  offstreetZonesGeoReducer,
  offstreetZonesLayerReducer,
  parkingDurationsZoneGeoReducer,
  revenueBlockfaceGeoReducer,
  revenueMeterGeoReducer,
  revenueOffstreetZoneGeoReducer,
  revenueZoneGeoReducer,
  selectedAreaReducer,
  selectedCamerasReducer,
  selectedCurbSpacesReducer,
  selectedCustomLayersReducer,
  selectedMetersReducer,
  selectedOffstreetZonesReducer,
  selectedRevenueOffstreetZonesReducer,
  selectedStreetSignsReducer,
  selectedZoneHeatmapsReducer,
  selectedZonesReducer,
  sidebarReducer,
  signsGeoReducer,
  streetObjectsGeoReducer,
  streetObjectsLayerReducer,
  zonesGeoReducer,
  zoneObjectsGeoReducer,
  zoneObjectsLayerReducer,
  zonesLayerReducer,
  parkingDurationsBlockfaceGeoReducer,
  studyAreasLayerReducer,
  studyAreasGeoReducer,
  selectedStudyAreasReducer,
  occupancyDataReducer,
  revenueDataReducer,
  durationDataReducer,
  selectedStudyAreaHeatmapsReducer,
  violationDataReducer,
  suggestedStreetsLayerReducer,
  suggestedStreetsGeoReducer,
  rppZonesLayerReducer,
  rppAreasLayerReducer,
  selectedRppAreasReducer,
  layerIdsReducer,
} from '../features/map';
import { selectedSignsReducer } from '../features/map/layers/signs/selected-signs-slice';
import { signsLayerReducer } from '../features/map/layers/signs/signs-layer-slice';

import { heatmapFiltersReducer } from '../features/map';

import { popupRelationLinesReducer } from '../components/common/self-oriented-popup/popup-relation-line-slice';
import {
  alertsReducer,
  loadingZonesGeoReducer,
  loadingZonesLayerReducer,
  savedViewsReducer,
  selectedLoadingZonesReducer,
} from '../features/map';

import { parkingLotsGeoReducer, selectedParkingLotsReducer } from '../features/map';

import { blockfacesLayerReducer, blockfacesGeoReducer, selectedBlockfacesReducer } from '../features/map';
import { trafficGeoReducer } from '../features/map/layers/traffic/traffic-geo-slice';
import { selectedBlockfaceHeatmapsReducer } from '../features/map/layers/heatmaps/selected-blockface-heatmaps-slice';
import { selectedRppZonesReducer } from '../features/map/layers/zones/rpp-zones/selected-rpp-zones-slice';

export const rootReducer = combineReducers({
  appSettings: appSettingsReducer,
  userProfile: userProfileReducer,
  cities: citiesReducer,
  globalization: globalizationReducer,

  mapState: mapStateReducer,
  sidebar: sidebarReducer,
  selfOrientedPopup: popupRelationLinesReducer,

  areaLayer: areaLayerReducer,
  areaTypes: areaTypesReducer,
  areaGeo: areaGeoReducer,
  selectedAreas: selectedAreaReducer,

  zonesLayer: zonesLayerReducer,
  zonesGeo: zonesGeoReducer,
  zoneObjectsGeo: zoneObjectsGeoReducer,
  zoneObjectsLayer: zoneObjectsLayerReducer,
  rppZonesLayer: rppZonesLayerReducer,
  rppAreasLayer: rppAreasLayerReducer,
  selectedZones: selectedZonesReducer,
  selectedRppZones: selectedRppZonesReducer,
  selectedRppAreas: selectedRppAreasReducer,

  layerIds: layerIdsReducer,

  curbSpacesLayer: curbSpacesLayerReducer,
  curbSpacesGeo: curbSpacesGeoReducer,
  selectedCurbSpaces: selectedCurbSpacesReducer,

  offstreetZonesLayer: offstreetZonesLayerReducer,
  offstreetZonesGeo: offstreetZonesGeoReducer,
  selectedOffstreetZones: selectedOffstreetZonesReducer,

  metersLayer: metersLayerReducer,
  metersGeo: metersGeoReducer,
  metersEditing: metersEditingReducer,

  camerasLayer: camerasLayerReducer,
  camerasGeo: camerasGeoReducer,
  selectedCameras: selectedCamerasReducer,
  selectedMeters: selectedMetersReducer,

  signsLayer: signsLayerReducer,
  signsGeo: signsGeoReducer,
  selectedSigns: selectedSignsReducer,

  streetObjectsLayer: streetObjectsLayerReducer,
  streetObjectsGeo: streetObjectsGeoReducer,
  selectedStreetSigns: selectedStreetSignsReducer,

  trafficGeo: trafficGeoReducer,
  occupancyData: occupancyDataReducer,

  revenueMeterGeo: revenueMeterGeoReducer,
  revenueZoneGeo: revenueZoneGeoReducer,
  revenueOffstreetZoneGeo: revenueOffstreetZoneGeoReducer,
  revenueBlockfaceGeo: revenueBlockfaceGeoReducer,
  revenueData: revenueDataReducer,
  violationData: violationDataReducer,

  selectedRevenueOffstreetZones: selectedRevenueOffstreetZonesReducer,
  selectedZoneHeatmaps: selectedZoneHeatmapsReducer,
  selectedBlockfaceHeatmaps: selectedBlockfaceHeatmapsReducer,
  selectedStudyAreaHeatmaps: selectedStudyAreaHeatmapsReducer,

  keyMetrics: keyMetricsReducer,

  durationData: durationDataReducer,
  parkingDurationsZoneGeo: parkingDurationsZoneGeoReducer,
  parkingDurationsBlockfaceGeo: parkingDurationsBlockfaceGeoReducer,

  heatmapsFilter: heatmapFiltersReducer,

  loadingZonesLayer: loadingZonesLayerReducer,
  loadingZonesGeo: loadingZonesGeoReducer,
  selectedLoadingZones: selectedLoadingZonesReducer,

  alerts: alertsReducer,

  savedViews: savedViewsReducer,

  parkingLotsGeo: parkingLotsGeoReducer,
  selectedParkingLots: selectedParkingLotsReducer,
  customLayers: customLayersReducer,
  selectedCustomLayers: selectedCustomLayersReducer,

  blockfacesLayer: blockfacesLayerReducer,
  blockfacesGeo: blockfacesGeoReducer,
  selectedBlockfaces: selectedBlockfacesReducer,

  studyAreasLayer: studyAreasLayerReducer,
  studyAreasGeo: studyAreasGeoReducer,
  selectedStudyAreas: selectedStudyAreasReducer,

  suggestedStreetsLayer: suggestedStreetsLayerReducer,
  suggestedStreetsGeo: suggestedStreetsGeoReducer,
});
