import { FunctionComponent } from 'react';
import { useExtendedLocalization } from '../../../hooks/use-extended-localization-service';
import { LedColor, LedDecoderService } from '../led-decoder-service';
import styles from './LedColorText.module.scss';

interface Props {
  className?: string;
  color: number;
}

export const LedColorText: FunctionComponent<Props> = ({ className, color }) => {
  const localization = useExtendedLocalization();
  
  return <div className={`${styles.row} ${className || ''}`}>
    <div className={`${styles.cycle}`} style={{backgroundColor: LedDecoderService.ledToHex(color)}}></div>
    <span>{localization.toLanguageStringF(`sign.ledColor.${LedColor[LedDecoderService.ledToEnum(color) as LedColor]}`)}</span>
  </div>
};
