import { TabStripTab } from '@progress/kendo-react-layout';
import { Position } from 'geojson';
import React, { FC, useCallback, useMemo, useState } from 'react';

import {
  currentStudyArea,
  selectSidebarState,
  selectedBlockfacesActions,
  selectedCurbSpacesActions,
  selectedMetersActions,
  selectedStudyAreas,
  selectedStudyAreasActions,
  selectedZonesActions,
  sidebarActions,
} from '../../../../features';
import { useOccupancyTrafficEnabled, useOpenPopupDispatch, useReportBuilder } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ISelectedStudyArea, SidebarName } from '../../../../model';
import { ISelectedEntity, NavigationSource, PopupTab, PopupType, ReportName, amplitudeService } from '../../../../services';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  ContentPlaceholder,
  Link,
  PinButton,
  PopupBodyPlaceholder,
  PopupHeaderPlaceholder,
  SelfOrientedPopup,
  Spinner,
  TabStrip,
} from '../../../common';
import { RevenuePeriodSelector, ViewReportLink, ViolationsPeriodSelector } from '../../common';
import { OccupancyTrafficSelector } from '../../common/occupancy-traffic-popup-tab/OccupancyTrafficSelector';
import { StudyAreaIcon } from '../../icons';
import { CameraThumbnail } from '../cameras/camera-thumbnail/CameraThumbnail';
import { CurbSpacesStatusCount } from '../curb-spaces/curb-spaces-status-count/CurbSpacesStatusCount';

import styles from './StudyAreaPopup.module.scss';
import { HeatmapPeriod } from '../../../../model/api/heatmap-period';
import { OccupancyPeriodTypeSelector } from '../../common/occupancy-popup-tab/OccupancyPeriodTypeSelector';

const GENERAL_INFO_TAB = 0;
const REVENUE_TAB = 1;
const ENFORCEMENT_TAB = 2;
const OCCUPANCY_TAB = 3;
const OCCUPANCY_TRAFFIC_TAB = 4;

const TabToAmpTab = new Map<number, PopupTab>();
TabToAmpTab.set(GENERAL_INFO_TAB, PopupTab.StudyGeneralInfo);
TabToAmpTab.set(REVENUE_TAB, PopupTab.StudyRevenue);
TabToAmpTab.set(ENFORCEMENT_TAB, PopupTab.StudyEnforcemnt);
TabToAmpTab.set(OCCUPANCY_TAB, PopupTab.StudyOccupancy);
TabToAmpTab.set(OCCUPANCY_TRAFFIC_TAB, PopupTab.StudyOccupancyTraffic);

const ItemsCount: FC<{ count: number }> = ({ count }) =>
  count > 0 ? (
    <div className={styles.labelDescription}>
      {count} {count === 1 ? 'item' : 'items'}
    </div>
  ) : null;

const PopupItem: FC<{ studyAreaData: ISelectedEntity<ISelectedStudyArea> }> = ({ studyAreaData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const localization = useExtendedLocalization();
  const [selectedTab, setSelectedTab] = useState(0);
  const currentStudyAreaData = useAppSelector(currentStudyArea);
  const selectedSidebar = useAppSelector(selectSidebarState);
  const reportsBuilder = useReportBuilder();
  const occupancyTrafficEnabled = useOccupancyTrafficEnabled();

  const studyArea = studyAreaData.entity;

  const closePopup = () => dispatch(selectedStudyAreasActions.closePopup(studyAreaData.id));

  const pinPopup = useCallback(() => dispatch(selectedStudyAreasActions.pinPopup(studyAreaData.id)), [dispatch, studyAreaData.id]);

  const onDragEnd = (position: Position) => {
    if (!studyArea) {
      return;
    }
    dispatch(selectedStudyAreasActions.change({ id: studyArea.Id, position: position }));
    amplitudeService.trackPopupMove(PopupType.Study);
  };

  const moreInfo = useCallback(() => {
    dispatch(selectedStudyAreasActions.setCurrent(studyArea));
    dispatch(sidebarActions.setSelected({ name: SidebarName.studyArea, data: studyArea?.Id }));
  }, [dispatch, studyArea]);

  const openMeter = useCallback(
    (meterId: number) => {
      popupDispatch(selectedMetersActions.loadMeter({ id: meterId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Meters, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openSpot = useCallback(
    (spotId: number) => {
      popupDispatch(selectedCurbSpacesActions.load({ id: spotId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.CurbSpaces, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openZone = useCallback(
    (zoneId: number) => {
      popupDispatch(selectedZonesActions.loadZone({ id: zoneId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Zones, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openBlockface = useCallback(
    (blockfaceId: number) => {
      popupDispatch(selectedBlockfacesActions.loadBlockface({ id: blockfaceId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Blockfaces, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const onTabSelect = useCallback(
    (tab: number) => {
      if (selectedTab === tab) return;

      setSelectedTab(tab);

      const ampTab = TabToAmpTab.get(tab);
      if (ampTab !== undefined) {
        amplitudeService.trackPopupTab(ampTab);
      }
    },
    [selectedTab],
  );

  const report = useMemo(() => {
    return selectedTab === REVENUE_TAB
      ? { url: reportsBuilder.getOverviewStudyAreaAnalysis(), name: ReportName.StudyAreaAnalysis }
      : selectedTab === OCCUPANCY_TRAFFIC_TAB
      ? { url: reportsBuilder.getOverviewStudyAreaOccupancy(), name: ReportName.StudyAreaOccupancy }
      : selectedTab === ENFORCEMENT_TAB
      ? { url: reportsBuilder.getOverviewEnforcement(), name: ReportName.Enforcement }
      : null;
  }, [reportsBuilder, selectedTab]);

  const getViolationReport = useCallback(
    (filter: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => {
      dispatch(
        selectedStudyAreasActions.fetchViolationReport({
          studyAreaId: studyAreaData.id,
          filter: filter.heatmapPeriod.calcReportFilter(filter.period),
        }),
      );
    },
    [dispatch, studyAreaData.id],
  );

  const content = useMemo(
    () => (
      <>
        <ContentPlaceholder loading={studyAreaData.loading} element={<PopupHeaderPlaceholder />}>
          <div className={`map-popup-header ${styles.header}`}>
            <StudyAreaIcon className={styles.headerIcon} />
            <h4>{localization.toLanguageStringF('studyArea.popup.header', [studyArea?.Name])}</h4>
            <CurbSpacesStatusCount
              spotsStates={studyArea?.spotsStates.map((x) => x.Status) || []}
              spotsCount={studyArea?.SpotsCount}
              className={styles.curbSpacesStatusCount}
            />
            <PinButton className={styles.pinButton} pinned={studyAreaData.pinnedPopup} onClick={pinPopup} />
          </div>
        </ContentPlaceholder>
        <div className='map-popup-body'>
          <TabStrip onSelectTab={onTabSelect}>
            <TabStripTab title={localization.toLanguageStringF('common.generalInfo')}>
              <ContentPlaceholder loading={studyAreaData.loading} element={<PopupBodyPlaceholder />}>
                <div className={styles.tabContent}>
                  {studyArea && studyArea.zones.length > 0 && (
                    <div className='map-popup-row'>
                      <div className={styles.columnLabel}>
                        <label>{localization.toLanguageStringF('studyArea.zones')}:</label>
                        <ItemsCount count={studyArea.zones.length} />
                      </div>
                      <div className={`row-value ${styles.width100}`}>
                        {studyArea.zones.map((x, idx) => (
                          <Link key={x.Id} onClick={() => openZone(x.Id)} className={styles.listItem}>
                            <span>{x.Name}</span>
                            {idx < studyArea.zones.length - 1 && ','}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}

                  {studyArea && studyArea.blockfaces.length > 0 && (
                    <div className='map-popup-row'>
                      <div className={styles.columnLabel}>
                        <label>{localization.toLanguageStringF('studyArea.blockfaces')}:</label>
                        <ItemsCount count={studyArea.blockfaces.length} />
                      </div>
                      <div className='row-value'>
                        {studyArea.blockfaces.map((x, idx) => (
                          <Link key={x.Id} onClick={() => openBlockface(x.Id)} className={styles.listItem}>
                            <span>{x.Name}</span>
                            {idx < studyArea.blockfaces.length - 1 && ','}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className='map-popup-row'>
                    <div className={styles.columnLabel}>
                      <label>{localization.toLanguageStringF('studyArea.popup.address')}:</label>
                    </div>
                    <div className='row-value'>{studyArea?.Address}</div>
                  </div>

                  {studyArea && studyArea.camerasStates.length > 0 && (
                    <div className='map-popup-row'>
                      <div className={styles.columnLabel}>
                        <label>{localization.toLanguageStringF('studyArea.popup.cameras')}:</label>
                        <ItemsCount count={studyArea.camerasStates.length} />
                      </div>
                      <div className={`map-popup-row ${styles.rowValueWrap}`}>
                        {studyArea.camerasStates.map((x) => (
                          <div key={x.CameraId} className={styles.thumbnail}>
                            <CameraThumbnail state={x} />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className='map-popup-row'>
                    <div className={styles.columnLabel}>
                      <label>{localization.toLanguageStringF('common.parkingSpacesCount')}:</label>
                    </div>
                    <div className='row-value'>{studyArea?.SpotsCount}</div>
                  </div>

                  {studyArea && studyArea.metersNames.length > 0 && (
                    <div className='map-popup-row'>
                      <div className={styles.columnLabel}>
                        <label>{localization.toLanguageStringF('studyArea.popup.meters')}:</label>
                        <ItemsCount count={studyArea.metersNames.length} />
                      </div>
                      <div className={`row-value ${styles.width100}`}>
                        {studyArea.metersNames.map((x, idx) => (
                          <Link key={x.Id} onClick={() => openMeter(x.Id)} className={styles.listItem}>
                            <span>{x.Name}</span>
                            {idx < studyArea.metersNames.length - 1 && ','}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}

                  {studyArea && studyArea.sensors.length > 0 && (
                    <div className='map-popup-row'>
                      <div className={styles.columnLabel}>
                        <label>{localization.toLanguageStringF('studyArea.popup.sensors')}:</label>
                        <ItemsCount count={studyArea.sensors.length} />
                      </div>
                      <div className={`row-value ${styles.width100}`}>
                        {studyArea.sensors.map((x, idx) => (
                          <Link key={x.SensorId} onClick={() => openSpot(x.SpotId)} className={styles.listItem}>
                            <span>{x.SensorName}</span>
                            {idx < studyArea.sensors.length - 1 && ','}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </ContentPlaceholder>
            </TabStripTab>
            <TabStripTab title={localization.toLanguageStringF('studyArea.revenue')}>
              <ContentPlaceholder loading={studyAreaData.loading} element={<PopupBodyPlaceholder />}>
                <RevenuePeriodSelector studyAreaId={studyAreaData.id} report={studyArea?.revenueReport} />
              </ContentPlaceholder>
            </TabStripTab>
            <TabStripTab title={localization.toLanguageStringF('studyArea.enforcement')}>
              <ContentPlaceholder loading={studyAreaData.loading} element={<PopupBodyPlaceholder />}>
                <ViolationsPeriodSelector onChangeFilter={getViolationReport} report={studyArea?.violationReport} />
              </ContentPlaceholder>
            </TabStripTab>
            <TabStripTab title={localization.toLanguageStringF('occupancySource.title')}>
              <ContentPlaceholder loading={studyAreaData.loading} element={<PopupBodyPlaceholder />}>
                <OccupancyPeriodTypeSelector studyAreaId={studyAreaData.id} report={studyArea?.occupancySourceReport} />
              </ContentPlaceholder>
            </TabStripTab>
            {occupancyTrafficEnabled && (
              <TabStripTab title={localization.toLanguageStringF('studyArea.occupancyTraffic')}>
                <ContentPlaceholder loading={studyAreaData.loading} element={<PopupBodyPlaceholder />}>
                  <OccupancyTrafficSelector
                    report={studyArea?.occupancyTrafficReport}
                    onChangeFilter={(change) =>
                      dispatch(
                        selectedStudyAreasActions.fetchOccupancyTrafficReport({
                          studyAreaId: studyAreaData.id,
                          filter: change.heatmapPeriod.calcReportFilter(change.period),
                          occupancySource: change.occupancySource,
                        }),
                      )
                    }
                  />
                </ContentPlaceholder>
              </TabStripTab>
            )}
          </TabStrip>

          <div className={`map-popup-row more-info ${styles.moreInfo}`}>
            {report?.url && <ViewReportLink reportUrl={report?.url} reportName={report?.name} className={styles.reportLink} />}
            <Link onClick={moreInfo} disabled={selectedSidebar === SidebarName.studyArea && currentStudyAreaData?.id === studyArea?.Id}>
              {localization.toLanguageStringF('common.moreInfo')}
            </Link>
          </div>
        </div>
      </>
    ),
    [
      currentStudyAreaData?.id,
      dispatch,
      getViolationReport,
      occupancyTrafficEnabled,
      localization,
      moreInfo,
      onTabSelect,
      openBlockface,
      openMeter,
      openSpot,
      openZone,
      pinPopup,
      report?.name,
      report?.url,
      selectedSidebar,
      studyArea,
      studyAreaData.id,
      studyAreaData.loading,
      studyAreaData.pinnedPopup,
    ],
  );

  if (!studyAreaData.openPopup || !studyAreaData.position) return null;

  return (
    <React.StrictMode>
      <SelfOrientedPopup
        data={studyArea}
        maxWidth='690px'
        longitude={studyAreaData.position[0]}
        latitude={studyAreaData.position[1]}
        onClose={closePopup}
        closeOnClick={false}
        className='study-area-popup'
        updateOn={selectedTab}
        initPosition={studyAreaData.initPosition}
        onDragEnd={onDragEnd}
      >
        <div>
          {content}
          {studyAreaData.loading && <Spinner backdrop={true} />}
        </div>
      </SelfOrientedPopup>
    </React.StrictMode>
  );
};

export const StudyAreaPopup: FC = () => {
  const studyAreas = useAppSelector(selectedStudyAreas);
  return (
    <>
      {studyAreas.selected.map((x) => (
        <PopupItem key={x.id} studyAreaData={x} />
      ))}
    </>
  );
};
