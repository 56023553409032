/* eslint-disable jsx-a11y/label-has-associated-control */
import { TabStripTab } from '@progress/kendo-react-layout';
import { Position } from 'geojson';
import React, { FC, useCallback, useMemo, useState } from 'react';

import {
  currentCurbSpace,
  selectCity,
  selectCurbSpacesHasTransaction,
  selectedBlockfacesActions,
  selectedCurbSpaces,
  selectedCurbSpacesActions,
  selectedMetersActions,
  selectedStudyAreasActions,
  selectedZonesActions,
  selectSidebarState,
  sidebarActions,
} from '../../../../features';
import { useOpenPopupDispatch } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ISelectedCurbSpace, OccupancyStatus, SidebarName } from '../../../../model';
import { amplitudeService, CityCode, ISelectedEntity, NavigationSource, PopupTab, PopupType } from '../../../../services';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { dateUtils } from '../../../../utils';
import { ContentPlaceholder, Link, PinButton, PopupBodyPlaceholder, PopupPlaceholder, SelfOrientedPopup, TabStrip } from '../../../common';
import { CurbSpacePolicyIcon } from '../../icons';
import { CameraThumbnail } from '../cameras/camera-thumbnail/CameraThumbnail';
import { SignThumbnail } from '../signs/sign-thumbnail/SignThumbnail';
import { CurbSpaceOccupancyStatusText } from './CurbSpaceOccupancyStatusText';

import styles from './CurbSpacePopup.module.scss';
import { ShowGoogleStreetViewLink } from '../../common/show-google-street-view-link/ShowGoogleStreetViewLink';
import { OccupancyPeriodTypeSelector } from '../../common/occupancy-popup-tab/OccupancyPeriodTypeSelector';

const GENERAL_INFO_TAB = 0;
const OCCUPANCY_TAB = 1;

const TabToAmpTab = new Map<number, PopupTab>();
TabToAmpTab.set(GENERAL_INFO_TAB, PopupTab.SpotsGeneralInfo);
TabToAmpTab.set(OCCUPANCY_TAB, PopupTab.SpotsOccupancy);

const Sensor: FC<{ name: string; status?: OccupancyStatus; chargeLevel?: number; temperature?: number; updatedAt?: Date }> = (sensor) => {
  const localization = useExtendedLocalization();

  return (
    <>
      <p className={styles.groundSensorSeparator}></p>
      <div className='map-popup-row'>
        <label>
          <b>{localization.toLanguageStringF('common.groundSensor')}</b>
        </label>
      </div>

      <div className='map-popup-row'>
        <label>{localization.toLanguageStringF('curbSpace.popup.deviceId')}:</label>
        <div className='row-value'>#{sensor.name}</div>
      </div>

      <div className='map-popup-row'>
        <label>{localization.toLanguageStringF('common.status')}:</label>
        <div className='row-value'>{sensor.status ? <CurbSpaceOccupancyStatusText status={sensor.status} /> : '-'}</div>
      </div>

      <div className='map-popup-row'>
        <label>{localization.toLanguageStringF('curbSpace.popup.battery')}:</label>
        <div className='row-value'>{sensor.chargeLevel ? `${sensor.chargeLevel}%` : '-'}</div>
      </div>

      <div className='map-popup-row'>
        <label>{localization.toLanguageStringF('curbSpace.popup.temperature')}:</label>
        <div className='row-value'>{sensor.temperature ? `${sensor.temperature}°C` : '-'}</div>
      </div>

      <div className='map-popup-row'>
        <label>{localization.toLanguageStringF('common.lastUpdate')}:</label>
        <div className='row-value'>{sensor.updatedAt ? dateUtils.toLocalUiString(sensor.updatedAt) : '-'}</div>
      </div>
    </>
  );
};

export const PopupItem: FC<{ curbSpaceData: ISelectedEntity<ISelectedCurbSpace> }> = ({ curbSpaceData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const selectedSidebar = useAppSelector(selectSidebarState);
  const localization = useExtendedLocalization();
  const city = useAppSelector(selectCity);
  const hasTransaction = useAppSelector(selectCurbSpacesHasTransaction);
  const [selectedTab, setSelectedTab] = useState(0);

  const currentCurbSpaceData = useAppSelector(currentCurbSpace);
  const curbSpace = curbSpaceData.entity;

  const closePopup = () => {
    dispatch(selectedCurbSpacesActions.closePopup(curbSpaceData.id));
  };

  const pinPopup = useCallback(() => {
    dispatch(selectedCurbSpacesActions.pinPopup(curbSpaceData.id));
  }, [curbSpaceData.id, dispatch]);

  const moreInfo = useCallback(() => {
    dispatch(selectedCurbSpacesActions.setCurrent(curbSpace));
    dispatch(sidebarActions.setSelected({ name: SidebarName.curbSpace, data: curbSpace?.Id }));
  }, [curbSpace, dispatch]);

  const onTabSelect = useCallback(
    (tab: number) => {
      if (selectedTab === tab) return;
      setSelectedTab(tab);

      const ampTab = TabToAmpTab.get(tab);
      if (ampTab !== undefined) {
        amplitudeService.trackPopupTab(ampTab);
      }
    },
    [selectedTab],
  );

  const openZone = useCallback(
    (zoneId: number | undefined) => {
      if (!zoneId) return;

      popupDispatch(selectedZonesActions.loadZone({ id: zoneId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Zones, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openMeter = useCallback(
    (meterId: number) => {
      popupDispatch(selectedMetersActions.loadMeter({ id: meterId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Meters, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openBlockface = useCallback(
    (blockfaceId?: number) => {
      if (!blockfaceId) return;
      popupDispatch(selectedBlockfacesActions.loadBlockface({ id: blockfaceId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Blockfaces, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openStudyArea = useCallback(
    (studyAreaId: number) => {
      popupDispatch(selectedStudyAreasActions.loadStudyArea({ id: studyAreaId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Study, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const onDragEnd = useCallback(
    (position: Position) => {
      if (!curbSpaceData) {
        return;
      }
      dispatch(selectedCurbSpacesActions.change({ id: curbSpaceData.id, position: position }));
      amplitudeService.trackPopupMove(PopupType.CurbSpaces);
    },
    [curbSpaceData, dispatch],
  );

  const policy =
    curbSpace && curbSpace.PolicyTypeId !== 0 ? localization.toLanguageStringF(`curbSpace.policyType.${curbSpace.PolicyTypeId}`) : '';

  const generalContent = useMemo(
    () => (
      <>
        {(curbSpace?.cameraState || curbSpace?.signState) && (
          <div className='map-popup-row'>
            {curbSpace?.cameraState && (
              <div className='row-value'>
                <label className={styles.rowTitle}>{localization.toLanguageStringF('curbSpace.popup.camera')}</label>
                <CameraThumbnail state={curbSpace.cameraState} />
              </div>
            )}
            {curbSpace?.signState && (
              <div className='row-value'>
                <label className={styles.rowTitle}>{localization.toLanguageStringF('curbSpace.popup.sign')}</label>
                <SignThumbnail state={curbSpace.signState} />
              </div>
            )}
          </div>
        )}

        {curbSpace?.camera?.Address && (
          <div className='map-popup-row'>
            <label>{localization.toLanguageStringF('common.address')}:</label>
            <div className='row-value'>{curbSpace.camera.Address}</div>
          </div>
        )}

        {curbSpace && curbSpace.studyAreas.length > 0 && (
          <div className='map-popup-row'>
            <label>{localization.toLanguageStringF('curbSpace.studyAreas')}:</label>
            <div className='row-value'>
              {curbSpace.studyAreas.map((x, idx) => (
                <Link key={x.Id} onClick={() => openStudyArea(x.Id)} className={styles.listItem}>
                  <span>{x.Name}</span>
                  {idx < curbSpace.studyAreas.length - 1 && ','}
                </Link>
              ))}
            </div>
          </div>
        )}

        {curbSpace?.zone && (
          <div className='map-popup-row'>
            <label>{localization.toLanguageStringF('common.zone')}:</label>
            <div className='row-value'>
              <Link className={styles.link} onClick={() => openZone(curbSpace?.zone?.Id)}>
                {curbSpace?.zone?.Name}
              </Link>
            </div>
          </div>
        )}

        {curbSpace?.blockface && (
          <div className='map-popup-row'>
            <label>{localization.toLanguageStringF('common.blockface')}:</label>
            <div className='row-value'>
              <Link className={styles.link} onClick={() => openBlockface(curbSpace.blockface?.Id)}>
                {curbSpace.blockface.Name}
              </Link>
            </div>
          </div>
        )}

        {(curbSpace?.metersNames?.length || 0) > 0 && (
          <div className='map-popup-row'>
            <label>{localization.toLanguageStringF('curbSpace.popup.meters')}:</label>
            <div className='row-value'>
              {curbSpace?.metersNames.map((x, idx) => (
                <Link key={x.Id} onClick={() => openMeter(x.Id)} className={styles.listItem}>
                  <span>{x.Name}</span>
                  {idx < curbSpace.metersNames.length - 1 && ','}
                </Link>
              ))}
            </div>
          </div>
        )}

        {curbSpace?.state?.CompanyName && (
          <div className='map-popup-row'>
            <label>{localization.toLanguageStringF('curbSpace.popup.carrier')}:</label>
            <div className='row-value'>{curbSpace.state.CompanyName}</div>
          </div>
        )}
      </>
    ),
    [curbSpace, localization, openBlockface, openMeter, openStudyArea, openZone],
  );

  const sensorContent = useMemo(() => {
    const sensor = curbSpace?.sensorTelemetry;
    return sensor ? (
      <Sensor
        name={sensor.Name}
        status={sensor.Status as unknown as OccupancyStatus}
        chargeLevel={sensor.ChargeLevel ?? undefined}
        temperature={sensor.Temperature ?? undefined}
        updatedAt={sensor.UpdatedAt}
      />
    ) : null;
  }, [curbSpace?.sensorTelemetry]);

  const content = useMemo(
    () => (
      <>
        <div className={`map-popup-header ${styles.header}`}>
          <CurbSpacePolicyIcon policy={curbSpace?.PolicyTypeId} className={styles.icon} />
          <h4>{localization.toLanguageStringF('curbSpace.popup.title', [curbSpace?.Name])}</h4>
          {curbSpace && (
            <span className={styles.headerDescription}>
              {policy}
              {policy && curbSpace.state ? ' | ' : ''}
              <CurbSpaceOccupancyStatusText status={curbSpace.state?.Status} />
            </span>
          )}
          <PinButton className={styles.pinButton} pinned={curbSpaceData.pinnedPopup} onClick={pinPopup} />
        </div>

        <div className={`map-popup-body ${styles.mapPopupBody}`}>
          {curbSpace && (curbSpace.ParkingSensorId || hasTransaction) ? (
            <TabStrip onSelectTab={onTabSelect}>
              <TabStripTab title={localization.toLanguageStringF('common.generalInfo')}>
                <ContentPlaceholder loading={curbSpaceData.loading} element={<PopupBodyPlaceholder />}>
                  <div className={styles.tabContent}>
                    {generalContent}
                    {city?.Code === CityCode.Minneapolis ? <Sensor name={curbSpace.Name} /> : sensorContent}
                  </div>
                </ContentPlaceholder>
              </TabStripTab>
              <TabStripTab title={localization.toLanguageStringF('occupancySource.title')}>
                <ContentPlaceholder loading={curbSpaceData.loading} element={<PopupBodyPlaceholder />}>
                  <OccupancyPeriodTypeSelector spotId={curbSpaceData.id} report={curbSpace.occupancySourceReport} />
                </ContentPlaceholder>
              </TabStripTab>
            </TabStrip>
          ) : (
            generalContent
          )}

          <div className='map-popup-row more-info'>
            {curbSpaceData?.position && <ShowGoogleStreetViewLink coordinates={curbSpaceData.position} />}
            <Link onClick={moreInfo} disabled={selectedSidebar === SidebarName.curbSpace && currentCurbSpaceData?.id === curbSpace?.Id}>
              {localization.toLanguageStringF('common.moreInfo')}
            </Link>
          </div>
        </div>
      </>
    ),
    [
      curbSpace,
      localization,
      policy,
      curbSpaceData.pinnedPopup,
      curbSpaceData.loading,
      curbSpaceData.id,
      curbSpaceData.position,
      pinPopup,
      hasTransaction,
      onTabSelect,
      generalContent,
      city?.Code,
      sensorContent,
      moreInfo,
      selectedSidebar,
      currentCurbSpaceData?.id,
    ],
  );

  if (!curbSpaceData.openPopup || !curbSpaceData.position) {
    return null;
  }

  return (
    <React.StrictMode>
      <SelfOrientedPopup
        data={curbSpace}
        longitude={curbSpaceData.position[0]}
        latitude={curbSpaceData.position[1]}
        onClose={closePopup}
        closeOnClick={false}
        className='spot-popup'
        maxWidth='600px'
        initPosition={curbSpaceData.initPosition}
        onDragEnd={onDragEnd}
      >
        <PopupPlaceholder loading={curbSpaceData.loading}>{content}</PopupPlaceholder>
      </SelfOrientedPopup>
    </React.StrictMode>
  );
};

export const CurbSpacePopup: FC = () => {
  const data = useAppSelector(selectedCurbSpaces);
  const items = data.selected;

  return (
    <>
      {items.map((x) => (
        <PopupItem key={x.id} curbSpaceData={x} />
      ))}
    </>
  );
};
