import { IControl } from "mapbox-gl";

export class CustomCompositeControl implements IControl {
    constructor(private container: HTMLElement, private controls: IControl[], private position?: string) {
    }

    onAdd(map: mapboxgl.Map) {
        this.controls.forEach(c => this.container.prepend(c.onAdd(map)));
        return this.container;
    }

    onRemove(map: mapboxgl.Map) {
        this.controls.forEach(c => c.onRemove(map));
        this.container.parentNode && this.container.parentNode.removeChild(this.container);
    }

    getDefaultPosition() {
        return this.position || 'top-right';
    }
}