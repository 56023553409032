import { FunctionComponent, useState } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';

import { IParkingEvent } from '../../../../model';
import { dateUtils } from '../../../../utils';

import styles from './ParkingEventsList.module.scss';
import { ParkingEventsPopup } from './ParkingEventsPopup';
import playIcon from './snapshot-play-button.png';

interface Props {
  events: IParkingEvent[];
  openPopupOnSelect: boolean;
  activeEvent?: IParkingEvent;
  onEventSelect?: (event: IParkingEvent) => void;
  className?: string;
}

export const ParkingEventsList: FunctionComponent<Props> = ({ events, openPopupOnSelect, activeEvent, onEventSelect, className }) => {
  const [selectedEvent, setSelectedEvent] = useState<IParkingEvent | null>(activeEvent || null);
  const localization = useExtendedLocalization();

  const rowClick = (event: IParkingEvent) => {
    setSelectedEvent(event);
    if (onEventSelect) {
      onEventSelect(event);
    }
  };

  const popupClosed = () => setSelectedEvent(null);

  return (
    <>
      {openPopupOnSelect && selectedEvent && <ParkingEventsPopup events={events} activeEvent={selectedEvent} closed={popupClosed} />}
      <table className={`table table-borderless table-striped table-sm ${styles.eventsTable} ${className ?? ''}`}>
        <thead>
          <tr>
            <th></th>
            <th>{localization.toLanguageStringF('camera.sidebar.events.table.title.spot')}</th>
            <th>{localization.toLanguageStringF('camera.sidebar.events.table.title.timeIn')}</th>
            <th>{localization.toLanguageStringF('camera.sidebar.events.table.title.timeOut')}</th>
            <th>{localization.toLanguageStringF('camera.sidebar.events.table.title.type')}</th>
            <th>{localization.toLanguageStringF('camera.sidebar.events.table.title.event')}</th>
          </tr>
        </thead>
        <tbody>
          {events.map((item) => (
            <tr key={item.Id} onClick={() => rowClick(item)} className={`${item.Id === selectedEvent?.Id ? styles.Active : ''}`}>
              <td>
                <img src={playIcon} alt='Open Snapshot' className={styles.playButton}></img>
              </td>
              <td>{item.SpotId ? '#' + item.SpotId : 'N/A'}</td>
              <td>{dateUtils.toLocalUiString(item.Start)}</td>
              <td>{dateUtils.toLocalUiString(item.End)}</td>
              <td>{item.VehicleType}</td>
              <td title={localization.toLanguageStringF(`parkingEvent.lane.${item.EventType}.l`) || item.EventType}>
                {localization.toLanguageStringF(`parkingEvent.lane.${item.EventType}.s`)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
