import { FunctionComponent } from 'react';
import { OccupancyStatus, SpotOccupancyCalculator } from '../../../../../model';

import styles from './CurbSpacesStatusCount.module.scss';

interface Props {
  spotsStates: OccupancyStatus[];
  spotsCount?: number;
  className?: string;
}

export const CurbSpacesStatusCount: FunctionComponent<Props> = ({
  spotsStates,
  spotsCount,
  className,
}) => {
  const count = SpotOccupancyCalculator.getCountSpotStatuses(spotsStates, spotsCount);

  return (
    <div className={`${className || ''} ${styles.zoneSpotsStatus}`}>
      <span
        className={styles.separator}
        style={{ color: SpotOccupancyCalculator.getColor(OccupancyStatus.Vacant) }}
      >
        Vacant: {count.vacant}
      </span>
      <span
        className={styles.separator}
        style={{ color: SpotOccupancyCalculator.getColor(OccupancyStatus.Booked) }}
      >
        Booked: {count.booked}
      </span>
      <span
        className={styles.separator}
        style={{ color: SpotOccupancyCalculator.getColor(OccupancyStatus.Occupied) }}
      >
        Occupied: {count.occupied}
      </span>
      <span>Not Monitored: {count.notMonitored}</span>
    </div>
  );
};
