import { FC, useState } from 'react';
import { IOccupancySourceReport, OccupancySource } from '../../../../model';
import { HeatmapPeriod } from '../../../../model/api/heatmap-period';

import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ReportPeriodSelector } from '../report-period-selector/ReportPeriodSelector';
import { OccupancyPopupTab } from './OccupancyPopupTab';
import { OccupancySourceSelector } from '../occupancy-source-selector/OccupancySourceSelector';
import {
  heatmapsFilterActions,
  selectedBlockfacesActions,
  selectedCurbSpacesActions,
  selectedStudyAreasActions,
  selectedZonesActions,
  selectHeatmapFilters,
} from '../../../../features';

import styles from './OccupancyPeriodTypeSelector.module.scss';

interface IProps {
  zoneId?: number;
  blockfaceId?: number;
  studyAreaId?: number;
  spotId?: number;
  report: IOccupancySourceReport | null | undefined;
  onChange?: (change: { heatmapPeriod: HeatmapPeriod; period: [Date, Date]; source: OccupancySource }) => void;
}

export const OccupancyPeriodTypeSelector: FC<IProps> = ({ zoneId, blockfaceId, studyAreaId, spotId, report, onChange }) => {
  const localization = useExtendedLocalization();
  const dispatch = useAppDispatch();
  const heatmapFilter = useAppSelector(selectHeatmapFilters);
  const [datesPeriod, setDatesPeriod] = useState<{ heatmapPeriod: HeatmapPeriod; period: [Date, Date] } | null>(null);
  const [source, setSource] = useState(heatmapFilter.reportOccupancySource ?? heatmapFilter.heatmapOccupancySource);

  const onChangeDatesPeriod = (value: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => {
    setDatesPeriod(value);
    onChangeHandler(value, source);
  };

  const onChangeOccupancySource = (value: OccupancySource) => {
    setSource(value);
    dispatch(heatmapsFilterActions.setReportOccupancySource(value));
    onChangeHandler(datesPeriod, value);
  };

  const onChangeHandler = (datesPeriod: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] } | null, source: OccupancySource) => {
    if (!datesPeriod) {
      return;
    }

    if (onChange) {
      onChange({ ...datesPeriod, source });
      return;
    }

    const period = datesPeriod.heatmapPeriod.calcReportFilter(datesPeriod.period).period;
    if (zoneId !== undefined) {
      dispatch(selectedZonesActions.fetchOccupancySourceReport({ zoneId, period, occupancySource: source }));
    } else if (blockfaceId !== undefined) {
      dispatch(selectedBlockfacesActions.fetchOccupancySourceReport({ blockfaceId, period, occupancySource: source }));
    } else if (studyAreaId !== undefined) {
      dispatch(selectedStudyAreasActions.fetchOccupancySourceReport({ studyAreaId, period, occupancySource: source }));
    } else if (spotId !== undefined) {
      dispatch(selectedCurbSpacesActions.fetchOccupancySourceReport({ id: spotId, period, occupancySource: source }));
    }
  };

  return (
    <>
      <ReportPeriodSelector onChangeFilter={onChangeDatesPeriod}>
        <OccupancySourceSelector className={styles.occupancyType} value={source} onChange={onChangeOccupancySource} />
      </ReportPeriodSelector>
      {report ? <OccupancyPopupTab report={report} /> : localization.toLanguageStringF('common.noDataToDisplay')}
    </>
  );
};
