export class LedDecoderService {  

  public static hexToLed(hex: string): number {
    return parseInt(hex.replace('#', ''), 16);
  }

  public static rgbaToLed(rgba: string): number {
    const s = rgba.replace(/[^\d\s]/g, '').split(' ');
    return (parseInt(s[0]) << 16) + (parseInt(s[1]) << 8) + parseInt(s[2]);
  }

  public static isCustomLed(led: number): boolean {
    return !Object.values(LedColor).includes(led);
  }

  public static ledToRgba(led: number) {
    return `rgba(${(led & 0xff0000) >> 16}, ${(led & 0x00ff00) >> 8}, ${led & 0x0000ff}, 1)`;
  }

  public static ledToHex(led: number): string {
    return `#${led.toString(16).padStart(6, '0').toUpperCase()}`;
  }

  public static ledToEnum(led: number): LedColor {
    return Object.values(LedColor).includes(led) ? led as LedColor : LedColor.Custom;
  }
}

export enum LedColor {
  Parking = LedDecoderService.hexToLed('#00FF00'),
  NoParking = LedDecoderService.hexToLed('#FF0000'),
  Handicap = LedDecoderService.hexToLed('#0000FF'),
  Taxi = LedDecoderService.hexToLed('#FFFF00'),
  TNC = LedDecoderService.hexToLed('#A020F0'),
  Courier = LedDecoderService.hexToLed('#FF8C00'),
  Custom = -1
}


