import { FunctionComponent } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ITransaction } from '../../../../model/api/meter-transactions';
import { dateUtils } from '../../../../utils';
import styles from './MeterTransactionsTable.module.scss';

interface Props {
  className?: string;
  transactions: ITransaction[];
}

export const MeterTransactionsTable: FunctionComponent<Props> = ({ className, transactions }) => {
  const localization = useExtendedLocalization();

  return (
    <table className={`table table-borderless table-striped table-sm ${styles.transactionsTable} ${className || ''}`}>
      <thead>
        <tr>
          <th scope='col' style={{ width: '60px' }}>
            {localization.toLanguageStringF('meter.sidebar.transactions.table.title.dateAndTime')}
          </th>
          <th scope='col' style={{ width: '60px' }}>
            {localization.toLanguageStringF('meter.sidebar.transactions.table.title.paidBy')}
          </th>
          <th scope='col' style={{ width: '40px' }}>
            {localization.toLanguageStringF('meter.sidebar.transactions.table.title.event')}
          </th>
          <th scope='col' style={{ width: '40px' }}>
            {localization.toLanguageStringF('meter.sidebar.transactions.table.title.duration')}
          </th>
          <th scope='col' style={{ width: '40px' }}>
            {localization.toLanguageStringF('meter.sidebar.transactions.table.title.amount')}
          </th>
        </tr>
      </thead>
      <tbody>
        {transactions.map((item, index) => (
          <tr key={index}>
            <td>{dateUtils.toUiString(item.SessionStart)}</td>
            <td>{localization.toLanguageStringF(`meter.paymentType.${item.PaymentMethodId}`)}</td>
            <td title={localization.toLanguageStringF(`meter.eventType.${item.MeterEventTypeId}`)}>
              {localization.toLanguageStringF(`meter.eventTypeShort.${item.MeterEventTypeId}`)}
            </td>
            <td>{dateUtils.diffMinutes(item.SessionStart, item.SessionEnd) || '-'}</td>
            <td>{item.GrossPaid}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
