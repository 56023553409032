import { FunctionComponent, useEffect, useState } from 'react';
import { images } from '../../../services';
import styles from './ServerImg.module.scss';

interface Props {
  className?: string;
  url: string;
  useApi?: boolean;
  urlData?: string;
  alt?: string;
  showBackground?: boolean;
  onClick?: () => void;
  discriminator?: any;
}

export const ServerImg: FunctionComponent<Props> = ({
  className,
  url,
  useApi = true,
  urlData,  
  alt,
  onClick,  
  showBackground = true,
  discriminator
}) => {
  const [state, setState] = useState<string | undefined>(urlData);

  const handleImgClick = () => {
    if (onClick) {
      onClick();
    }
  };

  useEffect(() => {
    if (urlData ) {
      setState(urlData);
      return;
    }

    if (!url || !useApi) {
      return;
    }

    images
      .get(url)
      .then((imgBlob) => {
        if (imgBlob) {
          setState(URL.createObjectURL(imgBlob));
        }
      })
      .catch(console.error);
  }, [url, urlData, useApi, discriminator]);

  return (
    <div
      className={`${className || ''} ${styles.imgHolder} ${
        showBackground ? styles.background : ''
      }`}
    >
      {state && (
        <img
          className={`${styles.img}`}
          alt={alt ?? ''}
          src={state ? state : (url && !useApi ? url : undefined)}
          role='presentation'
          onClick={() => handleImgClick()}
        />
      )}
    </div>
  );
};
