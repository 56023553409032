import { Switch } from '@progress/kendo-react-inputs';
import { FunctionComponent, useMemo } from 'react';
import { customLayersActions, selectCustomLayers } from '../../../../features';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export const CustomLayersToggleSidebar: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const layers = useAppSelector(selectCustomLayers);

  const content = useMemo(() => {
    const keys = Object.keys(layers.layers);
    if (!keys.length) {
      return null;
    }

    return (
      <>
        {keys.map((x) => {
          const layer = layers.layers[x];
          return (
            <div key={layer.id} className='map-sidebar-row space-between'>
              <label key={`label-${layer.id}`}>{layer.name}</label>
              <Switch
                key={`switcher-${layer.id}`}
                checked={layer.visibility}
                onChange={(ev) => dispatch(customLayersActions.setLayerVisability({ id: layer.id, visibility: ev.value }))}
              />
            </div>
          );
        })}
        <div className='map-sidebar-row-border'></div>
      </>
    );
  }, [dispatch, layers.layers]);

  return content;
};
