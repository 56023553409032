import { Action } from '@reduxjs/toolkit';

import { StateObservable, combineEpics } from 'redux-observable';
import { Observable, catchError, concatMap, filter, from, map, mergeMap, of } from 'rxjs';
import { reports, zones } from '../../../../services';
import { RootState } from '../../../../store';
import { mapStateActions } from '../../map-state';
import { selectedZoneHeatmapsActions } from './selected-zone-heatmaps-slice';
import { citiesActions } from '../../../common';

const loadHeatmap = (id: number, state: RootState) => {
  const existing = state.selectedZoneHeatmaps.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return from(zones.get(id)).pipe(
    map((x) => ({
      zone: x,
    })),
  );
};

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((_) => selectedZoneHeatmapsActions.closePopup()),
  );

const zoneHeatmapSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedZoneHeatmapsActions.loadZoneHeatmap.match),
    concatMap((action) =>
      loadHeatmap(action.payload.id, state$.value).pipe(
        mergeMap((x) =>
          of(
            selectedZoneHeatmapsActions.loadZoneHeatmapSuccess({
              heatmap: x,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              position: action.payload.position!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              initPosition: action.payload.initPosition ? action.payload.initPosition : action.payload.position!,
            }),
          ),
        ),
        catchError((err) => of(selectedZoneHeatmapsActions.loadZoneHeatmapFailed(err.message))),
      ),
    ),
  );

const fetchDurationReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedZoneHeatmapsActions.fetchDurationReport.match),
    concatMap((action) => {
      return from(reports.getParkingDurationReport(action.payload.zoneId, action.payload.filter)).pipe(
        map((x) => selectedZoneHeatmapsActions.fetchDurationReportSuccess({ zoneId: action.payload.zoneId, report: x })),
        catchError((err) => of(selectedZoneHeatmapsActions.fetchReportFailed(err.message))),
      );
    }),
  );
};

const fetchZoneRevenueReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedZoneHeatmapsActions.fetchZoneRevenueReport.match),
    concatMap((action) => {
      return from(reports.getZoneRevenueReport(action.payload.zoneId, action.payload.filter)).pipe(
        map((x) => selectedZoneHeatmapsActions.fetchZoneRevenueReportSuccess({ zoneId: action.payload.zoneId, report: x })),
        catchError((err) => of(selectedZoneHeatmapsActions.fetchReportFailed(err.message))),
      );
    }),
  );
};

const fetchViolationReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedZoneHeatmapsActions.fetchViolationReport.match),
    concatMap((action) => {
      return from(reports.getZoneEnforcementReport(action.payload.zoneId, action.payload.filter)).pipe(
        map((x) => selectedZoneHeatmapsActions.fetchViolationReportSuccess({ zoneId: action.payload.zoneId, report: x })),
        catchError((err) => of(selectedZoneHeatmapsActions.fetchReportFailed(err.message))),
      );
    }),
  );
};

const fetchOccupancyTrafficReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedZoneHeatmapsActions.fetchOccupancyTrafficReport.match),
    concatMap((action) =>
      from(reports.getZoneOccupancyTrafficReport(action.payload.zoneId, action.payload.filter, action.payload.occupancySource)).pipe(
        map((x) => selectedZoneHeatmapsActions.fetchOccupancyTrafficReportSuccess({ zoneId: action.payload.zoneId, report: x })),
        catchError((err) => of(selectedZoneHeatmapsActions.fetchReportFailed(err.message))),
      ),
    ),
  );
};

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap(() => of(selectedZoneHeatmapsActions.collapsePopups())),
  );

export const heatmapEpic = combineEpics(
  zoneHeatmapSelectedEpic,
  fetchDurationReportEpic,
  fetchZoneRevenueReportEpic,
  fetchViolationReportEpic,
  closePopupsEpic,
  fetchOccupancyTrafficReportEpic,
  citySelectedEpic,
);
