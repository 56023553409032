import { IEntityBase } from './entity-base';

export enum OffstreetZoneLayerType {
  Garages = 'Garages',
  Lots = 'Lots',
}

export interface IOffstreetZone extends IEntityBase {
  Name: string;
  Address: string;
  Position: [number, number];
  AreaId?: number;
  VendorId: number | undefined;
  SpotsCount?: number;
  LocationId: string;
  Ownership?: string;
}

export interface IOffstreetZoneRevenueReportItem {
  CurrentTotal?: number;
  PreviousTotal?: number;
  TotalChange?: number;
  CurrentAverage?: number;
  PreviousAverage?: number;
  CurrentAverageChange?: number;
  CurrentDailyAverage?: number;
  PreviousDailyAverage?: number;
  DailyAverageChange?: number;
  YearlyAverage?: number;
  CurrentCount?: number;
  PreviousCount?: number;
  CountChange?: number;
  PaymentChannelRevenueChartData: Array<{ Id: number; Revenue: number }>;
  VendorRevenueChartData: Array<{ Name: string; Revenue: number }>;

  PaymentChannelCountChartData: Array<{ Id: number; Count: number }>;
  VendorCountChartData: Array<{ Name: string; Count: number }>;
}

export interface IOffstreetVendor {
  Id: number;
  Name: string;
  Count: number;
}

export interface IOffstreetZoneName extends IEntityBase {
  Name: string;
}

export interface IOffstreetOwnership {
  Name: string;
  Count: number;
}
