import React, { FC, FunctionComponent, useCallback, useMemo } from 'react';
import { PopupEvent } from 'react-map-gl';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectAreaTypes,
  selectedAreaActions,
  selectedAreas,
  selectedOffstreetZonesActions,
  selectedZonesActions,
  selectSidebarState,
  sidebarActions,
} from '../../../../features';
import { areaUtility } from './area-helpers';

import styles from './AreaPopup.module.scss';
import {
  Collapsible,
  Spinner,
  Link,
  SelfOrientedPopup,
  ContentPlaceholder,
  PopupHeaderPlaceholder,
  PinButton,
  PopupBodyPlaceholder,
} from '../../../common';
import { AreaIcon } from '../../icons';
import { ISelectedArea, SidebarName } from '../../../../model';
import { useOpenPopupDispatch } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { amplitudeService, ISelectedEntity, PopupType } from '../../../../services';
import { Position } from 'geojson';

export const PopupItem: FC<{ areaData: ISelectedEntity<ISelectedArea> }> = ({ areaData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const selectedSidebar = useAppSelector(selectSidebarState);
  const areaTypes = useAppSelector(selectAreaTypes);
  const area = areaData.entity;
  const localization = useExtendedLocalization();

  const closePopup = useCallback(
    (event: PopupEvent) => {
      dispatch(selectedAreaActions.closePopup(areaData.id));
    },
    [areaData.id, dispatch],
  );

  const pinPopup = useCallback(() => {
    dispatch(selectedAreaActions.pinPopup(areaData.id));
  }, [dispatch, areaData.id]);

  const subDistrictClick = useCallback(
    (areaId: number, typeId: number) => {
      popupDispatch(
        selectedAreaActions.loadArea({
          id: areaId,
          position: null,
        }),
      );
    },
    [popupDispatch],
  );

  const openZone = useCallback(
    (zoneId: number) => {
      popupDispatch(
        selectedZonesActions.loadZone({
          id: zoneId,
          position: null,
        }),
      );
    },
    [popupDispatch],
  );

  const openOffstreetZone = useCallback(
    (zoneId: number) => {
      popupDispatch(
        selectedOffstreetZonesActions.loadZone({
          id: zoneId,
          position: null,
        }),
      );
    },
    [popupDispatch],
  );

  const moreInfo = useCallback(() => {
    dispatch(selectedAreaActions.setCurrent(area));
    dispatch(sidebarActions.setSelected({ name: SidebarName.area }));
  }, [area, dispatch]);

  const onDragEnd = useCallback(
    (position: Position) => {
      if (!area) {
        return;
      }
      dispatch(selectedAreaActions.change({ id: area.Id, position: position }));
      amplitudeService.trackPopupMove(PopupType.Areas);
    },
    [dispatch, area],
  );

  const { areaCounts, isNotEmptyChildren, showSidebar } = areaUtility(area, areaTypes);

  const popupContent = useMemo(() => {
    const zoneNames = area?.zoneNames || [];
    const offstreetZoneNames = area?.offstreetZoneNames || [];

    return (
      <>
        <ContentPlaceholder loading={areaData.loading} element={<PopupHeaderPlaceholder />}>
          <div className={`map-popup-header ${styles.header}`}>
            <AreaIcon className='area-icon' />
            <h4>
              {area?.typeName}: {area?.Name}
            </h4>
            {isNotEmptyChildren && (
              <div className='header-description'>
                {areaCounts.map((area, idx) => (
                  <span key={idx}>
                    {areaTypes && areaTypes[area.typeId] ? areaTypes[area.typeId].PluralName : area.name}{' '}
                    {idx < areaTypes.length - 1 && ' |'}
                    {': ' + area.descendants.length}
                  </span>
                ))}
              </div>
            )}
            <PinButton className={styles.pinButton} pinned={areaData.pinnedPopup} onClick={pinPopup} />
          </div>
        </ContentPlaceholder>

        <div className='map-popup-body'>
          <ContentPlaceholder loading={areaData.loading} element={<PopupBodyPlaceholder />}>
            {isNotEmptyChildren &&
              areaCounts.map((area, index) => (
                <div key={index} className='map-popup-row'>
                  <Collapsible header={areaTypes && areaTypes[area.typeId] ? areaTypes[area.typeId].PluralName : ''} expanded={false}>
                    {area.descendants.map((child, idx) => (
                      <span key={idx} className='map-sidebar-row descendant-link'>
                        <Link onClick={() => subDistrictClick(child.Id, child.TypeId)} className={styles.listItem}>
                          {child?.Name}
                        </Link>
                      </span>
                    ))}
                  </Collapsible>
                </div>
              ))}

            {zoneNames.length > 0 && (
              <div className='map-popup-row'>
                <div className={`${styles.columnLabel} ${(isNotEmptyChildren && styles.addleftMargin) || ''}`}>
                  <label htmlFor='fff'>{localization.toLanguageStringF('area.popup.zones')}:</label>
                  <div className={styles.labelDescription}>
                    {zoneNames.length} {zoneNames.length === 1 ? 'item' : 'items'}
                  </div>
                </div>
                <div className='row-value' style={{ width: '100%' }}>
                  {zoneNames.slice(0, 5).map((zone, idx) => (
                    <Link key={idx} onClick={() => openZone(zone.Id)} className={styles.listItem}>
                      <span>{zone.Name}</span>
                      {idx < Math.min(zoneNames.length, 5) - 1 && ','}
                    </Link>
                  ))}
                  {zoneNames.length > 5 && <label htmlFor='fff'>...</label>}
                </div>
              </div>
            )}

            {offstreetZoneNames.length > 0 && (
              <div className='map-popup-row'>
                <div className={`column-label ${isNotEmptyChildren} ? 'add-left-margin' : ''`}>
                  <label htmlFor='fff'>{localization.toLanguageStringF('area.popup.offStreetParking')}:</label>
                  <div className='label-description'>
                    {offstreetZoneNames.length} {offstreetZoneNames.length === 1 ? 'item' : 'items'}
                  </div>
                </div>
                <div className='row-value' style={{ width: '100%' }}>
                  {offstreetZoneNames.slice(0, 5).map((zone, idx) => (
                    <Link key={idx} onClick={() => openOffstreetZone(zone.Id)} className={styles.listItem}>
                      <span>{zone.Name}</span>
                      {idx < Math.min(offstreetZoneNames.length, 5) - 1 && ','}
                    </Link>
                  ))}
                  {offstreetZoneNames.length > 5 && <label htmlFor='fff'>...</label>}
                </div>
              </div>
            )}

            {showSidebar ? (
              <div className='map-popup-row more-info'>
                <Link onClick={moreInfo} disabled={selectedSidebar === SidebarName.area}>
                  {localization.toLanguageStringF('common.moreInfo')}
                </Link>
              </div>
            ) : (
              <div className='map-popup-row no-data'>{localization.toLanguageStringF('area.popup.noSubareaAvailable')}</div>
            )}
          </ContentPlaceholder>
        </div>
      </>
    );
  }, [
    area?.Name,
    area?.offstreetZoneNames,
    area?.typeName,
    area?.zoneNames,
    areaCounts,
    areaData.loading,
    areaData.pinnedPopup,
    areaTypes,
    isNotEmptyChildren,
    localization,
    moreInfo,
    openOffstreetZone,
    openZone,
    pinPopup,
    selectedSidebar,
    showSidebar,
    subDistrictClick,
  ]);

  const content = useMemo(() => {
    if (!areaData.openPopup || !areaData.position) return null;

    return (
      <React.StrictMode>
        <SelfOrientedPopup
          data={area}
          maxWidth='496px'
          longitude={areaData.position[0]}
          latitude={areaData.position[1]}
          onClose={closePopup}
          closeOnClick={false}
          className='area-popup'
          initPosition={areaData.initPosition}
          onDragEnd={onDragEnd}
        >
          <div className={`${styles.popup}`}>
            {popupContent}
            {areaData.loading && <Spinner backdrop={true} />}
          </div>
        </SelfOrientedPopup>
      </React.StrictMode>
    );
  }, [area, areaData.initPosition, areaData.loading, areaData.openPopup, areaData.position, closePopup, onDragEnd, popupContent]);

  return content;
};

export const AreaPopup: FunctionComponent = () => {
  const areasData = useAppSelector(selectedAreas);
  const areas = areasData.selected;

  const content = useMemo(() => {
    return (
      <>
        {areas.map((x) => (
          <PopupItem key={x.id} areaData={x} />
        ))}
      </>
    );
  }, [areas]);

  return content;
};
