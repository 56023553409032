import { FC, useCallback } from 'react';
import { Layer, Source, MapLayerMouseEvent } from 'react-map-gl';
import { AnyLayout, AnyPaint, SymbolLayout, SymbolPaint } from 'mapbox-gl';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectBlockfacesGeo, selectBlockfacesLayer, selectedBlockfacesActions } from '../../../../features';
import { useMapLayerHover, useMapLayerPopup, useMapToolTip } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { BlockfacePopup } from './BlockfacePopup';
import { NavigationSource, PopupType, amplitudeService } from '../../../../services';

const Color = '#FE7A01';
const LinesLayer = 'blockfaceLinesLayer';
const PointsLayer = 'blockfacePointsLayer';
const CodesLayer = 'blockfaceCodesLayer';
const Layers = [PointsLayer, LinesLayer, CodesLayer];

const blockfaceLinesPaint: AnyPaint = {
  'line-color': Color,
  'line-width': {
    base: 6,
    stops: [
      [14, 8],
      [16, 10],
      [17, 12],
    ],
  },
};

const blockfacePointsPaint: AnyPaint = {
  'circle-radius': {
    base: 3,
    stops: [
      [16, 4],
      [17, 8],
      [19, 20],
      [20, 40],
      [21, 60],
    ],
  },
  'circle-color': Color,
};

const blockfaceCodesPaint: SymbolPaint = {
  'text-color': '#fff',
};

export const BlockfacesLayer: FC = () => {
  const dispatch = useAppDispatch();
  const localization = useExtendedLocalization();
  const geojson = useAppSelector(selectBlockfacesGeo);
  const layer = useAppSelector(selectBlockfacesLayer);

  const layout: AnyLayout = {
    visibility: layer.enabled ? 'visible' : 'none',
  };
  const blockfaceCodesLayout: SymbolLayout = {
    visibility: layer.enabled && layer.displayBlockfaceId ? 'visible' : 'none',
    'text-field': ['get', 'code'],
    'symbol-placement': 'line-center',
    'text-allow-overlap': true,
    'text-size': {
      base: 14,
      stops: [
        [15, 10],
        [16, 12],
        [17, 14],
      ],
    },
  };

  useMapToolTip(
    (event: MapLayerMouseEvent) => {
      const code = event.features && event.features.length > 0 ? event.features[0].properties?.code : '';
      return localization.toLanguageStringF('blockface.toolTip', [code]);
    },
    () => layer.enabled,
    ...Layers,
  );

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      const feature = evt.features ? evt.features[0] : null;
      if (!feature || !feature.properties) {
        return;
      }

      const id = feature.properties.id;
      const position = [evt.lngLat.lng, evt.lngLat.lat];
      setTimeout(() => {
        dispatch(selectedBlockfacesActions.loadBlockface({ id, position }));
        amplitudeService.trackPopupOpen(PopupType.Blockfaces, NavigationSource.Map);
      }, 10);
    },
    [dispatch],
  );

  useMapLayerPopup(handleLayerClick, ...Layers);

  useMapLayerHover(Layers);

  return (
    <>
      <Source id='blockfaces-source' type='geojson' data={geojson} generateId={true}>
        <Layer
          {...{
            id: LinesLayer,
            type: 'line',
            layout,
            paint: blockfaceLinesPaint,
            filter: ['==', '$type', 'LineString'],
          }}
        />
        <Layer
          {...{
            id: PointsLayer,
            type: 'circle',
            layout,
            paint: blockfacePointsPaint,
            filter: ['==', '$type', 'Point'],
          }}
        />

        <Layer
          {...{
            id: CodesLayer,
            type: 'symbol',
            layout: blockfaceCodesLayout,
            paint: blockfaceCodesPaint,
            before: 'building-extrusion',
            filter: ['all', ['has', 'code']],
          }}
        />
      </Source>

      <BlockfacePopup />
    </>
  );
};
