import { FC } from 'react';

import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';

import styles from './PerformanceParkingLabel.module.scss';

interface Props {
  className?: string;
}

export const PerformanceParkingLabel: FC<Props> = ({ className }) => {
  const localization = useExtendedLocalization();

  return (
    <div className={`${className || ''} ${styles.performanceParking}`}>
      <div className='k-icon k-i-info'></div>
      <div>{localization.toLanguageStringF('common.performanceParking')}</div>
    </div>
  );
};
