import { FC, useMemo } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { IRule } from '../../../../model';

export const PolicyRules: FC<{ rules: IRule[] | null; labelClass: string; textClass: string }> = ({ rules, labelClass, textClass }) => {
  const localization = useExtendedLocalization();

  const content = useMemo(
    () => (
      <>
        {rules?.map((x, index) => (
          <div key={`rule${index}`}>
            <div>
              <span className={labelClass}>{localization.toLanguageStringF('parkingPolicy.rule.activity')}</span>
              <span className={textClass}>{localization.toLanguageStringF(`parkingPolicy.activity.${x.Activity}`)}</span>
            </div>
            {x.MaxStay != null && (
              <div>
                <span className={labelClass}>{localization.toLanguageStringF('parkingPolicy.rule.maxStay')}</span>
                <span className={textClass}>
                  {x.MaxStay}&nbsp;{x.MaxStayUnit}
                </span>
              </div>
            )}
            {!!x.UserClasses?.length && (
              <div>
                <span className={labelClass}>{localization.toLanguageStringF('parkingPolicy.rule.use')}</span>
                <span className={textClass}>
                  {x.UserClasses.map((x) => localization.toLanguageStringF(`parkingPolicy.class.${x}`)).join(',')}
                </span>
              </div>
            )}
            {x.NoReturn != null && (
              <div>
                <span className={labelClass}>{localization.toLanguageStringF('parkingPolicy.rule.returnInterval')}</span>
                <span className={textClass}>
                  {x.NoReturn}&nbsp;{x.NoReturnUnit}
                </span>
              </div>
            )}
            {!!x.Rate?.length && (
              <div>
                <span className={labelClass}>{localization.toLanguageStringF('parkingPolicy.rule.rateTitle')}</span>
                {x.Rate.map((x, index) => (
                  <span key={`rate${index}`} className={textClass} style={index > 0 ? { display: 'block' } : {}}>
                    {!x.StartDuration
                      ? localization.toLanguageStringF(`parkingPolicy.rule.rate`, [
                          x.Rate * 0.01,
                          localization.toLanguageStringF(`parkingPolicy.unitOfTime.${x.RateUnit}`),
                        ])
                      : localization.toLanguageStringF(`parkingPolicy.rule.rateFrom`, [
                          x.Rate * 0.01,
                          x.StartDuration,
                          localization.toLanguageStringF(`parkingPolicy.unitOfTime.${x.RateUnit}`),
                        ])}
                  </span>
                ))}
              </div>
            )}
          </div>
        ))}
      </>
    ),
    [labelClass, localization, rules, textClass],
  );

  return content;
};
