/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

import { sidebarActions, selectedLoadingZonesActions, currentLoadingZone } from '../../../../features';
import { ISelectedLoadingZone, SidebarName, loadingZone } from '../../../../model';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { MapSidebarContainer } from '../../controls';
import { Link } from '../../../common';

import { CameraThumbnail } from '../cameras/camera-thumbnail/CameraThumbnail';
import { useOpenPopupDispatch } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { LoadingZoneQR } from './LoadingZoneQR';

import styles from './LoadingZoneSidebar.module.scss';
import { ISelectedEntity } from '../../../../services';

const SidebarRow: FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
  <div className='map-sidebar-row'>
    <label>{title}</label>
    <div>{children}</div>
  </div>
);

interface Props {
  zoneData: ISelectedEntity<ISelectedLoadingZone>;
}

const Sidebar: FC<Props> = ({ zoneData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const zone = zoneData.entity;
  const localization = useExtendedLocalization();

  const handleCloseClick = (ev: React.FormEvent) => {
    ev.preventDefault();
    dispatch(sidebarActions.clearSelected());
  };

  const mapViewClick = () => {
    popupDispatch(selectedLoadingZonesActions.openPopup(zoneData.id));
  };

  if (!zone) {
    return null;
  }

  const occupancy = loadingZone.occupancyCalculator(zone.SpotsCount, zone.OccupiedSpotsCount || 0);

  return (
    <div className='map-sidebar map-sidebar map-sidebar-info'>
      <div className='map-sidebar-header'>
        <h3>{localization.toLanguageStringF('loadingZone.sidebar.title', [zone?.Code])}</h3>
        <Button size='small' className='close' icon='close' onClick={handleCloseClick}></Button>
      </div>

      <div className='map-sidebar-body'>
        <PanelBar keepItemsMounted={true}>
          <PanelBarItem title={localization.toLanguageStringF('common.generalInfo')} expanded={true}>
            <div>
              <SidebarRow title={`${localization.toLanguageStringF('loadingZone.sidebar.address')}:`}>{zone.Address}</SidebarRow>
              <SidebarRow title='Vacant:'>{occupancy.vacantSpotsCount}</SidebarRow>
              <SidebarRow title='Occupied:'>{occupancy.occupiedSpotsCount}</SidebarRow>
              <SidebarRow title={`${localization.toLanguageStringF('common.parkingSpacesCount')}:`}>{zone.SpotsCount}</SidebarRow>
            </div>
            <div className='map-sidebar-row more-info'>
              <Link onClick={mapViewClick} className='more-info-button' disabled={zoneData.openPopup}>
                {localization.toLanguageStringF('common.mapView')}
              </Link>
            </div>
          </PanelBarItem>

          {zone.camerasStates.length > 0 && (
            <PanelBarItem title={localization.toLanguageStringF('loadingZone.sidebar.cameras')} expanded={false}>
              <div className={styles.wrapContainer}>
                {zone?.camerasStates.map((x) => {
                  return (
                    <div key={x.CameraId} className={styles.thumbnail}>
                      <CameraThumbnail state={x} thumbnailWidth={316} thumbnailHeight={198} />
                    </div>
                  );
                })}
              </div>
            </PanelBarItem>
          )}

          <PanelBarItem title='QR Code' expanded={false}>
            <div className={styles.wrapContainer}>
              <div className={styles.thumbnail}>
                <LoadingZoneQR zoneNumber={zone?.Code} height={200} />
              </div>
            </div>
          </PanelBarItem>
        </PanelBar>
      </div>
    </div>
  );
};

export const LoadingZoneSidebar: FC = () => {
  const current = useAppSelector(currentLoadingZone);

  return current?.entity ? (
    <MapSidebarContainer name={SidebarName.loadingZone}>
      <Sidebar zoneData={current} />
    </MapSidebarContainer>
  ) : null;
};
