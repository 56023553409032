/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useCallback, useState } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';

import {
  currentCamera,
  selectedCameras,
  selectedCamerasActions,
  selectedStudyAreasActions,
  selectSidebarState,
  sidebarActions,
} from '../../../../features';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ISelectedCamera, SidebarName } from '../../../../model';
import { dateUtils } from '../../../../utils';
import { Link, PinButton, PopupPlaceholder, SelfOrientedPopup } from '../../../common';
import { ShowGoogleStreetViewLink } from '../../common/show-google-street-view-link/ShowGoogleStreetViewLink';
import { ImgVideoPlayer } from '../../../common/img-video-player/ImgVideoPlayer';
import { CameraStatusIcon } from '../../icons';
import { CurbSpacesList } from '../curb-spaces/curb-spaces-list/CurbSpacesList';
import { CameraStatusText } from './camera-status-text/CameraStatusText';
import { amplitudeService, ISelectedEntity, NavigationSource, PopupType } from '../../../../services';
import { Position } from 'geojson';
import { useOpenPopupDispatch } from '../../../../hooks';

import styles from './CameraPopup.module.scss';

const minWidth = 590;
const maxWidth = 950;
const widthStep = (maxWidth - minWidth) / 3;
const widthStep1 = minWidth + widthStep;
const widthStep2 = widthStep1 + widthStep;

const PopupItem: FC<{ cameraData: ISelectedEntity<ISelectedCamera, string> }> = ({ cameraData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const selectedSidebar = useAppSelector(selectSidebarState);
  const currentCameraData = useAppSelector(currentCamera);
  const camera = cameraData.entity;
  const localization = useExtendedLocalization();

  const [step, setStep] = useState<string | null>(null);
  const [width, setWidth] = useState(690);
  const [height, setHeight] = useState(460);
  const onResize = useCallback((event: React.SyntheticEvent, data: ResizeCallbackData) => {
    setWidth(data.size.width);
    setHeight(data.size.height);

    if (data.size.width >= minWidth && data.size.width <= widthStep1) {
      setStep('step1');
    } else if (data.size.width >= widthStep1 && data.size.width <= widthStep2) {
      setStep('step2');
    } else if (data.size.width >= widthStep2 && data.size.width <= maxWidth) {
      setStep('step3');
    } else {
      setStep(null);
    }
  }, []);

  const openStudyArea = useCallback(
    (studyAreaId: number) => {
      popupDispatch(selectedStudyAreasActions.loadStudyArea({ id: studyAreaId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Study, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  if (!cameraData.openPopup || !cameraData.position) return null;

  const closePopup = () => dispatch(selectedCamerasActions.closePopup(cameraData.id));

  const pinPopup = () => {
    dispatch(selectedCamerasActions.setCurrent(camera));
    dispatch(selectedCamerasActions.pinPopup(cameraData.id));
  };

  const moreInfo = () => {
    dispatch(selectedCamerasActions.setCurrent(camera));
    dispatch(sidebarActions.setSelected({ name: SidebarName.camera, data: camera?.Id }));
  };

  const onDragEnd = (position: Position) => {
    if (!camera) {
      return;
    }
    dispatch(selectedCamerasActions.change({ id: camera.Id, position: position }));
    amplitudeService.trackPopupMove(PopupType.Cameras);
  };

  const content = (
    <>
      <div className={`map-popup-header ${styles.header}`}>
        <CameraStatusIcon status={camera?.state?.Status} className={styles.icon} />
        <h4>{localization.toLanguageStringF('camera.popup.title', [camera?.Name])}</h4>
        <div className={styles.headerDescription}>
          <CameraStatusText status={camera?.state?.Status} />
        </div>
        <PinButton className={styles.pinButton} pinned={cameraData.pinnedPopup} onClick={pinPopup} />
      </div>

      <div className='map-popup-body'>
        <div className={styles.cameraRows}>
          <div className={`${styles.leftColumn} ${step}`}>
            <ImgVideoPlayer videoUrl={camera?.VideoUrl} className={styles.videoPlayer} />
            <div className={styles.picInfo}>
              {localization.toLanguageStringF('common.lastUpdate')}: {dateUtils.toLocalUiString(camera?.state?.LastUpdated)}
            </div>
          </div>

          <div className={`${styles.rightColumn} ${step}`}>
            <div className={`map-popup-row ${styles.mapPopupRow}`}>
              <label>{localization.toLanguageStringF('common.address')}:</label>
              <div className='row-value'>{camera?.Address}</div>
            </div>

            {camera && camera.studyAreas.length > 0 && (
              <div className={`map-popup-row ${styles.mapPopupRow}`}>
                <label>{localization.toLanguageStringF('camera.studyAreas')}:</label>
                <div className='row-value'>
                  {camera.studyAreas.map((x, idx) => (
                    <Link key={x.Id} onClick={() => openStudyArea(x.Id)} className={styles.listItem}>
                      <span>{x.Name}</span>
                      {idx < camera.studyAreas.length - 1 && ','}
                    </Link>
                  ))}
                </div>
              </div>
            )}

            <div className={`map-popup-row ${styles.mapPopupRow}`}>
              <div>
                <label>{localization.toLanguageStringF('camera.popup.curbSpaces')}:</label>
                <div className={styles.spotsNumber}>{localization.toLanguageStringF('camera.popup.items', [camera?.SpotIds?.length])}</div>
              </div>
              <div className={`row-value ${styles.rowValue}`}>
                <CurbSpacesList spotsStates={camera?.spotsStates || []} showIndex={true} />
              </div>
            </div>
          </div>
        </div>

        <div className='map-popup-row more-info'>
          {cameraData?.position && <ShowGoogleStreetViewLink coordinates={cameraData.position} />}
          <Link onClick={moreInfo} disabled={selectedSidebar === SidebarName.camera && currentCameraData?.id === camera?.Id}>
            {localization.toLanguageStringF('common.moreInfo')}
          </Link>
        </div>
      </div>
    </>
  );

  return (
    <React.StrictMode>
      <SelfOrientedPopup
        data={camera}
        longitude={cameraData.position[0]}
        latitude={cameraData.position[1]}
        onClose={closePopup}
        closeOnClick={false}
        maxWidth='none'
        className='camera-popup'
        initPosition={cameraData.initPosition}
        onDragEnd={onDragEnd}
      >
        <Resizable
          height={height}
          width={width}
          minConstraints={[minWidth, 393]}
          maxConstraints={[maxWidth, 633]}
          lockAspectRatio={true}
          onResize={onResize}
          className={styles.popup}
        >
          <div
            style={{
              height: height,
              width: width,
            }}
          >
            <PopupPlaceholder loading={cameraData.loading}>{content}</PopupPlaceholder>
          </div>
        </Resizable>
      </SelfOrientedPopup>
    </React.StrictMode>
  );
};

export const CameraPopup: FC = () => {
  const data = useAppSelector(selectedCameras);
  const items = data.selected;

  return (
    <>
      {items.map((x) => (
        <PopupItem key={x.id} cameraData={x} />
      ))}
    </>
  );
};
