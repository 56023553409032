import { AnyLayout, FillPaint, LinePaint, MapLayerMouseEvent, SymbolLayout, SymbolPaint } from 'mapbox-gl';
import { FC, useCallback, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { selectHeatmapStudyAreasGeo, selectStudyAreasGeo, selectStudyAreasLayer, selectedStudyAreasActions } from '../../../../features';
import { useMapLayerHover, useMapLayerPopup, useMapToolTip } from '../../../../hooks';
import { NavigationSource, PopupType, amplitudeService } from '../../../../services';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { StudyAreaPopup } from './StudyAreaPopup';

const layerName = 'study-areas-layer-polygons';

const polygonsPaint: FillPaint = {
  'fill-color': '#670D92',
  'fill-opacity': ['match', ['get', 'primary'], 0, 0.2, 0.3],
};

const linesPaint: LinePaint = {
  'line-color': '#670D92',
  'line-opacity': 0.8,
  'line-dasharray': ['match', ['get', 'primary'], 0, ['literal', [6, 6]], ['literal', []]],
  'line-width': {
    base: 2,
    stops: [
      [15, 2],
      [16, 3],
      [17, 4],
      [18, 5],
    ],
  },
};

const namesLayout: SymbolLayout = {
  'text-field': ['get', 'name'],
  'symbol-placement': 'line-center',
  'text-allow-overlap': true,
  'text-size': {
    base: 14,
    stops: [
      [15, 10],
      [16, 12],
      [17, 14],
    ],
  },
};

const studyAreaNames: SymbolPaint = {
  'text-color': '#000',
};

export const StudyAreasLayer: FC = () => {
  const dispatch = useAppDispatch();
  const layer = useAppSelector(selectStudyAreasLayer);
  const geoJson = useAppSelector(selectStudyAreasGeo);
  const centeredStudyAreaLineGeo = useAppSelector(selectHeatmapStudyAreasGeo);
  const filteredGeoJson = useMemo(() => {
    return {
      ...geoJson,
      features: !layer.selectedStudyAreas.length
        ? geoJson.features
        : geoJson.features.filter((x) => layer.selectedStudyAreas.some((y) => y === x.properties?.id)),
    };
  }, [geoJson, layer.selectedStudyAreas]);

  const filteredCenteredStudyAreaLineGeo = useMemo(() => {
    return {
      ...centeredStudyAreaLineGeo,
      features: !layer.selectedStudyAreas.length
        ? centeredStudyAreaLineGeo.features
        : centeredStudyAreaLineGeo.features.filter((x) => layer.selectedStudyAreas.some((y) => y === x.properties?.id)),
    };
  }, [centeredStudyAreaLineGeo, layer.selectedStudyAreas]);

  const layout: AnyLayout = {
    visibility: layer.enabled ? 'visible' : 'none',
  };

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      const feature = evt.features ? evt.features[0] : null;
      if (!feature || !feature.properties) {
        return;
      }

      const id = feature.properties.id;
      const position = [evt.lngLat.lng, evt.lngLat.lat];
      setTimeout(() => {
        dispatch(selectedStudyAreasActions.loadStudyArea({ id: id, position: position }));
        amplitudeService.trackPopupOpen(PopupType.Study, NavigationSource.Map);
      }, 10);
    },
    [dispatch],
  );

  useMapToolTip(
    (event: MapLayerMouseEvent) => (event.features && event.features.length > 0 ? event.features[0].properties?.name : null),
    () => layer.enabled,
    layerName,
  );

  useMapLayerHover([layerName]);

  useMapLayerPopup(handleLayerClick, layerName);

  return layer.enabled ? (
    <>
      <Source id='study-areas-source' type='geojson' data={filteredGeoJson} generateId={true}>
        <Layer
          {...{
            id: layerName,
            type: 'fill',
            layout: layout,
            paint: polygonsPaint,
            before: 'building-extrusion',
          }}
        />
        <Layer
          {...{
            id: 'study-areas-layer-lines',
            type: 'line',
            layout: layout,
            paint: linesPaint,
            before: 'building-extrusion',
          }}
        />
      </Source>

      <Source id='study-areas-names-source' type='geojson' data={filteredCenteredStudyAreaLineGeo} generateId={true}>
        <Layer
          {...{
            id: 'study-areas-names-layer',
            type: 'symbol',
            layout: namesLayout,
            paint: studyAreaNames,
            before: 'building-extrusion',
          }}
        />
      </Source>

      <StudyAreaPopup />
    </>
  ) : null;
};
