import { FunctionComponent, useState, useEffect, useMemo } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { ScrollView } from '@progress/kendo-react-scrollview';
import { of, zip, from } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IParkingEvent, ISpotState } from '../../../../model';
import { dateUtils } from '../../../../utils';
import { ParkingEventsList } from './ParkingEventsList';
import { images, spots } from '../../../../services/api';
import { CurbSpacesList } from '../../layers/curb-spaces/curb-spaces-list/CurbSpacesList';
import { Link, ModalDialog, Spinner } from '../../../common';
import { useOpenPopupDispatch } from '../../../../hooks';
import { selectedCamerasActions, selectedCurbSpacesActions } from '../../../../features';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { NavigationSource, PopupType, amplitudeService } from '../../../../services';

import styles from './ParkingEventsPopup.module.scss';

const EventRow: FunctionComponent<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
  <div className={`map-sidebar-row ${styles.mapSidebarRow}`}>
    <label>{title}</label>
    <div>{children}</div>
  </div>
);

interface Props {
  events: IParkingEvent[];
  activeEvent: IParkingEvent;
  closed?: () => void;
}

interface State {
  blobs: string[];
  loading: boolean;
  event: IParkingEvent;
}

export const ParkingEventsPopup: FunctionComponent<Props> = ({ events, activeEvent, closed }) => {
  const popupDispatch = useOpenPopupDispatch();
  const localization = useExtendedLocalization();

  const [state, setState] = useState<State>({
    blobs: [],
    loading: true,
    event: activeEvent,
  });
  const [spotStatus, setSpotStatus] = useState<ISpotState | null>();

  const event = state.event;

  useEffect(() => {
    const directions = event.End ? ['in', 'out'] : ['in'];

    const subscription = zip(
      event.SpotId ? from(spots.getSpotState(event.SpotId)) : of([]),
      ...directions.map((x) => from(images.get(`/ParkingEvent/${event.Id}/snapshot/${x}`))),
    )
      .pipe(
        tap((res) => {
          setSpotStatus(res[0].length ? res[0][0] : null);
          const blobs: string[] = [];
          if (res[1]) {
            blobs.push(URL.createObjectURL(res[1]));
            if (res[2]) {
              blobs.push(URL.createObjectURL(res[2]));
            }
          }
          setState((prevState) => ({ ...prevState, blobs: blobs, loading: false }));
        }),
      )
      .subscribe();

    return () => subscription.unsubscribe();
  }, [event]);

  const content = useMemo(() => {
    const close = () => {
      if (closed) {
        closed();
      }
    };

    const openCameraPopup = () => {
      popupDispatch(selectedCamerasActions.loadCamera({ id: event.CameraId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Cameras, NavigationSource.Navigation);
      close();
    };

    const openCurbSpace = (id: number) => {
      popupDispatch(selectedCurbSpacesActions.load({ id: id, position: null }));
      amplitudeService.trackPopupOpen(PopupType.CurbSpaces, NavigationSource.Navigation);
      close();
    };

    const carousel = (
      <ScrollView
        className={styles.scrollView}
        automaticViewChange={false}
        arrows={state.blobs.length > 1}
        pageable={state.blobs.length > 1}
      >
        {state.blobs.map((x, index) => (
          <div key={`${event.Id}_${index}`} className={styles.carouselItem}>
            <label>
              {index === 0
                ? localization.toLanguageStringF('camera.sidebar.events.popup.parkIn')
                : localization.toLanguageStringF('camera.sidebar.events.popup.parkOut')}
            </label>
            <img
              src={x}
              alt={localization.toLanguageStringF('camera.sidebar.events.popup.parkingEvent')}
              onLoad={(e) => URL.revokeObjectURL(e.currentTarget.src)}
            />
          </div>
        ))}
      </ScrollView>
    );

    return (
      <ModalDialog
        className={styles.eventPopup}
        onClose={close}
        width={1255}
        height={535}
        contentStyle={{ padding: 0, position: 'relative', overflow: 'hidden' }}
      >
        <Button className={styles.closeButton} icon='close' onClick={close}></Button>
        <div className={styles.container}>
          <div className={styles.left}>
            <div className={styles.carouselContainer}>{state.loading ? <Spinner /> : carousel}</div>
          </div>

          <div className={`map-sidebar-info ${styles.right}`}>
            <h4>{localization.toLanguageStringF('camera.sidebar.events.popup.snapshotEvent')}</h4>
            <EventRow title={localization.toLanguageStringF('camera.sidebar.events.popup.camera')}>
              <Link onClick={openCameraPopup}>{event.CameraId}</Link>
            </EventRow>
            {spotStatus && (
              <EventRow title={localization.toLanguageStringF('camera.sidebar.events.popup.spot')}>
                <CurbSpacesList spotsStates={[spotStatus]} onClick={openCurbSpace} />
              </EventRow>
            )}
            <EventRow title={localization.toLanguageStringF('camera.sidebar.events.popup.vehicleType')}>{event.VehicleType}</EventRow>
            {event.Start && (
              <EventRow title={localization.toLanguageStringF('camera.sidebar.events.popup.timeIn')}>
                {dateUtils.toLocalUiString(event.Start)}
              </EventRow>
            )}
            {event.End && (
              <EventRow title={localization.toLanguageStringF('camera.sidebar.events.popup.timeOut')}>
                {dateUtils.toLocalUiString(event.End)}
              </EventRow>
            )}
            {event.Start && event.End && (
              <EventRow title={localization.toLanguageStringF('camera.sidebar.events.popup.duration')}>
                {dateUtils.diffMinutes(event.Start, event.End)}
              </EventRow>
            )}
            <EventRow title={localization.toLanguageStringF('camera.sidebar.events.popup.event')}>
              {localization.toLanguageStringF(`parkingEvent.lane.${event.EventType}.l`) || event.EventType}
            </EventRow>
            {spotStatus?.CompanyName && (
              <EventRow title={localization.toLanguageStringF('camera.sidebar.events.popup.company')}>{spotStatus.CompanyName}</EventRow>
            )}

            <h4>{localization.toLanguageStringF('camera.sidebar.events.popup.events')}</h4>
            <ParkingEventsList
              events={events}
              activeEvent={event}
              openPopupOnSelect={false}
              onEventSelect={(e) => setState({ event: e, loading: true, blobs: [] })}
            />
          </div>
        </div>
      </ModalDialog>
    );
  }, [closed, event, events, localization, popupDispatch, spotStatus, state.blobs, state.loading]);

  return content;
};
