import { FunctionComponent, useEffect, useState } from 'react';
import { useExtendedLocalization } from '../../../../../hooks/use-extended-localization-service';
import { IViolationAmount, IViolationInfo, VIOLATION_GROUP_ALL } from '../../../../../model';
import { HeatmapPeriod } from '../../../../../model/api/heatmap-period';
import { useAppSelector } from '../../../../../store/hooks';
import { PeriodChartInfo } from '../period-chart-info/PeriodChartInfo';
import { selectHeatmapFilters } from '../../../../../features';

interface Props {
  period: HeatmapPeriod;
  violation: IViolationInfo;
  valueSelector: (violation: IViolationAmount) => number;
  selectedGroupId?: number;
  className?: string;
}

export const PeriodViolationsChartInfo: FunctionComponent<Props> = ({ period, violation, valueSelector, className, selectedGroupId }) => {
  const localization = useExtendedLocalization();
  const [series, setSeries] = useState<Array<{ name: string; value: number }>>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>();
  const heatmapsFilter = useAppSelector(selectHeatmapFilters);

  useEffect(() => {
    const groups = (heatmapsFilter.enforcementGroups || [])
      .filter((x) => x.Id !== VIOLATION_GROUP_ALL)
      .map((x) => ({
        id: x.Id,
        name: localization.toLanguageString(`common.violationGroup.${x.Name}`, x.Name),
      }));

    const data = violation?.Amounts || [];

    const seriesData = data
      .filter((x) => valueSelector(x) > 0)
      .map((x) => {
        const group = groups.find((i) => i.id === x.GroupId);
        return { name: group?.name || '', value: valueSelector(x), explode: group?.id === selectedGroupId };
      });

    setSeries(seriesData);
    if (selectedGroupId !== undefined) {
      setSelectedGroup(groups.find((x) => x.id === selectedGroupId)?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localization, heatmapsFilter.enforcementGroups, selectedGroupId, violation, period]);

  return (
    <PeriodChartInfo
      title={localization.toLanguageStringF('period-info.byType')}
      className={className}
      series={series}
      selectedGroup={selectedGroup}
      type='pie'
    />
  );
};
