/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC } from 'react';
import { PopupEvent } from 'react-map-gl';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectedStreetSigns, selectedStreetSignsActions } from '../../../../features';

import styles from './StreetSignPopup.module.scss';
import { StreetObjectIcon, StreetSignIcon } from '../../icons';
import { ISelectedStreetSign, signObjectType } from '../../../../model';
import { GeoCoordinatesText, PinButton } from '../../../common';
import { dateUtils } from '../../../../utils';
import { HtmlLink } from '../../../common/link/HtmlLink';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { SelfOrientedPopup } from '../../../common/self-oriented-popup/SelfOrientedPopup';
import { ISelectedEntity, PopupType, amplitudeService } from '../../../../services';
import { Position } from 'geojson';
import { ShowGoogleStreetViewLink } from '../../common/show-google-street-view-link/ShowGoogleStreetViewLink';

export const PopupItem: FC<{ signData: ISelectedEntity<ISelectedStreetSign, string> }> = ({ signData }) => {
  const dispatch = useAppDispatch();
  // const signData = useAppSelector(selectedStreetSign);
  const sign = signData.entity;
  const localization = useExtendedLocalization();

  const closePopup = (event: PopupEvent) => {
    dispatch(selectedStreetSignsActions.closePopup(signData.id));
  };

  const pinPopup = () => {
    dispatch(selectedStreetSignsActions.pinPopup(signData.id));
  };

  const onDragEnd = (position: Position) => {
    if (!signData) {
      return;
    }
    dispatch(selectedStreetSignsActions.change({ id: signData.id, position: position }));
    amplitudeService.trackPopupMove(PopupType.StreetObjects);
  };

  if (!signData.openPopup || !signData.position || !sign) return null;

  const content = (
    <>
      <div className={`map-popup-header ${styles.header}`}>
        <StreetObjectIcon className={styles.icon} type={signObjectType(sign?.Code)} />
        <h4>{localization.toLanguageStringF('streetObject.popup.title', [sign?.Description])}</h4>
        <div className={styles.headerDescription}>{sign?.Code}</div>
        <PinButton className={styles.pinButton} pinned={signData.pinnedPopup} onClick={pinPopup} />
      </div>

      <div className='map-popup-body'>
        <div className={styles.columns}>
          <div>
            <div className='map-popup-row'>
              <label>{localization.toLanguageStringF('streetObject.popup.direction')}:</label>
              <div className='row-value'>{sign?.Direction}</div>
            </div>

            <div className='map-popup-row'>
              <label>{localization.toLanguageStringF('common.geo')}:</label>
              <div className='row-value'>
                <GeoCoordinatesText coordinates={sign?.Position} />
              </div>
            </div>

            <div className='map-popup-row'>
              <label>{localization.toLanguageStringF('streetObject.popup.id')}:</label>
              <div className='row-value'>{sign?.Id}</div>
            </div>

            <div className='map-popup-row'>
              <label>{localization.toLanguageStringF('streetObject.popup.collectionDate')}:</label>
              <div className='row-value'>{dateUtils.toLocalUiString(sign?.CollectionDate)}</div>
            </div>

            <div className='map-popup-row'>
              <label>{localization.toLanguageStringF('streetObject.popup.actualImage')}:</label>
              <div className='row-value'>
                <HtmlLink href={sign?.ImageUrl} target='_blank'>
                  {localization.toLanguageStringF('common.view')}
                </HtmlLink>
              </div>
            </div>
          </div>

          <StreetSignIcon className={styles.signIcon} code={sign?.Code} />
        </div>

        <div className='map-popup-row more-info'>
          {signData?.position && <ShowGoogleStreetViewLink coordinates={signData.position} />}
        </div>
      </div>
    </>
  );

  return (
    <React.StrictMode>
      <SelfOrientedPopup
        data={sign}
        longitude={signData.position[0]}
        latitude={signData.position[1]}
        onClose={closePopup}
        closeOnClick={false}
        className='street-sign-popup'
        maxWidth='540px'
        initPosition={signData.initPosition}
        onDragEnd={onDragEnd}
      >
        <div className={`${styles.popup}`}>{content}</div>
      </SelfOrientedPopup>
    </React.StrictMode>
  );
};

export const StreetSignPopup: FC = () => {
  const signsData = useAppSelector(selectedStreetSigns);
  const signs = signsData.selected;

  return (
    <>
      {signs.map((x) => (
        <PopupItem key={x.id} signData={x} />
      ))}
    </>
  );
};
