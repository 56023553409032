export const palette: (number | string)[] = [
  -1,
  'rgba(124, 124, 124, 0.5)',
  0,
  'hsl(355, 70%, 22%)',
  0.35,
  'hsl(355, 100%, 37%)',
  0.5,
  'hsl(30, 100%, 42%)',
  1,
  'hsl(145, 95%, 30%)',
];
