import { AnyLayout, CirclePaint, SymbolPaint } from 'mapbox-gl';
import { FunctionComponent, useCallback } from 'react';
import { Layer, MapLayerMouseEvent, Source } from 'react-map-gl';

import {
  selectCurbSpacesEnabled,
  selectCurbSpacesGeo,
  selectCurbSpacesSensorsCount,
  selectedCurbSpacesActions,
} from '../../../../features';
import { useMapLayerHover, useMapLayerPopup, useMapToolTip } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { NavigationSource, PopupType, amplitudeService } from '../../../../services';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { MapImage } from '../../controls';
import { CurbSpacePopup } from './CurbSpacePopup';

const getOffsetLiteral = (direction: string | null = null, offset = 2): mapboxgl.Expression => {
  let base: [number, number] = [0, 0];
  switch (direction) {
    case 'left':
      base = [-offset, 0];
      break;
    case 'right':
      base = [offset, 0];
      break;
    case 'top':
      base = [0, -offset];
      break;
    case 'bottom':
      base = [0, offset];
      break;
  }

  return ['interpolate', ['linear'], ['zoom'], 14, ['literal', [base[0] / 3, base[1] / 3]], 18, ['literal', [base[0] * 6, base[1] * 6]]];
};

const getSpotsPaint = (direction: string | null = null): CirclePaint => {
  return {
    'circle-radius': {
      base: 2,
      stops: [
        [16, 4],
        [17, 8],
        [19, 20],
        [20, 40],
        [21, 60],
      ],
    },
    'circle-color': ['match', ['get', 'status'], 'Vacant', '#00B62D', 'Occupied', 'red', 'Booked', '#cccc00', /* other */ '#262626'],
    'circle-opacity': 0.3,
    'circle-translate': getOffsetLiteral(direction),
  };
};

const spotsPaint = getSpotsPaint();
const spotsPaintLeft = getSpotsPaint('left');
const spotsPaintRight = getSpotsPaint('right');
const spotsPaintTop = getSpotsPaint('top');
const spotsPaintBottom = getSpotsPaint('bottom');

const getSensorsPaint = (direction: string | null = null): SymbolPaint => {
  return {
    'icon-translate': getOffsetLiteral(direction),
  };
};

const sensorsPaint = getSensorsPaint();
const sensorsPaintLeft = getSensorsPaint('left');
const sensorsPaintRight = getSensorsPaint('right');
const sensorsPaintTop = getSensorsPaint('top');
const sensorsPaintBottom = getSensorsPaint('bottom');

const spotsLogosPaint = {
  'text-color': 'black',
};

const layer = 'spots-layer';
const layerLeft = 'spots-layer-left';
const layerRight = 'spots-layer-right';
const layerTop = 'spots-layer-top';
const layerBottom = 'spots-layer-bottom';
const layerLogo = 'spots-layer-logos';
const layers = [layer, layerLeft, layerRight, layerTop, layerBottom];

export const CurbSpacesLayer: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const geojson = useAppSelector(selectCurbSpacesGeo);
  const layerEnabled = useAppSelector(selectCurbSpacesEnabled);
  const sensorsCount = useAppSelector(selectCurbSpacesSensorsCount);

  const localization = useExtendedLocalization();
  useMapToolTip(
    (e) => {
      const companyName = e?.features?.length ? e.features[0].properties?.companyName : undefined;
      return companyName ?? localization.toLanguageStringF('curbSpace.toolTip');
    },
    () => layerEnabled,
    ...layers,
  );

  const layout: AnyLayout = {
    visibility: layerEnabled ? 'visible' : 'none',
  };
  const logosLayout: AnyLayout = {
    visibility: layerEnabled ? 'visible' : 'none',
    'icon-image': [
      'match',
      ['get', 'companyName'],
      'Aramark',
      'logo-aramac',
      'FedEx',
      'logo-fedex',
      'UPS',
      'logo-ups',
      'USPS',
      'logo-usps',
      'Amazon',
      'logo-amazon',
      'OnTrac',
      'logo-ontrac',
      '',
    ],
    'icon-allow-overlap': true,
    'icon-size': {
      base: 0.2,
      stops: [
        [16, 0.4],
        [18, 0.6],
        [20, 1.0],
        [21, 1.6],
      ],
    },
    'icon-offset': [15, -10],
  };

  const sensorLayour: AnyLayout = {
    visibility: layerEnabled && sensorsCount > 0 ? 'visible' : 'none',
    'icon-image': 'spot-sensor',
    'icon-allow-overlap': true,
    'icon-size': {
      base: 0.8,
      stops: [
        [14, 0.05],
        [15, 0.1],
        [16, 0.4],
        [17, 0.6],
        [18, 0.8],
      ],
    },
  };

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      dispatch(selectedCurbSpacesActions.closePopup());
      const feature = evt.features ? evt.features[0] : null;
      if (!feature || !feature.properties) {
        return;
      }

      const id = feature.properties.id;
      const position = [evt.lngLat.lng, evt.lngLat.lat];
      setTimeout(() => {
        dispatch(
          selectedCurbSpacesActions.load({
            id,
            position,
          }),
        );
        amplitudeService.trackPopupOpen(PopupType.CurbSpaces, NavigationSource.Map);
      }, 10);
    },
    [dispatch],
  );

  useMapLayerPopup(handleLayerClick, ...layers);

  useMapLayerHover(layers);

  const getSpotsLayer = (id: string, paint: CirclePaint, offset: string) => (
    <Layer
      {...{
        id: id,
        type: 'circle',
        layout,
        paint,
        filter: ['==', 'offset', offset],
        before: 'building-extrusion',
      }}
    />
  );

  const getSensorsLayer = (id: string, paint: SymbolPaint, offset: string) => (
    <Layer
      {...{
        id: id,
        type: 'symbol',
        layout: sensorLayour,
        paint,
        filter: ['all', ['has', 'parkingSensorId'], ['==', 'offset', offset]],
        before: 'building-extrusion',
      }}
    />
  );

  return (
    <>
      <Source id='spots-source' type='geojson' data={geojson} generateId={true}>
        {getSpotsLayer(layer, spotsPaint, 'center')}
        {getSpotsLayer(layerLeft, spotsPaintLeft, 'left')}
        {getSpotsLayer(layerRight, spotsPaintRight, 'right')}
        {getSpotsLayer(layerTop, spotsPaintTop, 'top')}
        {getSpotsLayer(layerBottom, spotsPaintBottom, 'bottom')}
        <Layer
          {...{
            id: layerLogo,
            type: 'symbol',
            layout: logosLayout,
            paint: spotsLogosPaint,
            before: 'building-extrusion',
          }}
        />
        {getSensorsLayer('spots-layer-sensors', sensorsPaint, 'center')}
        {getSensorsLayer('spots-layer-sensors-left', sensorsPaintLeft, 'left')}
        {getSensorsLayer('spots-layer-sensors-right', sensorsPaintRight, 'right')}
        {getSensorsLayer('spots-layer-sensors-top', sensorsPaintTop, 'top')}
        {getSensorsLayer('spots-layer-sensors-bottom', sensorsPaintBottom, 'bottom')}
      </Source>

      <CurbSpacePopup />

      <MapImage name='logo-aramac' url='/icons/curbSpaces/logos/aramac.png' />
      <MapImage name='logo-fedex' url='/icons/curbSpaces/logos/fedex.png' />
      <MapImage name='logo-ups' url='/icons/curbSpaces/logos/ups.png' />
      <MapImage name='logo-usps' url='/icons/curbSpaces/logos/usps.png' />
      <MapImage name='logo-amazon' url='/icons/curbSpaces/logos/amazon.png' />
      <MapImage name='logo-ontrac' url='/icons/curbSpaces/logos/ontrac.png' />
      <MapImage name='spot-sensor' url='/icons/curbSpaces/sensor.png' />
    </>
  );
};
