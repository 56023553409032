import { FC, useMemo } from 'react';
import classNames from 'classnames/bind';
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisLabels,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import { Group } from '@progress/kendo-drawing';

import { IOccupancySourceReport } from '../../../../model';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { PeriodTextInfo } from '../period-info/period-text-info/PeriodTextInfo';
import { dateUtils, numberUtils } from '../../../../utils';
import { PeriodChartInfo } from '../period-info/period-chart-info/PeriodChartInfo';

import styles from './OccupancyPopupTab.module.scss';

const cx = classNames.bind(styles);

interface IProps {
  report: IOccupancySourceReport;
}

export const OccupancyPopupTab: FC<IProps> = ({ report }) => {
  const localization = useExtendedLocalization();

  const occupancyByDayOfWeekSeries = useMemo(
    () =>
      (report.OccupancyByDayOfWeek || []).map((x) => ({
        name: localization.toLanguageStringF(`common.weekDaysNum.${x.Id}`),
        value: x.Value,
      })),
    [report.OccupancyByDayOfWeek, localization],
  );

  const occupancyByHourChart = useMemo(() => {
    const max = report.OccupancyByHour.reduce((prev, current) => (prev && prev.Value > current.Value ? prev : current)).Value;
    return (
      <Chart>
        <ChartValueAxis>
          <ChartValueAxisItem min={0} max={max + 2} labels={{ format: `{0}%` }} />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            title={{ text: localization.toLanguageStringF('occupancySource.report.hours') }}
            categories={report.OccupancyByHour.map((x) => localization.toLanguageStringF(`common.hours.${x.Id}`))}
            axisCrossingValue={[0, 24]}
          >
            <ChartCategoryAxisLabels rotation={-45}></ChartCategoryAxisLabels>
          </ChartCategoryAxisItem>
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem type='line' markers={{ visible: false }} data={report.OccupancyByHour.map((x) => x.Value)} color='#409640' />
        </ChartSeries>
      </Chart>
    );
  }, [localization, report.OccupancyByHour]);

  const occupancyByDayChart = useMemo(() => {
    const max = (report.OccupancyByDay || []).reduce((prev, current) => (prev && prev.Value > current.Value ? prev : current)).Value;
    return (
      <Chart>
        <ChartValueAxis>
          <ChartValueAxisItem min={0} max={max + 2} labels={{ format: `{0}%` }} />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            title={{ text: localization.toLanguageStringF('occupancySource.report.days') }}
            categories={report.OccupancyByDay.map((x) => x.Id)}
            axisCrossingValue={[0, 24]}
          >
            <ChartCategoryAxisLabels
              visual={(x) => ((x.value % 5 === 0 && x.value !== 30) || x.value === 1 || x.value === 31 ? x.createVisual() : new Group())}
            ></ChartCategoryAxisLabels>
          </ChartCategoryAxisItem>
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem type='column' markers={{ visible: false }} data={report.OccupancyByDay.map((x) => x.Value)} />
        </ChartSeries>
      </Chart>
    );
  }, [localization, report.OccupancyByDay]);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.column}>
          <PeriodTextInfo
            label={localization.toLanguageStringF('occupancySource.report.totalSessions')}
            value={report.TotalSessions.toString()}
            small={true}
          />
        </div>
        <div className={styles.column}>
          <PeriodTextInfo
            label={localization.toLanguageStringF('occupancySource.report.turnover')}
            value={numberUtils.toUiString(report.Turnover)}
            small={true}
          ></PeriodTextInfo>
        </div>
        <div className={styles.column}>
          <PeriodTextInfo
            label={localization.toLanguageStringF('occupancySource.report.avgDwellTime')}
            value={dateUtils.formatMinutes(report.AverageDwellTime)}
            small={true}
          ></PeriodTextInfo>
        </div>
        <div className={styles.column}>
          <PeriodTextInfo
            label={localization.toLanguageStringF('occupancySource.report.occupancyPecentage')}
            value={`${numberUtils.toUiString(report.OccupancyPercent)}%`}
            small={true}
          ></PeriodTextInfo>
        </div>
      </div>
      <div className={styles.row}>
        <div className={cx('column', 'column75')}>
          <div className={styles.title}>{localization.toLanguageStringF('occupancySource.report.occupancyByDay')}</div>
          {occupancyByDayChart}
        </div>
        <div className={styles.column}>
          <div className={styles.title}>{localization.toLanguageStringF('occupancySource.report.occupancyByDoW')}</div>
          <PeriodChartInfo type='bar' series={occupancyByDayOfWeekSeries} showLabels={false} height={200} />
        </div>
      </div>
      <div className={styles.row}>
        <div className={cx('column', 'column100')}>
          <div className={styles.title}>{localization.toLanguageStringF('occupancySource.report.occupancyByHour')}</div>
          {occupancyByHourChart}
        </div>
      </div>
    </div>
  );
};
