import { FC } from 'react';
import classNames from 'classnames/bind';
import { Position } from 'geojson';
import icon from './google-street-view.svg';

import { Link } from '../../../common';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';

import styles from './ShowGoogleStreetViewLink.module.scss';

const cx = classNames.bind(styles);

interface Props {
  coordinates: Position;
  className?: string;
}

export const ShowGoogleStreetViewLink: FC<Props> = ({ coordinates, className }) => {
  const localization = useExtendedLocalization();

  const openStreetView = (ev: React.FormEvent) => {
    ev.preventDefault();
    window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${coordinates[1]},${coordinates[0]}`, '_blank');
  };

  return (
    <div className={cx('googleStreetViewLink', className)}>
      {localization.toLanguageStringF('common.showOn')}
      <Link className={styles.link} onClick={openStreetView}>
        <img className={styles.icon} src={icon} alt='icon' />
        {localization.toLanguageStringF('common.googleStreetView')}
      </Link>
    </div>
  );
};
