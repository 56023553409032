/* eslint-disable react/no-unescaped-entities */
import React, { FunctionComponent } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

import { metersEditingActions, selectMetersEditing } from '../../../../../features';

import styles from './MetersEditingTopbar.module.scss';
import { useConfirmation } from '../../../../common';
import { useExtendedLocalization } from '../../../../../hooks/use-extended-localization-service';

interface Props {
  className?: string;
}

export const MetersEditingTopbar: FunctionComponent<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const confirmation = useConfirmation();
  const metersEditing = useAppSelector(selectMetersEditing);
  const localization = useExtendedLocalization();

  const count = Object.keys(metersEditing.meters).length;

  const onClose = () => {
    if (count === 0) {
      dispatch(metersEditingActions.setEditing({ edit: false }));
      return;
    }

    confirmation({
      message: localization.toLanguageStringF('meter.editMode.discardMessage'),
    })
      .then(() => {
        dispatch(metersEditingActions.setEditing({ edit: false }));
      })
      .catch((err) => {});
  };

  const toggleSidebar = () => {
    dispatch(metersEditingActions.toggleSidebar());
  };

  if (!metersEditing.isEditing) return null;

  return (
    <div className={`${styles.topBar} ${className}`}>
      <span className={styles.topBarContent}>
        <button className={styles.btnEditCount} disabled={count === 0 || !metersEditing.isEditing} onClick={toggleSidebar}>
          <div className={styles.editTitle}>
            <span className='k-icon k-i-edit'></span>
            <span className={styles.titleText}>{localization.toLanguageStringF('meter.editMode.title')}</span>
          </div>

          <BadgeContainer>
            <span className='k-icon k-i-layout-side-by-side'></span>
            <Badge
              themeColor='error'
              align={{
                vertical: 'top',
                horizontal: 'end',
              }}
            >
              {count}
            </Badge>
          </BadgeContainer>
        </button>
      </span>

      <Button size='small' className={styles.btnClose} icon='close' onClick={onClose}></Button>
    </div>
  );
};
