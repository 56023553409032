import { MapLayerMouseEvent } from 'mapbox-gl';
import { FunctionComponent, useCallback, useContext, useEffect, useMemo } from 'react';
import { MapContext } from 'react-map-gl/dist/esm/components/map';
import { selectCustomLayers, selectedCustomLayersActions } from '../../../../features';
import { useMapLayerPopup, useMapToolTip, useOpenPopupDispatch } from '../../../../hooks';
import { useAppSelector } from '../../../../store/hooks';
import { CustomLayerPopup } from './CustomLayerPopup';

export const CustomLayersLayer: FunctionComponent = () => {
  const popupDispatch = useOpenPopupDispatch();
  const customLayers = useAppSelector(selectCustomLayers);
  const { map } = useContext(MapContext);

  const layers = useMemo(() => Object.keys(customLayers.layers), [customLayers.layers]);

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      const feature = evt.features?.length ? evt.features[0] : null;
      if (!feature) {
        return;
      }

      const position = [evt.lngLat.lng, evt.lngLat.lat];
      const layer = {
        FeatureId: feature.id,
        LayerId: feature.layer.id,
        LayerName: customLayers.layers[feature.layer.id].name,
        Properties: feature?.properties,
      };

      popupDispatch(selectedCustomLayersActions.selectCustomaLayer({ layer: layer, position }));
    },
    [customLayers.layers, popupDispatch],
  );

  useMapToolTip(
    (event: MapLayerMouseEvent) => {
      const feature = event.features?.length ? event.features[0] : null;
      const defName = 'Custom layer';
      if (!feature) {
        return defName;
      }

      const layer = customLayers.layers[feature.layer.id];
      if (!layer) {
        return defName;
      }

      return layer.name;
    },
    () => layers.some((x) => customLayers.layers[x].visibility),
    ...layers,
  );

  const setPointerCursor = useCallback(() => {
    map.getCanvas().style.cursor = 'pointer';
  }, [map]);

  const setDefaultCursor = useCallback(() => {
    map.getCanvas().style.cursor = 'default';
  }, [map]);

  useMapLayerPopup(handleLayerClick, ...layers);
  useEffect(() => {
    layers.forEach((x) => map.on('mouseenter', x, setPointerCursor));
    layers.forEach((x) => map.on('mouseleave', x, setDefaultCursor));

    return () => {
      layers.forEach((x) => map.off('mouseenter', x, setPointerCursor));
      layers.forEach((x) => map.off('mouseleave', x, setPointerCursor));
    };
  }, [layers, map, setDefaultCursor, setPointerCursor]);

  return (
    <>
      <CustomLayerPopup />
    </>
  );
};
