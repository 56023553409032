import { AnyLayout, AnyPaint, MapLayerMouseEvent } from 'mapbox-gl';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';

import {
  layerIdsActions,
  selectOffstreetZonesLayer,
  selectParkingLotsCenterGeo,
  selectParkingLotsGeo,
  selectedParkingLotsActions,
} from '../../../../features';
import { useMapLayerHover, useMapLayerPopup, useMapToolTip } from '../../../../hooks';
import { LayerName, OffstreetZoneLayerType } from '../../../../model';
import { NavigationSource, PopupType, amplitudeService } from '../../../../services';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { MapImage } from '../../controls';
import { ParkingLotPopup } from './ParkingLotPopup';

const iconsPaint = {
  'text-color': 'black',
};

const polygonsFill: AnyPaint = {
  'fill-color': '#D13438',
  'fill-opacity': 0.3,
};

export const ParkingLotsLayer: FC = () => {
  const dispatch = useAppDispatch();
  const geoJson = useAppSelector(selectParkingLotsGeo);
  const geoCenterJson = useAppSelector(selectParkingLotsCenterGeo);
  const offstreetZoneLayer = useAppSelector(selectOffstreetZonesLayer);

  const layerEnabled = offstreetZoneLayer.enabled && offstreetZoneLayer.layerTypeFilter.isTagEnabled(OffstreetZoneLayerType.Lots);
  const iconsLayer = 'parking-lots-icon-layer';

  const filter = useMemo(
    () => (offstreetZoneLayer.showOnlyCityOwnedFacilities ? ['==', ['get', 'ownership'], 'City of San Jose'] : ['boolean', true]),
    [offstreetZoneLayer.showOnlyCityOwnedFacilities],
  );

  const layout: AnyLayout = useMemo(() => {
    return {
      visibility: layerEnabled ? 'visible' : 'none',
    };
  }, [layerEnabled]);

  const iconLayout: AnyLayout = useMemo(() => {
    return {
      visibility: layerEnabled ? 'visible' : 'none',
      'icon-image': 'point-parking-lot',
      'icon-allow-overlap': true,
      'icon-size': {
        base: 0.4,
        stops: [
          [16, 0.6],
          [18, 0.8],
          [20, 1.4],
          [21, 1.6],
        ],
      },
    };
  }, [layerEnabled]);

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      dispatch(selectedParkingLotsActions.closePopup());
      const feature = evt.features ? evt.features[0] : null;
      if (!feature || !feature.properties) return;

      const id = feature.properties.id;
      const position = [evt.lngLat.lng, evt.lngLat.lat];
      setTimeout(() => {
        dispatch(
          selectedParkingLotsActions.load({
            id: id,
            position: position,
          }),
        );
        amplitudeService.trackPopupOpen(PopupType.ParkingLot, NavigationSource.Map);
      }, 10);
    },
    [dispatch],
  );

  useMapToolTip(
    (event: MapLayerMouseEvent) => (event.features && event.features.length > 0 ? event.features[0].properties?.name : null),
    () => layerEnabled,
    iconsLayer,
  );

  useMapLayerPopup(handleLayerClick, iconsLayer);

  useMapLayerHover([iconsLayer]);

  // for Legend
  useEffect(() => {
    dispatch(layerIdsActions.registerLayers({ layerName: LayerName.ParkingLots, layerIds: [iconsLayer] }));

    return () => {
      dispatch(layerIdsActions.unregisterLayers({ layerName: LayerName.ParkingLots }));
    };
  }, [dispatch]);

  const content = useMemo(
    () => (
      <>
        <Source id='parking-lots-source' type='geojson' data={geoJson} generateId={true}>
          <Layer
            {...{
              id: 'parking-lots-layer',
              type: 'fill',
              layout: layout,
              paint: polygonsFill,
              before: 'building-extrusion',
              filter: filter,
            }}
          />
        </Source>
        <Source id='parking-lots-center-source' type='geojson' data={geoCenterJson} generateId={true}>
          <Layer
            {...{
              id: iconsLayer,
              type: 'symbol',
              layout: iconLayout,
              paint: iconsPaint,
              before: 'building-extrusion',
              filter: filter,
            }}
          />
        </Source>
        <ParkingLotPopup />
        <MapImage name='point-parking-lot' url='/icons/parkingLot.png' />
      </>
    ),
    [filter, geoCenterJson, geoJson, iconLayout, layout],
  );

  return content;
};
