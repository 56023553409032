import { Group, Text } from '@progress/kendo-drawing';
import {
  AxisLabelVisualArgs,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  LegendItemVisualArgs,
  SeriesType,
} from '@progress/kendo-react-charts';
import 'hammerjs';
import { FunctionComponent } from 'react';
import { useExtendedLocalization } from '../../../../../hooks/use-extended-localization-service';
import { numberUtils } from '../../../../../utils';
import { RefreshChartWrapper } from '../../../../common';
import styles from './PeriodChartInfo.module.scss';

interface Props {
  title?: string | null | undefined;
  series: Array<{ name: string; value: number }>;
  type?: SeriesType | undefined;
  selectedGroup?: string;
  fractionDigits?: number;
  labelFormatter?: (item: { value: number; name: string }) => string;
  showLabels?: boolean;
  className?: string;
  height?: number | string;
}

const setBoldFont = (text: Text) => {
  const font = text?.options?.get('font') as string;
  if (font) {
    const splitted = font.split(' ');
    splitted[0] = '700';
    text.options.set('font', splitted.join(' '));
  }
};

const defaultLabelFormatter = (props: { dataItem: { value: number; name: string } }, fractionDigits: number) =>
  `${numberUtils.toUiString(props.dataItem.value, fractionDigits)}`;

export const PeriodChartInfo: FunctionComponent<Props> = ({
  title,
  series,
  type,
  className,
  selectedGroup,
  labelFormatter,
  showLabels = true,
  fractionDigits = 2,
  height = 86,
}) => {
  const localization = useExtendedLocalization();

  if (!series?.length) {
    return <span className={`${styles.noData} ${className || ''}`}>{localization.toLanguageStringF(`common.noDataToDisplay`)}</span>;
  }

  const chartLegendItemVisual = (args: LegendItemVisualArgs) => {
    const visual = args.createVisual();
    if (selectedGroup) {
      const selectedCategory = args.series.data[args.pointIndex].name;
      if (selectedCategory === selectedGroup) {
        const root = visual as Group;
        if (Array.isArray(root.children) && root.children.length > 1) {
          const textGroup = root.children[1] as Group;
          if (Array.isArray(textGroup.children) && textGroup.children.length > 0) {
            const text = textGroup.children[0] as Text;
            setBoldFont(text);
          }
        }
      }
    }

    return visual;
  };

  const chartLabelVisual = (args: AxisLabelVisualArgs) => {
    const visual = args.createVisual();
    if (selectedGroup) {
      if (args.value === selectedGroup) {
        const root = visual as Group;
        if (Array.isArray(root.children) && root.children.length > 0) {
          const text = root.children[0] as Text;
          setBoldFont(text);
        }
      }
    }

    return visual;
  };

  return (
    <div className={`${styles.container} ${className || ''}`}>
      <div className={styles.title}>{title}</div>
      <RefreshChartWrapper
        style={{
          height: height,
          border: 'none',
        }}
        refreshOn={[title, series, type, selectedGroup]}
      >
        <ChartLegend position='right' orientation='vertical' item={{ visual: chartLegendItemVisual }} width={100} height={100} />
        <ChartValueAxis>
          <ChartValueAxisItem visible={false} />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem labels={{ visual: chartLabelVisual }}></ChartCategoryAxisItem>
        </ChartCategoryAxis>

        <ChartSeries>
          <ChartSeriesItem
            type={type || 'pie'}
            data={series}
            field='value'
            categoryField='name'
            labels={{
              visible: showLabels && type === 'bar',
              content: (e) => (labelFormatter ? labelFormatter(e.dataItem) : defaultLabelFormatter(e, fractionDigits)),
              position: 'insideBase',
            }}
          />
        </ChartSeries>
      </RefreshChartWrapper>
    </div>
  );
};
