import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOffstreetOwnership, IOffstreetVendor, IParkingLotOwnership, SavedOffstreetZoneLayer, TagFilter } from '../../../../model';

import { RootState } from '../../../../store';

export interface IOffstreetZonesLayerState {
  enabled: boolean;
  vendors: Array<IOffstreetVendor>;
  ownerships: Array<IOffstreetOwnership>;
  vendorsFilter: TagFilter;
  garagesCount: number | null;
  showVendorLogo: boolean;
  layerTypeFilter: TagFilter;
  parkingLotsCount: number;
  count: number;
  showOnlyCityOwnedFacilities: boolean;
}

const initialState: IOffstreetZonesLayerState = {
  enabled: false,
  vendors: [],
  ownerships: [],
  vendorsFilter: new TagFilter(false),
  garagesCount: null,
  showVendorLogo: false,
  layerTypeFilter: new TagFilter(true),
  parkingLotsCount: 0,
  count: 0,
  showOnlyCityOwnedFacilities: false,
};

const slice = createSlice({
  name: 'offstreetZonesLayer',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },
    setDisabled(state) {
      state.enabled = false;
    },

    fetchVendors(state) {},
    fetchVendorsSuccess(state, action: PayloadAction<Array<IOffstreetVendor>>) {
      state.vendors = action.payload;
      state.vendorsFilter = action.payload.length > 0 ? state.vendorsFilter.enable(true) : new TagFilter(false);
    },
    fetchVendorsFailed(state, action: PayloadAction<string>) {
      console.log(action);
    },

    setVendorsFilter(state, action: PayloadAction<TagFilter>) {
      state.vendorsFilter = action.payload;
    },

    fetchOwnerships(state) {},
    fetchOwnershipsSuccess(state, action: PayloadAction<Array<IOffstreetOwnership | IParkingLotOwnership>>) {
      state.ownerships = action.payload;
    },
    fetchOwnershipsFailed(state, action: PayloadAction<string>) {
      console.error(action);
    },

    fetchCount(state) {},
    fetchCountSuccess(state, action: PayloadAction<{ garagesCount: number; parkingLotsCount: number }>) {
      state.garagesCount = action.payload.garagesCount;
      state.parkingLotsCount = action.payload.parkingLotsCount;
      state.count = action.payload.garagesCount + action.payload.parkingLotsCount;
    },
    fetchCountFailed(state, action: PayloadAction<string>) {
      state.garagesCount = 0;
      state.parkingLotsCount = 0;
      state.count = 0;
      console.log(action);
    },

    setVendorLogoVisible(state, action: PayloadAction<boolean>) {
      state.showVendorLogo = action.payload;
    },

    setShowOnlyCityOwnedFacilities(state, action: PayloadAction<boolean>) {
      state.showOnlyCityOwnedFacilities = action.payload;
    },

    setLayerTypeFilter(state, action: PayloadAction<TagFilter>) {
      state.layerTypeFilter = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedOffstreetZoneLayer | undefined>) {
      if (action.payload) {
        state.enabled = action.payload.enabled;
        state.showVendorLogo = action.payload.showVendorLogo;
        state.vendorsFilter = TagFilter.fromSavedValue(action.payload.vendorsFilter);
        state.layerTypeFilter = TagFilter.fromSavedValue(action.payload.layerTypeFilter);
        state.showOnlyCityOwnedFacilities = action.payload.showOnlyCityOwnedFacilities;
      }
    },
  },
});

// Actions
export const offstreetZonesLayerActions = slice.actions;

// Selectors
export const selectOffstreetZonesLayer = (state: RootState) => state.offstreetZonesLayer;
export const selectOffstreetZonesCount = (state: RootState) => state.offstreetZonesLayer.garagesCount;
export const selectOffstreetVendors = (state: RootState) => state.offstreetZonesLayer.vendors;

// Reducer
export const offstreetZonesLayerReducer = slice.reducer;
