import { IEntityBase } from './entity-base';

export enum MeterType {
  Unknown = 0,
  SingleSpot = 1,
  MultiSpot = 2,
  DoubleSpot = 3,
}

export enum MeterStatus {
  Unknown = 'Unknown',
  Active = 'Active',
  TemporarilyInactive = 'TemporarilyInactive',
}

export enum Direction {
  Unknown = 'Unknown',
  North = 'North',
  South = 'South',
  West = 'West',
  East = 'East',
}

export interface IMeterVendor {
  Id: number;
  Name: string;
  Count: number;
}

export interface IMeter extends IEntityBase {
  Name: string;
  VendorId: number;
  Model: string;
  TypeId: MeterType;
  Jurisdiction: string;
  Position: [number, number];
  Address: string;
  Status: MeterStatus;
  SpotIds: Array<number>;
  ZoneId: number | null;
  HoursOfOperation: string | null;
  MaxTime: string | null;
  CreatedAt: Date;
  UpdatedAt: Date | null;
  SpotsCount: number | null;
  BlockfaceId: number | null;
  PerformanceParking: boolean | null;
}

export interface IMeterName extends IEntityBase {
  Name: string;
}

export interface IMeterTypeCount {
  TypeId: MeterType;
  Count: number;
}

export interface IMeterStatusCount {
  Status: MeterStatus;
  Count: number;
}
