import { Action } from '@reduxjs/toolkit';
import { Observable, of, from } from 'rxjs';
import { map, filter, catchError, switchMap, concatMap, mergeMap } from 'rxjs/operators';
import { StateObservable, combineEpics } from 'redux-observable';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';

import { parkingLotsGeoActions } from './parking-lots-geo-slice';
import { geoProcessor, parkingLots } from '../../../../services';
import { RootState } from '../../../../store';
import { offstreetZonesLayerActions } from '../offstreet-zones/offstreet-zones-layer-slice';
import { selectedParkingLotsActions } from './selected-parking-lots-slice';
import { geoUtils } from '../../../../utils';
import { mapStateActions } from '../../map-state';
import { OffstreetZoneLayerType } from '../../../../model';
import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { citiesActions } from '../../../common';

const findParkingLotFeature = (id: number, state: RootState): Feature<Geometry, GeoJsonProperties> | null => {
  return state.parkingLotsGeo.data.features.find((x) => x.properties?.id === id) || null;
};

const loadParkingLot = (id: number, state: RootState) => {
  const existing = state.selectedParkingLots.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return from(parkingLots.get(id));
};

const parkingLotSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedParkingLotsActions.load.match),
    concatMap((action) =>
      loadParkingLot(action.payload.id, state$.value).pipe(
        mergeMap((x) => {
          if (action.payload.position) {
            const center = action.payload.position;
            const initPosition = action.payload.initPosition;
            return of(
              offstreetZonesLayerActions.setEnabled(true),
              selectedParkingLotsActions.loadSuccess({
                parkingLot: x,
                position: center,
                initPosition: initPosition ? initPosition : center,
              }),
            );
          } else {
            const feature: Feature<Geometry, GeoJsonProperties> = findParkingLotFeature(action.payload.id, state$.value) || {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: x.Center,
              },
              properties: {},
            };
            const center = geoUtils.findCenter(feature).geometry.coordinates;
            return of(
              offstreetZonesLayerActions.setEnabled(true),
              mapStateActions.setMapCenter(center),
              selectedParkingLotsActions.loadSuccess({ parkingLot: x, position: center }),
            );
          }
        }),
        catchError((err) => of(selectedParkingLotsActions.loadFailed(err.message))),
      ),
    ),
  );

const fetchParkingLotsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(parkingLotsGeoActions.fetch.match),
    switchMap((action) => {
      if (action.payload.types.isTagEnabled(OffstreetZoneLayerType.Lots)) {
        return geoProcessor.loadParkingLots(action.payload.box, action.payload.box.zoom).pipe(
          map((x) => parkingLotsGeoActions.fetchSuccess(x)),
          catchError((err) => of(parkingLotsGeoActions.fetchFailed(err.message))),
        );
      } else {
        return of(parkingLotsGeoActions.fetchSuccess({ data: EMPTY_FEATURE_COLLECTION, center: EMPTY_FEATURE_COLLECTION }));
      }
    }),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((action) => selectedParkingLotsActions.closePopups()),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap((_) => of(selectedParkingLotsActions.collapsePopups())),
  );

export const parkingLotsEpic = combineEpics(fetchParkingLotsEpic, parkingLotSelectedEpic, closePopupsEpic, citySelectedEpic);
