/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { FunctionComponent } from 'react';
import classNames from 'classnames';

import { SearchItemProps } from '../Search';
import { CurbSpacePolicyIcon } from '../../../../map/icons';
import { CurbSpaceOccupancyStatusText } from '../../../../map/layers';
import { useExtendedLocalization } from '../../../../../hooks/use-extended-localization-service';

export const CurbSpaceItem: FunctionComponent<SearchItemProps> = ({ model, onItemSelected }) => {
  const localization = useExtendedLocalization();
  const policy =
    model.item.PolicyType !== 0
      ? localization.toLanguageStringF(`curbSpace.policyType.${model.item.PolicyType}`)
      : '';

  return (
    <li className={classNames('item', { disabled: !model.enabled })} title={model.displayName} onClick={() => onItemSelected(model)}>
      <CurbSpacePolicyIcon className='item-icon' policy={model.item.PolicyType} />
      <div className='item-info'>
        <div className='item-info-row'>
          <label>{`${localization.toLanguageStringF('common.curbSpace')}: `}</label>#
          <span className='value' dangerouslySetInnerHTML={{ __html: model.item.Name }}></span>
        </div>
        <div className='item-info-row'>
          {policy}
          {policy && model.item.Status ? ' | ' : ''}
          <CurbSpaceOccupancyStatusText status={model.item.Status} />
        </div>
      </div>
    </li>
  );
};
