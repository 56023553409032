import React, { FunctionComponent, useCallback } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

import { ISelectedArea, SidebarName } from '../../../../model';
import { MapSidebarContainer } from '../../controls';

import styles from './AreaSidebar.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectAreaTypes, selectedOffstreetZonesActions, selectedZonesActions, sidebarActions } from '../../../../features';
import { currentArea, ISelectedAreaState, selectedAreaActions } from '../../../../features/map/layers/area/selected-area-slice';

import { areaUtility } from './area-helpers';
import { RevenueTable } from '../../../revenue';

import { useOpenPopupDispatch, useReportBuilder } from '../../../../hooks';
import { Link } from '../../../common';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ISelectedEntity } from '../../../../services';

type Props = {
  areaData: ISelectedEntity<ISelectedArea>;
};

const Sidebar: FunctionComponent<Props> = ({ areaData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const areaTypes = useAppSelector(selectAreaTypes);
  const area = areaData.entity;
  const localization = useExtendedLocalization();
  const reportsBuilder = useReportBuilder();

  const { areaCounts, isNotEmptyChildren } = areaUtility(area, areaTypes);
  const zoneNames = area?.zoneNames || [];
  const offstreetZoneNames = area?.offstreetZoneNames || [];

  const revenueReportUrl = area && reportsBuilder.getAreaRevenue(area.Id);

  const handleCloseClick = (ev: React.FormEvent) => {
    ev.preventDefault();
    dispatch(sidebarActions.clearSelected());
  };

  const mapView = () => {
    area && dispatch(selectedAreaActions.openPopup(area.Id));
  };

  const revenueMoreInfo = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (revenueReportUrl) {
      window.open(revenueReportUrl, '_blank');
    }
  };

  const openZone = useCallback(
    (zoneId: number) => {
      popupDispatch(
        selectedZonesActions.loadZone({
          id: zoneId,
          position: null,
        }),
      );
    },
    [popupDispatch],
  );

  const openOffstreetZone = useCallback(
    (zoneId: number) => {
      popupDispatch(
        selectedOffstreetZonesActions.loadZone({
          id: zoneId,
          position: null,
        }),
      );
    },
    [popupDispatch],
  );

  return (
    <div className={`map-sidebar map-sidebar-info ${styles.sidebar}`}>
      <div className='map-sidebar-header'>
        <h3>
          {area?.typeName}: {area?.Name}
        </h3>

        <Button size='small' className='close' icon='close' onClick={handleCloseClick}></Button>
      </div>

      <div className='map-sidebar-body'>
        <PanelBar>
          <PanelBarItem title={localization.toLanguageStringF('common.generalInfo')} expanded={true}>
            <div>
              {isNotEmptyChildren &&
                areaCounts.map((area, index) => (
                  <div key={index} className={`map-sidebar-row ${styles.generalInfo}`}>
                    <label>
                      {areaTypes && areaTypes[area.typeId] ? areaTypes[area.typeId].PluralName : area.name}{' '}
                      {localization.toLanguageStringF('area.sidebar.count')}:
                    </label>
                    <div>{area.descendants.length}</div>
                  </div>
                ))}

              {zoneNames.length > 0 && (
                <div className={`map-sidebar-row ${styles.generalInfo}`}>
                  <label htmlFor='fff'>{localization.toLanguageStringF('area.sidebar.zoneCount')}:</label>
                  <div>{zoneNames.length}</div>
                </div>
              )}

              {offstreetZoneNames.length > 0 && (
                <div className={`map-sidebar-row ${styles.generalInfo}`}>
                  <label htmlFor='fff'>{localization.toLanguageStringF('area.sidebar.offStreetParkingCount')}:</label>
                  <div>{offstreetZoneNames.length}</div>
                </div>
              )}

              {!isNotEmptyChildren && !zoneNames.length && !offstreetZoneNames.length && (
                <div className={styles.noData}>{localization.toLanguageStringF('area.sidebar.noSubAreasAvailable')}</div>
              )}
            </div>
            <div className='map-sidebar-row more-info'>
              <Link onClick={mapView} className='more-info-button' disabled={areaData.openPopup}>
                {localization.toLanguageStringF('common.mapView')}
              </Link>
            </div>
          </PanelBarItem>

          {area?.revenue && area.revenue.length > 0 && (
            <PanelBarItem title={localization.toLanguageStringF('area.sidebar.revenue')}>
              <RevenueTable revenue={area.revenue} />
              <div className='map-sidebar-row more-info'>
                {revenueReportUrl && (
                  <Link onClick={revenueMoreInfo} className='more-info-button'>
                    {localization.toLanguageStringF('common.moreInfo')}
                  </Link>
                )}
              </div>
            </PanelBarItem>
          )}

          {zoneNames.length > 0 && (
            <PanelBarItem title={localization.toLanguageStringF('area.sidebar.zones')} className='panelbar-scrollable'>
              <div className={styles.panelMargin}>
                {zoneNames.map((zone, idx) => (
                  <Link key={idx} onClick={() => openZone(zone.Id)} className={styles.link}>
                    <span>{zone.Name}</span>
                    {idx < zoneNames.length - 1 && ','}
                  </Link>
                ))}
              </div>
            </PanelBarItem>
          )}

          {offstreetZoneNames.length > 0 && (
            <PanelBarItem title={localization.toLanguageStringF('area.sidebar.offStreetParking')} className='panelbar-scrollable'>
              <div className={styles.panelMargin}>
                {offstreetZoneNames.map((zone, idx) => (
                  <Link key={idx} onClick={() => openOffstreetZone(zone.Id)} className={styles.link}>
                    <span>{zone.Name}</span>
                    {idx < offstreetZoneNames.length - 1 && ','}
                  </Link>
                ))}
              </div>
            </PanelBarItem>
          )}
        </PanelBar>
      </div>
    </div>
  );
};

export const AreaSidebar: FunctionComponent = () => {
  const current = useAppSelector(currentArea);

  return current ? (
    <MapSidebarContainer name={SidebarName.area}>
      <Sidebar areaData={current} />
    </MapSidebarContainer>
  ) : null;
};
