import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { ISelectedParkingLot } from '../../../../model';
import { RootState, store } from '../../../../store';
import { ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { selectedSliceLogic } from '../selected-slice-logic';

export interface ISelectedParkingLotState {
  selected: ISelectedEntity<ISelectedParkingLot>[];
  current: number | null;
}

const initialState: ISelectedParkingLotState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedParkingLots',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.ParkingLots,
        initialState,
        (id) => parseInt(id),
        (selected) => {
          selected.map((x) => store.dispatch(selectedParkingLotsActions.load(x)));
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: number; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.ParkingLots, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.ParkingLots);
    },
    load(state, action: PayloadAction<{ id: number; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadSuccess(state, action: PayloadAction<{ parkingLot: ISelectedParkingLot; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.parkingLot.Id,
        action.payload.parkingLot,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },
    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.ParkingLots, action.payload);
    },
    closePopups(state) {
      selectedSliceLogic.closePopups(state, SelectedEntityName.ParkingLots);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.ParkingLots, action.payload);
    },
    setCurrent(state, action: PayloadAction<ISelectedParkingLot | null>) {
      state.current = action.payload?.Id || null;
    },
  },
});

// Actions
export const selectedParkingLotsActions = slice.actions;

// Selectors
export const selectedParkingLots = (state: RootState) => state.selectedParkingLots;
export const currentParkingLot = (state: RootState) =>
  state.selectedParkingLots.selected.find((x) => x.id === state.selectedParkingLots.current);

// Reducer
export const selectedParkingLotsReducer = slice.reducer;
