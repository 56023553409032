import { AnyLayout, AnyPaint, MapLayerMouseEvent } from 'mapbox-gl';
import { FunctionComponent, useCallback, useContext, useEffect } from 'react';
import { Layer, Source } from 'react-map-gl';
import { MapContext } from 'react-map-gl/dist/esm/components/map';
import { selectSignsGeo } from '../../../../features';
import { selectedSignsActions } from '../../../../features/map/layers/signs/selected-signs-slice';
import { selectSignsEnabled } from '../../../../features/map/layers/signs/signs-layer-slice';
import { useMapLayerPopup, useMapToolTip } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { MapImage } from '../../controls/MapImage';
import { SignPopup } from './SignPopup';

export const SignsLayer: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const geojson = useAppSelector(selectSignsGeo);
  const layerEnabled = useAppSelector(selectSignsEnabled);
  const signsLayer = 'signs-layer';
  const localization = useExtendedLocalization();

  useMapToolTip(
    () => localization.toLanguageStringF('sign.toolTip'),
    () => layerEnabled,
    signsLayer,
  );

  const { map } = useContext(MapContext);

  const layout: AnyLayout = {
    visibility: layerEnabled ? 'visible' : 'none',
    'icon-image': [
      'match',
      ['get', 'status'],
      'Offline',
      'point-sign-offline',
      'Inactive',
      'point-sign-inactive',
      'Active',
      'point-sign-active',
      'point-sign-offline',
    ],
    'icon-allow-overlap': true,
    'icon-size': {
      base: 0.4,
      stops: [
        [16, 0.6],
        [18, 0.8],
        [20, 1.4],
        [21, 1.6],
      ],
    },
  };

  const paint: AnyPaint = {
    'text-color': '#fff',
    'text-halo-color': '#106EBE',
    'text-halo-width': 20,
  };

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      dispatch(selectedSignsActions.closePopup());
      const feature = evt.features ? evt.features[0] : null;
      if (!feature || !feature.properties) return;

      const signId = feature.properties.id;
      const position = [evt.lngLat.lng, evt.lngLat.lat];
      setTimeout(() => {
        dispatch(
          selectedSignsActions.loadSign({
            id: signId,
            position: position,
          }),
        );
      }, 10);
    },
    [dispatch],
  );

  const setPointerCursor = () => {
    map.getCanvas().style.cursor = 'pointer';
  };

  const setDefaultCursor = () => {
    map.getCanvas().style.cursor = 'default';
  };

  const layers = ['signs-layer'];

  useMapLayerPopup(handleLayerClick, ...layers);
  useEffect(() => {
    layers.forEach((x) => map.on('mouseenter', x, setPointerCursor));
    layers.forEach((x) => map.on('mouseleave', x, setDefaultCursor));

    return () => {
      layers.forEach((x) => map.off('mouseenter', x, setPointerCursor));
      layers.forEach((x) => map.off('mouseleave', x, setPointerCursor));
    };
  }, [map]);

  return (
    <>
      <Source id='signs-source' type='geojson' data={geojson} generateId={true}>
        <Layer
          {...{
            id: signsLayer,
            type: 'symbol',
            layout: layout,
            paint: paint,
            before: 'building-extrusion',
          }}
        />

        <MapImage name='point-sign-active' url='/icons/signs/active.png' />
        <MapImage name='point-sign-inactive' url='/icons/signs/inactive.png' />
        <MapImage name='point-sign-offline' url='/icons/signs/offline.png' />

        <SignPopup />
      </Source>
    </>
  );
};
