import { Action } from '@reduxjs/toolkit';
import { StateObservable, combineEpics } from 'redux-observable';
import { Observable, catchError, concatMap, filter, from, map, mergeMap, of } from 'rxjs';

import { reports, studyAreas } from '../../../../services';
import { RootState } from '../../../../store';
import { selectedStudyAreaHeatmapsActions } from './selected-study-area-heatmaps-slice';
import { mapStateActions } from '../../map-state';
import { citiesActions } from '../../../common';

const loadHeatmap = (id: number, state: RootState) => {
  const existing = state.selectedStudyAreaHeatmaps.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return from(studyAreas.get(id)).pipe(
    map((x) => ({
      studyArea: x,
    })),
  );
};

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((_) => selectedStudyAreaHeatmapsActions.closePopup()),
  );

const heatmapSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedStudyAreaHeatmapsActions.loadHeatmap.match),
    concatMap((action) =>
      loadHeatmap(action.payload.id, state$.value).pipe(
        mergeMap((x) =>
          of(
            selectedStudyAreaHeatmapsActions.loadHeatmapSuccess({
              heatmap: x,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              position: action.payload.position!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              initPosition: action.payload.initPosition ? action.payload.initPosition : action.payload.position!,
            }),
          ),
        ),
        catchError((err) => of(selectedStudyAreaHeatmapsActions.loadHeatmapFailed(err.message))),
      ),
    ),
  );

const fetchDurationReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedStudyAreaHeatmapsActions.fetchDurationReport.match),
    concatMap((action) => {
      return from(reports.getStudyAreaParkingDurationReport(action.payload.id, action.payload.filter)).pipe(
        map((x) => selectedStudyAreaHeatmapsActions.fetchDurationReportSuccess({ id: action.payload.id, report: x })),
        catchError((err) => of(selectedStudyAreaHeatmapsActions.fetchReportFailed(err.message))),
      );
    }),
  );
};

const fetchViolationReportEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedStudyAreaHeatmapsActions.fetchViolationReport.match),
    concatMap((action) => {
      return from(reports.getStudyAreaEnforcementReport(action.payload.studyAreaId, action.payload.filter)).pipe(
        map((x) => selectedStudyAreaHeatmapsActions.fetchViolationReportSuccess({ studyAreaId: action.payload.studyAreaId, report: x })),
        catchError((err) => of(selectedStudyAreaHeatmapsActions.fetchViolationReportFailed(err.message))),
      );
    }),
  );
};

const fetchRevenueReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedStudyAreaHeatmapsActions.fetchRevenueReport.match),
    concatMap((action) => {
      return from(reports.getStudyAreaRevenueReport(action.payload.id, action.payload.filter)).pipe(
        map((x) => selectedStudyAreaHeatmapsActions.fetchRevenueReportSuccess({ id: action.payload.id, report: x })),
        catchError((err) => of(selectedStudyAreaHeatmapsActions.fetchReportFailed(err.message))),
      );
    }),
  );
};

const fetchOccupancyTrafficReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedStudyAreaHeatmapsActions.fetchOccupancyTrafficReport.match),
    concatMap((action) =>
      from(reports.getStudyAreaOccupancyTrafficReport(action.payload.id, action.payload.filter, action.payload.occupancySource)).pipe(
        map((x) => selectedStudyAreaHeatmapsActions.fetchOccupancyTrafficReportSuccess({ id: action.payload.id, report: x })),
        catchError((err) => of(selectedStudyAreaHeatmapsActions.fetchReportFailed(err.message))),
      ),
    ),
  );
};

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap(() => of(selectedStudyAreaHeatmapsActions.collapsePopups())),
  );

export const studyAreaHeatmapEpic = combineEpics(
  heatmapSelectedEpic,
  fetchDurationReportEpic,
  fetchViolationReportEpic,
  fetchRevenueReportEpic,
  closePopupsEpic,
  fetchOccupancyTrafficReportEpic,
  citySelectedEpic,
);
