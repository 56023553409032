import { FunctionComponent } from 'react';
import { useMap } from 'react-map-gl';

type Props = {
  url: string;
  name: string;
};

export const MapImage: FunctionComponent<Props> = ({ url, name }) => {
  const { current: map } = useMap();

  if (!map) return null;

  if (!map.hasImage(name)) {
    map.loadImage(url, (error, image) => {
      if (error && !error.message.includes('Cannot read properties of undefined')) throw error;

      if (image && !map.hasImage(name)) map.addImage(name, image);
    });
  }

  return null;
};
