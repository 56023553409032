import { FunctionComponent } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { SignStatus } from '../../../../model';

import styles from './SignStatusText.module.scss';

interface Props {
  className?: string;
  status: SignStatus;
}

export const SignStatusText: FunctionComponent<Props> = ({ className, status }) => {  
  const localization = useExtendedLocalization();

  return (
    <span className={`${className || ''} ${styles.status} status-${SignStatus[status]}`}>
      {localization.toLanguageStringF(`sign.status.${SignStatus[status]}`)}
    </span>
  );
};
