import { FC } from 'react';

import styles from './LoadingZoneOccupancy.module.scss';
import { loadingZone } from '../../../../../model';

interface Props {
  spotsCount: number;
  occupiedSpotsCount: number;
  className?: string;
}

export const LoadingZoneOccupancy: FC<Props> = ({ spotsCount, occupiedSpotsCount, className }) => {
  const occupancy = loadingZone.occupancyCalculator(spotsCount, occupiedSpotsCount);

  return (
    <div className={`${className || ''} ${styles.zoneSpotsStatus}`}>
      <span className={styles.separator} style={{ color: '#00B62D' }}>
        Vacant: {occupancy.vacantSpotsCount}
      </span>
      <span style={{ color: 'red' }}>Occupied: {occupancy.occupiedSpotsCount}</span>
    </div>
  );
};
