import { FunctionComponent } from 'react';
import { MeterStatus } from '../../../../model';

import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import styles from './MeterStatusText.module.scss';

interface Props {
  className?: string;
  status: MeterStatus;
}

export const MeterStatusText: FunctionComponent<Props> = ({ className, status }) => {
  const localization = useExtendedLocalization();
  
  return (
    <span className={`${className || ''} ${styles.status} status-${MeterStatus[status]}`}>
      {localization.toLanguageStringF(`meter.status.${MeterStatus[status]}`)}
    </span>
  );
};
