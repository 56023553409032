/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import { Spinner } from '../spinner/Spinner';
import styles from './ImgVideoPlayer.module.scss';
import pauseIcon from './assets/Pause-1.svg';
import playIcon from './assets/Play-1.svg';
import collapseIcon from './assets/collapse.svg';
import expandIcon from './assets/expand.svg';
import videoError from './assets/placeholder.png';

interface Props {
  videoUrl?: string;
  className?: string;
}

export const ImgVideoPlayer: FunctionComponent<Props> = ({ videoUrl, className }) => {
  const [playing, setPlaying] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const fullScreenHandle = useFullScreenHandle();

  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const img = imgRef.current;
    if (img && videoUrl) {
      img.src = error ? videoError : videoUrl;
    }

    return img ? () => img.removeAttribute('src') : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgRef.current, playing, error]);

  const fullScreenContent = useMemo(() => {
    const toggleFullscreen = () => {
      if (!fullScreenHandle.active) {
        fullScreenHandle.enter();
      } else {
        fullScreenHandle.exit();
      }
    };

    const content = videoUrl ? (
      <>
        {playing && (
          <>
            <img
              ref={imgRef}
              className={`${loaded ? styles.displayBlock : styles.displayNone}`}
              src={error ? videoError : videoUrl}
              alt='video'
              onError={() => setError(true)}
              onLoad={() => setLoaded(true)}
            ></img>
            {!loaded && <Spinner backdrop={true} />}
          </>
        )}

        {loaded && !error && (
          <div className={`${styles.commandPanel} ${fullScreenHandle.active ? styles.commandPanelFullscreen : ''}`}>
            <div className={styles.mainCommands} onClick={() => setPlaying(!playing)} role='button' tabIndex={0}>
              <img src={playing ? pauseIcon : playIcon} alt='play'></img>
            </div>
            <div className={styles.expandCommand} onClick={toggleFullscreen} role='button' tabIndex={0}>
              <img src={fullScreenHandle.active ? collapseIcon : expandIcon} alt='expand'></img>
            </div>
          </div>
        )}
      </>
    ) : (
      <img src={videoError} alt='video' />
    );

    return (
      <FullScreen handle={fullScreenHandle}>
        <div className={`${styles.player} ${fullScreenHandle.active ? styles.videoFullscreen : ''} ${className || ''}`}>{content}</div>
      </FullScreen>
    );
  }, [className, error, fullScreenHandle, loaded, playing, videoUrl]);

  return fullScreenContent;
};
