import { FunctionComponent } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';

import { IKpiReportItem, Period } from '../../../../model';
import { MetricsTable, MetricValue } from '../metrics-table-table/MetricsTable';

interface Props {
  kpiReport: IKpiReportItem[];
  className?: string;
}

export const ZoneKpiTable: FunctionComponent<Props> = ({ kpiReport, className }) => {
  const localization = useExtendedLocalization();
  const periods = [Period.Yesterday, Period.Week, Period.Month, Period.Quarter];

  const createMetricValues = (valueProp: (x: IKpiReportItem) => number, changeProp: (x: IKpiReportItem) => number, isMoney: boolean) => {
    const values: { [key in Period]?: MetricValue | undefined } = {};
    periods.forEach((x) => {
      const violationMetric = kpiReport.find((y) => y.PeriodId === x);
      values[x] = violationMetric
        ? { value: valueProp(violationMetric), change: changeProp(violationMetric), isMoney: isMoney }
        : undefined;
    });
    return values;
  };

  const metricItems = [
    {
      name: localization.toLanguageStringF(`zone.kpiReportMetrics.revenue`),
      values: createMetricValues(
        (y) => y.CurRevenue,
        (y) => y.CurRevenueChange,
        true,
      ),
    },
    {
      name: localization.toLanguageStringF(`zone.kpiReportMetrics.transactions`),
      values: createMetricValues(
        (y) => y.CurCount,
        (y) => y.CurCountChange,
        false,
      ),
    },
    {
      name: localization.toLanguageStringF(`zone.kpiReportMetrics.avgPrice`),
      values: createMetricValues(
        (y) => y.CurAvgPrice,
        (y) => y.CurAvgPriceChange,
        true,
      ),
    },
    {
      name: localization.toLanguageStringF(`zone.kpiReportMetrics.dailyAvg`),
      values: createMetricValues(
        (y) => y.CurAvgDailyRevenue,
        (y) => y.CurAvgDailyRevenueChange,
        true,
      ),
    },
  ];

  return <MetricsTable periods={periods} metricItems={metricItems} className={className}></MetricsTable>;
};
