import { FunctionComponent } from 'react';
import { Layer, Source } from 'react-map-gl';

import { AnyLayout, LinePaint } from 'mapbox-gl';
import { MIN_ZOOM } from '../../../../constants';
import { selectZoneObjectsGeo, selectZoneObjectsLayer, selectZonesLayer } from '../../../../features';
import { useMapLayerHover, useMapToolTip } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { useAppSelector } from '../../../../store/hooks';

const curbfacesPaint: LinePaint = {
  'line-width': {
    base: 1.5,
    stops: [
      [14, 2.5],
      [20, 3],
    ],
  },
  'line-color': ['get', 'CURBCOLOR'],
};

export const ZoneObjectsLayer: FunctionComponent = () => {
  const zonesLayer = useAppSelector(selectZonesLayer);
  const zoneObjectsGeo = useAppSelector(selectZoneObjectsGeo);
  const zoneObjectsLayer = useAppSelector(selectZoneObjectsLayer);

  const curbfacesLayout: AnyLayout = {
    visibility: zonesLayer.enabled && zoneObjectsLayer.displayCurbfaces ? 'visible' : 'none',
  };

  const localization = useExtendedLocalization();

  const curbfacesLayer = 'curbs-zone-objects-layer';

  useMapToolTip(
    (e) => {
      const description: string = e.features?.[0].properties?.FACILITYID;
      return localization.toLanguageStringF('zone.zoneObjects.curbfaceTooltip', [description]);
    },
    () => zoneObjectsLayer.displayCurbfaces,
    curbfacesLayer,
  );

  useMapLayerHover([curbfacesLayer]);

  return (
    <>
      <Source id='curbfaces-zone-objects-source' type='geojson' data={zoneObjectsGeo.curbfaces} generateId={true}>
        <Layer
          {...{
            id: curbfacesLayer,
            type: 'line',
            layout: curbfacesLayout,
            paint: curbfacesPaint,
            before: 'building-extrusion',
            minzoom: MIN_ZOOM,
          }}
        />
      </Source>
    </>
  );
};
