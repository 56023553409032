/* eslint-disable jsx-a11y/label-has-associated-control */
import { TabStripTab } from '@progress/kendo-react-layout';
import React, { FC, FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Position } from 'geojson';

import {
  currentZone,
  selectAreaTypeNames,
  selectSidebarState,
  selectedAreaActions,
  selectedCurbSpacesActions,
  selectedMetersActions,
  selectedStudyAreasActions,
  selectedZones,
  selectedZonesActions,
  sidebarActions,
} from '../../../../features';
import { useOccupancyTrafficEnabled, useOpenPopupDispatch, useReportBuilder } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ISelectedZone, SidebarName } from '../../../../model';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  ContentPlaceholder,
  Link,
  PinButton,
  PopupBodyPlaceholder,
  PopupHeaderPlaceholder,
  SelfOrientedPopup,
  Spinner,
  TabStrip,
} from '../../../common';
import { PerformanceParkingLabel, ViewReportLink, ViolationsPeriodSelector } from '../../common';
import { RevenuePeriodSelector } from '../../common/revenue-popup-tab/RevenuePeriodSelector';
import { ZoneIcon } from '../../icons';
import { CameraThumbnail } from '../cameras/camera-thumbnail/CameraThumbnail';
import { CurbSpacesStatusCount } from '../curb-spaces/curb-spaces-status-count/CurbSpacesStatusCount';
import { SignThumbnail } from '../signs/sign-thumbnail/SignThumbnail';
import { ISelectedEntity, NavigationSource, PopupTab, PopupType, ReportName, amplitudeService } from '../../../../services';
import { OccupancyTrafficSelector } from '../../common/occupancy-traffic-popup-tab/OccupancyTrafficSelector';

import styles from './ZonePopup.module.scss';
import { PolicyTile } from './PolicyTile';
import { HeatmapPeriod } from '../../../../model/api/heatmap-period';
import { ShowGoogleStreetViewLink } from '../../common/show-google-street-view-link/ShowGoogleStreetViewLink';
import { OccupancyPeriodTypeSelector } from '../../common/occupancy-popup-tab/OccupancyPeriodTypeSelector';

const GENERAL_INFO_TAB = 0;
const REVENUE_TAB = 1;
const ENFORCEMENT_TAB = 2;
const OCCUPANCY_TAB = 3;
const OCCUPANCY_TRAFFIC_TAB = 4;

const TabToAmpTab = new Map<number, PopupTab>();
TabToAmpTab.set(GENERAL_INFO_TAB, PopupTab.ZonesGeneralInfo);
TabToAmpTab.set(REVENUE_TAB, PopupTab.ZonesRevenue);
TabToAmpTab.set(ENFORCEMENT_TAB, PopupTab.ZonesEnforcement);
TabToAmpTab.set(OCCUPANCY_TAB, PopupTab.ZonesOccupancy);
TabToAmpTab.set(OCCUPANCY_TRAFFIC_TAB, PopupTab.ZonesOccupancyTraffic);

const ItemsCount: FC<{ count: number }> = ({ count }) =>
  count > 0 ? (
    <div className={styles.labelDescription}>
      {count} {count === 1 ? 'item' : 'items'}
    </div>
  ) : null;

const PopupItem: FC<{ zoneData: ISelectedEntity<ISelectedZone> }> = ({ zoneData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const selectedSidebar = useAppSelector(selectSidebarState);
  const currentZoneData = useAppSelector(currentZone);
  const areaTypes = useAppSelector(selectAreaTypeNames);
  const localization = useExtendedLocalization();
  const reportsBuilder = useReportBuilder();
  const [selectedTab, setSelectedTab] = useState(0);
  const occupancyTrafficEnabled = useOccupancyTrafficEnabled();

  const zone = zoneData.entity;
  const areaType = zone?.area ? areaTypes[zone?.area?.TypeId] : null;

  const closePopup = useCallback(() => {
    dispatch(selectedZonesActions.closePopup(zoneData.id));
  }, [dispatch, zoneData.id]);

  const pinPopup = useCallback(() => {
    dispatch(selectedZonesActions.pinPopup(zoneData.id));
  }, [dispatch, zoneData.id]);

  const moreInfo = useCallback(() => {
    dispatch(selectedZonesActions.setCurrent(zone));
    dispatch(sidebarActions.setSelected({ name: SidebarName.zone, data: zone?.Id }));
  }, [dispatch, zone]);

  const report = useMemo(() => {
    return zone
      ? selectedTab === REVENUE_TAB
        ? { url: reportsBuilder.getZoneRevenue(zone.Id), name: ReportName.ZoneRevenue }
        : selectedTab === ENFORCEMENT_TAB
        ? { url: reportsBuilder.getOverviewEnforcement(), name: ReportName.Enforcement }
        : selectedTab === OCCUPANCY_TRAFFIC_TAB
        ? { url: reportsBuilder.getOverviewOccupancy(), name: ReportName.OccupancyOverview }
        : null
      : null;
  }, [reportsBuilder, selectedTab, zone]);

  const onDragEnd = useCallback(
    (position: Position) => {
      if (!zone) {
        return;
      }
      dispatch(selectedZonesActions.change({ id: zone.Id, position: position }));
      amplitudeService.trackPopupMove(PopupType.Zones);
    },
    [dispatch, zone],
  );

  const onTabSelect = useCallback(
    (tab: number) => {
      if (selectedTab === tab) return;

      setSelectedTab(tab);

      const ampTab = TabToAmpTab.get(tab);
      if (ampTab !== undefined) {
        amplitudeService.trackPopupTab(ampTab);
      }
    },
    [selectedTab],
  );

  const openSpot = useCallback(
    (spotId: number) => {
      popupDispatch(
        selectedCurbSpacesActions.load({
          id: spotId,
          position: null,
        }),
      );
      amplitudeService.trackPopupOpen(PopupType.CurbSpaces, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openStudyArea = useCallback(
    (studyAreaId: number) => {
      popupDispatch(selectedStudyAreasActions.loadStudyArea({ id: studyAreaId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Study, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openArea = useCallback(
    (areaId: number | undefined) => {
      areaId && popupDispatch(selectedAreaActions.loadArea({ id: areaId, position: null }));
    },
    [popupDispatch],
  );

  const openMeter = useCallback(
    (meterId: number) => {
      popupDispatch(selectedMetersActions.loadMeter({ id: meterId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Meters, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const getViolationReport = useCallback(
    (filter: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => {
      dispatch(
        selectedZonesActions.fetchViolationReport({
          zoneId: zoneData.id,
          filter: filter.heatmapPeriod.calcReportFilter(filter.period),
        }),
      );
    },
    [dispatch, zoneData.id],
  );

  const popupContent = useMemo(() => {
    return (
      <>
        <ContentPlaceholder loading={zoneData.loading} element={<PopupHeaderPlaceholder />}>
          <div className={`map-popup-header ${styles.header}`}>
            <ZoneIcon />
            <h4>Zone: #{zone?.Code} </h4>
            <CurbSpacesStatusCount
              spotsStates={zone?.spotsStates.map((x) => x.Status) || []}
              spotsCount={zone?.SpotsCount}
              className={styles.curbSpacesStatusCount}
            />
            <PinButton className={styles.pinButton} pinned={zoneData.pinnedPopup} onClick={pinPopup} />
          </div>
        </ContentPlaceholder>

        <div className='map-popup-body'>
          <TabStrip onSelectTab={onTabSelect}>
            <TabStripTab title={localization.toLanguageStringF('common.generalInfo')}>
              <ContentPlaceholder loading={zoneData.loading} element={<PopupBodyPlaceholder />}>
                <>
                  {zone?.PerformanceParking && <PerformanceParkingLabel />}

                  <div className={styles.tabContent}>
                    {zone && zone.studyAreas.length > 0 && (
                      <div className='map-popup-row'>
                        <div className={styles.columnLabel}>
                          <label>{localization.toLanguageStringF('zone.studyAreas')}:</label>
                        </div>
                        <div className='row-value'>
                          {zone.studyAreas.map((x, idx) => (
                            <Link key={x.Id} onClick={() => openStudyArea(x.Id)} className={styles.listItem}>
                              <span>{x.Name}</span>
                              {idx < zone.studyAreas.length - 1 && ','}
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className='map-popup-row'>
                      <div className={styles.columnLabel}>
                        <label>{localization.toLanguageStringF('zone.popup.address')}:</label>
                      </div>
                      <div className='row-value'>{zone?.Address}</div>
                    </div>

                    {areaType && (
                      <div className='map-popup-row'>
                        <div className={styles.columnLabel}>
                          <label>{areaType.name}:</label>
                        </div>
                        <div className='row-value'>
                          <Link onClick={() => openArea(zone?.area?.Id)}>{zone?.area?.Name}</Link>
                        </div>
                      </div>
                    )}

                    {zone && zone.camerasStates.length > 0 && (
                      <div className='map-popup-row'>
                        <div className={styles.columnLabel}>
                          <label>{localization.toLanguageStringF('zone.popup.cameras')}:</label>
                          <ItemsCount count={zone.camerasStates.length} />
                        </div>
                        <div className={`map-popup-row ${styles.rowValueWrap}`}>
                          {zone.camerasStates.map((x) => (
                            <div key={x.CameraId} className={styles.thumbnail}>
                              <CameraThumbnail state={x} />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className='map-popup-row'>
                      <div className={styles.columnLabel}>
                        <label>{localization.toLanguageStringF('common.parkingSpacesCount')}:</label>
                      </div>
                      <div className='row-value'>{zone?.SpotsCount}</div>
                    </div>

                    {zone && zone.signsStates.length > 0 && (
                      <div className='map-popup-row'>
                        <div className={styles.columnLabel}>
                          <label>{localization.toLanguageStringF('zone.popup.signs')}:</label>
                          <ItemsCount count={zone.signsStates.length} />
                        </div>
                        <div className={`map-popup-row ${styles.rowValueWrap}`}>
                          {zone.signsStates.map((x) => (
                            <div key={x.SignId} className={styles.thumbnail}>
                              <SignThumbnail state={x} />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {zone && zone.metersNames.length > 0 && (
                      <div className='map-popup-row'>
                        <div className={styles.columnLabel}>
                          <label>{localization.toLanguageStringF('zone.popup.meters')}:</label>
                          <ItemsCount count={zone.metersNames.length} />
                        </div>
                        <div className='row-value' style={{ width: '100%' }}>
                          {zone.metersNames.map((x, idx) => (
                            <Link key={x.Id} onClick={() => openMeter(x.Id)} className={styles.listItem}>
                              <span>{x.Name}</span>
                              {idx < zone.metersNames.length - 1 && ','}
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}

                    {zone && zone.sensors.length > 0 && (
                      <div className='map-popup-row'>
                        <div className={styles.columnLabel}>
                          <label>{localization.toLanguageStringF('zone.popup.sensors')}:</label>
                          <ItemsCount count={zone.sensors.length} />
                        </div>
                        <div className='row-value' style={{ width: '100%' }}>
                          {zone.sensors.map((x, idx) => (
                            <Link key={x.SensorId} onClick={() => openSpot(x.SpotId)} className={styles.listItem}>
                              <span>{x.SensorName}</span>
                              {idx < zone.sensors.length - 1 && ','}
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}

                    {zone && !!zone.policies.length && (
                      <div className='map-popup-row'>
                        <div className={styles.columnLabel}>
                          <label>{localization.toLanguageStringF('zone.popup.policies')}:</label>
                        </div>
                        <div className={`row-value ${styles.policyRowValue}`}>
                          {zone.policies.map((x) => (
                            <PolicyTile key={`policy${x.Id}`} policy={x} />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </ContentPlaceholder>
            </TabStripTab>
            <TabStripTab title={localization.toLanguageStringF('zone.revenue')}>
              <ContentPlaceholder loading={zoneData.loading} element={<PopupBodyPlaceholder />}>
                <RevenuePeriodSelector zoneId={zoneData.id} report={zone?.revenueReport} />
              </ContentPlaceholder>
            </TabStripTab>
            <TabStripTab title={localization.toLanguageStringF('zone.enforcement')}>
              <ContentPlaceholder loading={zoneData.loading} element={<PopupBodyPlaceholder />}>
                <ViolationsPeriodSelector onChangeFilter={getViolationReport} report={zone?.violationReport} />
              </ContentPlaceholder>
            </TabStripTab>
            <TabStripTab title={localization.toLanguageStringF('occupancySource.title')}>
              <ContentPlaceholder loading={zoneData.loading} element={<PopupBodyPlaceholder />}>
                <OccupancyPeriodTypeSelector zoneId={zoneData.id} report={zone?.occupancySourceReport} />
              </ContentPlaceholder>
            </TabStripTab>
            {occupancyTrafficEnabled && (
              <TabStripTab title={localization.toLanguageStringF('zone.occupancyTraffic')}>
                <ContentPlaceholder loading={zoneData.loading} element={<PopupBodyPlaceholder />}>
                  <OccupancyTrafficSelector
                    report={zone?.occupancyTrafficReportData}
                    onChangeFilter={(change) =>
                      dispatch(
                        selectedZonesActions.fetchOccupancyTrafficReportData({
                          zoneId: zoneData.id,
                          filter: change.heatmapPeriod.calcReportFilter(change.period),
                          occupancySource: change.occupancySource,
                        }),
                      )
                    }
                  />
                </ContentPlaceholder>
              </TabStripTab>
            )}
          </TabStrip>

          <div className={`map-popup-row more-info ${styles.moreInfo}`}>
            {zoneData?.position && <ShowGoogleStreetViewLink coordinates={zoneData.position} />}
            {report && <ViewReportLink reportUrl={report?.url} reportName={report?.name} className={styles.reportLink} />}
            <Link onClick={moreInfo} disabled={selectedSidebar === SidebarName.zone && currentZoneData?.id === zone?.Id}>
              {localization.toLanguageStringF('common.moreInfo')}
            </Link>
          </div>
        </div>
      </>
    );
  }, [
    areaType,
    currentZoneData?.id,
    dispatch,
    getViolationReport,
    occupancyTrafficEnabled,
    localization,
    moreInfo,
    onTabSelect,
    openArea,
    openMeter,
    openSpot,
    openStudyArea,
    pinPopup,
    report,
    selectedSidebar,
    zone,
    zoneData.id,
    zoneData.loading,
    zoneData.pinnedPopup,
  ]);

  const content = useMemo(() => {
    if (!zoneData.openPopup || !zoneData.position) return null;

    return (
      <React.StrictMode>
        <SelfOrientedPopup
          data={zone}
          maxWidth='690px'
          longitude={zoneData.position[0]}
          latitude={zoneData.position[1]}
          onClose={closePopup}
          closeOnClick={false}
          className='zone-popup'
          updateOn={selectedTab}
          initPosition={zoneData.initPosition}
          onDragEnd={onDragEnd}
        >
          <div>
            {popupContent}
            {zoneData.loading && <Spinner backdrop={true} />}
          </div>
        </SelfOrientedPopup>
      </React.StrictMode>
    );
  }, [
    closePopup,
    onDragEnd,
    popupContent,
    selectedTab,
    zone,
    zoneData.initPosition,
    zoneData.loading,
    zoneData.openPopup,
    zoneData.position,
  ]);

  return content;
};

export const ZonePopup: FunctionComponent = () => {
  const zonesData = useAppSelector(selectedZones);
  const zones = zonesData.selected;

  const content = useMemo(() => {
    return (
      <>
        {zones.map((x) => (
          <PopupItem key={x.id} zoneData={x} />
        ))}
      </>
    );
  }, [zones]);

  return content;
};
