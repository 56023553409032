import { Period } from './period';

export enum OccupancySource {
  Sensors = 'Sensors',
  Camera = 'Camera',
  Transactional = 'Transactional',
}

export interface IOccupancyTrafficReport {
  OccupancyPercentage: number;
  OccupancyPrevPercentage: number;
  OccupancyDiffPercentage: number;

  MaxSpeed: number;
  CurrAvgSpeed: number;
  PervAvgSpeed: number;
  DiffPercentageAvgSpeed: number;

  Count: number;
  PrevCount: number;
  DiffCount: number;

  Turnover: number;
  PrevTurnover: number;
  DiffTurnover: number;

  DwellTime: number;
  PrevDwellTime: number;
  DiffDwellTime: number;

  Occupancy: number;
  PrevOccupancy: number;
  DiffOccupancy: number;

  OccupancyHourChartData: IHourItem[];
  OccupancyDayChartData: IDayItem[];

  TrafficHourChartData: IHourItem[];
  TrafficDayChartData: IDayItem[];
}

export interface IHourItem {
  Hour: number;
  Value: number;
}

export interface IDayItem {
  Day: number;
  Value: number;
}

export interface IOccupancyReportItem {
  Period: Period;

  PrevCount: number;
  Count: number;
  DiffCount: number;

  PrevOccupancyRate: number;
  OccupancyRate: number;
  DiffOccupancyRate: number;

  PrevDwellTime: number;
  DwellTime: number;
  DiffDwellTime: number;

  PrevTurnover: number;
  Turnover: number;
  DiffTurnover: number;
}
