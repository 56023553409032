import { FunctionComponent, useCallback, useMemo } from 'react';
import { Layer, Source, MapLayerMouseEvent } from 'react-map-gl';
import { AnyLayout, CirclePaint, FillPaint, LinePaint, SymbolLayout, SymbolPaint } from 'mapbox-gl';

import { useMapLayerHover, useMapLayerPopup, useMapToolTip } from '../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectLoadingZonesGeo,
  selectLoadingZonesGeoOccupiedCount,
  selectLoadingZonesLayer,
  selectLoadingZonesSpotsGeo,
  selectedLoadingZonesActions,
} from '../../../../features';

import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { LoadingZonePopup } from './LoadingZonePopup';
import { MapImage } from '../../controls';

const COLOR = '#FFE600';
const OUTLINE_COLOR = '#525150';
const OPACITY = 0.5;

const zonesLines: LinePaint = {
  'line-color': COLOR,
  'line-width': {
    base: 6,
    stops: [
      [14, 8],
      [16, 10],
      [17, 12],
    ],
  },
};
const zonesPolygons: FillPaint = {
  'fill-color': COLOR,
  'fill-outline-color': OUTLINE_COLOR,
  'fill-opacity': OPACITY,
};

const zonesCircles: CirclePaint = {
  'circle-radius': {
    base: 3,
    stops: [
      [16, 4],
      [17, 8],
      [19, 20],
      [20, 40],
      [21, 60],
    ],
  },
  'circle-color': COLOR,
  'circle-opacity': OPACITY,
};
const zoneCodes: SymbolPaint = {
  'text-color': OUTLINE_COLOR,
};

const spotsPaint: CirclePaint = {
  'circle-radius': {
    base: 2,
    stops: [
      [16, 4],
      [17, 8],
      [19, 20],
      [20, 40],
      [21, 60],
    ],
  },
  'circle-color': ['match', ['get', 'status'], 'Vacant', '#00B62D', 'Occupied', 'red', 'Booked', '#cccc00', /* other */ '#262626'],
  'circle-opacity': 0.3,
};

const occupiedCountPaint: SymbolPaint = {
  'text-color': '#fff',
  'text-translate': ['interpolate', ['linear'], ['zoom'], 15, ['literal', [9, -9]], 16, ['literal', [13, -13]], 17, ['literal', [16, -16]]],
  'icon-translate': ['interpolate', ['linear'], ['zoom'], 15, ['literal', [9, -9]], 16, ['literal', [13, -13]], 17, ['literal', [16, -16]]],
};

export const LoadingZonesLayer: FunctionComponent = () => {
  const zoneLinesLayer = 'loading-zones-lines';
  const zonePointsLayer = 'loading-zone-points';
  const zonePolygonsLayer = 'loading-zone-polygons';
  const zoneCodesLayer = 'loading-zone-codes';
  const spotsLayer = 'spots';

  const layers = useMemo(() => [zoneLinesLayer, zonePointsLayer, zonePolygonsLayer, zoneCodesLayer], []);
  const localization = useExtendedLocalization();
  const zonesLayer = useAppSelector(selectLoadingZonesLayer);
  const dispatch = useAppDispatch();
  const geojson = useAppSelector(selectLoadingZonesGeo);
  const spotsGeojson = useAppSelector(selectLoadingZonesSpotsGeo);
  const occupiedCountGeojson = useAppSelector(selectLoadingZonesGeoOccupiedCount);

  useMapToolTip(
    (event: MapLayerMouseEvent) => {
      if (event.features && event.features.length > 0) {
        const code = event.features[0].properties?.code;

        return localization.toLanguageStringF('loadingZone.toolTip', [code]);
      }

      return localization.toLanguageStringF('loadingZone.toolTip', ['']);
    },
    () => zonesLayer.enabled,
    ...layers,
  );

  const layout: AnyLayout = {
    visibility: zonesLayer.enabled ? 'visible' : 'none',
  };
  const codesLayout: SymbolLayout = {
    visibility: zonesLayer.enabled && zonesLayer.displayZoneId ? 'visible' : 'none',
    'text-field': ['get', 'code'],
    'symbol-placement': 'line-center',
    'text-allow-overlap': true,
    'text-size': {
      base: 14,
      stops: [
        [15, 10],
        [16, 12],
        [17, 14],
      ],
    },
  };

  const occupiedCountLayout: SymbolLayout = {
    visibility: zonesLayer.enabled ? 'visible' : 'none',
    'text-field': ['get', 'vacantCount'],
    'text-allow-overlap': true,
    'text-size': {
      base: 14,
      stops: [
        [15, 8],
        [16, 10],
        [17, 14],
      ],
    },
    'icon-image': ['match', ['get', 'vacantCount'], 0, 'loading-zone-occupied-icon', /* default */ 'loading-zone-free-icon'],
    'icon-allow-overlap': true,
    'icon-size': {
      base: 0.8,
      stops: [
        [15, 0.6],
        [16, 0.7],
        [17, 0.8],
      ],
    },
  };

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      dispatch(selectedLoadingZonesActions.closePopup());
      const feature = evt.features ? evt.features[0] : null;
      if (!feature || !feature.properties) {
        return;
      }

      const zoneId = feature.properties.id;
      const position = [evt.lngLat.lng, evt.lngLat.lat];
      setTimeout(() => {
        dispatch(
          selectedLoadingZonesActions.loadZone({
            id: zoneId,
            position: position,
          }),
        );
      }, 10);
    },
    [dispatch],
  );

  useMapLayerPopup(handleLayerClick, ...layers);
  useMapLayerHover(layers);

  return (
    <>
      <Source id='loading-zones-source' type='geojson' data={geojson} generateId={true}>
        <Layer
          {...{
            id: zonePolygonsLayer,
            type: 'fill',
            layout,
            paint: zonesPolygons,
            filter: ['==', '$type', 'Polygon'],
          }}
        />
        <Layer
          {...{
            id: zoneLinesLayer,
            type: 'line',
            layout,
            paint: zonesLines,
            filter: ['==', '$type', 'LineString'],
          }}
        />
        <Layer
          {...{
            id: zonePointsLayer,
            type: 'circle',
            layout,
            paint: zonesCircles,
            filter: ['==', '$type', 'Point'],
          }}
        />

        <Layer
          {...{
            id: zoneCodesLayer,
            type: 'symbol',
            layout: codesLayout,
            paint: zoneCodes,
            before: 'building-extrusion',
            filter: ['has', 'code'],
          }}
        />
      </Source>

      <Source id='loading-zones-spots-source' type='geojson' data={spotsGeojson} generateId={true}>
        <Layer
          {...{
            id: spotsLayer,
            type: 'circle',
            layout,
            paint: spotsPaint,
            before: 'building-extrusion',
          }}
        />
      </Source>

      <Source id='loading-zones-occupied-source' type='geojson' data={occupiedCountGeojson} generateId={true}>
        <Layer
          {...{
            id: 'loading-zone-occupied',
            type: 'symbol',
            layout: occupiedCountLayout,
            paint: occupiedCountPaint,
            before: 'building-extrusion',
          }}
        />
        <MapImage name='loading-zone-free-icon' url='/icons/loading-zone-free.png' />
        <MapImage name='loading-zone-occupied-icon' url='/icons/loading-zone-occupied.png' />
      </Source>

      <LoadingZonePopup />
    </>
  );
};
