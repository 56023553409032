import { IArea, IAreaType, ISelectedArea } from '../../../../model';

interface IAreaCounts {
  name: string;
  typeId: number;
  descendants: IArea[];
}

interface IAreasByTypeId {
  [key: string]: { name: string; descendants: IArea[] };
}

function collectAreasByTypeId(area: ISelectedArea | null): IAreaCounts[] {
  const areaCounts: IAreaCounts[] = [];
  const areasByTypeId: IAreasByTypeId = {};
  if (area) {
    area.Descendants.forEach((value: IArea) => {
      if (areasByTypeId[value.TypeId]) {
        areasByTypeId[value.TypeId].descendants.push(value);
      } else {
        areasByTypeId[value.TypeId] = { name: value.Name, descendants: [value] };
      }
    });
  }

  Object.keys(areasByTypeId).forEach((key: string) => {
    areaCounts.push({
      name: areasByTypeId[key].name,
      typeId: Number(key),
      descendants: areasByTypeId[key].descendants,
    });
  });

  return areaCounts;
}

export function areaUtility(area: ISelectedArea | null, areaTypes: IAreaType[]) {
  const areaCounts = collectAreasByTypeId(area);
  const isNotEmptyChildren = !!areaCounts.find((area) => areaTypes && !!areaTypes[area.typeId]);
  const showSidebar =
    (isNotEmptyChildren && areaCounts.length > 0) ||
    (area?.offstreetZoneNames?.length || 0) > 0 ||
    (area?.zoneNames?.length || 0) > 0 ||
    (area?.revenue?.length || 0) > 0;

  return {
    areaCounts,
    isNotEmptyChildren,
    showSidebar,
  };
}
