/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { FunctionComponent } from 'react';
import classNames from 'classnames';

import { Link } from '../../../../common';
import { SearchItemProps } from '../Search';
import { useExtendedLocalization } from '../../../../../hooks/use-extended-localization-service';

export const MoreResultsItem: FunctionComponent<SearchItemProps> = ({ model, onItemSelected }) => {
  const localization = useExtendedLocalization();

  return (
    <li className={classNames('item')} onClick={() => onItemSelected(model)}>
      <Link className={classNames('moreResults')} onClick={() => {}}>
        {localization.toLanguageStringF('search.moreResults')}
      </Link>
    </li>
  );
};
