import { FunctionComponent, useCallback } from 'react';
import { Layer, Source, MapLayerMouseEvent } from 'react-map-gl';
import { AnyLayout, SymbolPaint } from 'mapbox-gl';

import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { selectCamerasLayer, selectCamerasGeo, selectedCamerasActions } from '../../../../features';
import { MapImage } from '../../controls';
import { CameraPopup } from './CameraPopup';
import { useMapLayerHover, useMapLayerPopup, useMapToolTip } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { NavigationSource, PopupType, amplitudeService } from '../../../../services';

const paint: SymbolPaint = {
  'text-color': 'black',
};

export const CamerasLayer: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const geojson = useAppSelector(selectCamerasGeo);
  const layer = useAppSelector(selectCamerasLayer);
  const localization = useExtendedLocalization();

  const layerId = 'cameras-layer';
  useMapToolTip(
    () => localization.toLanguageStringF('camera.toolTip'),
    () => layer.enabled,
    layerId,
  );

  const layout: AnyLayout = {
    visibility: layer.enabled ? 'visible' : 'none',
    'icon-image': [
      'match',
      ['get', 'status'],
      'Offline',
      'point-camera-offline',
      'Inactive',
      'point-camera-inactive',
      'Active',
      'point-camera-active',
      'point-camera-offline',
    ],
    'icon-allow-overlap': true,
    'icon-size': {
      base: 0.4,
      stops: [
        [16, 0.6],
        [18, 0.8],
        [20, 1.4],
        [21, 1.6],
      ],
    },
    'text-font': ['Open Sans Regular'],
    'text-offset': [0, 1.25],
    'text-anchor': 'top',
  };

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      dispatch(selectedCamerasActions.closePopup());
      const feature = evt.features ? evt.features[0] : null;
      if (!feature || !feature.properties) return;

      const id = feature.properties.id;
      const position = [evt.lngLat.lng, evt.lngLat.lat];
      setTimeout(() => {
        dispatch(selectedCamerasActions.loadCamera({ id, position }));
        amplitudeService.trackPopupOpen(PopupType.Cameras, NavigationSource.Map);
      }, 10);
    },
    [dispatch],
  );

  useMapLayerPopup(handleLayerClick, layerId);

  useMapLayerHover([layerId]);

  return (
    <>
      <Source id='cameras-source' type='geojson' data={geojson} generateId={true}>
        <Layer
          {...{
            id: layerId,
            type: 'symbol',
            layout: layout,
            paint: paint,
            before: 'building-extrusion',
          }}
        />
      </Source>

      <CameraPopup />

      <MapImage name='point-camera-offline' url='/icons/cameras/offline.png' />
      <MapImage name='point-camera-inactive' url='/icons/cameras/inactive.png' />
      <MapImage name='point-camera-active' url='/icons/cameras/active.png' />
    </>
  );
};
