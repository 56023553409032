/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, useState, useEffect, useMemo } from 'react';
import classNames from 'classnames/bind';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';

import { ILayersSettings, ISavedView } from '../../../model';
import { ColorDropDownList } from './ColorDropDownList';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { savedViewsActions, selectSavedViews } from '../../../features';
import { useExtendedLocalization } from '../../../hooks/use-extended-localization-service';
import { AmplitudeEvent, amplitudeService } from '../../../services';

import styles from './SavedViews.module.scss';

const cx = classNames.bind(styles);

enum EditMode {
  None,
  Add,
  Update,
  Delete,
}

interface ColorMap {
  id: string;
  color: string;
  borderColor: string;
}

const colorsMap: ColorMap[] = [
  { id: 'blue', color: '#BFDDF5', borderColor: '#0078D7' },
  { id: 'red', color: '#F9D1D5', borderColor: '#E74856' },
  { id: 'yellow', color: '#FFEDBF', borderColor: '#FFB900' },
  { id: 'purple', color: '#E1D8ED', borderColor: '#8764B8' },
  { id: 'green', color: '#BFF2DA', borderColor: '#00CC6A' },
];

interface Props {
  onEditEnabled: (enabled: boolean) => void;
  layersSettingsForSave?: ILayersSettings; //triggered by Save click
  cancelEdit?: boolean; //triggerred by Cancel click
}

export const SavedViews: FC<Props> = ({ onEditEnabled, layersSettingsForSave, cancelEdit }) => {
  const dispatch = useAppDispatch();
  const localization = useExtendedLocalization();
  const viewsData = useAppSelector(selectSavedViews);

  const [editMode, setEditMode] = useState(EditMode.None);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [name, setName] = useState<string>();
  const [color, setColor] = useState<string>();
  const [nameValidationMessage, setNameValidationMessage] = useState<string>();

  const colors = useMemo(() => {
    return colorsMap.map((x) => ({ id: x.id, color: x.color, name: localization.toLanguageStringF(`savedView.colors.${x.id}`) }));
  }, [localization]);

  const validateName = (value: string) => {
    if (!value) {
      setNameValidationMessage(localization.toLanguageStringF('savedView.nameEmpty'));
      return false;
    }

    const sameNameIndex = viewsData.views.findIndex((x) => x.name === value);
    if (sameNameIndex > -1 && sameNameIndex !== selectedIndex) {
      setNameValidationMessage(localization.toLanguageStringF('savedView.nameAlreadyInUse'));
      return false;
    }

    setNameValidationMessage('');
    return true;
  };

  useEffect(() => {
    if (layersSettingsForSave && color && name !== undefined) {
      if (!validateName(name)) {
        return;
      }

      const view: ISavedView = {
        name: name,
        color: color,
        layers: layersSettingsForSave,
      };
      if (editMode === EditMode.Add) {
        dispatch(savedViewsActions.add({ view }));
        amplitudeService.track(AmplitudeEvent.CreateView);
      } else if (editMode === EditMode.Update && selectedIndex !== null) {
        dispatch(savedViewsActions.update({ view, index: selectedIndex }));
        amplitudeService.track(AmplitudeEvent.UpdateView);
      }
      setEditMode(EditMode.None);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layersSettingsForSave]);

  useEffect(() => {
    if (cancelEdit !== undefined) {
      setEditMode(EditMode.None);
      setName('');
      setColor(colorsMap[0].id);
      setNameValidationMessage('');
    }
  }, [cancelEdit]);

  useEffect(() => {
    onEditEnabled(editMode === EditMode.Add || editMode === EditMode.Update);
  }, [editMode, onEditEnabled]);

  const onSavedViewSelect = (view: ISavedView, index: number) => {
    setEditMode(EditMode.None);
    setSelectedIndex(index);
    dispatch(savedViewsActions.applySavedLayersSettings(view.layers));
    amplitudeService.track(AmplitudeEvent.OpenView);
  };

  const onNewClick = () => {
    setEditMode(EditMode.Add);
    setSelectedIndex(null);
    setName('');
    setColor(colorsMap[0].id);
  };

  const onUpdateClick = () => {
    setEditMode(EditMode.Update);
    if (selectedIndex !== null) {
      setName(viewsData.views[selectedIndex].name);
      setColor(viewsData.views[selectedIndex].color);
    }
  };

  const onDeleteClick = () => {
    if (selectedIndex !== null) {
      dispatch(savedViewsActions.delete({ index: selectedIndex }));
      amplitudeService.track(AmplitudeEvent.RemoveView);
    }
    setEditMode(EditMode.None);
    setSelectedIndex(null);
  };

  return (
    <div className='map-sidebar-row'>
      <div className={styles.spaceBetween}>
        <label>{localization.toLanguageStringF('savedView.sidebarTitle')}</label>
        <Button className={styles.addButton} icon='add' onClick={onNewClick}>
          {localization.toLanguageStringF('savedView.new')}
        </Button>
      </div>
      <div className={styles.tags}>
        {viewsData.views.map((x, idx) => {
          const color = colorsMap.find((c) => c.id === x.color);
          return (
            <button
              key={idx}
              style={{
                backgroundColor: color?.color,
                borderColor: selectedIndex === idx ? color?.borderColor : '',
              }}
              className={cx('tag', 'nameLabel', { bold: selectedIndex === idx })}
              onClick={() => onSavedViewSelect(x, idx)}
            >
              <span>{x.name}</span>
            </button>
          );
        })}
      </div>
      {(editMode === EditMode.Add || editMode === EditMode.Update) && (
        <>
          <div>
            <Input
              className={cx('bold', { invalidInput: !!nameValidationMessage })}
              maxLength={50}
              value={name}
              onChange={(ev) => setName(ev.value)}
            />
            <div className={styles.validation}>{nameValidationMessage}</div>
          </div>
          <div className={styles.row}>
            <label>{localization.toLanguageStringF('savedView.labelColor')}</label>
            <div className={styles.rowValue}>
              <ColorDropDownList className={styles.colorDropDown} colors={colors} initialId={color || null} colorSelected={setColor} />
            </div>
          </div>
        </>
      )}
      {selectedIndex !== null && (editMode === EditMode.None || editMode === EditMode.Delete) && (
        <div className={styles.spaceBetween}>
          <label className={cx('nameLabel', 'bold')}>{viewsData.views[selectedIndex].name}</label>
          <div className={styles.editButtons}>
            {editMode === EditMode.None ? (
              <>
                <Button className={styles.button} fillMode='flat' size='small' icon='edit' onClick={onUpdateClick} />
                <Button className={styles.button} fillMode='flat' size='small' icon='delete' onClick={() => setEditMode(EditMode.Delete)} />
              </>
            ) : (
              <>
                <Button className={styles.button} fillMode='flat' size='small' icon='check' onClick={onDeleteClick} />
                <Button className={styles.button} fillMode='flat' size='small' icon='close' onClick={() => setEditMode(EditMode.None)} />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
