/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from '@progress/kendo-react-buttons';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import React, { FC, useCallback, useState } from 'react';

import {
  currentCurbSpace,
  heatmapsFilterActions,
  selectCurbSpacesHasTransaction,
  selectedCurbSpacesActions,
  selectedMetersActions,
  selectedStudyAreasActions,
  selectedZonesActions,
  selectHeatmapFilters,
  sidebarActions,
} from '../../../../features';
import { useOpenPopupDispatch, useReportBuilder } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ISelectedCurbSpace, OccupancySource, SidebarName } from '../../../../model';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { dateUtils, geoUtils } from '../../../../utils';
import { Link } from '../../../common';
import { ParkingEventsList } from '../../common/parking-events/ParkingEventsList';
import { MapSidebarContainer } from '../../controls';
import { CameraThumbnail } from '../cameras/camera-thumbnail/CameraThumbnail';
import { SignThumbnail } from '../signs/sign-thumbnail/SignThumbnail';
import { CurbSpaceOccupancyStatusText } from './CurbSpaceOccupancyStatusText';
import { FilterName, ISelectedEntity, NavigationSource, PopupType, ReportName, amplitudeService } from '../../../../services';
import { OccupancyTable, SidebarSectionLazyLoader } from '../../common';
import { MeterTransactionsTable } from '../meters/MeterTransactionsTable';
import { RevenueTable } from '../../../revenue';
import { ZoneKpiTable } from '../../common/zone-kpi-table/ZoneKpiTable';

import styles from './CurbSpaceSidebar.module.scss';
import { OccupancySourceSelector } from '../../common/occupancy-source-selector/OccupancySourceSelector';

const SidebarRow: FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
  <div className='map-sidebar-row'>
    <label>{title}</label>
    <div>{children}</div>
  </div>
);

interface Props {
  curbSpaceData: ISelectedEntity<ISelectedCurbSpace>;
}

const Sidebar: FC<Props> = ({ curbSpaceData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const curbSpace = curbSpaceData.entity;
  const localization = useExtendedLocalization();
  const hasTransaction = useAppSelector(selectCurbSpacesHasTransaction);
  const heatmapsFilter = useAppSelector(selectHeatmapFilters);
  const [occupancySource, setOccupancySource] = useState(heatmapsFilter.reportOccupancySource ?? heatmapsFilter.heatmapOccupancySource);
  const reportsBuilder = useReportBuilder();

  const onReportOccupancySourceChanged = useCallback(
    (occupancySource: OccupancySource) => {
      dispatch(heatmapsFilterActions.setReportOccupancySource(occupancySource));
      setOccupancySource(occupancySource);
      amplitudeService.trackFilter(FilterName.ReportOccupancySource, occupancySource);
    },
    [dispatch],
  );

  const handleCloseClick = (ev: React.FormEvent) => {
    ev.preventDefault();
    dispatch(sidebarActions.clearSelected());
  };

  const mapViewClick = () => {
    dispatch(selectedCurbSpacesActions.openPopup(curbSpaceData.id));
  };

  const openZone = useCallback(
    (zoneId: number | undefined) => {
      if (!zoneId) return;

      popupDispatch(selectedZonesActions.loadZone({ id: zoneId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Zones, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openMeter = useCallback(
    (meterId: number) => {
      popupDispatch(selectedMetersActions.loadMeter({ id: meterId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Meters, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openStudyArea = useCallback(
    (studyAreaId: number) => {
      popupDispatch(selectedStudyAreasActions.loadStudyArea({ id: studyAreaId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Study, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  if (!curbSpace) {
    return null;
  }

  const revenueReportUrl = reportsBuilder.getSpotRevenue(curbSpace.Id);

  const revenueMoreInfo = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (revenueReportUrl) {
      window.open(revenueReportUrl, '_blank');
      amplitudeService.trackReport(ReportName.SpotRevenue, NavigationSource.Sidebar);
    }
  };

  const cameraEvent = curbSpace.cameraEvents.length ? curbSpace.cameraEvents[0] : null;

  return (
    <div className='map-sidebar map-sidebar map-sidebar-info'>
      <div className='map-sidebar-header'>
        <h3>{localization.toLanguageStringF('curbSpace.sidebar.title', [curbSpace.Name])}</h3>
        <Button size='small' className='close' icon='close' onClick={handleCloseClick}></Button>
      </div>

      <div className='map-sidebar-body'>
        <PanelBar keepItemsMounted={true}>
          <PanelBarItem title={localization.toLanguageStringF('common.generalInfo')} expanded={true}>
            <div>
              <SidebarRow title={localization.toLanguageStringF('common.status') + ':'}>
                {curbSpace.state && <CurbSpaceOccupancyStatusText status={curbSpace.state.Status} />}
              </SidebarRow>
              {curbSpace.studyAreas.length > 0 && (
                <SidebarRow title={`${localization.toLanguageStringF('curbSpace.studyAreas')}:`}>
                  {curbSpace.studyAreas.map((x) => (
                    <Link key={x.Id} className={styles.meterButton} onClick={() => openStudyArea(x.Id)}>
                      {x.Name}
                    </Link>
                  ))}
                </SidebarRow>
              )}
              <SidebarRow title={localization.toLanguageStringF('common.zone') + ':'}>
                <Link onClick={() => openZone(curbSpace?.zone?.Id)} className={styles.link}>
                  {curbSpace?.zone?.Name}
                </Link>
              </SidebarRow>
              <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.meters') + ':'}>
                {curbSpace.metersNames.map((x) => (
                  <Link key={x.Id} className={styles.meterButton} onClick={() => openMeter(x.Id)}>
                    {x.Name}
                  </Link>
                ))}
              </SidebarRow>
              <SidebarRow title={localization.toLanguageStringF('common.geo') + ':'}>{geoUtils.toUiString(curbSpace.Position)}</SidebarRow>
              {curbSpace.state?.CompanyName && (
                <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.carrier') + ':'}>
                  {curbSpace.state.CompanyName}
                </SidebarRow>
              )}
            </div>
            <div className='map-sidebar-row more-info'>
              <Link onClick={mapViewClick} className='more-info-button' disabled={curbSpaceData.openPopup}>
                {localization.toLanguageStringF('common.mapView')}
              </Link>
            </div>
          </PanelBarItem>

          <PanelBarItem title={localization.toLanguageStringF('curbSpace.sidebar.events')} expanded={false}>
            <SidebarSectionLazyLoader
              hasData={(curbSpace.events?.length || 0) > 0}
              shouldLoadData={curbSpace.events === undefined}
              loadData={() => dispatch(selectedCurbSpacesActions.fetchEvents(curbSpace.Id))}
            >
              <ParkingEventsList className={styles.panelMargin} events={curbSpace.events || []} openPopupOnSelect={true} />
            </SidebarSectionLazyLoader>
          </PanelBarItem>

          {curbSpace.cameraState && (
            <PanelBarItem title={localization.toLanguageStringF('curbSpace.sidebar.camera.title')} expanded={false}>
              <div className='map-sidebar-row'>
                <CameraThumbnail state={curbSpace.cameraState} thumbnailWidth={110} thumbnailHeight={70} />
                {cameraEvent && (
                  <div className={styles.rightColumn}>
                    <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.camera.timeIn')}>
                      {dateUtils.toLocalUiString(cameraEvent.Start, false, true)}
                    </SidebarRow>
                    <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.camera.timeOut')}>
                      {dateUtils.toLocalUiString(cameraEvent.End, false, true)}
                    </SidebarRow>
                    <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.camera.duration')}>
                      {dateUtils.diffMinutes(cameraEvent.Start, cameraEvent.End)}
                    </SidebarRow>
                    <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.camera.type')}>
                      {cameraEvent.VehicleType}
                    </SidebarRow>
                    <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.camera.event')}>
                      {localization.toLanguageStringF(`parkingEvent.lane.${cameraEvent.EventType}.l`) || cameraEvent.EventType}
                    </SidebarRow>
                  </div>
                )}
              </div>
            </PanelBarItem>
          )}

          {curbSpace.signState && (
            <PanelBarItem title={localization.toLanguageStringF('curbSpace.sidebar.sign.title')} expanded={false} keepItemsMounted={true}>
              <SignThumbnail state={curbSpace.signState} thumbnailWidth={106} className={styles.panelMargin} />
            </PanelBarItem>
          )}

          <PanelBarItem title={localization.toLanguageStringF('curbSpace.sidebar.occupancyReport.title')} expanded={false}>
            <div className={styles.wrapContainer}>
              <OccupancySourceSelector value={occupancySource} onChange={onReportOccupancySourceChanged} />
            </div>

            <SidebarSectionLazyLoader
              hasData={(curbSpace.occupancyReport?.length || 0) > 0}
              shouldLoadData={curbSpace.occupancyReport === undefined}
              reloadDependencies={[occupancySource]}
              loadData={() =>
                dispatch(
                  selectedCurbSpacesActions.fetchOccupancyReport({
                    curbSpaceId: curbSpace.Id,
                    occupancySource: occupancySource,
                  }),
                )
              }
            >
              <OccupancyTable occupancyReport={curbSpace.occupancyReport || []} />
            </SidebarSectionLazyLoader>
          </PanelBarItem>

          {hasTransaction && (
            <PanelBarItem title={localization.toLanguageStringF('curbSpace.sidebar.transactions.title')} expanded={false}>
              <SidebarSectionLazyLoader
                hasData={(curbSpace.transactions?.length || 0) > 0}
                shouldLoadData={curbSpace.transactions === undefined}
                loadData={() => dispatch(selectedCurbSpacesActions.fetchTransactions(curbSpace.Id))}
              >
                <MeterTransactionsTable transactions={curbSpace.transactions || []} className={styles.panelMargin}></MeterTransactionsTable>
                <div className='map-sidebar-row more-info'>
                  {revenueReportUrl && (
                    <Link onClick={revenueMoreInfo} className='more-info-button'>
                      {localization.toLanguageStringF('common.moreInfo')}
                    </Link>
                  )}
                </div>
              </SidebarSectionLazyLoader>
            </PanelBarItem>
          )}

          {hasTransaction && (
            <PanelBarItem title={localization.toLanguageStringF('curbSpace.sidebar.revenue.title')} expanded={false}>
              <SidebarSectionLazyLoader
                hasData={(curbSpace.revenue?.length || 0) > 0}
                shouldLoadData={curbSpace.revenue === undefined}
                loadData={() => dispatch(selectedCurbSpacesActions.fetchRevenue(curbSpace.Id))}
              >
                <RevenueTable revenue={curbSpace.revenue || []} className={styles.panelMargin} />
                <div className='map-sidebar-row more-info'>
                  {revenueReportUrl && (
                    <Link onClick={revenueMoreInfo} className='more-info-button'>
                      {localization.toLanguageStringF('common.moreInfo')}
                    </Link>
                  )}
                </div>
              </SidebarSectionLazyLoader>
            </PanelBarItem>
          )}

          {hasTransaction && (
            <PanelBarItem title={localization.toLanguageStringF('common.report.kpi')} expanded={false}>
              <SidebarSectionLazyLoader
                hasData={(curbSpace.kpiReport?.length || 0) > 0}
                shouldLoadData={curbSpace.kpiReport === undefined}
                loadData={() => dispatch(selectedCurbSpacesActions.fetchKpiReport(curbSpace.Id))}
              >
                <ZoneKpiTable kpiReport={curbSpace.kpiReport || []} className={styles.panelMargin} />
              </SidebarSectionLazyLoader>
            </PanelBarItem>
          )}
        </PanelBar>
      </div>
    </div>
  );
};

export const CurbSpaceSidebar: FC = () => {
  const current = useAppSelector(currentCurbSpace);

  return current?.entity ? (
    <MapSidebarContainer name={SidebarName.curbSpace}>
      <Sidebar curbSpaceData={current} />
    </MapSidebarContainer>
  ) : null;
};
