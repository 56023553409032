/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useCallback } from 'react';
import { PopupEvent } from 'react-map-gl';

import { ISelectedCustomLayer, selectedCustomLayers, selectedCustomLayersActions } from '../../../../features';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

import styles from './CustomLayerPopup.module.scss';

import { Position } from 'geojson';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ISelectedEntity, PopupType, amplitudeService } from '../../../../services';
import { PinButton } from '../../../common';
import { SelfOrientedPopup } from '../../../common/self-oriented-popup/SelfOrientedPopup';

export const PopupItem: FC<{ layerData: ISelectedEntity<ISelectedCustomLayer, string> }> = ({ layerData }) => {
  const dispatch = useAppDispatch();
  const layer = layerData.entity;
  const localization = useExtendedLocalization();

  const closePopup = useCallback(
    (event: PopupEvent) => {
      dispatch(selectedCustomLayersActions.closePopup(layerData.id));
    },
    [dispatch, layerData.id],
  );

  const pinPopup = useCallback(() => {
    dispatch(selectedCustomLayersActions.pinPopup(layerData.id));
  }, [dispatch, layerData.id]);

  const onDragEnd = useCallback(
    (position: Position) => {
      if (!layerData) {
        return;
      }
      dispatch(selectedCustomLayersActions.change({ id: layerData.id, position: position }));
      amplitudeService.trackPopupMove(PopupType.StreetObjects);
    },
    [dispatch, layerData],
  );

  if (!layerData.openPopup || !layerData.position || !layer) return null;

  const content = (
    <>
      <div className={`map-popup-header ${styles.header}`}>
        <h4>{layer.LayerName}</h4>
        <PinButton className={styles.pinButton} pinned={layerData.pinnedPopup} onClick={pinPopup} />
      </div>

      <div className={`map-popup-body ${styles.mapPopupBody}`}>
        <div className={styles.columns}>
          <div>
            <div className='map-popup-row'>
              <label>{localization.toLanguageStringF('customLayer.popup.featureId')}</label>
              <div className='row-value'>{layer?.FeatureId}</div>
            </div>

            {Object.keys(layer.Properties).map((x, index) => {
              const propValue = layer.Properties[x];
              return (
                <div key={index} className='map-popup-row'>
                  <label>{x}:</label>
                  <div className='row-value'>{propValue}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <React.StrictMode>
      <SelfOrientedPopup
        data={layer}
        longitude={layerData.position[0]}
        latitude={layerData.position[1]}
        onClose={closePopup}
        closeOnClick={false}
        className='custom-layer-popup'
        maxWidth='540px'
        initPosition={layerData.initPosition}
        onDragEnd={onDragEnd}
      >
        <div className={`${styles.popup}`}>{content}</div>
      </SelfOrientedPopup>
    </React.StrictMode>
  );
};

export const CustomLayerPopup: FC = () => {
  const layersData = useAppSelector(selectedCustomLayers);
  const layers = layersData.selected;

  return (
    <>
      {layers.map((x) => (
        <PopupItem key={x.id} layerData={x} />
      ))}
    </>
  );
};
