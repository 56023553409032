import { Button } from '@progress/kendo-react-buttons';
import { Popup } from '@progress/kendo-react-popup';
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';

import { heatmapsFilterActions, selectHeatmapFilters } from '../../../../features';
import { HeatmapPeriod } from '../../../../model/api/heatmap-period';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { HeatmapPeriodSelector } from '../heatmap-period-selector/HeatmapPeriodSelector';
import styles from './ReportPeriodSelector.module.scss';

interface Props {
  onChangeFilter: (change: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => void;
  children?: ReactNode;
}

export const ReportPeriodSelector: FC<Props> = ({ onChangeFilter, children }) => {
  const localization = useExtendedLocalization();
  const [showPeriodHint, setShowPeriodHint] = useState(false);
  const popupAnchor = useRef<HTMLSpanElement | null>(null);
  const dispatch = useAppDispatch();
  const firstRender = useRef(true);

  const heatmapFilter = useAppSelector(selectHeatmapFilters);

  const [heatmapPeriod, setHeatmapPeriod] = useState(
    heatmapFilter.reportPeriodFilter?.heatmapPeriod ?? heatmapFilter.periodFilter.heatmapPeriod,
  );
  const [period, setPeriod] = useState(heatmapFilter.reportPeriodFilter?.period ?? heatmapFilter.periodFilter.period);

  const dragStartHandler = () => {
    setShowPeriodHint(false);
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      onChangeFilter({ heatmapPeriod: heatmapPeriod, period: period });
    }
  });

  useEffect(() => {
    document.addEventListener('dragstart', dragStartHandler);
    return () => {
      document.removeEventListener('dragstart', dragStartHandler);
    };
  }, []);

  const onChangeHeatmapPeriodSelector = useCallback(
    (change: { heatmapPeriod?: HeatmapPeriod; period?: [Date, Date] }) => {
      onChangeFilter({ heatmapPeriod: change.heatmapPeriod ?? heatmapPeriod, period: change.period ?? period });

      if (change.period) {
        setPeriod(change.period);
      }

      if (change.heatmapPeriod) {
        setHeatmapPeriod(change.heatmapPeriod);
      }

      dispatch(
        heatmapsFilterActions.setReportPeriodFilter({
          heatmapPeriod: change.heatmapPeriod ?? heatmapPeriod,
          period: change.period ?? period,
        }),
      );
    },
    [dispatch, heatmapPeriod, onChangeFilter, period],
  );

  const content = useMemo(
    () => (
      <div className={styles.rootContainer}>
        <div className={styles.periodSelector}>
          <HeatmapPeriodSelector heatmapPeriod={heatmapPeriod} period={period} onChange={onChangeHeatmapPeriodSelector} />
        </div>
        {children}
        <div className={styles.infoButton}>
          <span ref={popupAnchor}></span>
          <Button icon='info' fillMode='flat' className={styles.button} onClick={() => setShowPeriodHint((x) => !x)} />
        </div>
        <Popup className={styles.popup} anchor={popupAnchor.current} show={showPeriodHint}>
          <div>{localization.toLanguageStringF('revenue.reportPeriodSelector.periodHint')}</div>
        </Popup>
      </div>
    ),
    [heatmapPeriod, localization, onChangeHeatmapPeriodSelector, period, showPeriodHint, children],
  );

  return content;
};
