import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';

import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

import { metersEditingActions, selectMetersEditing } from '../../../../../features';
import { Sidebar } from '../../../../common';
import { MeterEditingTile } from './meter-editing-tile/MeterEditingTile';
import { IMeterState } from '../../../../../model';

import styles from './MetersEditingSidebar.module.scss';
import { Button } from '@progress/kendo-react-buttons';

interface Props {
  className?: string;
}

export const MetersEditingSidebar: FunctionComponent<Props> = ({ className }) => {
  const dispatch = useAppDispatch();
  const metersEditing = useAppSelector(selectMetersEditing);
  const [meters, setMeters] = useState<Array<IMeterState>>([]);
  const [allMetersSelected, setAllMetersSelected] = useState(false);
  const [anyMeterSelected, setAnyMeterSelected] = useState(false);

  useEffect(() => {
    const metersList: Array<IMeterState> = [];
    Object.keys(metersEditing.meters).forEach((key) => {
      const id = parseInt(key);
      metersList.push(metersEditing.meters[id]);
    });

    setMeters(metersList);
    setAllMetersSelected(metersList.length > 0 && !metersList.find((x) => !x.isSelected));
    setAnyMeterSelected(!!metersList.find((x) => x.isSelected));
  }, [metersEditing.meters]);

  const onClose = useCallback(() => {
    dispatch(metersEditingActions.closeSidebar());
  }, [dispatch]);

  const onSave = useCallback(() => {
    dispatch(metersEditingActions.saveMeters(meters));
  }, [dispatch, meters]);

  const toggleSelected = (enabled: boolean | 'mixed') => {
    dispatch(metersEditingActions.setAllMetersSelected(!allMetersSelected));
  };

  return (
    <Sidebar
      title={`Changed ${Object.keys(metersEditing.meters).length} objects location`}
      isOpen={metersEditing.isSidebarOpen}
      onClose={onClose}
      className={`${styles.sidebar} ${className}`}
    >
      <Checkbox
        className={styles.selectAll}
        checked={allMetersSelected}
        onChange={(ev: CheckboxChangeEvent) => toggleSelected(ev.value)}
        label='Select All'
      />

      <div className={styles.content}>
        {meters.map((meter, idx) => (
          <MeterEditingTile key={meter.id} meter={meter} />
        ))}
      </div>

      <div className={styles.sidebarFooter}>
        <Button themeColor={'primary'} onClick={onSave} disabled={!anyMeterSelected}>
          Save
        </Button>
        <Button onClick={onClose}>Discard</Button>
      </div>
    </Sidebar>
  );
};
