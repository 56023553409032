import { FC } from 'react';
import { IPolicy, UserClass } from '../../../../model/api/policy';
import { dateUtils } from '../../../../utils';
import { PolicyRules } from './PolicyRules';
import styles from './PolicyTile.module.scss';
import { PolicyTimeSpans } from './PolicyTimeSpans';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';

export const PolicyTile: FC<{ policy: IPolicy }> = ({ policy }) => {
  const localization = useExtendedLocalization();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <PolicyTimeSpans timeSpans={policy.TimeSpans} className={styles.headerLabel}></PolicyTimeSpans>

        {/*only applicable for policies with emty time spans and for special events*/}
        {!policy.TimeSpans?.length && policy.Rules.some((x) => x.UserClasses?.some((y) => y === UserClass.SpecialEvents)) && (
          <span className={styles.headerLabel}>{localization.toLanguageStringF(`parkingPolicy.class.${UserClass.SpecialEvents}`)}</span>
        )}
        <div className={styles.headerText}>Published: {dateUtils.toLocalUiString(policy.PublishedDate, true, false)}</div>
      </div>
      <div className={styles.body}>
        <PolicyRules rules={policy.Rules} labelClass={styles.bodyLabel} textClass={styles.bodyText}></PolicyRules>
      </div>
    </div>
  );
};
