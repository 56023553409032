/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FunctionComponent } from 'react';
import { selectedCurbSpacesActions } from '../../../../../features';
import { useOpenPopupDispatch } from '../../../../../hooks';
import { ISpotState, SpotOccupancyCalculator } from '../../../../../model';

import styles from './CurbSpacesList.module.scss';
import { NavigationSource, PopupType, amplitudeService } from '../../../../../services';

interface Props {
  spotsStates: ISpotState[];
  showIndex?: boolean;
  className?: string;
  onClick?: (spotId: number) => void;
}

export const CurbSpacesList: FunctionComponent<Props> = ({ spotsStates, showIndex, className, onClick }) => {
  const popupDispatch = useOpenPopupDispatch();

  const openCurbSpace = (id: number) => {
    if (onClick) {
      onClick(id);
    } else {
      popupDispatch(
        selectedCurbSpacesActions.load({
          id: id,
          position: null,
        }),
      );
      amplitudeService.trackPopupOpen(PopupType.CurbSpaces, NavigationSource.Navigation);
    }
  };

  return (
    <span className={className || ''}>
      {spotsStates.map((x, index) => (
        <span
          onClick={() => openCurbSpace(x.SpotId)}
          key={x.SpotId}
          className={styles.spotStatus}
          style={{ backgroundColor: SpotOccupancyCalculator.getColor(x.Status) }}
        >
          {showIndex && <span className={styles.spotIndex}>{index + 1}</span>}
        </span>
      ))}
    </span>
  );
};
