import { ISelectedEntity, PopupType, SelectedEntityName, amplitudeService, selectedEntitiesStateSerivce } from '../../../services';
import { Position, Geometry, Point, Feature, GeoJsonProperties } from 'geojson';

type StateType<TEntity, TId extends number | string, TGeometry extends Geometry> = {
  selected: Array<ISelectedEntity<TEntity, TId, TGeometry>>;
  current?: TId | null;
};

const pinPopupMap: { [key in SelectedEntityName]?: PopupType } = {
  [SelectedEntityName.Areas]: PopupType.Areas,
  [SelectedEntityName.Zones]: PopupType.Zones,
  [SelectedEntityName.RppZones]: PopupType.RppZones,
  [SelectedEntityName.RppAreas]: PopupType.RppAreas,
  [SelectedEntityName.OffstreetZones]: PopupType.OffstreetParking,
  [SelectedEntityName.CurbSpaces]: PopupType.CurbSpaces,
  [SelectedEntityName.Meters]: PopupType.Meters,
  [SelectedEntityName.StreetSigns]: PopupType.StreetObjects,
  [SelectedEntityName.Heatmap]: PopupType.HeatmapZone,
  [SelectedEntityName.RevenueOffstreetZones]: PopupType.HeatmapZone,
  [SelectedEntityName.ParkingLots]: PopupType.ParkingLot,
  [SelectedEntityName.Blockfaces]: PopupType.Blockfaces,
  [SelectedEntityName.StudyAreas]: PopupType.Study,
  [SelectedEntityName.Cameras]: PopupType.Cameras,
  [SelectedEntityName.BlockfacesHeatmap]: PopupType.HeatmapBlockface,
  [SelectedEntityName.StudyAreasHeatmap]: PopupType.HeatmapStudy,
};

function handleLoad<TEntity, TId extends number | string, TGeometry extends Geometry = Point>(
  state: StateType<TEntity, TId, TGeometry>,
  id: TId,
  position: Position | null,
  entity?: TEntity,
  feature: Feature<TGeometry, GeoJsonProperties> | null = null,
  initPosition?: Position | null,
): void {
  state.current = null;

  let selected = state.selected.filter((x) => x.pinnedPopup || x.id === id);
  const item = selected.find((x) => x.id === id);

  if (!item) {
    selected = [
      ...selected,
      ...[
        {
          id: id,
          openPopup: true,
          pinnedPopup: false,
          loading: true,
          position: position,
          feature: feature,
          entity: entity || null,
          initPosition: initPosition ? initPosition : position,
        },
      ],
    ];
  } else {
    item.openPopup = true;
    item.entity = entity || item.entity || null;
  }

  state.selected = [...selected];
}

function handleLoadSuccess<TEntity, TId extends number | string, TGeometry extends Geometry = Point>(
  state: StateType<TEntity, TId, TGeometry>,
  id: TId,
  entity: TEntity,
  position: Position,
  initPosition?: Position,
): void {
  state.selected = state.selected.map((x) =>
    x.id === id
      ? { ...x, ...{ loading: false, entity: entity, position: position, initPosition: initPosition ? initPosition : position } }
      : x,
  );
  state.current = id;
}

function handleChanges<TEntity, TId extends number | string, TGeometry extends Geometry = Point>(
  state: StateType<TEntity, TId, TGeometry>,
  id: TId,
  entity: SelectedEntityName,
  position?: Position,
): void {
  state.selected = state.selected.map((x) => (x.id === id ? { ...x, ...{ position: position !== undefined ? position : x.position } } : x));

  const selected = state.selected.find((x) => x.id === id);
  if (selected?.pinnedPopup) {
    selectedEntitiesStateSerivce.saveState(entity, state.selected);
  }
  state.current = id;
}

function handleLoadFail<TEntity, TId extends number | string, TGeometry extends Geometry = Point>(
  state: StateType<TEntity, TId, TGeometry>,
  id: TId,
): void {
  state.selected = state.selected.map((x) => (x.id === id ? { ...x, ...{ loading: false } } : x));
}

function closePopup<TEntity, TId extends number | string, TGeometry extends Geometry = Point>(
  state: StateType<TEntity, TId, TGeometry>,
  entity: SelectedEntityName,
  id: TId | undefined,
): void {
  if (id)
    state.selected = state.selected
      .filter((x) => x.pinnedPopup || x.id === id)
      .map((x) => (x.id === id ? { ...x, ...{ openPopup: false, pinnedPopup: x.openPopup ? false : x.pinnedPopup } } : x));
  else state.selected = state.selected.filter((x) => x.pinnedPopup);

  selectedEntitiesStateSerivce.saveState(entity, state.selected);
}

function closePopups<TEntity, TId extends number | string, TGeometry extends Geometry = Point>(
  state: StateType<TEntity, TId, TGeometry>,
  entity: SelectedEntityName,
): void {
  state.selected = state.selected
    .filter((x) => x.pinnedPopup || x.id === state.current)
    .map((x) => (x.pinnedPopup ? x : { ...x, ...{ openPopup: false } }));

  selectedEntitiesStateSerivce.saveState(entity, state.selected);
}

function openPopup<TEntity, TId extends number | string, TGeometry extends Geometry = Point>(
  state: StateType<TEntity, TId, TGeometry>,
  id: TId,
): void {
  state.selected = state.selected.map((x) => (x.id === id ? { ...x, ...{ openPopup: true } } : x));
  state.current = state.selected.find((x) => x.id === id)?.id || null;
}

function pinPopup<TEntity, TId extends number | string, TGeometry extends Geometry = Point>(
  state: StateType<TEntity, TId, TGeometry>,
  entity: SelectedEntityName,
  id: TId,
): void {
  state.selected = state.selected.map((x) => (x.id === id ? { ...x, ...{ pinnedPopup: !x.pinnedPopup } } : x));
  selectedEntitiesStateSerivce.saveState(entity, state.selected);

  const pinnedEntity = state.selected.find((x) => x.id === id);
  if (pinnedEntity?.pinnedPopup) {
    const popupType = pinPopupMap[entity];
    if (popupType) {
      amplitudeService.trackPopupPin(popupType);
    }
  }
}

function collapsePopups<TEntity, TId extends number | string, TGeometry extends Geometry = Point>(
  state: StateType<TEntity, TId, TGeometry>,
  entity: SelectedEntityName,
): void {
  state.selected = state.selected.map((x) => ({ ...x, ...{ openPopup: false } }));
  selectedEntitiesStateSerivce.saveState(entity, state.selected);
}

export const selectedSliceLogic = {
  handleLoad,
  handleLoadSuccess,
  handleChanges,
  handleLoadFail,
  closePopup,
  closePopups,
  openPopup,
  pinPopup,
  collapsePopups,
};
