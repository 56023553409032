import { OccupancySource, WeekDay } from '../../model';
import { dateUtils } from '../../utils';
import { getApi } from './api';

const BASE_URL = '/occupancy';

export interface IOccupancyHeatmapData {
  Id: number;
  Occupancy: number;
  NormalizedOccupancy: number;
}

const getMeterOccupancyHeatmapData = (
  occupancySource: OccupancySource,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Promise<Array<IOccupancyHeatmapData>> => {
  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  return getApi()
    .get<Array<IOccupancyHeatmapData>>(
      `${BASE_URL}/meter/heatmap-data?occupancySource=${occupancySource}&period.from=${dateUtils.toDateString(
        period[0],
      )}&period.to=${dateUtils.toDateString(period[1])}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
    )
    .then((response) => response.data || []);
};

const getZoneOccupancyHeatmapData = (
  occupancySource: OccupancySource,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Promise<Array<IOccupancyHeatmapData>> => {
  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  return getApi()
    .get<Array<IOccupancyHeatmapData>>(
      `${BASE_URL}/zone/heatmap-data?occupancySource=${occupancySource}&period.from=${dateUtils.toDateString(
        period[0],
      )}&period.to=${dateUtils.toDateString(period[1])}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
    )
    .then((response) => response.data || []);
};

const getBlockfaceOccupancyHeatmapData = (
  occupancySource: OccupancySource,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Promise<Array<IOccupancyHeatmapData>> => {
  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  return getApi()
    .get<Array<IOccupancyHeatmapData>>(
      `${BASE_URL}/blockface/heatmap-data?occupancySource=${occupancySource}&period.from=${dateUtils.toDateString(
        period[0],
      )}&period.to=${dateUtils.toDateString(period[1])}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
    )
    .then((response) => response.data || []);
};

const getStudyAreaOccupancyHeatmapData = (
  occupancySource: OccupancySource,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Promise<Array<IOccupancyHeatmapData>> => {
  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  return getApi()
    .get<Array<IOccupancyHeatmapData>>(
      `${BASE_URL}/study-area/heatmap-data?occupancySource=${occupancySource}&period.from=${dateUtils.toDateString(
        period[0],
      )}&period.to=${dateUtils.toDateString(period[1])}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
    )
    .then((response) => response.data || []);
};

const getSpotOccupancyHeatmapData = (
  occupancySource: OccupancySource,
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Promise<Array<IOccupancyHeatmapData>> => {
  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  return getApi()
    .get<Array<IOccupancyHeatmapData>>(
      `${BASE_URL}/spot/heatmap-data?occupancySource=${occupancySource}&period.from=${dateUtils.toDateString(
        period[0],
      )}&period.to=${dateUtils.toDateString(period[1])}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
    )
    .then((response) => response.data || []);
};

export const occupancyData = {
  getMeterOccupancyHeatmapData,
  getZoneOccupancyHeatmapData,
  getBlockfaceOccupancyHeatmapData,
  getStudyAreaOccupancyHeatmapData,
  getSpotOccupancyHeatmapData,
};
