import { FC } from 'react';

interface Props {
  zoneNumber?: string;
  width?: string | number;
  height?: string | number;
}

export const LoadingZoneQR: FC<Props> = ({ zoneNumber, width, height }) => {
  if (!zoneNumber) {
    return null;
  }

  return <img src={`/loading-zones/${zoneNumber}.png`} alt={zoneNumber} width={width} height={height}></img>;
};
