import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';

import {
  IOccupancyTrafficReport,
  IParkingDurationReportItem,
  ISelectedZoneHeatmap,
  IViolationInfo,
  IZoneRevenueReportItem,
} from '../../../../model';
import { OccupancySource } from '../../../../model/api/occupancy';
import { IReportFilter, ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { RootState, store } from '../../../../store';
import { selectedSliceLogic } from '../selected-slice-logic';

export interface ISelectedZoneHeatmapState {
  selected: ISelectedEntity<ISelectedZoneHeatmap>[];
  current: number | null;
}

const initialState: ISelectedZoneHeatmapState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedRevenueZones',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.Heatmap,
        initialState,
        (id) => parseInt(id),
        (selected) => {
          selected.map((x) => store.dispatch(selectedZoneHeatmapsActions.loadZoneHeatmap(x)));
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: number; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.Heatmap, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.Heatmap);
    },
    loadZoneHeatmap(state, action: PayloadAction<{ id: number; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadZoneHeatmapSuccess(state, action: PayloadAction<{ heatmap: ISelectedZoneHeatmap; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.heatmap.zone.Id,
        action.payload.heatmap,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadZoneHeatmapFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },

    fetchDurationReport(state, action: PayloadAction<{ zoneId: number; filter: IReportFilter }>) {
      const zone = state.selected.find((x) => x.entity?.zone.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = true;
    },
    fetchDurationReportSuccess(state, action: PayloadAction<{ zoneId: number; report: IParkingDurationReportItem | null }>) {
      const zone = state.selected.find((x) => x.entity?.zone.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;

      if (!zone.entity) {
        return;
      }
      zone.entity.durationReport = action.payload.report;
    },
    fetchReportFailed(state, action: PayloadAction<{ zoneId: number; error: string }>) {
      const zone = state.selected.find((x) => x.entity?.zone.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;
      console.error(action.payload.error);
    },

    fetchZoneRevenueReport(state, action: PayloadAction<{ zoneId: number; filter: IReportFilter }>) {
      const zone = state.selected.find((x) => x.entity?.zone.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = true;
    },
    fetchZoneRevenueReportSuccess(state, action: PayloadAction<{ zoneId: number; report: IZoneRevenueReportItem | null }>) {
      const zone = state.selected.find((x) => x.entity?.zone.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;

      if (!zone.entity) {
        return;
      }
      zone.entity.revenueReport = action.payload.report;
    },

    fetchViolationReport(state, action: PayloadAction<{ zoneId: number; filter: IReportFilter }>) {
      const zone = state.selected.find((x) => x.entity?.zone.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = true;
    },
    fetchViolationReportSuccess(state, action: PayloadAction<{ zoneId: number; report: IViolationInfo | null }>) {
      const zone = state.selected.find((x) => x.entity?.zone.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;

      if (!zone.entity) {
        return;
      }
      zone.entity.enforcementReport = action.payload.report;
    },

    fetchOccupancyTrafficReport(state, action: PayloadAction<{ zoneId: number; filter: IReportFilter; occupancySource: OccupancySource }>) {
      const zone = state.selected.find((x) => x.entity?.zone?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = true;
    },
    fetchOccupancyTrafficReportSuccess(state, action: PayloadAction<{ zoneId: number; report: IOccupancyTrafficReport | null }>) {
      const zone = state.selected.find((x) => x.entity?.zone?.Id === action.payload.zoneId);
      if (!zone) {
        return;
      }
      zone.loading = false;
      if (!zone.entity) {
        return;
      }
      zone.entity.occupancyTrafficReport = action.payload.report;
    },

    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.Heatmap, action.payload);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.Heatmap, action.payload);
    },
  },
});

// Actions
export const selectedZoneHeatmapsActions = slice.actions;

// Selectors
export const selectedZoneHeatmaps = (state: RootState) => state.selectedZoneHeatmaps;

export const currentZoneHeatmap = (state: RootState) =>
  state.selectedZoneHeatmaps.selected.find((x) => x.id === state.selectedZoneHeatmaps.current);

// Reducer
export const selectedZoneHeatmapsReducer = slice.reducer;
