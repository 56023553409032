import { Action, AnyAction } from 'redux';
import { combineEpics, StateObservable } from 'redux-observable';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { LngLat } from 'mapbox-gl';

import { catchError, concatMap, debounceTime, filter, from, map, mergeMap, Observable, of, switchMap, tap, zip } from 'rxjs';

import { blockfaces, geoProcessor, notifications, revenueData, spots, studyAreas, transactions, zones } from '../../../../services';
import { meters, meters as metersService } from '../../../../services/api/meters';
import { RootState, store } from '../../../../store';
import { citiesActions } from '../../../common';
import { metersGeoActions } from './meters-geo-slice';
import { metersLayerActions } from './meters-layer-slice';
import { ISelectedMeter, selectedMetersActions } from './selected-meters-slice';
import { geoUtils } from '../../../../utils';
import { mapStateActions } from '../../map-state';
import { metersEditingActions } from './meters-editing-slice';
import { geoRevisionProcessor } from '../../../../services';
import { LayerName } from '../../../../model';

const fetchMetersEpic = (actions$: Observable<AnyAction>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(metersGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor
        .loadMeters(
          action.payload,
          action.payload.zoom,
          state$.value.metersLayer.vendorsFilter,
          state$.value.metersLayer.statusesFilter,
          state$.value.metersLayer.typesFilter,
          state$.value.metersLayer.showPerformanceParkingOnly,
          state$.value.metersEditing.meters,
          state$.value.metersEditing.meter,
          state$.value.metersEditing.isEditing,
        )
        .pipe(
          map((x) => metersGeoActions.fetchSuccess(x)),
          catchError((err) => of(metersGeoActions.fetchFailed(err.message))),
        ),
    ),
  );

const meterSelectedEpic = (actions$: Observable<AnyAction>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedMetersActions.loadMeter.match),
    concatMap((action) =>
      loadMeter(action.payload.id, state$).pipe(
        mergeMap((x) => {
          if (action.payload.position) {
            const center = action.payload.position;
            const initPosition = action.payload.initPosition;
            return of(
              metersLayerActions.setEnabled(true),
              selectedMetersActions.loadMeterSuccess({ meter: x, position: center, initPosition: initPosition ? initPosition : center }),
            );
          } else {
            const feature: Feature<Geometry, GeoJsonProperties> = action.payload.feature ||
              findMeterFeature(action.payload.id, state$) || {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: x.Position,
                },
                properties: {},
              };

            const center = geoUtils.findCenter(feature).geometry.coordinates;
            return of(
              metersLayerActions.setEnabled(true),
              mapStateActions.setMapCenter(center),
              selectedMetersActions.loadMeterSuccess({ meter: x, position: center }),
            );
          }
        }),
        catchError((err) => of(selectedMetersActions.loadMeterFailed(err.message))),
      ),
    ),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap((_) =>
      of(
        metersLayerActions.fetchTypes(),
        metersLayerActions.fetchVendors(),
        metersLayerActions.fetchStatusCount(),
        selectedMetersActions.collapsePopups(),
      ),
    ),
  );

const fetchTypesEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(metersLayerActions.fetchTypes.match),
    concatMap((action) =>
      from(meters.getTypes()).pipe(
        map((x) => metersLayerActions.fetchTypesSuccess(x)),
        catchError((err) => of(metersLayerActions.fetchTypesFailed(err.message))),
      ),
    ),
  );

const fetchVendorsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(metersLayerActions.fetchVendors.match),
    concatMap((action) =>
      from(meters.getVendors()).pipe(
        map((x) => metersLayerActions.fetchVendorsSuccess(x)),
        catchError((err) => of(metersLayerActions.fetchVendorsFailed(err.message))),
      ),
    ),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((action) => selectedMetersActions.closePopup()),
  );

const fetchPerformanceParkingSuccessEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.fetchPerformanceParkingCountSuccess.match),
    filter((x) => x.payload === 0),
    map((_) => metersLayerActions.setPerformanceParkingFilterValue(false)),
  );

const moveMeterEpic = (actions$: Observable<AnyAction>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(metersEditingActions.moveMeter.match),
    debounceTime(5),
    concatMap((action) => {
      const meterFeature = action.payload.meterFeature;
      const state = state$.value.metersEditing;
      const properties = meterFeature.properties || {};
      const id = parseInt(properties['id']);
      const meters = state.meters;
      let current = meters[id];

      const position = action.payload.position;
      const currPos: [number, number] = position instanceof LngLat ? [position.lng, position.lat] : position;

      meterFeature.geometry.coordinates = currPos;

      if (current) {
        current = {
          ...current,
          ...{ position: currPos, feature: meterFeature },
        };
      } else {
        current = {
          id: id,
          name: '',
          typeId: properties['type'],
          status: properties['status'],
          position: currPos,
          originalPosition: meterFeature.geometry.coordinates,
          isSelected: false,
          feature: meterFeature,
        };

        from(metersService.get(id)).subscribe((res) => {
          store.dispatch(metersEditingActions.moveMeterSuccess({ meter: { ...current, ...{ name: res.Name } } }));
        });
      }

      return of(
        metersEditingActions.moveMeterSuccess({ meter: current }),
        selectedMetersActions.updateMeterPosition({ id: current.id, position: currPos }),
      );
    }),
    catchError((err) => of(metersEditingActions.moveMeterFailed(err.message))),
  );

const saveMetersEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(metersEditingActions.saveMeters.match),
    concatMap((action) => {
      const selectedMeters = action.payload
        .filter((x) => x.isSelected)
        .map((x) => ({ Id: x.id, NewPosition: x.position as [number, number] }));

      if (selectedMeters.length === 0) {
        return of(metersEditingActions.setEditing({ edit: false }));
      }

      return from(metersService.update(selectedMeters)).pipe(
        tap((x) => {
          notifications.success('The city map changes were successfully saved. The map update may take up to 15 minutes.');
          geoRevisionProcessor.regenerateRevision(LayerName.Meters);
        }),
        map((x) => metersEditingActions.saveMetersSuccess()),
        catchError((err) => of(metersEditingActions.saveMetersFailed(err.message))),
      );
    }),
  );

const fetchTransactionsEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedMetersActions.fetchTransactions.match),
    concatMap((action) => {
      return from(transactions.getTopMeterTransactions(action.payload)).pipe(
        map((x) => selectedMetersActions.fetchTransactionsSuccess({ id: action.payload, transactions: x })),
        catchError((err) => of(selectedMetersActions.fetchTransactionsFailed(err.message))),
      );
    }),
  );
};

const fetchRevenueEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedMetersActions.fetchRevenue.match),
    concatMap((action) => {
      return from(revenueData.getRevenueByMeter(action.payload)).pipe(
        map((x) => selectedMetersActions.fetchRevenueSuccess({ id: action.payload, revenue: x })),
        catchError((err) => of(selectedMetersActions.fetchRevenueFailed(err.message))),
      );
    }),
  );
};

const fetchStatusCount = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(metersLayerActions.fetchStatusCount.match),
    concatMap(() =>
      from(meters.getStatusCount()).pipe(
        map((x) => metersLayerActions.fetchStatusCountSuccess(x)),
        catchError((err) => of(metersLayerActions.fetchStatusCountFailed(err.message))),
      ),
    ),
  );

export const metersEpic = combineEpics(
  fetchMetersEpic,
  fetchTypesEpic,
  fetchVendorsEpic,
  citySelectedEpic,
  meterSelectedEpic,
  closePopupsEpic,
  moveMeterEpic,
  saveMetersEpic,
  fetchPerformanceParkingSuccessEpic,
  fetchTransactionsEpic,
  fetchRevenueEpic,
  fetchStatusCount,
);

function loadMeter(id: number, state: StateObservable<RootState>): Observable<ISelectedMeter> {
  const existing = state.value.selectedMeters.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return from(meters.get(id)).pipe(
    switchMap((meter) =>
      zip(
        of(meter),
        meter.ZoneId ? from(zones.get(meter.ZoneId)) : of(null),
        meter.SpotIds?.length ? from(spots.getSpotsStates(meter.SpotIds)) : of([]),
        meter.BlockfaceId ? from(blockfaces.get(meter.BlockfaceId)) : of(null),
        from(studyAreas.getMeterStudyAreaNames(id)),
      ),
    ),
    map(([meter, zone, spotsStates, blockface, studyAreas]) => ({
      ...meter,
      zone: zone,
      group: [],
      spotsStates: spotsStates,
      blockface: blockface,
      studyAreas: studyAreas,
    })),
  );
}

function findMeterFeature(meterId: number, state: StateObservable<RootState>): Feature<Geometry, GeoJsonProperties> | null {
  return state.value.metersGeo.data.features.find((x) => x.properties?.id === meterId) || null;
}
