import { FunctionComponent } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { OccupancyStatus, SpotOccupancyCalculator } from '../../../../model';

interface Props {
  status?: OccupancyStatus;
  className?: string;
}

export const CurbSpaceOccupancyStatusText: FunctionComponent<Props> = ({ status, className }) => {
  const localization = useExtendedLocalization();
  const occupancyColor = status ? SpotOccupancyCalculator.getColor(status) : '';

  return (
    <span className={`${className || ''}`} style={{ color: occupancyColor }}>
      {status ? localization.toLanguageStringF(`curbSpace.occupancyStatus.${OccupancyStatus[status]}`) : ''}
    </span>
  );
};
