import {
  IBlockface,
  IOccupancyTrafficReport,
  IOffstreetZone,
  IOffstreetZoneRevenueReportItem,
  IParkingDurationReportItem,
  IStudyArea,
  IViolationInfo,
  IZone,
  IZoneRevenueReportItem,
} from '../../api';

export enum RevenueAmountType {
  Actual = 'Actual',
  Average = 'Average',
}

export interface ISelectedZoneHeatmap {
  zone: IZone;
  revenueReport?: IZoneRevenueReportItem | null;
  durationReport?: IParkingDurationReportItem | null;
  enforcementReport?: IViolationInfo | null;
  occupancyTrafficReport?: IOccupancyTrafficReport | null;
}

export interface ISelectedOffstreetZoneRevenue extends IOffstreetZone {
  revenueReport: IOffstreetZoneRevenueReportItem | null;
}

export interface ISelectedBlockfaceHeatmap {
  blockface: IBlockface;
  enforcementReport?: IViolationInfo | null;
  revenueReport?: IZoneRevenueReportItem | null;
  durationReport?: IParkingDurationReportItem | null;
  occupancyTrafficReport?: IOccupancyTrafficReport | null;
}

export interface ISelectedStudyAreaHeatmap {
  studyArea: IStudyArea;
  enforcementReport?: IViolationInfo | null;
  revenueReport?: IZoneRevenueReportItem | null;
  durationReport?: IParkingDurationReportItem | null;
  occupancyTrafficReport?: IOccupancyTrafficReport | null;
}
