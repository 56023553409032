/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent, useCallback } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

import { currentCamera, selectedCamerasActions, selectedStudyAreasActions, sidebarActions } from '../../../../features';
import { ISelectedCamera, SidebarName } from '../../../../model';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Link } from '../../../common';
import { MapSidebarContainer } from '../../controls';
import { CameraStatusText } from './camera-status-text/CameraStatusText';
import { geoUtils } from '../../../../utils';
import { ParkingEventsList } from '../../common/parking-events/ParkingEventsList';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ISelectedEntity, NavigationSource, PopupType, amplitudeService } from '../../../../services';
import { useOpenPopupDispatch } from '../../../../hooks';
import { SidebarSectionLazyLoader } from '../../common';

import styles from './CameraSidebar.module.scss';

interface Props {
  cameraData: ISelectedEntity<ISelectedCamera, string>;
}

const Sidebar: FunctionComponent<Props> = ({ cameraData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const camera = cameraData.entity;
  const localization = useExtendedLocalization();

  const handleCloseClick = (ev: React.FormEvent) => {
    ev.preventDefault();
    dispatch(sidebarActions.clearSelected());
  };

  const mapViewClick = () => {
    dispatch(selectedCamerasActions.openPopup(cameraData.id));
  };

  const openStudyArea = useCallback(
    (studyAreaId: number) => {
      popupDispatch(selectedStudyAreasActions.loadStudyArea({ id: studyAreaId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Study, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  return (
    <div className={`map-sidebar map-sidebar map-sidebar-info`}>
      <div className='map-sidebar-header'>
        <h3>{localization.toLanguageStringF('camera.sidebar.title', [camera?.Name])}</h3>

        <Button size='small' className='close' icon='close' onClick={handleCloseClick}></Button>
      </div>

      <div className='map-sidebar-body'>
        <PanelBar>
          <PanelBarItem title={localization.toLanguageStringF('common.generalInfo')} expanded={true}>
            <div>
              <div className='map-sidebar-row'>
                <label>{localization.toLanguageStringF('camera.sidebar.cameraId')}:</label>
                <div>{camera?.Id}</div>
              </div>

              <div className='map-sidebar-row'>
                <label>{localization.toLanguageStringF('common.status')}:</label>
                <div>
                  <CameraStatusText status={camera?.state?.Status} />
                </div>
              </div>

              <div className='map-sidebar-row'>
                <label>{localization.toLanguageStringF('common.address')}:</label>
                <div>{camera?.Address}</div>
              </div>

              {camera && camera.studyAreas.length > 0 && (
                <div className='map-sidebar-row'>
                  <label>{localization.toLanguageStringF('camera.studyAreas')}:</label>
                  <div>
                    {camera.studyAreas.map((x) => (
                      <Link key={x.Id} className={styles.linkButton} onClick={() => openStudyArea(x.Id)}>
                        {x.Name}
                      </Link>
                    ))}
                  </div>
                </div>
              )}

              <div className='map-sidebar-row'>
                <label>{localization.toLanguageStringF('common.geo')}:</label>
                <div>{geoUtils.toUiString(camera?.Position)}</div>
              </div>
            </div>
            <div className='map-sidebar-row more-info'>
              <Link onClick={mapViewClick} className='more-info-button' disabled={cameraData.openPopup}>
                {localization.toLanguageStringF('common.mapView')}
              </Link>
            </div>
          </PanelBarItem>

          {camera && (
            <PanelBarItem title={localization.toLanguageStringF('camera.sidebar.events.title')} expanded={false}>
              <SidebarSectionLazyLoader
                hasData={(camera.events?.length || 0) > 0}
                shouldLoadData={camera.events === undefined}
                loadData={() => dispatch(selectedCamerasActions.fetchEvents(camera.Id))}
              >
                <ParkingEventsList className={styles.panelMargin} events={camera.events || []} openPopupOnSelect={true} />
              </SidebarSectionLazyLoader>
            </PanelBarItem>
          )}

          {camera?.Configuration ? (
            <PanelBarItem title={localization.toLanguageStringF('camera.sidebar.settings.title')} expanded={false}>
              <div>
                <div className='map-sidebar-row'>
                  <label>{localization.toLanguageStringF('camera.sidebar.settings.captureMode')}:</label>
                  <div>{camera.Configuration.Mode}</div>
                </div>

                <div className='map-sidebar-row'>
                  <label>{localization.toLanguageStringF('camera.sidebar.settings.uploadInterval')}:</label>
                  <div>{((camera.Configuration.UploadIntervalMs || 0) / 1000).toFixed()}s</div>
                </div>

                <div className='map-sidebar-row'>
                  <label>{localization.toLanguageStringF('camera.sidebar.settings.telemetryTimeout')}:</label>
                  <div>{((camera.Configuration.TelemetryTimeoutMs || 0) / 1000).toFixed()}s</div>
                </div>

                {camera.Configuration.BootTime?.length > 0 && (
                  <div className='map-sidebar-row'>
                    <label>{localization.toLanguageStringF('camera.sidebar.settings.bootTime')}:</label>
                    <div>
                      {camera.Configuration.BootTime.map((x, index) => (
                        <div key={index}>
                          {x.From} - {x.To}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </PanelBarItem>
          ) : null}
        </PanelBar>
      </div>
    </div>
  );
};

export const CameraSidebar: FunctionComponent = () => {
  const current = useAppSelector(currentCamera);

  return current?.entity ? (
    <MapSidebarContainer name={SidebarName.camera}>
      <Sidebar cameraData={current} />
    </MapSidebarContainer>
  ) : null;
};
