import { FunctionComponent } from 'react';
import { useExtendedLocalization } from '../../../../../hooks/use-extended-localization-service';
import { IViolationInfo } from '../../../../../model';
import { HeatmapPeriod } from '../../../../../model/api/heatmap-period';
import { numberUtils } from '../../../../../utils';
import { PeriodTextInfo } from '../period-text-info/PeriodTextInfo';

interface Props {
  period: HeatmapPeriod;
  violation: IViolationInfo;
  className?: string;
}

export const PeriodViolationsAmountTextInfo: FunctionComponent<Props> = ({ period, violation, className }) => {
  const localization = useExtendedLocalization();

  const current = violation.Amounts.reduce((sum, a) => sum + a.CurrentTotal, 0);
  const previous = violation.Amounts.reduce((sum, a) => sum + a.PreviousTotal, 0);

  return (
    <PeriodTextInfo
      className={className}
      label={localization.toLanguageString('period-info.collected-amount', 'Issued Amount')}
      value={`$${numberUtils.toUiString(current)}`}
      prevValue={`$${numberUtils.toUiString(previous)}`}
      change={numberUtils.diffPercentage(current, previous)}
      small={true}
    />
  );
};
