/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, useMemo, useState } from 'react';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';

import { OccupancySource } from '../../../../model';
import { DropDownList } from '../../../common';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';

import styles from './OccupancySourceSelector.module.scss';

interface IProps {
  hideLable?: boolean;
  value?: OccupancySource;
  onChange?: (value: OccupancySource) => void;
  className?: string;
}

export const OccupancySourceSelector: FC<IProps> = ({ value, onChange, className, hideLable }) => {
  const localization = useExtendedLocalization();
  const [selectedValue, setSelectedValue] = useState(value);

  const types = useMemo(() => {
    return [
      { id: OccupancySource.Camera, name: localization.toLanguageStringF(`occupancySource.occupancySourceEnum.${OccupancySource.Camera}`) },
      {
        id: OccupancySource.Transactional,
        name: localization.toLanguageStringF(`occupancySource.occupancySourceEnum.${OccupancySource.Transactional}`),
      },
      {
        id: OccupancySource.Sensors,
        name: localization.toLanguageStringF(`occupancySource.occupancySourceEnum.${OccupancySource.Sensors}`),
      },
    ];
  }, [localization]);

  const onDropDownChange = (evt: DropDownListChangeEvent) => {
    if (evt.target.value) {
      setSelectedValue(evt.target.value.id);
      if (onChange) {
        onChange(evt.target.value.id);
      }
    }
  };

  return (
    <div className={`${styles.container} ${className || ''}`}>
      {!hideLable && <label className={styles.label}>{localization.toLanguageStringF('occupancySource.source')}</label>}
      <DropDownList
        dataItemKey='id'
        textField='name'
        data={types}
        value={types.find((x) => x.id === selectedValue)}
        onChange={onDropDownChange}
      />
    </div>
  );
};
