import { FunctionComponent } from 'react';

import { useExtendedLocalization } from '../../../../../hooks/use-extended-localization-service';
import { numberUtils } from '../../../../../utils';
import styles from './PeriodTextInfo.module.scss';

interface Props {
  label?: string;
  value: string;
  prevValue?: string;
  change?: number | null;
  className?: string;
  small?: boolean;
}

export const PeriodTextInfo: FunctionComponent<Props> = ({ label, value, prevValue, change, className, small }) => {
  const localization = useExtendedLocalization();

  let changeClassName = '';
  change = change || 0;
  if (change > 0) changeClassName = styles.positive;
  if (change < 0) changeClassName = styles.negative;

  return (
    <div className={`${styles.periodInfo} ${small ? styles.small : ''} ${className ?? ''}`}>
      {label && <div className={`${styles.label}`}>{label}</div>}
      <div className={`${styles.value}`}>{value}</div>
      {prevValue !== undefined && (
        <div className={`${styles.changeRow}`}>
          <div className={`${styles.change} ${changeClassName}`}>
            {change > 0 ? '+' : null}
            {numberUtils.toUiString(change)}%
          </div>

          <div className={`${styles.prevInfo}`}>
            <div className={`${styles.prevValue}`}>{prevValue}</div>
            <div className={`${styles.period}`}>{localization.toLanguageString('period-info.last', 'Last Period')}</div>
          </div>
        </div>
      )}
    </div>
  );
};
