import { Action } from '@reduxjs/toolkit';
import { from, Observable, of, zip } from 'rxjs';
import { map, filter, concatMap, catchError, mergeMap, switchMap } from 'rxjs/operators';
import { combineEpics, StateObservable } from 'redux-observable';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';

import { geoProcessor, zones, spots, cameras } from '../../../../services';
import { RootState } from '../../../../store';
import { loadingZonesGeoActions } from './loading-zones-geo-slice';
import { selectedLoadingZonesActions } from './selected-loading-zones-slice';
import { geoUtils } from '../../../../utils';
import { mapStateActions } from '../../map-state';
import { loadingZonesLayerActions } from './loading-zones-layer-slice';
import { citiesActions } from '../../../common';
import { createInactiveCameraState, ZoneType } from '../../../../model';

const fetchZonesEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(loadingZonesGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor.loadLoadingZones(action.payload, action.payload.zoom).pipe(
        map((x) => loadingZonesGeoActions.fetchSuccess(x)),
        catchError((err) => of(loadingZonesGeoActions.fetchFailed(err.message))),
      ),
    ),
  );

const loadZone = (id: number, state: RootState) => {
  const existing = state.selectedLoadingZones.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return zip(from(zones.get(id)), from(spots.getZoneSpotsStates(id))).pipe(
    mergeMap(([zone, spotsStates]) =>
      zip(of({ zone }), spotsStates.length ? from(cameras.getCameraBySpotsIds(spotsStates.map((x) => x.SpotId))) : of([])),
    ),
    mergeMap(([zone, camerasIds]) =>
      (camerasIds.length > 0
        ? zip(
            ...camerasIds.map((x) =>
              from(cameras.getState(x.CameraId)).pipe(map((s) => (s === null ? createInactiveCameraState(x.CameraId) : s))),
            ),
          )
        : of([])
      ).pipe(
        map((x) => ({
          zone,
          camerasStates: x,
        })),
      ),
    ),
    map((res) => ({
      ...res.zone.zone,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      camerasStates: res.camerasStates.filter((x) => x !== null).map((x) => x!),
    })),
  );
};

const findZoneFeature = (id: number, state: RootState): Feature<Geometry, GeoJsonProperties> | null => {
  const result = state.loadingZonesGeo.data.features.find((x) => x.properties?.id === id);
  return result || null;
};

const zoneSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedLoadingZonesActions.loadZone.match),
    concatMap((action) =>
      loadZone(action.payload.id, state$.value).pipe(
        mergeMap((x) => {
          if (action.payload.position) {
            const center = action.payload.position;
            const initPosition = action.payload.initPosition;
            return of(
              loadingZonesLayerActions.setEnabled(true),
              selectedLoadingZonesActions.loadZoneSuccess({
                zone: x,
                position: center,
                initPosition: initPosition ? initPosition : center,
              }),
            );
          } else {
            const feature: Feature<Geometry, GeoJsonProperties> = findZoneFeature(action.payload.id, state$.value) || {
              type: 'Feature',
              geometry: geoUtils.toGeometry(x.Positions),
              properties: {},
            };

            const center = geoUtils.findCenter(feature).geometry.coordinates;
            return of(
              loadingZonesLayerActions.setEnabled(true),
              mapStateActions.setMapCenter(center),
              selectedLoadingZonesActions.loadZoneSuccess({ zone: x, position: center }),
            );
          }
        }),
        catchError((err) => of(selectedLoadingZonesActions.loadZoneFailed(err.message))),
      ),
    ),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((action) => selectedLoadingZonesActions.closePopups()),
  );

const fetchCount = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(loadingZonesLayerActions.fetchCount.match),
    concatMap(() =>
      from(zones.getCount(ZoneType.CommercialLoading)).pipe(
        map((x) => loadingZonesLayerActions.fetchCountSuccess(x)),
        catchError((err) => of(loadingZonesLayerActions.fetchCountFailed(err.message))),
      ),
    ),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap((_) => of(loadingZonesLayerActions.fetchCount(), selectedLoadingZonesActions.collapsePopups())),
  );

export const loadingZonesEpic = combineEpics(fetchZonesEpic, zoneSelectedEpic, closePopupsEpic, fetchCount, citySelectedEpic);
