import React, { FC } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

import { ISelectedParkingLot, SidebarName } from '../../../../model';
import { ISelectedEntity } from '../../../../services';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { currentParkingLot, selectedParkingLotsActions, sidebarActions } from '../../../../features';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { MapSidebarContainer } from '../../controls';
import { geoUtils } from '../../../../utils';
import { Link } from '../../../common';

const Sidebar: FC<{ lotData: ISelectedEntity<ISelectedParkingLot> }> = ({ lotData }) => {
  const dispatch = useAppDispatch();
  const localization = useExtendedLocalization();
  const lot = lotData.entity;

  if (!lot) {
    return null;
  }

  const handleCloseClick = (ev: React.FormEvent) => {
    ev.preventDefault();
    dispatch(sidebarActions.clearSelected());
  };

  const mapViewClick = () => {
    dispatch(selectedParkingLotsActions.openPopup(lotData.id));
  };

  return (
    <div className='map-sidebar map-sidebar map-sidebar-info'>
      <div className='map-sidebar-header'>
        <h3>{lot.Name}</h3>
        <Button size='small' className='close' icon='close' onClick={handleCloseClick}></Button>
      </div>
      <div className='map-sidebar-body'>
        <PanelBar keepItemsMounted={true}>
          <PanelBarItem title={localization.toLanguageStringF('common.generalInfo')} expanded={true}>
            <div>
              {(lot.SpotsCount || 0) > 0 && (
                <div className='map-sidebar-row'>
                  <label>{localization.toLanguageStringF('parkingLot.sidebar.spaceCount')}:</label>
                  <div>{lot.SpotsCount}</div>
                </div>
              )}
              <div className='map-sidebar-row'>
                <label>{localization.toLanguageStringF('common.address')}:</label>
                <div>{lot.Address}</div>
              </div>
              {!!lot.Ownership && (
                <div className='map-sidebar-row'>
                  <label>{localization.toLanguageStringF('common.ownership')}:</label>
                  <div>{lot.Ownership}</div>
                </div>
              )}
              <div className='map-sidebar-row'>
                <label>{localization.toLanguageStringF('common.geo')}:</label>
                <div>{geoUtils.toUiString(lot.Center)}</div>
              </div>
            </div>
            <div className='map-sidebar-row more-info'>
              <Link onClick={mapViewClick} className='more-info-button' disabled={lotData.openPopup}>
                {localization.toLanguageStringF('common.mapView')}
              </Link>
            </div>
          </PanelBarItem>
        </PanelBar>
      </div>
    </div>
  );
};

export const ParkingLotSidebar: FC = () => {
  const current = useAppSelector(currentParkingLot);

  return current?.entity ? (
    <MapSidebarContainer name={SidebarName.parkingLot}>
      <Sidebar lotData={current} />
    </MapSidebarContainer>
  ) : null;
};
