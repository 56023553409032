import { Feature, Point, GeoJsonProperties } from 'geojson';
import { CirclePaint, GeoJSONSource, LngLat, MapLayerMouseEvent } from 'mapbox-gl';
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { MapContext } from 'react-map-gl/dist/esm/components/map';
import { EMPTY_FEATURE_COLLECTION } from '../../../../../constants';
import { metersEditingActions, selectMetersEditing } from '../../../../../features';

import { useMapLayerEditing, useMapLayerHover } from '../../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { ILayouts } from '../../common';

interface Props {
  layerId: string;
  paint: CirclePaint;
  enabled: boolean;
  onLayerClick: (evt: MapLayerMouseEvent) => void;
}

export const MetersEditingLayer: FunctionComponent<Props> = ({ layerId, paint, enabled, onLayerClick }) => {
  const dispatch = useAppDispatch();
  const metersEditing = useAppSelector(selectMetersEditing);
  const [geojson] = useState(EMPTY_FEATURE_COLLECTION);

  const { map } = useContext(MapContext);

  const layouts: ILayouts = {
    meters: {
      visibility: enabled ? 'visible' : 'none',
    },
  };

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      onLayerClick(evt);
    },
    [onLayerClick],
  );

  useEffect(() => {
    const features: Array<Feature<Point, GeoJsonProperties>> = [];
    if (metersEditing.isEditing) {
      Object.keys(metersEditing.meters).forEach((key) => {
        const id = parseInt(key);
        features.push(metersEditing.meters[id].feature);
      });
    }

    (map.getSource('meters-editing-source') as GeoJSONSource)?.setData({
      type: 'FeatureCollection',
      features,
    });
  }, [map, metersEditing.isEditing, metersEditing.meters]);

  useMapLayerHover([layerId]);
  useEffect(() => {
    map.on('click', layerId, handleLayerClick);

    return () => {
      map.off('click', layerId, handleLayerClick);
    };
  }, [map, handleLayerClick, layerId]);

  const handleMeterMove = useCallback(
    (feature: Feature<Point, GeoJsonProperties>, position: LngLat) => {
      dispatch(metersEditingActions.moveMeter({ meterFeature: feature, position }));
    },
    [dispatch],
  );
  const handleMeterMoveFinish = useCallback(() => {
    dispatch(metersEditingActions.finishEditing());
  }, [dispatch]);
  useMapLayerEditing(layerId, true, handleMeterMove, handleMeterMoveFinish);

  return (
    <Source id='meters-editing-source' type='geojson' data={geojson} generateId={true}>
      <Layer
        {...{
          id: layerId,
          type: 'circle',
          layout: layouts.meters,
          paint: paint,
          before: 'building-extrusion',
        }}
      />
    </Source>
  );
};
