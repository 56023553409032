/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import classNames from 'classnames/bind';

import { ISelectedOffstreetZone, SidebarName } from '../../../../model';
import { MapSidebarContainer } from '../../controls';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { currentOffstreetZone, selectedOffstreetZonesActions, sidebarActions } from '../../../../features';
import { selectedAreaActions } from '../../../../features/map';
import { Link } from '../../../common';
import { RevenueTable } from '../../../revenue';
import { AreaTypeName } from '../area/AreaTypeName';
import { GeoCoordinatesText } from '../../../common/geo-coordinates-text/GeoCoordinatesText';
import { OffstreetZoneVendorIcon } from '../../icons';
import { useOpenPopupDispatch, useReportBuilder } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ISelectedEntity, NavigationSource, ReportName, amplitudeService } from '../../../../services';
import { SidebarSectionLazyLoader } from '../../common';

import styles from './OffstreetZoneSidebar.module.scss';

const cx = classNames.bind(styles);

type Props = {
  zoneData: ISelectedEntity<ISelectedOffstreetZone>;
};

const Sidebar: FunctionComponent<Props> = ({ zoneData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const zone = zoneData.entity;
  const spotsCount = zone?.SpotsCount || 0;
  const localization = useExtendedLocalization();
  const reportsBuilder = useReportBuilder();

  const revenueReportUrl = zone && reportsBuilder.getGarageRevenue(zone.Id);

  const handleCloseClick = (ev: React.FormEvent) => {
    ev.preventDefault();
    dispatch(sidebarActions.clearSelected());
  };

  const areaClick = (areaId: number | undefined) => {
    if (areaId) popupDispatch(selectedAreaActions.loadArea({ id: areaId, position: null }));
  };

  const mapView = () => {
    dispatch(selectedOffstreetZonesActions.openPopup(zoneData.id));

    if (zoneData.entity)
      dispatch(
        selectedOffstreetZonesActions.moveToZone({
          id: zoneData.id,
          position: zoneData.position,
        }),
      );
  };

  const revenueMoreInfo = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (revenueReportUrl) {
      window.open(revenueReportUrl, '_blank');
      amplitudeService.trackReport(ReportName.OffStreetRevenue, NavigationSource.Sidebar);
    }
  };

  return (
    <div className='map-sidebar map-sidebar-info'>
      <div className='map-sidebar-header'>
        <h3>{zone?.Name}</h3>

        <Button size='small' className='close' icon='close' onClick={handleCloseClick}></Button>
      </div>

      <div className='map-sidebar-body'>
        <PanelBar>
          <PanelBarItem title={localization.toLanguageStringF('common.generalInfo')} expanded={true}>
            <div className={styles.imageContainer}>
              {spotsCount > 0 && (
                <div className={cx('map-sidebar-row', 'row-img')}>
                  <label>{localization.toLanguageStringF('offstreetZone.sidebar.spaceCount')}:</label>
                  <div className='row-value'>{spotsCount}</div>
                </div>
              )}

              <div className={cx('map-sidebar-row', 'row-img')}>
                <label>{localization.toLanguageStringF('common.vendor')}:</label>
                <div className='row-value'>{zone?.vendor?.Name}</div>
              </div>

              <div className={cx('map-sidebar-row', 'row-img')}>
                <label>{localization.toLanguageStringF('common.address')}:</label>
                <div className='row-value'>{zone?.Address}</div>
              </div>

              {!!zone?.Ownership && (
                <div className={cx('map-sidebar-row', 'row-img')}>
                  <label>{localization.toLanguageStringF('common.ownership')}:</label>
                  <div className='row-value'>{zone?.Ownership}</div>
                </div>
              )}

              {zone?.area ? (
                <div className='map-sidebar-row'>
                  <label>{<AreaTypeName area={zone.area} />}</label>
                  <div className='row-value'>
                    <Link className={styles.link} onClick={() => areaClick(zone.AreaId)}>
                      {zone.area.Name}
                    </Link>
                  </div>
                </div>
              ) : null}

              <div className='map-sidebar-row'>
                <label>{localization.toLanguageStringF('common.geo')}:</label>
                <div className='row-value'>
                  <GeoCoordinatesText position={zone?.Position} />
                </div>
              </div>

              <OffstreetZoneVendorIcon vendorId={zone?.VendorId} className={styles.vendorIcon} />
            </div>

            <div className='map-sidebar-row more-info'>
              <Link className='more-info-button' onClick={mapView} disabled={zoneData.openPopup}>
                {localization.toLanguageStringF('common.mapView')}
              </Link>
            </div>
          </PanelBarItem>

          {zone && (
            <PanelBarItem title={localization.toLanguageStringF('offstreetZone.sidebar.revenue')} expanded={false}>
              <SidebarSectionLazyLoader
                hasData={(zone.revenue?.length || 0) > 0}
                shouldLoadData={zone.revenue === undefined}
                loadData={() => dispatch(selectedOffstreetZonesActions.fetchRevenue(zone.Id))}
              >
                <RevenueTable revenue={zone.revenue || []} />
                <div className='map-sidebar-row more-info'>
                  {revenueReportUrl && (
                    <Link onClick={revenueMoreInfo} className='more-info-button'>
                      {localization.toLanguageStringF('common.moreInfo')}
                    </Link>
                  )}
                </div>
              </SidebarSectionLazyLoader>
            </PanelBarItem>
          )}
        </PanelBar>
      </div>
    </div>
  );
};

export const OffstreetZoneSidebar: FunctionComponent = () => {
  const current = useAppSelector(currentOffstreetZone);

  return current?.entity ? (
    <MapSidebarContainer name={SidebarName.offstreetZone}>
      <Sidebar zoneData={current} />
    </MapSidebarContainer>
  ) : null;
};
