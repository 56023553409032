import { FC } from 'react';

import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ITrafficReportItem, Period } from '../../../../model';
import { MetricsTable, MetricValue } from '../metrics-table-table/MetricsTable';
import { dateUtils, numberUtils } from '../../../../utils';

interface Props {
  report: ITrafficReportItem[];
  className?: string;
}

export const TrafficTable: FC<Props> = ({ report, className }) => {
  const localization = useExtendedLocalization();
  const periods = [Period.Yesterday, Period.Week, Period.Month, Period.Quarter];

  const createMetricValues = (
    valueProp: (x: ITrafficReportItem) => number | string,
    changeProp: (x: ITrafficReportItem) => number,
    isMoney: boolean,
  ) => {
    const values: { [key in Period]?: MetricValue | undefined } = {};
    periods.forEach((x) => {
      const metric = report.find((y) => y.Period === x);
      values[x] = metric ? { value: valueProp(metric), change: changeProp(metric), isMoney: isMoney } : undefined;
    });
    return values;
  };

  const metricItems = [
    {
      name: localization.toLanguageStringF(`traffic.trafficReport.speed`),
      values: createMetricValues(
        (y) => (y.AvgSpeed > 0 ? `${numberUtils.toUiString(y.AvgSpeed, 0)} mph` : ''),
        (y) => y.AvgSpeedChange,
        false,
      ),
    },
    {
      name: localization.toLanguageStringF(`traffic.trafficReport.travelTime`),
      values: createMetricValues(
        (y) => dateUtils.formatMinutes(y.AvgTravelTimeMinutes, true),
        (y) => y.AvgTravelTimeMinutesChange,
        false,
      ),
    },
  ];

  return <MetricsTable periods={periods} metricItems={metricItems} className={className}></MetricsTable>;
};
