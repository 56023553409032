import classNames from 'classnames/bind';
import { FC, useMemo } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { IOffstreetZoneRevenueReportItem, IZoneRevenueReportItem } from '../../../../model';
import { numberUtils } from '../../../../utils';
import { PeriodChartInfo } from '../period-info/period-chart-info/PeriodChartInfo';
import { PeriodTextInfo } from '../period-info/period-text-info/PeriodTextInfo';
import styles from './RevenuePopupTab.module.scss';

const isWeighted = (revenueItem: IZoneRevenueReportItem | IOffstreetZoneRevenueReportItem): revenueItem is IZoneRevenueReportItem => {
  const r = revenueItem as IZoneRevenueReportItem;
  return r.CurrentWeighted !== undefined || r.PreviousWeighted !== undefined;
};

interface IProps {
  revenueItem: IZoneRevenueReportItem | IOffstreetZoneRevenueReportItem;
}

const cx = classNames.bind(styles);

export const RevenuePopupTab: FC<IProps> = ({ revenueItem }) => {
  const localization = useExtendedLocalization();

  const revenueByPeriodSeries = useMemo(() => {
    return [
      { name: localization.toLanguageStringF('revenue.popupTab.previous'), value: revenueItem?.PreviousTotal || 0 },
      { name: localization.toLanguageStringF('revenue.popupTab.current'), value: revenueItem?.CurrentTotal || 0 },
      { name: localization.toLanguageStringF('revenue.popupTab.12MonthAverage'), value: revenueItem?.YearlyAverage || 0 },
    ];
  }, [localization, revenueItem]);

  const [paymentMethodRevenueSeries, vendorRevenueSeries, paymentMethodCountSeries, vendorCountSeries] = useMemo(() => {
    let paymentMethodRevenueSeries;
    let vendorRevenueSeries;
    let paymentMethodCountSeries;
    let vendorCountSeries;

    if ((revenueItem as IZoneRevenueReportItem).PaymentMethodRevenueChartData) {
      const zoneRevenueItem = revenueItem as IZoneRevenueReportItem;

      paymentMethodRevenueSeries = zoneRevenueItem.PaymentMethodRevenueChartData?.map((x) => {
        return { name: localization.toLanguageStringF(`meter.paymentMethod.${x.Id}`), value: x.Revenue };
      });

      paymentMethodCountSeries = zoneRevenueItem.PaymentMethodCountChartData?.map((x) => {
        return { name: localization.toLanguageStringF(`meter.paymentMethod.${x.Id}`), value: x.Count };
      });

      vendorRevenueSeries = zoneRevenueItem.VendorRevenueChartData?.map((x) => {
        return { name: x.Name, value: x.Revenue };
      });

      vendorCountSeries = zoneRevenueItem.VendorCountChartData?.map((x) => {
        return { name: x.Name, value: x.Count };
      });
    } else {
      const offstreetZoneRevenueItem = revenueItem as IOffstreetZoneRevenueReportItem;

      paymentMethodRevenueSeries = offstreetZoneRevenueItem.PaymentChannelRevenueChartData?.map((x) => {
        return { name: localization.toLanguageStringF(`offstreetZone.paymentChannel.${x.Id}`), value: x.Revenue };
      });

      paymentMethodCountSeries = offstreetZoneRevenueItem.PaymentChannelCountChartData?.map((x) => {
        return { name: localization.toLanguageStringF(`offstreetZone.paymentChannel.${x.Id}`), value: x.Count };
      });

      vendorRevenueSeries = offstreetZoneRevenueItem.VendorRevenueChartData?.map((x) => {
        return { name: x.Name, value: x.Revenue };
      });

      vendorCountSeries = offstreetZoneRevenueItem.VendorCountChartData?.map((x) => {
        return { name: x.Name, value: x.Count };
      });
    }

    return [paymentMethodRevenueSeries || [], vendorRevenueSeries || [], paymentMethodCountSeries || [], vendorCountSeries || []];
  }, [revenueItem, localization]);

  return (
    <div className={cx('container')}>
      <div className={cx('row')}>
        <div className={cx('column', 'first-column')}>
          <PeriodTextInfo
            label={localization.toLanguageStringF('revenue.popupTab.totalRevenue')}
            value={`$${numberUtils.toUiString(revenueItem.CurrentTotal)}`}
            prevValue={`$${numberUtils.toUiString(revenueItem.PreviousTotal)}`}
            change={revenueItem.TotalChange ?? 0}
            small={true}
          ></PeriodTextInfo>
        </div>
        <div className={cx('column')}>
          <PeriodChartInfo
            title={localization.toLanguageStringF('revenue.popupTab.byPaymentMethod')}
            type='pie'
            series={paymentMethodRevenueSeries}
          />
        </div>
        <div className={cx('column')}>
          <PeriodChartInfo title={localization.toLanguageStringF('revenue.popupTab.byVendor')} type='pie' series={vendorRevenueSeries} />
        </div>
      </div>

      <div className={cx('row')}>
        <div className={cx('column', 'first-column')}>
          <PeriodTextInfo
            label={localization.toLanguageStringF('revenue.popupTab.transactions')}
            value={`${numberUtils.toUiString(revenueItem.CurrentCount, 0)}`}
            prevValue={`${numberUtils.toUiString(revenueItem.PreviousCount, 0)}`}
            change={revenueItem.CountChange ?? 0}
            small={true}
          ></PeriodTextInfo>
        </div>
        <div className={cx('column')}>
          <PeriodChartInfo
            type='bar'
            title={localization.toLanguageStringF('revenue.popupTab.byPaymentMethod')}
            series={paymentMethodCountSeries}
            fractionDigits={0}
          />
        </div>
        <div className={cx('column')}>
          <PeriodChartInfo
            type='bar'
            title={localization.toLanguageStringF('revenue.popupTab.byVendor')}
            series={vendorCountSeries}
            fractionDigits={0}
          />
        </div>
      </div>

      <div className={cx('row')}>
        <div className={cx('column', 'first-column')}>
          <PeriodTextInfo
            label={localization.toLanguageStringF('revenue.popupTab.averagePrice')}
            value={`$${numberUtils.toUiString(revenueItem.CurrentAverage)}`}
            prevValue={`$${numberUtils.toUiString(revenueItem.PreviousAverage)}`}
            change={revenueItem.CurrentAverageChange ?? 0}
            small={true}
          ></PeriodTextInfo>
        </div>
        <div className={cx('column')}>
          <PeriodTextInfo
            label={localization.toLanguageStringF('revenue.popupTab.averageDailyRevenue')}
            value={`$${numberUtils.toUiString(revenueItem.CurrentDailyAverage)}`}
            prevValue={`$${numberUtils.toUiString(revenueItem.PreviousDailyAverage)}`}
            change={revenueItem.DailyAverageChange ?? 0}
            small={true}
          ></PeriodTextInfo>
        </div>
        {isWeighted(revenueItem) && (
          <div className={cx('column')}>
            <PeriodTextInfo
              label={localization.toLanguageStringF('revenue.popupTab.weighted')}
              value={`$${numberUtils.toUiString((revenueItem as IZoneRevenueReportItem).CurrentWeighted)}`}
              prevValue={`$${numberUtils.toUiString((revenueItem as IZoneRevenueReportItem).PreviousWeighted)}`}
              change={(revenueItem as IZoneRevenueReportItem).WeightedChange ?? 0}
              small={true}
            ></PeriodTextInfo>
          </div>
        )}
        <div className={cx('column')}>
          <PeriodChartInfo
            type='bar'
            title={localization.toLanguageStringF('revenue.popupTab.revenueComparison')}
            series={revenueByPeriodSeries}
          />
        </div>
      </div>
    </div>
  );
};
