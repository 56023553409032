import React, { FC } from 'react';
import { Position } from 'geojson';
import classNames from 'classnames/bind';

import { ISelectedParkingLot, SidebarName } from '../../../../model';
import { ISelectedEntity, PopupType, amplitudeService } from '../../../../services';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  currentParkingLot,
  selectSidebarState,
  selectedParkingLots,
  selectedParkingLotsActions,
  sidebarActions,
} from '../../../../features';
import { PinButton, PopupHeaderPlaceholder, SelfOrientedPopup, Spinner, ContentPlaceholder, Link } from '../../../common';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ParkingLotIcon } from '../../icons';

import styles from './ParkingLotPopup.module.scss';
import { ShowGoogleStreetViewLink } from '../../common/show-google-street-view-link/ShowGoogleStreetViewLink';

const cx = classNames.bind(styles);

const PopupItem: FC<{ lotData: ISelectedEntity<ISelectedParkingLot> }> = ({ lotData }) => {
  const dispatch = useAppDispatch();
  const selectedSidebar = useAppSelector(selectSidebarState);
  const localization = useExtendedLocalization();
  const currentParkingLotData = useAppSelector(currentParkingLot);

  const lot = lotData.entity;

  if (!lotData.openPopup || !lotData.position) return null;

  const pinPopup = () => {
    dispatch(selectedParkingLotsActions.pinPopup(lotData.id));
  };

  const closePopup = () => {
    dispatch(selectedParkingLotsActions.closePopup(lotData.id));
  };

  const onDragEnd = (position: Position) => {
    if (!lotData) {
      return;
    }
    dispatch(selectedParkingLotsActions.change({ id: lotData.id, position: position }));
    amplitudeService.trackPopupMove(PopupType.ParkingLot);
  };

  const moreInfo = () => {
    dispatch(selectedParkingLotsActions.setCurrent(lot));
    dispatch(sidebarActions.setSelected({ name: SidebarName.parkingLot, data: lotData.id }));
  };

  const content = (
    <>
      <ContentPlaceholder loading={lotData.loading} element={<PopupHeaderPlaceholder />}>
        <div className={cx('map-popup-header', 'header')}>
          <ParkingLotIcon className={styles.headerIcon} />
          <h4>{lot?.Name}</h4>
          <div className={styles.headerDescription}>{localization.toLanguageStringF('parkingLot.popup.title')}</div>
          <PinButton className={styles.pinButton} pinned={lotData.pinnedPopup} onClick={pinPopup} />
        </div>
      </ContentPlaceholder>
      <div className={cx('map-popup-body', 'mapPopupBody')}>
        {(lot?.SpotsCount || 0) > 0 && (
          <div className='map-popup-row'>
            <label>{localization.toLanguageStringF('parkingLot.popup.spaceCount')}:</label>
            <div className='row-value'>{lot?.SpotsCount}</div>
          </div>
        )}
        {lot && (
          <div className='map-popup-row'>
            <label>{localization.toLanguageStringF('common.address')}:</label>
            <div className='row-value'>{lot.Address}</div>
          </div>
        )}
        {!!lot?.Ownership && (
          <div className='map-popup-row'>
            <label>{localization.toLanguageStringF('common.ownership')}:</label>
            <div className='row-value'>{lot.Ownership}</div>
          </div>
        )}

        <div className='map-popup-row more-info'>
          {lotData?.position && <ShowGoogleStreetViewLink coordinates={lotData.position} />}
          <Link onClick={moreInfo} disabled={selectedSidebar === SidebarName.parkingLot && currentParkingLotData?.id === lot?.Id}>
            {localization.toLanguageStringF('common.moreInfo')}
          </Link>
        </div>
      </div>
    </>
  );
  return (
    <React.StrictMode>
      <SelfOrientedPopup
        data={lot}
        longitude={lotData.position[0]}
        latitude={lotData.position[1]}
        onClose={closePopup}
        closeOnClick={false}
        className='parking-lot-popup'
        maxWidth='360px'
        initPosition={lotData.initPosition}
        onDragEnd={onDragEnd}
      >
        <div className={styles.popup}>
          {content}
          {lotData.loading && <Spinner backdrop={true} />}
        </div>
      </SelfOrientedPopup>
    </React.StrictMode>
  );
};

export const ParkingLotPopup: FC = () => {
  const lotData = useAppSelector(selectedParkingLots);
  return (
    <>
      {lotData.selected.map((x) => (
        <PopupItem key={x.id} lotData={x} />
      ))}
    </>
  );
};
