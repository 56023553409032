import { FC } from 'react';
import classNames from 'classnames/bind';
import { Icon } from '@progress/kendo-react-common';

import { Link } from '../../../common';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { NavigationSource, ReportName, amplitudeService } from '../../../../services';

import styles from './ViewReportLink.module.scss';

const cx = classNames.bind(styles);

interface Props {
  reportUrl: string | null | undefined;
  reportName?: ReportName;
  className?: string;
}

export const ViewReportLink: FC<Props> = ({ reportUrl, reportName, className }) => {
  const localization = useExtendedLocalization();

  const openReport = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (reportUrl) {
      window.open(reportUrl, '_blank');
      if (reportName) {
        amplitudeService.trackReport(reportName, NavigationSource.Popup);
      }
    }
  };

  return (
    <div className={cx('reportLink', className)}>
      {localization.toLanguageStringF('common.viewIn')}
      <Link className={styles.link} onClick={openReport}>
        {localization.toLanguageStringF('common.reports')}
        <Icon name='hyperlink-open' size='medium' className={styles.icon} />
      </Link>
    </div>
  );
};
