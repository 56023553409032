import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';

import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { TagFilter, TilesBoundingBox } from '../../../../model';
import { RootState } from '../../../../store';

export interface IParkingLotsLevelGeoState {
  loading: boolean;
  data: FeatureCollection;
  centerPoints: FeatureCollection;
  count: number;
}

const initialState: IParkingLotsLevelGeoState = {
  loading: false,
  data: EMPTY_FEATURE_COLLECTION,
  centerPoints: EMPTY_FEATURE_COLLECTION,
  count: 0,
};

const slice = createSlice({
  name: 'parkingLotsGeo',
  initialState: initialState,
  reducers: {
    fetch(state, action: PayloadAction<{ box: TilesBoundingBox; types: TagFilter }>) {
      state.loading = true;
    },
    fetchSuccess(state, action: PayloadAction<{ data: FeatureCollection; center: FeatureCollection }>) {
      state.loading = false;
      state.data = action.payload.data;
      state.centerPoints = action.payload.center;
      state.count = action.payload.data.features.length;
    },
    fetchFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.data = EMPTY_FEATURE_COLLECTION;
      state.centerPoints = EMPTY_FEATURE_COLLECTION;
      state.count = 0;
      console.log(action);
    },
  },
});

// Actions
export const parkingLotsGeoActions = slice.actions;

//Selectors
export const selectParkingLotsGeo = (state: RootState) => state.parkingLotsGeo.data;
export const selectParkingLotsCenterGeo = (state: RootState) => state.parkingLotsGeo.centerPoints;
export const selectParkingLotsGeoCount = (state: RootState) => state.parkingLotsGeo.count;

// Reducer
export const parkingLotsGeoReducer = slice.reducer;
