/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC } from 'react';

import {
  currentLoadingZone,
  selectSidebarState,
  selectedLoadingZones,
  selectedLoadingZonesActions,
  sidebarActions,
} from '../../../../features';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ISelectedLoadingZone, SidebarName } from '../../../../model';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Link, PinButton, PopupPlaceholder, SelfOrientedPopup } from '../../../common';
import { LoadingZoneIcon } from '../../icons';
import { CameraThumbnail } from '../cameras/camera-thumbnail/CameraThumbnail';
import { LoadingZoneQR } from './LoadingZoneQR';
import { LoadingZoneOccupancy } from './loading-zones-occupancy/LoadingZoneOccupancy';

import { Position } from 'geojson';
import { ISelectedEntity } from '../../../../services';
import styles from './LoadingZonePopup.module.scss';
import { ShowGoogleStreetViewLink } from '../../common/show-google-street-view-link/ShowGoogleStreetViewLink';

export const PopupItem: FC<{ zoneData: ISelectedEntity<ISelectedLoadingZone> }> = ({ zoneData }) => {
  const dispatch = useAppDispatch();
  const selectedSidebar = useAppSelector(selectSidebarState);
  const localization = useExtendedLocalization();

  const currentZoneData = useAppSelector(currentLoadingZone);
  const zone = zoneData.entity;

  const closePopup = () => {
    dispatch(selectedLoadingZonesActions.closePopup(zoneData.id));
  };

  const pinPopup = () => {
    dispatch(selectedLoadingZonesActions.pinPopup(zoneData.id));
  };

  const moreInfo = () => {
    dispatch(selectedLoadingZonesActions.setCurrent(zone));
    dispatch(sidebarActions.setSelected({ name: SidebarName.loadingZone, data: zone?.Id }));
  };

  const onDragEnd = (position: Position) => {
    if (!zoneData) {
      return;
    }
    dispatch(selectedLoadingZonesActions.change({ id: zoneData.id, position: position }));
  };

  if (!zoneData.openPopup || !zoneData.position) return null;

  const content = (
    <>
      <div className={`map-popup-header ${styles.header}`}>
        <LoadingZoneIcon />
        <h4>Loading Zone: #{zone?.Code} </h4>
        <LoadingZoneOccupancy
          spotsCount={zone?.SpotsCount || 0}
          occupiedSpotsCount={zone?.OccupiedSpotsCount || 0}
          className={styles.curbSpacesStatusCount}
        />
        <PinButton className={styles.pinButton} pinned={zoneData.pinnedPopup} onClick={pinPopup} />
      </div>

      <div className='map-popup-body'>
        <div className='map-popup-row'>
          <div className={styles.columnLabel}>
            <label>{localization.toLanguageStringF('zone.popup.address')}:</label>
          </div>
          <div className='row-value'>{zone?.Address}</div>
        </div>

        <div className='map-popup-row'>
          <div className={styles.columnLabel}>
            <label>{localization.toLanguageStringF('common.parkingSpacesCount')}:</label>
          </div>
          <div className='row-value'>{zone?.SpotsCount}</div>
        </div>

        <div className='map-popup-row'>
          {(zone?.camerasStates?.length || 0) > 0 && (
            <div className={`${styles.camerasColumn}`}>
              <label className={styles.label}>Camera:</label>

              <div className={styles.items}>
                {zone?.camerasStates.map((x) => (
                  <div key={x.CameraId} className={styles.thumbnail}>
                    <CameraThumbnail state={x} thumbnailWidth={465} thumbnailHeight={300} />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div>
            <label className={styles.label}>QR Code:</label>
            <div className={styles.items}>
              <div className={styles.thumbnail}>
                <LoadingZoneQR zoneNumber={zone?.Code} height={300} />
              </div>
            </div>
          </div>
        </div>

        <div className={`map-popup-row more-info ${styles.moreInfo}`}>
          {zoneData?.position && <ShowGoogleStreetViewLink coordinates={zoneData.position} />}
          <Link onClick={moreInfo} disabled={selectedSidebar === SidebarName.loadingZone && currentZoneData?.id === zone?.Id}>
            {localization.toLanguageStringF('common.moreInfo')}
          </Link>
        </div>
      </div>
    </>
  );

  return (
    <React.StrictMode>
      <SelfOrientedPopup
        data={zone}
        maxWidth='690px'
        longitude={zoneData.position[0]}
        latitude={zoneData.position[1]}
        onClose={closePopup}
        closeOnClick={false}
        className={(zone?.camerasStates?.length || 0) > 0 ? 'loading-zone-popup' : 'loading-zone-popup-qr-code-only'}
        initPosition={zoneData.initPosition}
        onDragEnd={onDragEnd}
      >
        <PopupPlaceholder loading={zoneData.loading} rows={3}>
          {content}
        </PopupPlaceholder>
      </SelfOrientedPopup>
    </React.StrictMode>
  );
};

export const LoadingZonePopup: FC = () => {
  const zonesData = useAppSelector(selectedLoadingZones);
  const zones = zonesData.selected;

  return (
    <>
      {zones.map((x) => (
        <PopupItem key={x.id} zoneData={x} />
      ))}
    </>
  );
};
