export enum SidebarName {
  layers = 'layers',
  curbSpace = 'curbSpace',
  camera = 'camera',
  meter = 'meter',
  sign = 'sign',
  zone = 'zone',
  rppZone = 'rpp-zone',
  rppArea = 'rpp-area',
  area = 'area',
  offstreetZone = 'offstreetZone',
  enforcement = 'enforcement',
  loadingZone = 'loadingZone',
  parkingLot = 'parkingLot',
  blockface = 'blockface',
  studyArea = 'studyArea',
}
