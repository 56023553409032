import { FC, useState } from 'react';

import { IPeriodFilter } from '../../../../../features';
import { useExtendedLocalization } from '../../../../../hooks/use-extended-localization-service';
import { IViolationInfo } from '../../../../../model';
import { HeatmapPeriod } from '../../../../../model/api/heatmap-period';
import { ReportPeriodSelector } from '../../report-period-selector/ReportPeriodSelector';
import { ViolationsPeriodInfo } from './ViolationsPeriodInfo';

interface Props {
  report: IViolationInfo | null | undefined;
  selectedGroupId?: number;
  onChangeFilter: (change: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => void;
}

export const ViolationsPeriodSelector: FC<Props> = ({ report, selectedGroupId, onChangeFilter }) => {
  const localization = useExtendedLocalization();
  const [filter, setFilter] = useState<IPeriodFilter | undefined>(undefined);

  const onChangeFilterHandler = (filter: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => {
    setFilter(filter);
    onChangeFilter(filter);
  };

  return (
    <>
      <ReportPeriodSelector onChangeFilter={onChangeFilterHandler} />
      {report && filter ? (
        <ViolationsPeriodInfo filter={filter} violation={report} selectedGroupId={selectedGroupId} />
      ) : (
        localization.toLanguageStringF('common.noDataToDisplay')
      )}
    </>
  );
};
