export enum LayerName {
  Areas = 'areas',
  Traffic = 'traffic',
  Spots = 'spots',
  Meters = 'meters',
  Revenue = 'revenue',
  Cameras = 'cameras',
  Zones = 'zones',
  RppZones = 'rpp-zones',
  RppAreas = 'rpp-areas',
  Signs = 'signs',
  OffstreetZones = 'offstreet-zones',
  MetersRevenue = 'meters-revenue',
  ZonesRevenue = 'zones-revenue',
  OffstreetZonesRevenue = 'offstreet-zones-revenue',
  StreetObjects = 'streetObjects',
  StreetSigns = 'streetSigns',
  LoadingZones = 'loading-zones',
  ParkingLots = 'parking-lots',
  Blockfaces = 'blockfaces',
  BlockfacesRevenue = 'blockfaces-revenue',
  BlockfacesDuration = 'blockfaces-duration',
  StudyAreas = 'study-areas',
  HeatmapStudyAreas = 'heatmap-study-areas',
}
