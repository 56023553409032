import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisLabels,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import classNames from 'classnames/bind';
import { FC, useCallback, useMemo, useState } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { IDayItem, IHourItem, IOccupancyTrafficReport, OccupancySource } from '../../../../model';
import { HeatmapPeriod } from '../../../../model/api/heatmap-period';
import { dateUtils, numberUtils } from '../../../../utils';
import { PeriodTextInfo } from '../period-info/period-text-info/PeriodTextInfo';
import { ReportPeriodSelector } from '../report-period-selector/ReportPeriodSelector';
import styles from './OccupancyTrafficSelector.module.scss';
import { OccupancySourceSelector } from '../occupancy-source-selector/OccupancySourceSelector';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { heatmapsFilterActions, selectHeatmapFilters } from '../../../../features';

const cx = classNames.bind(styles);

const calcMaxOccRate = (occupancySeries: IHourItem[] | IDayItem[]) => {
  const max = Math.max(...occupancySeries.map((x) => x.Value));
  return Math.min(max, 100) + 5;
};

const OccupancyTrafficChart: FC<{
  occupancySeries: IHourItem[] | IDayItem[];
  trafficSeries: IHourItem[] | IDayItem[];
  trafficMaxValue: number;
  category: string;
  categories: string[];
}> = ({ occupancySeries, trafficSeries, trafficMaxValue, category, categories }) => {
  const localization = useExtendedLocalization();

  const content = useMemo(() => {
    return (
      <Chart>
        <ChartValueAxis>
          <ChartValueAxisItem name={'occupancy'} min={0} max={calcMaxOccRate(occupancySeries)} labels={{ format: `{0}%` }} />
          <ChartValueAxisItem name={'traffic'} min={0} max={trafficMaxValue + 2} labels={{ format: `{0}mph` }}></ChartValueAxisItem>
        </ChartValueAxis>

        <ChartCategoryAxis>
          <ChartCategoryAxisItem title={{ text: category }} categories={categories} axisCrossingValue={[0, 24]}>
            <ChartCategoryAxisLabels rotation={-45}></ChartCategoryAxisLabels>
          </ChartCategoryAxisItem>
        </ChartCategoryAxis>

        <ChartSeries>
          <ChartSeriesItem
            name={localization.toLanguageStringF('occupancyVsTraffic.popupTab.occupancy')}
            type='line'
            markers={{ visible: false }}
            data={occupancySeries.map((x) => x.Value)}
            axis={'occupancy'}
          />
          <ChartSeriesItem
            name={localization.toLanguageStringF('occupancyVsTraffic.popupTab.traffic')}
            color={'green'}
            type='line'
            markers={{ visible: false }}
            data={trafficSeries.map((x) => x.Value)}
            axis={'traffic'}
          />
        </ChartSeries>

        <ChartLegend position='bottom' orientation='horizontal' labels={{ font: '12px Segoe UI' }} />
      </Chart>
    );
  }, [categories, category, localization, occupancySeries, trafficMaxValue, trafficSeries]);

  return content;
};

interface IProps {
  report?: IOccupancyTrafficReport | null;
  onChangeFilter?: (change: { heatmapPeriod: HeatmapPeriod; period: [Date, Date]; occupancySource: OccupancySource }) => void;
}

export const OccupancyTrafficSelector: FC<IProps> = ({ report, onChangeFilter }) => {
  const localization = useExtendedLocalization();
  const heatmapsFilter = useAppSelector(selectHeatmapFilters);
  const dispatch = useAppDispatch();
  const [occupancySource, setOccupancySource] = useState(heatmapsFilter.reportOccupancySource ?? heatmapsFilter.heatmapOccupancySource);
  const [datesPeriod, setDatesPeriod] = useState<{ heatmapPeriod: HeatmapPeriod; period: [Date, Date] } | null>(null);

  const onReportOccupancySourceChanged = useCallback(
    (occupancySource: OccupancySource) => {
      dispatch(heatmapsFilterActions.setReportOccupancySource(occupancySource));
      setOccupancySource(occupancySource);
      if (onChangeFilter && datesPeriod) {
        onChangeFilter({ ...datesPeriod, occupancySource: occupancySource });
      }
    },
    [datesPeriod, dispatch, onChangeFilter],
  );

  const content = useMemo(() => {
    const onChangeFilterHandler = (filter: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => {
      if (onChangeFilter) {
        setDatesPeriod(filter);
        onChangeFilter({ ...filter, occupancySource: occupancySource });
      }
    };

    return (
      <>
        <ReportPeriodSelector onChangeFilter={onChangeFilterHandler}>
          <OccupancySourceSelector className={styles.occupancyType} value={occupancySource} onChange={onReportOccupancySourceChanged} />
        </ReportPeriodSelector>

        {report ? (
          <div className={cx('container')}>
            {/*metrics*/}
            <div className={cx('row')}>
              <div className={cx('column', 'metrics')}>
                <PeriodTextInfo
                  label={localization.toLanguageStringF('occupancyVsTraffic.popupTab.totalSessions')}
                  value={`${numberUtils.toUiString(report.Count ?? 0)}`}
                  prevValue={`${numberUtils.toUiString(report.PrevCount ?? 0)}`}
                  change={report.DiffCount ?? 0}
                  small={true}
                ></PeriodTextInfo>
              </div>
              <div className={cx('column', 'metrics')}>
                <PeriodTextInfo
                  label={localization.toLanguageStringF('occupancyVsTraffic.popupTab.turnover')}
                  value={`${numberUtils.toUiString(report.Turnover ?? 0)}`}
                  prevValue={`${numberUtils.toUiString(report.PrevTurnover ?? 0)}`}
                  change={report.DiffTurnover ?? 0}
                  small={true}
                ></PeriodTextInfo>
              </div>
              <div className={cx('column', 'metrics')}>
                <PeriodTextInfo
                  label={localization.toLanguageStringF('occupancyVsTraffic.popupTab.avDwellTime')}
                  value={dateUtils.formatMinutes(report.DwellTime ?? 0)}
                  prevValue={dateUtils.formatMinutes(report.PrevDwellTime ?? 0)}
                  change={report.DiffDwellTime ?? 0}
                  small={true}
                ></PeriodTextInfo>
              </div>
              <div className={cx('column', 'metrics')}>
                <PeriodTextInfo
                  label={localization.toLanguageStringF('occupancyVsTraffic.popupTab.occupancyPercentage')}
                  value={`${numberUtils.toUiString(report.Occupancy ?? 0)}`}
                  prevValue={`${numberUtils.toUiString(report.PrevOccupancy ?? 0)}`}
                  change={report.DiffOccupancy ?? 0}
                  small={true}
                ></PeriodTextInfo>
              </div>
            </div>

            {/*occupancy report*/}
            <div className={cx('row')}>
              <div className={cx('column', 'second-column')}>
                <OccupancyTrafficChart
                  category={localization.toLanguageStringF('occupancyVsTraffic.popupTab.hours')}
                  categories={report.OccupancyHourChartData.map((x) => localization.toLanguageStringF(`common.hours.${x.Hour}`))}
                  occupancySeries={report.OccupancyHourChartData}
                  trafficSeries={report.TrafficHourChartData}
                  trafficMaxValue={report.MaxSpeed}
                />
              </div>
            </div>

            {/*traffic report*/}
            <div className={cx('row')}>
              <div className={cx('column', 'second-column')}>
                <OccupancyTrafficChart
                  category={localization.toLanguageStringF('occupancyVsTraffic.popupTab.days')}
                  categories={report.OccupancyDayChartData.map((x) => localization.toLanguageStringF(`common.weekDaysNum.${x.Day}`))}
                  occupancySeries={report.OccupancyDayChartData}
                  trafficSeries={report.TrafficDayChartData}
                  trafficMaxValue={report.MaxSpeed}
                />
              </div>
            </div>
          </div>
        ) : (
          localization.toLanguageStringF('common.noDataToDisplay')
        )}
      </>
    );
  }, [occupancySource, onReportOccupancySourceChanged, report, localization, onChangeFilter]);

  return content;
};
