import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DEFAULT_MINUTES_END,
  DEFAULT_MINUTES_START,
  DEFAULT_WEEK_DAYS,
  HeatmapLayerType,
  IViolationGroup,
  OccupancySource,
  RevenueAmountType,
  SavedHeatmapFilterLayer,
  TrafficLayerType,
  ViolationMeasure,
  WeekDay,
} from '../../../../model';
import { HeatmapPeriod } from '../../../../model/api/heatmap-period';
import { RootState } from '../../../../store';

export interface IPeriodFilter {
  heatmapPeriod: HeatmapPeriod;
  period: [Date, Date];
}

export interface IHeatmapPeriodFilter extends IPeriodFilter {
  weekDays: WeekDay[];
  minutesStart: number;
  minutesEnd: number;
}

export interface IHeatmapsState {
  enabled: boolean;
  reportPeriodFilter?: IPeriodFilter;
  periodFilter: IHeatmapPeriodFilter;
  layer: HeatmapLayerType;
  selectedStudyAreas: number[];

  durationEnabled: boolean;

  enforcementEnabled: boolean;
  enforcementGroups: IViolationGroup[];
  enforcementGroupId?: number | null;
  enforcementMeasure: ViolationMeasure;

  revenueEnabled: boolean;
  revenueAmount: RevenueAmountType;

  trafficEnabled: boolean;
  trafficLayerType: TrafficLayerType;
  trafficSegmentsCount: number;

  occupancyEnabled: boolean;
  reportOccupancySource?: OccupancySource;
  heatmapOccupancySource: OccupancySource;
}

const initialState: IHeatmapsState = {
  enabled: false,
  layer: HeatmapLayerType.Zone,
  selectedStudyAreas: [],

  periodFilter: {
    heatmapPeriod: HeatmapPeriod.LastMonthToDate,
    period: HeatmapPeriod.LastMonthToDate.calcPeriod(),
    weekDays: DEFAULT_WEEK_DAYS,
    minutesStart: DEFAULT_MINUTES_START,
    minutesEnd: DEFAULT_MINUTES_END,
  },

  durationEnabled: false,

  enforcementEnabled: false,
  enforcementGroups: [],
  enforcementGroupId: null,
  enforcementMeasure: ViolationMeasure.Count,

  revenueEnabled: false,
  revenueAmount: RevenueAmountType.Actual,

  trafficEnabled: false,
  trafficLayerType: TrafficLayerType.Traffic,
  trafficSegmentsCount: 0,

  occupancyEnabled: false,
  reportOccupancySource: undefined,
  heatmapOccupancySource: OccupancySource.Camera,
};

const slice = createSlice({
  name: 'heatmapsFilter',
  initialState: initialState,
  reducers: {
    setEnabled(state, action: PayloadAction<boolean>) {
      state.enabled = action.payload;
    },

    setReportPeriodFilter(state, action: PayloadAction<IPeriodFilter>) {
      state.reportPeriodFilter = action.payload;
    },

    setHeatmapPeriodFilter(state, action: PayloadAction<IHeatmapPeriodFilter>) {
      state.periodFilter = action.payload;
    },

    setLayer(state, action: PayloadAction<HeatmapLayerType>) {
      state.layer = action.payload;
    },
    selectStudyAreas(state, action: PayloadAction<number[]>) {
      state.selectedStudyAreas = action.payload;
    },

    setDurationEnabled(state, action: PayloadAction<boolean>) {
      state.durationEnabled = action.payload;
    },

    setEnforcementEnabled(state, action: PayloadAction<boolean>) {
      state.enforcementEnabled = action.payload;
    },
    setEnforcementMeasure(state, action: PayloadAction<ViolationMeasure>) {
      state.enforcementMeasure = action.payload;
    },

    fetchEnforcementGroups(state) {},
    fetchEnforcementGroupsSuccess(state, action: PayloadAction<IViolationGroup[]>) {
      state.enforcementGroups = action.payload;
      state.enforcementGroupId = action.payload.length > 0 ? action.payload[0].Id : null;

      if (action.payload.length === 0) state.enforcementEnabled = false;
    },
    fetchEnforcementGroupsFailed(state, action: PayloadAction<string>) {
      console.error(action.payload);
    },
    setEnforcementGroup(state, action: PayloadAction<number | null>) {
      state.enforcementGroupId = action.payload;
    },

    setRevenueEnabled(state, action: PayloadAction<boolean>) {
      state.revenueEnabled = action.payload;
    },
    setRevenueAmount(state, action: PayloadAction<RevenueAmountType>) {
      state.revenueAmount = action.payload;
    },

    setTrafficEnabled(state, action: PayloadAction<boolean>) {
      state.trafficEnabled = action.payload;
    },
    setTrafficLayerType(state, action: PayloadAction<TrafficLayerType>) {
      state.trafficLayerType = action.payload;
    },

    setTrafficSegmentsCountSuccess(state, action: PayloadAction<number>) {
      state.trafficSegmentsCount = action.payload;
      if (state.trafficSegmentsCount === 0) {
        state.trafficLayerType = TrafficLayerType.Traffic;
      }
    },

    setTrafficSegmentsCountFailed(state, action: PayloadAction<string>) {
      console.error(action);
    },

    setOccupancyEnabled(state, action: PayloadAction<boolean>) {
      state.occupancyEnabled = action.payload;
    },

    setReportOccupancySource(state, action: PayloadAction<OccupancySource>) {
      state.reportOccupancySource = action.payload;
    },

    setHeatmapOccupancySource(state, action: PayloadAction<OccupancySource>) {
      state.heatmapOccupancySource = action.payload;
    },

    applySavedLayerSettings(state, action: PayloadAction<SavedHeatmapFilterLayer | undefined>) {
      if (action.payload) {
        state.enabled = action.payload.enabled;

        if (action.payload.reportPeriodFilter) {
          state.reportPeriodFilter = {
            heatmapPeriod: HeatmapPeriod.fromStr(action.payload.reportPeriodFilter.heatmapPeriod.name),
            period: [new Date(action.payload.reportPeriodFilter.period[0]), new Date(action.payload.reportPeriodFilter.period[1])],
          };
        }

        state.periodFilter.heatmapPeriod = HeatmapPeriod.fromStr(action.payload.periodFilter.heatmapPeriod.name);
        state.periodFilter.period = [new Date(action.payload.periodFilter.period[0]), new Date(action.payload.periodFilter.period[1])];
        state.periodFilter.weekDays = action.payload.periodFilter.weekDays;
        state.periodFilter.minutesStart = action.payload.periodFilter.minutesStart;
        state.periodFilter.minutesEnd = action.payload.periodFilter.minutesEnd;
        state.layer = action.payload.layer;
        state.selectedStudyAreas = action.payload.selectedStudyAreas ?? [];

        state.durationEnabled = action.payload.durationEnabled;

        state.enforcementEnabled = action.payload.enforcementEnabled;
        state.enforcementGroupId = action.payload.enforcementGroupId;
        state.enforcementMeasure = action.payload.enforcementMeasure;

        state.revenueEnabled = action.payload.revenueEnabled;
        state.revenueAmount = action.payload.revenueAmount;

        state.trafficEnabled = action.payload.trafficEnabled;
        state.trafficLayerType = action.payload.trafficLayerType;

        state.occupancyEnabled = action.payload.occupancyEnabled;
        state.reportOccupancySource = action.payload.reportOccupancySource;
        state.heatmapOccupancySource = action.payload.heatmapOccupancySource;
      }
    },
  },
});

// Actions
export const heatmapsFilterActions = slice.actions;

// Selectors
export const selectHeatmapFilters = (state: RootState) => state.heatmapsFilter;
export const selectHeatmapFiltersPeriod = (state: RootState) => state.heatmapsFilter.periodFilter.heatmapPeriod;

// Reducer
export const heatmapFiltersReducer = slice.reducer;
