import React, { FC } from 'react';
import { DropDownListChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';

import { DropDownList } from '../../common';
import styles from './ColorDropDownList.module.scss';

interface ColorName {
  id: string;
  name: string;
  color: string;
}

interface Props {
  colors: ColorName[];
  initialId: string | null;
  colorSelected: (id: string) => void;
  className?: string;
}

const ColorItem: FC<{ name: string; color: string }> = ({ name, color }) => {
  return (
    <div className={styles.row}>
      <div className={styles.circle} style={{ backgroundColor: color }}></div>
      <span>{name}</span>
    </div>
  );
};

const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
  const colorItem = <ColorItem key={itemProps.index} color={itemProps.dataItem.color} name={itemProps.dataItem.name} />;
  return React.cloneElement(li, li.props, colorItem);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const valueRender = (element: React.ReactElement<HTMLSpanElement>, value: any) => {
  if (!value) {
    return element;
  }
  const colorItem = <ColorItem color={value.color} name={value.name} />;
  return React.cloneElement(element, { ...element.props }, colorItem);
};

export const ColorDropDownList: FC<Props> = ({ colors, initialId, colorSelected, className }) => {
  return (
    <DropDownList
      className={className}
      data={colors}
      itemRender={itemRender}
      valueRender={valueRender}
      defaultValue={initialId ? colors.find((x) => x.id === initialId) : null}
      onChange={(ev: DropDownListChangeEvent) => colorSelected(ev.target.value.id)}
    />
  );
};
