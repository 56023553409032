import { DateRangePicker, DateRangePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import classNames from 'classnames/bind';
import moment from 'moment';
import { FC, useCallback, useMemo, useState } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { HeatmapPeriod } from '../../../../model/api/heatmap-period';
import styles from './HeatmapPeriodSelector.module.scss';

const cx = classNames.bind(styles);

const MinDate = moment().subtract(1, 'year').startOf('year').toDate();
const MaxDate = new Date();

export const HeatmapPeriodSelector: FC<{
  heatmapPeriod: HeatmapPeriod;
  period: [Date, Date];
  onChange: (change: { heatmapPeriod?: HeatmapPeriod; period?: [Date, Date] }) => void;
}> = (params) => {
  const localization = useExtendedLocalization();
  const [heatmapPeriod, setHeatmapPeriod] = useState<HeatmapPeriod>(params.heatmapPeriod);
  const [period, setPeriod] = useState<[Date, Date]>(params.period);

  const periods = useMemo(
    () => [
      {
        heatmapPeriod: HeatmapPeriod.Today,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.Today.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.LastDay,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.LastDay.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.LastWeek,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.LastWeek.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.LastWeekToDate,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.LastWeekToDate.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.ThisWeek,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.ThisWeek.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.LastMonth,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.LastMonth.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.LastMonthToDate,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.LastMonthToDate.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.ThisMonth,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.ThisMonth.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.LastQuarter,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.LastQuarter.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.LastQuarterToDate,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.LastQuarterToDate.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.ThisQuarter,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.ThisQuarter.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.LastYear,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.LastYear.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.LastYearToDate,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.LastYearToDate.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.ThisYear,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.ThisYear.name}`),
      },
      {
        heatmapPeriod: HeatmapPeriod.Custom,
        name: localization.toLanguageStringF(`common.heatmapPeriod.${HeatmapPeriod.Custom.name}`),
      },
    ],
    [localization],
  );

  const onPeriodSelected = useCallback(
    (evt: DropDownListChangeEvent) => {
      if (!evt.target.value) {
        return;
      }
      setHeatmapPeriod(evt.target.value.heatmapPeriod);
      params.onChange({ heatmapPeriod: evt.target.value.heatmapPeriod });
    },
    [params],
  );

  const onCustomPeriodSelected = useCallback(
    (event: DateRangePickerChangeEvent) => {
      if (event.target.value) {
        let from = event.target.value.start ? event.target.value.start : period[0];
        let to = event.target.value.end ? event.target.value.end : period[1];

        if (from > to) {
          [from, to] = [to, from];
        }

        setPeriod([from, to]);
        if (from >= MinDate && to <= MaxDate) {
          params.onChange({ period: [from, to] });
        }
      }
    },
    [params, period],
  );

  const content = useMemo(
    () => (
      <div className={cx('heatmap-period-selector')}>
        <div className={cx('first-row')}>
          <label className={cx('label')} htmlFor='period'>
            {localization.toLanguageStringF('common.period.title')}
          </label>
          <DropDownList
            id='period'
            dataItemKey='heatmapPeriod'
            textField='name'
            data={periods}
            value={periods.find((x) => x.heatmapPeriod === heatmapPeriod)}
            onChange={onPeriodSelected}
          />
        </div>

        {heatmapPeriod === HeatmapPeriod.Custom && (
          <div className={cx('second-row')}>
            <DateRangePicker
              id='dateRange'
              min={MinDate}
              max={MaxDate}
              startDateInputSettings={{ label: localization.toLanguageStringF('common.period.from') }}
              endDateInputSettings={{ label: localization.toLanguageStringF('common.period.to') }}
              value={{ start: period[0], end: period[1] }}
              onChange={onCustomPeriodSelected}
            />
          </div>
        )}
      </div>
    ),
    [heatmapPeriod, localization, onCustomPeriodSelected, onPeriodSelected, period, periods],
  );

  return content;
};
