import React, { FunctionComponent, useEffect, useMemo, useContext } from 'react';
import { createPortal } from 'react-dom';

import { IControl } from 'mapbox-gl';
import { MapContext } from 'react-map-gl/dist/esm/components/map';
import { CustomCompositeControl } from './CustomCompositeControl';

type MapControlProps = {
  className?: string;
  controls?: IControl[];
  position?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
  children?: React.ReactNode;
};

const _MapControl: FunctionComponent<MapControlProps> = ({ className, controls, position, children }) => {
  const { map } = useContext(MapContext);
  const containerPosition = position || 'top-right';

  const container = useMemo(() => {
    const res = document.createElement('div');
    res.className = 'mapboxgl-ctrl ' + (className || '');
    return res;
  }, [className]);

  useEffect(() => {
    const control = new CustomCompositeControl(container, controls || [], containerPosition);
    map.addControl(control, containerPosition);

    return () => {
      map.removeControl(control);
    };
  }, [container, controls, map, containerPosition]);

  return createPortal(children, container);
};

export const MapControl = React.memo(_MapControl);
