import { FunctionComponent } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { MeterType } from '../../../../model';

interface Props {
  className?: string;
  typeId: number;
}

export const MeterTypeText: FunctionComponent<Props> = ({ className, typeId }) => {
  const localization = useExtendedLocalization();
  

  return <span className={`${className || ''}`}>{localization.toLanguageStringF(`meter.type.${MeterType[typeId]}`)}</span>;
};
