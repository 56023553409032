import * as turf from '@turf/boolean-point-in-polygon';
import { Feature, GeoJsonProperties, Geometry, Polygon } from 'geojson';
import { AnyLayout } from 'mapbox-gl';
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Layer, MapLayerMouseEvent, MapRef, Source } from 'react-map-gl';
import { MapContext } from 'react-map-gl/dist/esm/components/map';

import { layerIdsActions, selectOffstreetZonesGeo, selectOffstreetZonesLayer, selectedOffstreetZonesActions } from '../../../../features';
import { useMapLayerHover, useMapLayerPopup, useMapToolTip } from '../../../../hooks';
import { LayerName, OffstreetZoneLayerType, TagFilter } from '../../../../model';
import { NavigationSource, PopupType, amplitudeService } from '../../../../services';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { MapImage } from '../../controls';
import { OffstreetZonePopup } from './OffstreetZonePopup';

const zonesPaint = {
  'text-color': 'black',
};

const vendorsPaint = {
  'text-color': '#fff',
  'text-halo-color': '#106EBE',
  'text-halo-width': 20,
};

export const OffstreetZonesLayer: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { map } = useContext(MapContext);
  const geojson = useAppSelector(selectOffstreetZonesGeo);
  const layer = useAppSelector(selectOffstreetZonesLayer);
  const [styleLoaded, setStyleLoaded] = useState(false);
  const layerEnabled = layer.enabled && layer.layerTypeFilter.isTagEnabled(OffstreetZoneLayerType.Garages);

  const offstreetZonesLayerVendors = 'offstreet-zones-layer-vendors';
  const offstreetZonesLayer = 'offstreet-zones-layer';

  const filter = useMemo(
    () => (layer.showOnlyCityOwnedFacilities ? ['==', ['get', 'ownership'], 'City of San Jose'] : ['boolean', true]),
    [layer.showOnlyCityOwnedFacilities],
  );

  useMapToolTip(
    (event: MapLayerMouseEvent) => {
      if (event.features && event.features.length > 0) {
        const name = event.features[0].properties?.name;

        return name;
      }

      return null;
    },
    () => layerEnabled,
    offstreetZonesLayer,
  );

  const layout: AnyLayout = useMemo(() => {
    return {
      visibility: layerEnabled ? 'visible' : 'none',
      'icon-image': 'point-garage',
      'icon-allow-overlap': true,
      'icon-size': {
        base: 0.4,
        stops: [
          [16, 0.6],
          [18, 0.8],
          [20, 1.4],
          [21, 1.6],
        ],
      },
      'text-font': ['Open Sans Regular'],
      'text-offset': [0, 1.25],
      'text-anchor': 'top',
    };
  }, [layerEnabled]);

  const vendorsLayout: AnyLayout = useMemo(() => {
    return {
      visibility: layerEnabled && layer.showVendorLogo ? 'visible' : 'none',
      'icon-offset': [15, -15],
      'icon-image': ['match', ['get', 'vendorId'], 1, 'vendor-flowbird', 2, 'vendor-parkonect', 'no-image'],
      'icon-allow-overlap': true,
      'icon-size': {
        base: 0.4,
        stops: [
          [16, 0.6],
          [18, 0.8],
          [20, 1.2],
          [21, 1.6],
        ],
      },
    };
  }, [layer.showVendorLogo, layerEnabled]);

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      dispatch(selectedOffstreetZonesActions.closePopup());
      const feature = evt.features ? evt.features[0] : null;
      if (!feature || !feature.properties) return;

      const zoneId = feature.properties.id;
      const position = [evt.lngLat.lng, evt.lngLat.lat];
      setTimeout(() => {
        dispatch(
          selectedOffstreetZonesActions.loadZone({
            id: zoneId,
            position: position,
          }),
        );
        amplitudeService.trackPopupOpen(PopupType.OffstreetParking, NavigationSource.Map);
      }, 10);
    },
    [dispatch],
  );

  useEffect(() => {
    if (!styleLoaded) return;

    if (layerEnabled) enableHiglighting(map);
    else disableHiglighting(map);
  }, [map, styleLoaded, layerEnabled]);

  useEffect(() => {
    if (!styleLoaded) return;

    highlightBuildings(map, geojson.features, layer.vendorsFilter);
  }, [map, styleLoaded, geojson, layer.vendorsFilter]);

  useMapLayerPopup(handleLayerClick, 'offstreet-zones-layer', 'offstreet-zones-layer-vendors');

  useMapLayerHover([offstreetZonesLayer, offstreetZonesLayerVendors]);

  // for Legend
  useEffect(() => {
    dispatch(layerIdsActions.registerLayers({ layerName: LayerName.OffstreetZones, layerIds: [offstreetZonesLayer] }));

    return () => {
      dispatch(layerIdsActions.unregisterLayers({ layerName: LayerName.OffstreetZones }));
    };
  }, [dispatch]);

  return (
    <>
      <Source id='offstreet-zones-source' type='geojson' data={geojson} generateId={true}>
        <Layer
          {...{
            id: offstreetZonesLayer,
            type: 'symbol',
            layout: layout,
            paint: zonesPaint,
            before: 'building-extrusion',
            filter: filter,
          }}
        />
        <Layer
          {...{
            id: offstreetZonesLayerVendors,
            type: 'symbol',
            layout: vendorsLayout,
            paint: vendorsPaint,
            before: 'building-extrusion',
            filter: filter,
          }}
        />
      </Source>

      <OffstreetZonePopup />

      <MapImage name='point-garage' url='/icons/garage.png' />
      <MapImage name='vendor-parkonect' url='/icons/vendors/parkonect.png' />
      <MapImage name='vendor-flowbird' url='/icons/vendors/flowbird.png' />
    </>
  );
};

function highlightBuildings(mapRef: MapRef, features: Feature<Geometry, GeoJsonProperties>[], vendorsFilter: TagFilter) {
  const map = mapRef.getMap();
  const visibleBuildings = map.queryRenderedFeatures(undefined, {
    layers: ['building-extrusion', 'landuse'],
  });

  visibleBuildings.forEach((x) => {
    if (x.state?.garage) {
      map.setFeatureState(
        {
          source: x.source,
          sourceLayer: x.sourceLayer,
          id: x.id,
        },
        {
          garage: false,
        },
      );
    }
  });

  features.forEach((x) => {
    if (x.geometry.type === 'Point') {
      const pt = [x.geometry.coordinates[0], x.geometry.coordinates[1]];

      visibleBuildings.forEach((f) => {
        const poly = f.geometry as Polygon;
        const isGarage = !vendorsFilter.enabled || vendorsFilter.isTagEnabled(x.properties?.vendorId);

        if (turf.default(pt, poly)) {
          map.setFeatureState(
            {
              source: f.source,
              sourceLayer: f.sourceLayer,
              id: f.id || x.properties?.id,
            },
            {
              garage: isGarage,
              garageId: x.properties?.id,
            },
          );
        }
      });
    }
  });
}

function enableHiglighting(mapRef: MapRef) {
  const map = mapRef.getMap();
  map.setPaintProperty('building-extrusion', 'fill-extrusion-color', [
    'case',
    ['boolean', ['feature-state', 'garage'], false],
    'red',
    'hsl(210, 87%, 18%)',
  ]);

  map.setPaintProperty('landuse', 'fill-color', [
    'case',
    ['boolean', ['feature-state', 'garage'], false],
    'rgba(255, 0, 0, 0.3)',
    'rgba(179, 211, 245, 1)',
  ]);
}

function disableHiglighting(mapRef: MapRef) {
  const map = mapRef.getMap();
  map.setPaintProperty('building-extrusion', 'fill-extrusion-color', 'hsl(210, 87%, 18%)');
  map.setPaintProperty('landuse', 'fill-color', 'rgba(179, 211, 245, 1)');
}
