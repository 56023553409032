import { FunctionComponent } from 'react';
import { Layer, Source } from 'react-map-gl';

import { AnyLayout } from 'mapbox-gl';
import { MIN_ZOOM } from '../../../../constants';
import { selectSuggestedStreetsGeo, selectSuggestedStreetsLayer } from '../../../../features';
import { useMapLayerHover, useMapToolTip } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { useAppSelector } from '../../../../store/hooks';
import { SuggestedStreetType } from '../../../../model';

const basePaint = {
  'line-width': {
    base: 3,
    stops: [
      [14, 7],
      [20, 9],
    ],
  },
};

const enforcementsPaint = {
  ...basePaint,
  'line-color': '#0038FF',
};

const planningsPaint = {
  ...basePaint,
  'line-color': '#FF0017',
};

const managementsPaint = {
  ...basePaint,
  'line-color': '#05FF00',
};

const studyAreasPaint = {
  ...basePaint,
  'line-color': '#670D92',
  'line-opacity': 0.8,
};

const enforcementLayer = 'suggested-street-enforcement';
const planningLayer = 'suggested-street-planning';
const managementLayer = 'suggested-street-parking';
const studyAreaLayer = 'suggested-street-study-area';

export const SuggestedStreetsLayer: FunctionComponent = () => {
  const suggestedStreetGeo = useAppSelector(selectSuggestedStreetsGeo);
  const suggestedStreetLayer = useAppSelector(selectSuggestedStreetsLayer);
  const localization = useExtendedLocalization();

  const streetlayout: AnyLayout = {
    visibility: suggestedStreetLayer.showStreets && suggestedStreetLayer.enabled ? 'visible' : 'none',
  };

  const studyArealayout: AnyLayout = {
    visibility: suggestedStreetLayer.enabled ? 'visible' : 'none',
  };

  useMapToolTip(
    () => localization.toLanguageStringF(`suggestedStreet.types.${SuggestedStreetType.Enforcement}`),
    () => suggestedStreetLayer.showStreets && suggestedStreetLayer.enabled,
    enforcementLayer,
  );
  useMapToolTip(
    () => localization.toLanguageStringF(`suggestedStreet.types.${SuggestedStreetType.Planning}`),
    () => suggestedStreetLayer.showStreets && suggestedStreetLayer.enabled,
    planningLayer,
  );
  useMapToolTip(
    () => localization.toLanguageStringF(`suggestedStreet.types.${SuggestedStreetType.Management}`),
    () => suggestedStreetLayer.showStreets && suggestedStreetLayer.enabled,
    managementLayer,
  );

  useMapLayerHover([enforcementLayer, planningLayer, managementLayer]);

  return (
    <>
      <Source id='suggested-street-study-area-source' type='geojson' data={suggestedStreetGeo.studyAreas} generateId={true}>
        <Layer
          {...{
            id: studyAreaLayer,
            type: 'line',
            layout: studyArealayout,
            paint: studyAreasPaint,
            before: 'building-extrusion',
            minzoom: MIN_ZOOM - 1,
          }}
        />
      </Source>
      <Source id='suggested-street-enforcement-source' type='geojson' data={suggestedStreetGeo.enforcements} generateId={true}>
        <Layer
          {...{
            id: enforcementLayer,
            type: 'line',
            layout: streetlayout,
            paint: enforcementsPaint,
            before: 'building-extrusion',
            minzoom: MIN_ZOOM - 1,
          }}
        />
      </Source>
      <Source id='suggested-street-planning-source' type='geojson' data={suggestedStreetGeo.plannings} generateId={true}>
        <Layer
          {...{
            id: planningLayer,
            type: 'line',
            layout: streetlayout,
            paint: planningsPaint,
            before: 'building-extrusion',
            minzoom: MIN_ZOOM - 1,
          }}
        />
      </Source>
      <Source id='suggested-street-planning-management' type='geojson' data={suggestedStreetGeo.managements} generateId={true}>
        <Layer
          {...{
            id: managementLayer,
            type: 'line',
            layout: streetlayout,
            paint: managementsPaint,
            before: 'building-extrusion',
            minzoom: MIN_ZOOM - 1,
          }}
        />
      </Source>
    </>
  );
};
