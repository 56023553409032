import { FunctionComponent } from 'react';
import { useExtendedLocalization } from '../../../../../hooks/use-extended-localization-service';
import { IViolationInfo } from '../../../../../model';
import { HeatmapPeriod } from '../../../../../model/api/heatmap-period';
import { numberUtils } from '../../../../../utils';
import { PeriodTextInfo } from '../period-text-info/PeriodTextInfo';

interface Props {
  period: HeatmapPeriod;
  violation: IViolationInfo;
  className?: string;
}

export const PeriodViolationsNumberTextInfo: FunctionComponent<Props> = ({ period, violation, className }) => {
  const localization = useExtendedLocalization();
  const data = violation.Amounts;

  const current = data.reduce((sum, a) => sum + a.CurrentCount, 0);
  const previous = data.reduce((sum, a) => sum + a.PreviousCount, 0);

  return (
    <PeriodTextInfo
      className={className}
      label={localization.toLanguageString('period-info.violations', 'Violations Count')}
      value={`${numberUtils.toUiString(current, 0)}`}
      prevValue={`${numberUtils.toUiString(previous, 0)}`}
      change={numberUtils.diffPercentage(current, previous)}
      small={true}
    />
  );
};
