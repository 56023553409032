import React, { FunctionComponent, useEffect, useState } from 'react';
import { selectSidebarState } from '../../../../features';
import { useAppSelector } from '../../../../store/hooks';
import { SidebarName } from '../../../../model';

import styles from './MapSidebarContainer.module.scss';
import { Spinner } from '../../../common';

const ANIMATION_TIMEOUT = 1000;

type Props = {
  name: SidebarName;
  children?: React.ReactNode;
};

export const MapSidebarContainer: FunctionComponent<Props> = ({ name, children }) => {
  const [render, setRender] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [prevSidebar, setPrevSidebar] = useState<SidebarName | null>(null);
  const sidebar = useAppSelector(selectSidebarState);

  useEffect(() => {
    if (sidebar === name) {
      if (!prevSidebar) {
        setRender(true);
        setCollapse(false);
      } else {
        setTimeout(() => {
          setRender(true);
          setCollapse(false);
        }, ANIMATION_TIMEOUT);
      }
    } else {
      if (!prevSidebar) {
        setRender(false);
        setTimeout(() => {
          setCollapse(true);
        }, ANIMATION_TIMEOUT);
      } else {
        setCollapse(true);
        setTimeout(() => {
          setRender(false);
        }, ANIMATION_TIMEOUT);
      }
    }

    setPrevSidebar(sidebar);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebar]);

  return <div className={`${styles.mapSidebar} ${collapse && styles.collapsed}`}>{render ? children || <Spinner /> : null}</div>;
};
