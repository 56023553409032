/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from '@progress/kendo-react-buttons';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Checkbox, Switch, SwitchChangeEvent, RadioButton, RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import classNames from 'classnames/bind';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  CameraStatus,
  HeatmapLayerType,
  ILayersSettings,
  IMeterVendor,
  IOffstreetVendor,
  MeterStatus,
  MeterType,
  MeterVendor,
  OccupancySource,
  OccupancyStatus,
  OffstreetZoneLayerType,
  RevenueAmountType,
  SensorFilter,
  SidebarName,
  SignStatus,
  StreetObjectType,
  SuggestedStreetType,
  TrafficLayerType,
  ViolationMeasure,
  WeekDay,
} from '../../../../model';
import { MapSidebarContainer } from '../../controls';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import styles from './ToggleLayersSidebar.module.scss';

import {
  areaLayerActions,
  blockfacesLayerActions,
  camerasLayerActions,
  curbSpacesLayerActions,
  heatmapsFilterActions,
  loadingZonesLayerActions,
  metersLayerActions,
  offstreetZonesLayerActions,
  selectAreaLevel,
  selectAreaLevels,
  selectBlockfacesLayer,
  selectCamerasLayer,
  selectCities,
  selectCurbSpacePolicyTypeIds,
  selectCurbSpacesLayer,
  selectLoadingZonesLayer,
  selectMapState,
  selectMetersLayer,
  selectMetersShowSpotsCount,
  selectOffstreetZonesLayer,
  selectSignsLayer,
  selectStreetObjectsLayer,
  selectStudyAreasLayer,
  selectSuggestedStreetsLayer,
  selectZonesLayer,
  selectZoneObjectsLayer,
  zoneObjectsLayerActions,
  selectedAreaActions,
  sidebarActions,
  signsLayerActions,
  streetObjectsLayerActions,
  studyAreasLayerActions,
  suggestedStreetsLayerActions,
  zonesLayerActions,
  rppZonesLayerActions,
  selectRppZonesLayer,
  selectRppAreasLayer,
  rppAreasLayerActions,
  selectViolationData,
} from '../../../../features';
import { selectHeatmapFilters } from '../../../../features/map/layers/heatmaps/heatmaps-slice';
import { useLoadingZonesEnabled } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { HeatmapPeriod } from '../../../../model/api/heatmap-period';
import { CityCode, FilterName, FilterProperty, LayerName, amplitudeService } from '../../../../services';
import { Collapsible, DayTimeRangeSelector, DropDownList } from '../../../common';
import { MultiSelectCheckBox } from '../../../common/multiselect-checkbox/MultiSelectCheckBox';
import { HeatmapPeriodSelector } from '../../common';
import {
  CameraStatusIcon,
  CurbSpaceLegendIcon,
  CurbSpaceStatusIcon,
  MeterVendorIcon,
  OffstreetZoneLayerTypeIcon,
  OffstreetZoneVendorIcon,
  SignStatusIcon,
  StreetObjectIcon,
  SuggestedStreetIcon,
} from '../../icons';
import { MeterTypeIcon } from '../../icons/meter-type-icon/MeterTypeIcon';
import { SavedViews } from '../../saved-views/SavedViews';
import { CustomLayersToggleSidebar } from './CustomLayersToggleSidebar';
import { OccupancySourceSelector } from '../../common/occupancy-source-selector/OccupancySourceSelector';

const cx = classNames.bind(styles);

const CloseIcon = (params: { active: boolean }) => <span className={cx('k-icon', { 'k-i-close': params?.active }, 'closeIcon')}></span>;

const Sidebar: FunctionComponent = () => {
  const dispatch = useAppDispatch();

  const areaLevels = useAppSelector(selectAreaLevels);
  const areaLevel = useAppSelector(selectAreaLevel);

  const zonesLayer = useAppSelector(selectZonesLayer);
  const zoneObjectsLayer = useAppSelector(selectZoneObjectsLayer);
  const rppZonesLayer = useAppSelector(selectRppZonesLayer);
  const rppAreasLayer = useAppSelector(selectRppAreasLayer);

  const loadingZonesLayer = useAppSelector(selectLoadingZonesLayer);
  const metersLayer = useAppSelector(selectMetersLayer);
  const signsLayer = useAppSelector(selectSignsLayer);
  const curbSpacesLayer = useAppSelector(selectCurbSpacesLayer);
  const offstreetZonesLayer = useAppSelector(selectOffstreetZonesLayer);
  const camerasLayer = useAppSelector(selectCamerasLayer);
  const streetObjectsLayer = useAppSelector(selectStreetObjectsLayer);
  const policies = useAppSelector(selectCurbSpacePolicyTypeIds);
  const statuses = [OccupancyStatus.Vacant, OccupancyStatus.Occupied, OccupancyStatus.Booked, OccupancyStatus.Unknown];
  const showSpotsCount = useAppSelector(selectMetersShowSpotsCount);
  const localization = useExtendedLocalization();
  const [enforcementTypes, setEnforcementTypes] = useState<Array<{ id: number; name: string }>>([]);
  const heatmapsFilter = useAppSelector(selectHeatmapFilters);
  const showLoadingZones = useLoadingZonesEnabled();
  const [savedViewLayersForSave, setSavedViewLayersForSave] = useState<ILayersSettings>();
  const [savedViewCancelSave, setSavedViewCancelSave] = useState<boolean>();
  const [savedViewEditEnabled, setSavedViewEditEnabled] = useState<boolean>(false);
  const blockfacesLayer = useAppSelector(selectBlockfacesLayer);
  const studyAreasLayer = useAppSelector(selectStudyAreasLayer);
  const cities = useAppSelector(selectCities);
  const layerSettings = useAppSelector(selectMapState);
  const suggestedStreetsLayer = useAppSelector(selectSuggestedStreetsLayer);
  const violationData = useAppSelector(selectViolationData);

  const sensorFilterOptions = useMemo(
    () =>
      Object.values(SensorFilter).map((x) => ({
        value: x,
        label: localization.toLanguageStringF(`curbSpace.sensorFilter.${x}`),
        count:
          x === SensorFilter.WithSensors
            ? curbSpacesLayer.sensorsCount
            : x === SensorFilter.WithoutSensors
            ? curbSpacesLayer.count - curbSpacesLayer.sensorsCount
            : curbSpacesLayer.count,
      })),
    [curbSpacesLayer.count, curbSpacesLayer.sensorsCount, localization],
  );

  const onChangeHeatmapPeriodSelector = useCallback(
    (change: { heatmapPeriod?: HeatmapPeriod; period?: [Date, Date] }) => {
      if (change.heatmapPeriod) {
        const periodFilter = { ...heatmapsFilter.periodFilter, heatmapPeriod: change.heatmapPeriod };

        dispatch(heatmapsFilterActions.setHeatmapPeriodFilter(periodFilter));
        amplitudeService.trackFilter(FilterName.HeatmapPeriod, change.heatmapPeriod.name);
      }

      if (change.period) {
        const periodFilter = { ...heatmapsFilter.periodFilter, period: [change.period[0], change.period[1]] as [Date, Date] };
        dispatch(heatmapsFilterActions.setHeatmapPeriodFilter(periodFilter));
      }
    },
    [dispatch, heatmapsFilter.periodFilter],
  );

  const trafficLayers = useMemo(() => {
    return [
      { id: TrafficLayerType.Traffic, name: localization.toLanguageStringF(`traffic.type.${TrafficLayerType.Traffic}`) },
      { id: TrafficLayerType.Speed, name: localization.toLanguageStringF(`traffic.type.${TrafficLayerType.Speed}`) },
    ];
  }, [localization]);

  const heatmapLayers = useMemo(() => {
    const layers = [];
    if ((studyAreasLayer.studyAreas?.length || 0) > 0) {
      layers.push({
        id: HeatmapLayerType.StudyArea,
        name: localization.toLanguageStringF(`revenue.layerType.${HeatmapLayerType.StudyArea}`),
      });
    }

    if (zonesLayer.count > 0) {
      layers.push({
        id: HeatmapLayerType.Zone,
        name: localization.toLanguageStringF(`revenue.layerType.${HeatmapLayerType.Zone}`),
      });
    }
    if (blockfacesLayer.count > 0) {
      layers.push({
        id: HeatmapLayerType.Blockface,
        name: localization.toLanguageStringF(`revenue.layerType.${HeatmapLayerType.Blockface}`),
      });
    }
    if ((metersLayer.count || 0) > 0) {
      layers.push({
        id: HeatmapLayerType.Meter,
        name: localization.toLanguageStringF(`revenue.layerType.${HeatmapLayerType.Meter}`),
      });
    }
    if ((curbSpacesLayer.count || 0) > 0 && violationData.hasSpotViolation) {
      layers.push({
        id: HeatmapLayerType.CurbSpace,
        name: localization.toLanguageStringF(`revenue.layerType.${HeatmapLayerType.CurbSpace}`),
      });
    }
    if ((offstreetZonesLayer.garagesCount || 0) > 0) {
      layers.push({
        id: HeatmapLayerType.OffstreetZone,
        name: localization.toLanguageStringF(`revenue.layerType.${HeatmapLayerType.OffstreetZone}`),
      });
    }

    return layers;
  }, [
    blockfacesLayer.count,
    localization,
    metersLayer.count,
    offstreetZonesLayer.garagesCount,
    studyAreasLayer.studyAreas?.length,
    zonesLayer.count,
    curbSpacesLayer.count,
    violationData.hasSpotViolation,
  ]);

  const weekdays = useMemo(
    () => [
      { id: WeekDay.Monday, text: localization.toLanguageStringF('common.weekDays.Mon'), order: 0 },
      { id: WeekDay.Tuesday, text: localization.toLanguageStringF('common.weekDays.Tue'), order: 1 },
      { id: WeekDay.Wednesday, text: localization.toLanguageStringF('common.weekDays.Wed'), order: 2 },
      { id: WeekDay.Thursday, text: localization.toLanguageStringF('common.weekDays.Thu'), order: 3 },
      { id: WeekDay.Friday, text: localization.toLanguageStringF('common.weekDays.Fri'), order: 4 },
      { id: WeekDay.Saturday, text: localization.toLanguageStringF('common.weekDays.Sat'), order: 5 },
      { id: WeekDay.Sunday, text: localization.toLanguageStringF('common.weekDays.Sun'), order: 6 },
    ],
    [localization],
  );

  const studyAreas = useMemo(
    () => studyAreasLayer.studyAreas.map((x, idx) => ({ id: x.Id.toString(), text: x.Name, order: idx })),
    [studyAreasLayer.studyAreas],
  );

  const revenueAmounts = [
    {
      id: RevenueAmountType.Actual,
      name: localization.toLanguageStringF(`revenue.amountType.${RevenueAmountType.Actual}`),
    },
    {
      id: RevenueAmountType.Average,
      name: localization.toLanguageStringF(`revenue.amountType.${RevenueAmountType.Average}`),
    },
  ];

  const enforcementMeasures = [
    { id: ViolationMeasure.Amount, name: localization.toLanguageStringF('enforcement.measure.amount') },
    { id: ViolationMeasure.Count, name: localization.toLanguageStringF('enforcement.measure.count') },
  ];

  useEffect(() => {
    const groups = (heatmapsFilter.enforcementGroups || []).map((x) => ({
      id: x.Id,
      name: localization.toLanguageString(`common.violationGroup.${x.Name}`, x.Name),
    }));
    setEnforcementTypes(groups);
  }, [heatmapsFilter.enforcementGroups, localization]);

  const handleCloseClick = (ev: React.FormEvent) => {
    ev.preventDefault();
    dispatch(sidebarActions.clearSelected());
  };

  const onAreaLevelSelected = (evt: DropDownListChangeEvent) => {
    dispatch(areaLayerActions.setEnabled({ level: evt.target.value }));
    dispatch(selectedAreaActions.closePopup());
  };

  const clickDisplayZoneId = (value: boolean) => {
    dispatch(zonesLayerActions.setDisplayZoneId(value));
    if (value) {
      amplitudeService.trackFilter(FilterName.DisplayZoneId, value, FilterProperty.FilterEnabled);
    }
  };

  const clickZonePerformanceParking = (visible: boolean) => {
    dispatch(zonesLayerActions.setPerformanceParkingFilterValue(visible));
    if (visible) {
      amplitudeService.trackFilter(FilterName.ZonesPerformanceParking, visible, FilterProperty.FilterEnabled);
    }
  };

  const clickDisplayRppAreas = (value: boolean) => {
    dispatch(rppAreasLayerActions.setDisplayRppAreas(value));
  };
  const clickDisplayRppZones = (value: boolean) => {
    dispatch(rppZonesLayerActions.setDisplayRppZones(value));
  };
  const clickDisplayCurbfaces = (value: boolean) => {
    dispatch(zoneObjectsLayerActions.setDisplayCurbfaces(value));
  };
  const toggleCurbfaceZoneObjectsType = (type: string) => {
    dispatch(zoneObjectsLayerActions.setCurbfaceTypesFilter(zoneObjectsLayer.curbfaceTypesFilter.toggleTag(type)));
  };

  const clickDisplayBlockfaceId = (value: boolean) => {
    dispatch(blockfacesLayerActions.setDisplayBlockfaceId(value));
    if (value) {
      amplitudeService.trackFilter(FilterName.DisplayBlockfaceId, value, FilterProperty.FilterEnabled);
    }
  };

  const clickBlockfacePerformanceParking = (visible: boolean) => {
    dispatch(blockfacesLayerActions.setPerformanceParkingFilterValue(visible));
    if (visible) {
      amplitudeService.trackFilter(FilterName.BlockfacesPerformanceParking, visible, FilterProperty.FilterEnabled);
    }
  };

  const clickTimeLimitEnable = (value: boolean) => {
    dispatch(zonesLayerActions.setTimeLimitEnabled(value));
    if (value) {
      amplitudeService.trackFilter(FilterName.DisplayParkingTimeLimit, value, FilterProperty.FilterEnabled);
    }
  };

  const toggleMeterType = (meterType: MeterType) => {
    dispatch(metersLayerActions.setTypesFilter(metersLayer.typesFilter.toggleTag(meterType)));
    if (metersLayer.typesFilter.tags[meterType]) {
      amplitudeService.trackFilter(FilterName.MetersType, MeterType[meterType]);
    }
  };

  const toggleMeterStatus = (meterStatus: MeterStatus) => {
    dispatch(metersLayerActions.setStatusesFilter(metersLayer.statusesFilter.toggleTag(meterStatus)));
    if (metersLayer.statusesFilter.tags[meterStatus]) {
      amplitudeService.trackFilter(FilterName.MetersStatus, meterStatus);
    }
  };

  const toggleMeterVendor = (meterVendor: IMeterVendor) => {
    dispatch(metersLayerActions.setVendorsFilter(metersLayer.vendorsFilter.toggleTag(meterVendor.Id)));
    if (metersLayer.vendorsFilter.tags[meterVendor.Id]) {
      amplitudeService.trackFilter(FilterName.MetersVendor, MeterVendor.getName(meterVendor));
    }
  };

  const clickShowVendorLogo = (visible: boolean) => {
    dispatch(metersLayerActions.setVendorLogoVisible(visible));
    if (visible) {
      amplitudeService.trackFilter(FilterName.MetersShowVendorLogo, visible, FilterProperty.FilterEnabled);
    }
  };

  const clickShowSpotsCount = (visible: boolean) => {
    dispatch(metersLayerActions.setSpotsCountVisible(visible));
    if (visible) {
      amplitudeService.trackFilter(FilterName.ShowParkingSpacesNumber, visible, FilterProperty.FilterEnabled);
    }
  };

  const clickMeterPerformanceParking = (visible: boolean) => {
    dispatch(metersLayerActions.setPerformanceParkingFilterValue(visible));
    if (visible) {
      amplitudeService.trackFilter(FilterName.MetersPerformanceParking, visible, FilterProperty.FilterEnabled);
    }
  };

  const toggleSignsStatus = (signStatus: SignStatus) => {
    dispatch(signsLayerActions.setStatusesFilter(signsLayer.statusesFilter.toggleTag(signStatus)));
  };

  const toggleSpotPolicyType = (policyType: number) => {
    dispatch(curbSpacesLayerActions.setPolicyTypesFilter(curbSpacesLayer.policyTypesFilter.toggleTag(policyType)));
  };

  const toggleSpotStatus = (status: OccupancyStatus) => {
    dispatch(curbSpacesLayerActions.setStatusesFilter(curbSpacesLayer.statusesFilter.toggleTag(status)));
    if (curbSpacesLayer.statusesFilter.tags[status]) {
      amplitudeService.trackFilter(FilterName.CurbSpacesStatus, status);
    }
  };

  const changeCurbSpaceSensorFilter = (ev: RadioButtonChangeEvent) => {
    dispatch(curbSpacesLayerActions.setSensorsFilter(ev.value));
    amplitudeService.trackFilter(FilterName.ParkingSpacesGroundSensor, ev.value);
  };

  const toggleOffstreetZoneVendorType = (vendor: IOffstreetVendor) => {
    dispatch(offstreetZonesLayerActions.setVendorsFilter(offstreetZonesLayer.vendorsFilter.toggleTag(vendor.Id)));
    if (offstreetZonesLayer.vendorsFilter.tags[vendor.Id]) {
      amplitudeService.trackFilter(FilterName.OffStreetParkingVendor, vendor.Name);
    }
  };

  const toggleOffstreetZoneVendors = (visible: boolean) => {
    dispatch(offstreetZonesLayerActions.setVendorLogoVisible(visible));
    if (visible) {
      amplitudeService.trackFilter(FilterName.OffStreetParkingShowVendorLogo, visible, FilterProperty.FilterEnabled);
    }
  };

  const setShowOnlyCityOwnedFacilities = useCallback(
    (visible: boolean) => {
      dispatch(offstreetZonesLayerActions.setShowOnlyCityOwnedFacilities(visible));
      if (visible) {
        amplitudeService.trackFilter(FilterName.OffStreetParkingShowOnlyCityOwnedFacilities, visible, FilterProperty.FilterEnabled);
      }
    },
    [dispatch],
  );

  const toggleOffstreetLayerType = (type: OffstreetZoneLayerType) => {
    dispatch(offstreetZonesLayerActions.setLayerTypeFilter(offstreetZonesLayer.layerTypeFilter.toggleTag(type)));
    if (offstreetZonesLayer.layerTypeFilter.tags[type]) {
      amplitudeService.trackFilter(FilterName.OffstreetParkingType, type);
    }
  };

  const toogleCameraStatus = (status: CameraStatus) => {
    const filter = camerasLayer.statusesFilter.toggleTag(status);
    dispatch(camerasLayerActions.setStatusesFilter(filter));
    if (camerasLayer.statusesFilter.tags[status]) {
      amplitudeService.trackFilter(FilterName.CamerasStatus, status);
    }
  };

  const toogleStreetObjectType = (type: StreetObjectType) => {
    const filter = streetObjectsLayer.typesFilter.toggleTag(type);
    dispatch(streetObjectsLayerActions.setTypesFilter(filter));
    if (filter.tags[type]) {
      amplitudeService.trackFilter(FilterName.StreetObjectType, type);
    }
  };

  const toogleSuggestetStreetType = (type: SuggestedStreetType) => {
    const filter = suggestedStreetsLayer.typesFilter.toggleTag(type);
    dispatch(suggestedStreetsLayerActions.setTypesFilter(filter));
    if (filter.tags[type]) {
      amplitudeService.trackFilter(FilterName.SuggestedStreetType, type);
    }
  };

  const onWeekDaysSelected = (weekDays: WeekDay[]) => {
    const periodFilter = { ...heatmapsFilter.periodFilter, weekDays: weekDays };
    dispatch(heatmapsFilterActions.setHeatmapPeriodFilter(periodFilter));
    if (weekDays.length > 0) {
      amplitudeService.trackFilter(FilterName.HeatmapWeekdays, weekDays);
    }
  };

  const onHeatmapOccupancySourceSelected = useCallback(
    (occupancySource: OccupancySource) => {
      dispatch(heatmapsFilterActions.setHeatmapOccupancySource(occupancySource));
      amplitudeService.trackFilter(FilterName.HeatmapOccupancySource, occupancySource);
    },
    [dispatch],
  );

  const onDayTimeSelected = (start: number, end: number) => {
    const periodFilter = { ...heatmapsFilter.periodFilter, minutesStart: start, minutesEnd: end };
    dispatch(heatmapsFilterActions.setHeatmapPeriodFilter(periodFilter));
    amplitudeService.trackFilterHeatmapDaytime(start, end);
  };

  const onHeatmapLayerSelected = (evt: DropDownListChangeEvent) => {
    if (evt.target.value) {
      dispatch(heatmapsFilterActions.setLayer(evt.target.value.id));

      if (evt.target.value.id === HeatmapLayerType.OffstreetZone) {
        dispatch(heatmapsFilterActions.setEnforcementEnabled(false));
        dispatch(heatmapsFilterActions.setDurationEnabled(false));
      }

      amplitudeService.trackFilter(FilterName.HeatmapRevenueLayer, evt.target.value.id);
    }
  };

  const onTrafficLayerSelected = useCallback(
    (evt: DropDownListChangeEvent) => {
      if (evt.target.value) {
        dispatch(heatmapsFilterActions.setTrafficLayerType(evt.target.value.id));
        amplitudeService.trackFilter(FilterName.HeatmapTrafficLayer, evt.target.value.id);
      }
    },
    [dispatch],
  );

  const onRevenueAmountSelected = (evt: DropDownListChangeEvent) => {
    if (evt.target.value) {
      dispatch(heatmapsFilterActions.setRevenueAmount(evt.target.value.id));
      amplitudeService.trackFilter(FilterName.HeatmapRevenueAmount, evt.target.value.id);
    }
  };

  const onEnforcementsTypeSelected = (evt: DropDownListChangeEvent) => {
    if (evt.value) {
      dispatch(heatmapsFilterActions.setEnforcementGroup(evt.value.id));
      amplitudeService.trackFilter(FilterName.HeatmapEnforcementType, enforcementTypes.find((x) => x.id === evt.value.id)?.name);
    }
  };
  const onEnforcementsMeasureSelected = (evt: DropDownListChangeEvent) => {
    if (evt.value) {
      dispatch(heatmapsFilterActions.setEnforcementMeasure(evt.value.id));
      amplitudeService.trackFilter(FilterName.HeatmapEnforcementMeasure, evt.value.id);
    }
  };

  const clickShowSuggestedStreets = (visible: boolean) => {
    dispatch(suggestedStreetsLayerActions.setShowStreets(visible));
  };

  const onSaveSavedViewClick = () => {
    if (!layerSettings.layersSettings) {
      return;
    }

    setSavedViewLayersForSave(layerSettings.layersSettings);
  };

  const showRevenueAmount = () => heatmapsFilter.layer === HeatmapLayerType.Zone || heatmapsFilter.layer === HeatmapLayerType.Blockface;

  const disabledSpotsCount = () =>
    !(Object.values(metersLayer.typesFilter.tags).every((x) => !x) || metersLayer.typesFilter.tags[MeterType.MultiSpot]);

  const zonesCount = useMemo(() => zonesLayer.count + rppZonesLayer.count, [rppZonesLayer.count, zonesLayer.count]);

  return (
    <div className={`map-sidebar ${styles.sidebar}`}>
      <div className='map-sidebar-header'>
        <h3>{localization.toLanguageStringF('toggleSidebar.title')}</h3>

        <Button size='small' className='close' icon='close' onClick={handleCloseClick}></Button>
      </div>

      <div className='map-sidebar-body'>
        <div className='map-sidebar-form scrollable-form1'>
          <SavedViews
            layersSettingsForSave={savedViewLayersForSave}
            cancelEdit={savedViewCancelSave}
            onEditEnabled={setSavedViewEditEnabled}
          />

          {areaLevels.length > 0 && (
            <>
              <div className={`map-sidebar-row ${styles.labelRow}`}>
                <label>{localization.toLanguageStringF('area.toggleSidebar')}</label>
                <DropDownList data={areaLevels} dataItemKey='id' textField='name' onChange={onAreaLevelSelected} value={areaLevel} />
              </div>
              <div className='map-sidebar-area-types-row-border'></div>
            </>
          )}

          {/* Suggested Streets*/}
          {suggestedStreetsLayer.available && (
            <div className='map-sidebar-row'>
              <div className='space-between'>
                <label>{localization.toLanguageStringF('suggestedStreet.toggleSidebar')}</label>
                <Switch
                  checked={suggestedStreetsLayer.enabled}
                  onChange={(ev) => {
                    dispatch(suggestedStreetsLayerActions.setEnabled(ev.value));
                    amplitudeService.trackLayer(LayerName.SuggestedStreets, ev.value);
                  }}
                />
              </div>
              {suggestedStreetsLayer.enabled && (
                <div className='map-sidebar-section'>
                  <Collapsible
                    header={localization.toLanguageStringF('suggestedStreet.legend')}
                    expanded={true}
                    className={styles.filterSection}
                  >
                    <Checkbox
                      label={localization.toLanguageStringF('suggestedStreet.showStreets')}
                      checked={suggestedStreetsLayer.showStreets}
                      onChange={(ev) => clickShowSuggestedStreets(ev.value)}
                    />
                    <div className={cx('tags', 'paddingTop')}>
                      {suggestedStreetsLayer.types.map((type) => (
                        <button
                          key={type}
                          className={cx('tag', {
                            selected: suggestedStreetsLayer.typesFilter.tags[type],
                          })}
                          onClick={() => toogleSuggestetStreetType(type)}
                        >
                          <SuggestedStreetIcon className={cx('tagIcon', 'tagIconStreetObject', 'tagIconCurb')} type={type} />
                          <span>{localization.toLanguageStringF(`suggestedStreet.types.${SuggestedStreetType[type]}`)}</span>
                          <CloseIcon active={suggestedStreetsLayer.typesFilter.tags[type]} />
                        </button>
                      ))}
                    </div>
                  </Collapsible>
                </div>
              )}
            </div>
          )}

          {/* Study areas */}
          {studyAreasLayer.studyAreas.length > 0 && (
            <div className='map-sidebar-row'>
              <div className='space-between'>
                <label>
                  {localization.toLanguageStringF('studyArea.toggleSidebar')}
                  {studyAreasLayer.enabled && <span className={styles.itemCount}>{studyAreasLayer.studyAreas.length}</span>}
                </label>
                <Switch
                  checked={studyAreasLayer.enabled}
                  onChange={(ev: SwitchChangeEvent) => {
                    dispatch(studyAreasLayerActions.setEnabled(ev.value));
                    amplitudeService.trackLayer(LayerName.Study, ev.value);
                  }}
                />
              </div>
              {studyAreasLayer.enabled && (
                <div className={styles.studyAreaSection}>
                  <MultiSelectCheckBox
                    items={studyAreas}
                    selected={studyAreasLayer.selectedStudyAreas.map((x) => x.toString())}
                    onChange={(items) => dispatch(studyAreasLayerActions.selectStudyAreas(items.map((x) => parseInt(x))))}
                  />
                </div>
              )}
            </div>
          )}

          {/* Zones */}
          {zonesCount > 0 && (
            <div className='map-sidebar-row'>
              <div className='space-between'>
                <label>
                  {localization.toLanguageStringF('zone.toggleSidebar')}
                  {zonesLayer.enabled && <span className={styles.itemCount}>{zonesCount}</span>}
                </label>
                <Switch
                  checked={zonesLayer.enabled}
                  onChange={(ev: SwitchChangeEvent) => {
                    dispatch(zonesLayerActions.setEnabled(ev.value));
                    amplitudeService.trackLayer(LayerName.Zones, ev.value);
                  }}
                />
              </div>

              {zonesLayer.enabled && (
                <>
                  <div className='map-sidebar-section'>
                    <Checkbox
                      checked={zonesLayer.displayZoneId}
                      onChange={(ev) => clickDisplayZoneId(ev.value)}
                      label={localization.toLanguageStringF('zone.sidebar.displayZoneId')}
                    />
                  </div>
                  {cities.selectedCity?.Code !== CityCode.SanJose && (
                    <div className={cx('map-sidebar-section', 'topMargin')}>
                      <Checkbox
                        checked={zonesLayer.timeLimitEnabled}
                        onChange={(ev) => clickTimeLimitEnable(ev.value)}
                        label={localization.toLanguageStringF('zone.sidebar.showLimit')}
                      />
                    </div>
                  )}
                  {cities.hasPerformanceParkingMeters && (
                    <div className={cx('map-sidebar-section', 'topMargin')}>
                      <Checkbox
                        className={styles.topPadding}
                        checked={zonesLayer.showPerformanceParkingOnly}
                        label={localization.toLanguageStringF('common.performanceParking')}
                        onChange={(ev) => clickZonePerformanceParking(ev.value)}
                      />
                    </div>
                  )}

                  {rppAreasLayer.enabled && (
                    <div className={cx('map-sidebar-section', 'topMargin')}>
                      <Checkbox
                        checked={rppAreasLayer.display}
                        onChange={(ev) => clickDisplayRppAreas(ev.value)}
                        label={localization.toLanguageStringF('rppArea.toggleSidebar')}
                      />
                    </div>
                  )}
                  {rppZonesLayer.enabled && (
                    <div className={cx('map-sidebar-section', 'topMargin')}>
                      <Checkbox
                        checked={rppZonesLayer.display}
                        onChange={(ev) => clickDisplayRppZones(ev.value)}
                        label={localization.toLanguageStringF('rppZone.toggleSidebar')}
                      />
                    </div>
                  )}
                  {zoneObjectsLayer.curbfacesAvailable && (
                    <div className={cx('map-sidebar-section', 'topMargin')}>
                      <Checkbox
                        checked={zoneObjectsLayer.displayCurbfaces}
                        onChange={(ev) => clickDisplayCurbfaces(ev.value)}
                        label={localization.toLanguageStringF('zone.zoneObjects.displayCurbfaces')}
                      />

                      {zoneObjectsLayer.displayCurbfaces && (
                        <div className={cx('tags', 'paddingTop')}>
                          {zoneObjectsLayer.curbfaceTypes.map((tag, idx) => (
                            <button
                              key={idx}
                              className={cx('tag', {
                                selected: zoneObjectsLayer.curbfaceTypesFilter.tags[tag.id],
                              })}
                              onClick={() => toggleCurbfaceZoneObjectsType(tag.id)}
                            >
                              <span
                                className={cx('tagIcon', 'tagIconSm')}
                                style={{ backgroundColor: tag.id, width: '14px', height: '4px', marginTop: '8px' }}
                              />
                              <span className={cx('itemCount', 'itemCountTag')}>{tag.count}</span>
                              <CloseIcon active={offstreetZonesLayer.vendorsFilter.tags[tag.id]} />
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {/* Blockfaces */}
          {blockfacesLayer.count > 0 && (
            <div className='map-sidebar-row'>
              <div className='space-between'>
                <label>
                  {localization.toLanguageStringF('blockface.toggleSidebar')}
                  {blockfacesLayer.enabled && <span className={styles.itemCount}>{blockfacesLayer.count}</span>}
                </label>
                <Switch
                  checked={blockfacesLayer.enabled}
                  onChange={(ev: SwitchChangeEvent) => {
                    dispatch(blockfacesLayerActions.setEnabled(ev.value));
                    amplitudeService.trackLayer(LayerName.Blockfaces, ev.value);
                  }}
                />
              </div>

              {blockfacesLayer.enabled && (
                <>
                  <div className='map-sidebar-section'>
                    <Checkbox
                      checked={blockfacesLayer.displayBlockfaceId}
                      onChange={(ev) => clickDisplayBlockfaceId(ev.value)}
                      label={localization.toLanguageStringF('blockface.sidebar.displayBlockfaceId')}
                    />
                  </div>
                  {cities.hasPerformanceParkingMeters && (
                    <div className={cx('map-sidebar-section', 'topMargin')}>
                      <Checkbox
                        className={styles.topPadding}
                        checked={blockfacesLayer.showPerformanceParkingOnly}
                        label={localization.toLanguageStringF('common.performanceParking')}
                        onChange={(ev) => clickBlockfacePerformanceParking(ev.value)}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {/* Offstreet zones */}
          {(offstreetZonesLayer.garagesCount || 0) > 0 && (
            <div className='map-sidebar-row'>
              <div className='space-between'>
                <label>
                  {localization.toLanguageStringF('offstreetZone.toggleSidebar')}
                  {offstreetZonesLayer.enabled && <span className={styles.itemCount}>{offstreetZonesLayer.count}</span>}
                </label>
                <Switch
                  checked={offstreetZonesLayer.enabled}
                  onChange={(ev: SwitchChangeEvent) => {
                    dispatch(offstreetZonesLayerActions.setEnabled(ev.value));
                    amplitudeService.trackLayer(LayerName.OffstreetZones, ev.value);
                  }}
                />
              </div>
              {offstreetZonesLayer.enabled && (
                <>
                  <div className='map-sidebar-section'>
                    {!!offstreetZonesLayer.ownerships.length && (
                      <Checkbox
                        checked={offstreetZonesLayer.showOnlyCityOwnedFacilities}
                        onChange={(ev) => setShowOnlyCityOwnedFacilities(ev.value)}
                        label={localization.toLanguageStringF('offstreetZone.sidebar.cityOwnedFacilitiesOnly')}
                      />
                    )}

                    <Collapsible
                      header={localization.toLanguageStringF('offstreetZone.sidebar.layerType')}
                      expanded={true}
                      className={styles.filterSection}
                    >
                      <div className={styles.tags}>
                        {Object.keys(OffstreetZoneLayerType).map((tag, idx) => (
                          <button
                            key={idx}
                            className={cx('tag', {
                              selected: offstreetZonesLayer.layerTypeFilter.tags[tag],
                            })}
                            onClick={() => toggleOffstreetLayerType(tag as OffstreetZoneLayerType)}
                          >
                            <OffstreetZoneLayerTypeIcon className={cx('tagIcon', 'tagIconSm')} layerType={tag as OffstreetZoneLayerType} />
                            <span>{localization.toLanguageStringF(`offstreetZone.layerType.${tag}`)}</span>
                            <span className={cx('itemCount', 'itemCountTag')}>
                              {tag === OffstreetZoneLayerType.Lots
                                ? offstreetZonesLayer.parkingLotsCount
                                : offstreetZonesLayer.garagesCount}
                            </span>
                            <CloseIcon active={offstreetZonesLayer.layerTypeFilter.tags[tag]} />
                          </button>
                        ))}
                      </div>
                    </Collapsible>
                  </div>
                  {offstreetZonesLayer.layerTypeFilter.isTagEnabled(OffstreetZoneLayerType.Garages) &&
                    offstreetZonesLayer.vendors.length > 0 && (
                      <div className='map-sidebar-section'>
                        <Collapsible
                          header={localization.toLanguageStringF('common.vendor')}
                          expanded={true}
                          className={styles.filterSection}
                        >
                          <Checkbox
                            checked={offstreetZonesLayer.showVendorLogo}
                            onChange={(ev) => toggleOffstreetZoneVendors(ev.value)}
                            label={localization.toLanguageStringF('offstreetZone.sidebar.showVendorLogo')}
                          />

                          <div className={cx('tags', 'paddingTop')}>
                            {offstreetZonesLayer.vendors.map((tag, idx) => (
                              <button
                                key={idx}
                                className={cx('tag', {
                                  selected: offstreetZonesLayer.vendorsFilter.tags[tag.Id],
                                })}
                                onClick={() => toggleOffstreetZoneVendorType(tag)}
                              >
                                <OffstreetZoneVendorIcon className={cx('tagIcon', 'tagIconSm')} vendorId={tag.Id} />
                                <span>{tag.Name}</span>
                                <span className={cx('itemCount', 'itemCountTag')}>{tag.Count}</span>
                                <CloseIcon active={offstreetZonesLayer.vendorsFilter.tags[tag.Id]} />
                              </button>
                            ))}
                          </div>
                        </Collapsible>
                      </div>
                    )}
                </>
              )}
            </div>
          )}

          {/* Loading Zones */}
          {showLoadingZones && (
            <div className='map-sidebar-row'>
              <div className='space-between'>
                <label>{localization.toLanguageStringF('loadingZone.toggleSidebar')}</label>
                <Switch
                  checked={loadingZonesLayer.enabled}
                  onChange={(ev: SwitchChangeEvent) => dispatch(loadingZonesLayerActions.setEnabled(ev.value))}
                />
              </div>

              {loadingZonesLayer.enabled && (
                <div className='map-sidebar-section'>
                  <Checkbox
                    checked={loadingZonesLayer.displayZoneId}
                    onChange={(ev) => dispatch(loadingZonesLayerActions.setDisplayZoneId(ev.value))}
                    label={localization.toLanguageStringF('loadingZone.sidebar.displayZoneId')}
                  />
                </div>
              )}
            </div>
          )}

          {/* Parking Spaces */}
          {curbSpacesLayer.count > 0 && (
            <div className='map-sidebar-row'>
              <div className='space-between'>
                <label>
                  {localization.toLanguageStringF('curbSpace.toggleSidebar')}
                  {curbSpacesLayer.enabled && <span className={styles.itemCount}>{curbSpacesLayer.count}</span>}
                </label>
                <Switch
                  checked={curbSpacesLayer.enabled}
                  onChange={(ev) => {
                    dispatch(curbSpacesLayerActions.setEnabled(ev.value));
                    amplitudeService.trackLayer(LayerName.CurbSpaces, ev.value);
                  }}
                />
              </div>
              {curbSpacesLayer.enabled && (
                <>
                  {curbSpacesLayer.sensorsCount > 0 && (
                    <div className='map-sidebar-section'>
                      <div className={styles.radioGroup}>
                        {sensorFilterOptions.map((x, idx) => (
                          <div key={idx} className='k-radio-item'>
                            <RadioButton
                              name='sensorsFilterGroup'
                              id={x.value}
                              value={x.value}
                              checked={x.value === curbSpacesLayer.sensorsFilter}
                              onChange={changeCurbSpaceSensorFilter}
                            >
                              <label htmlFor={x.value} className='k-radio-label'>
                                {x.label}
                                <span className={styles.itemCount}>{x.count}</span>
                              </label>
                            </RadioButton>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {curbSpacesLayer.policyTypesFilter.enabled && (
                    <div className='map-sidebar-section'>
                      <Collapsible
                        header={localization.toLanguageStringF('curbSpace.sidebar.parkingPolicy')}
                        expanded={true}
                        className={styles.filterSection}
                      >
                        <div className={styles.tags}>
                          {policies.map((tag, idx) => (
                            <button
                              key={idx}
                              className={cx('tag', {
                                selected: curbSpacesLayer.policyTypesFilter.tags[tag],
                              })}
                              onClick={() => toggleSpotPolicyType(tag)}
                            >
                              <CurbSpaceLegendIcon className={cx('tagIcon', 'tagIconSm')} policyType={tag} />
                              <span>{localization.toLanguageStringF(`curbSpace.policyType.${tag}`)}</span>
                              <CloseIcon active={curbSpacesLayer.policyTypesFilter.tags[tag]} />
                            </button>
                          ))}
                        </div>
                      </Collapsible>
                    </div>
                  )}
                  <div className='map-sidebar-section'>
                    <Collapsible header={localization.toLanguageStringF('common.status')} expanded={true} className={styles.filterSection}>
                      <div className={styles.tags}>
                        {statuses.map((tag, idx) => (
                          <button
                            key={idx}
                            className={cx('tag', {
                              selected: curbSpacesLayer.statusesFilter.tags[tag],
                            })}
                            onClick={() => toggleSpotStatus(tag)}
                          >
                            <CurbSpaceStatusIcon className={cx('tagIcon', 'tagIconSm')} status={tag} />
                            <span>{localization.toLanguageStringF(`curbSpace.occupancyStatus.${OccupancyStatus[tag]}`)}</span>
                            <span className={cx('itemCount', 'itemCountTag')}>{curbSpacesLayer.statusCount[tag]}</span>
                            <CloseIcon active={curbSpacesLayer.statusesFilter.tags[tag]} />
                          </button>
                        ))}
                      </div>
                    </Collapsible>
                  </div>
                </>
              )}
            </div>
          )}

          {/* Meters */}
          {(metersLayer.count || 0) > 0 && (
            <div className='map-sidebar-row'>
              <div className='space-between'>
                <label>
                  {localization.toLanguageStringF('meter.toggleSidebar')}
                  {metersLayer.enabled && <span className={styles.itemCount}>{metersLayer.count}</span>}
                </label>
                <Switch
                  checked={metersLayer.enabled}
                  onChange={(ev) => {
                    dispatch(metersLayerActions.setEnabled(ev.value));
                    amplitudeService.trackLayer(LayerName.Meters, ev.value);
                  }}
                />
              </div>
              {metersLayer.enabled && (
                <>
                  {metersLayer.types.length > 0 && (
                    <div className='map-sidebar-section'>
                      <Collapsible header={localization.toLanguageStringF('common.type')} expanded={true} className={styles.filterSection}>
                        <div className={styles.tags}>
                          {metersLayer.types.map((tag, idx) => (
                            <button
                              key={idx}
                              className={cx('tag', { selected: metersLayer.typesFilter.tags[tag.TypeId] })}
                              onClick={() => toggleMeterType(tag.TypeId)}
                            >
                              <MeterTypeIcon className={cx('tagIcon', 'tagIconSm')} typeId={tag.TypeId} />
                              <span>{localization.toLanguageStringF(`meter.type.${MeterType[tag.TypeId]}`)}</span>
                              <span className={cx('itemCount', 'itemCountTag')}>{tag.Count}</span>
                              <CloseIcon active={metersLayer.typesFilter.tags[tag.TypeId]} />
                            </button>
                          ))}
                        </div>
                      </Collapsible>
                    </div>
                  )}
                  <div className='map-sidebar-section'>
                    <Collapsible header={localization.toLanguageStringF('common.status')} expanded={true} className={styles.filterSection}>
                      <div className={styles.tags}>
                        {Object.keys(MeterStatus)
                          .filter((x) => x !== MeterStatus.Unknown)
                          .map((tag, idx) => (
                            <button
                              key={idx}
                              className={cx('tag', {
                                selected: metersLayer.statusesFilter.tags[tag],
                              })}
                              onClick={() => toggleMeterStatus(tag as MeterStatus)}
                            >
                              <span>{localization.toLanguageStringF(`meter.status.${MeterStatus[tag as MeterStatus]}`)}</span>
                              <span className={cx('itemCount', 'itemCountTag')}>{metersLayer.statusCount[tag as MeterStatus]}</span>
                              <CloseIcon active={metersLayer.statusesFilter.tags[tag]} />
                            </button>
                          ))}
                      </div>
                    </Collapsible>
                  </div>
                  {metersLayer.vendors.length > 0 && (
                    <div className='map-sidebar-section'>
                      <Collapsible
                        header={localization.toLanguageStringF('common.vendor')}
                        expanded={true}
                        className={styles.filterSection}
                      >
                        <Checkbox
                          label={localization.toLanguageStringF('meter.sidebar.showVendorLogo')}
                          checked={metersLayer.showVendorLogo}
                          onChange={(ev) => clickShowVendorLogo(ev.value)}
                        />
                        <div className={cx('tags', 'paddingTop')}>
                          {metersLayer.vendors.map((tag, idx) => (
                            <button
                              key={idx}
                              className={cx('tag', {
                                selected: metersLayer.vendorsFilter.tags[tag.Id],
                              })}
                              onClick={() => toggleMeterVendor(tag)}
                            >
                              <MeterVendorIcon className={cx('tagIcon', 'tagIconSm')} vendorId={tag.Id} />
                              <span>{MeterVendor.getName(tag)}</span>
                              <span className={cx('itemCount', 'itemCountTag')}>{tag.Count}</span>
                              <CloseIcon active={metersLayer.vendorsFilter.tags[tag.Id]} />
                            </button>
                          ))}
                        </div>
                      </Collapsible>
                    </div>
                  )}
                  {cities.hasPerformanceParkingMeters && (
                    <div className={cx('map-sidebar-section', 'topMargin')}>
                      <Checkbox
                        className={styles.topPadding}
                        checked={metersLayer.showPerformanceParkingOnly}
                        label={localization.toLanguageStringF('common.performanceParking')}
                        onChange={(ev) => clickMeterPerformanceParking(ev.value)}
                      />
                    </div>
                  )}
                  <div className={cx('map-sidebar-section', 'topMargin')}>
                    <Checkbox
                      disabled={disabledSpotsCount()}
                      className={styles.topPadding}
                      checked={showSpotsCount}
                      label={localization.toLanguageStringF('meter.sidebar.showSpotsCount')}
                      onChange={(ev) => clickShowSpotsCount(ev.value)}
                    />
                  </div>
                </>
              )}
            </div>
          )}

          {/* Signs */}
          {signsLayer.count > 0 && (
            <div className='map-sidebar-row'>
              <div className='space-between'>
                <label>
                  {localization.toLanguageStringF('sign.toggleSidebar')}
                  {signsLayer.enabled && <span className={styles.itemCount}>{signsLayer.count}</span>}
                </label>
                <Switch checked={signsLayer.enabled} onChange={(ev) => dispatch(signsLayerActions.setEnabled(ev.value))} />
              </div>
              {signsLayer.enabled && (
                <div className='map-sidebar-section'>
                  <Collapsible header={localization.toLanguageStringF('common.status')} expanded={true} className={styles.filterSection}>
                    <div className={styles.tags}>
                      {Object.keys(SignStatus).map((tag, idx) => (
                        <button
                          key={idx}
                          className={cx('tag', { selected: signsLayer.statusesFilter.tags[tag] })}
                          onClick={() => toggleSignsStatus(tag as SignStatus)}
                        >
                          <SignStatusIcon className={cx('tagIcon', 'tagIconSm')} status={tag as SignStatus} />
                          <span>{localization.toLanguageStringF(`sign.status.${SignStatus[tag as SignStatus]}`)}</span>
                          <span className={cx('itemCount', 'itemCountTag')}>{signsLayer.statusCount[tag as SignStatus]}</span>
                          <CloseIcon active={signsLayer.statusesFilter.tags[tag]} />
                        </button>
                      ))}
                    </div>
                  </Collapsible>
                </div>
              )}
            </div>
          )}

          {/* Cameras */}
          {camerasLayer.count > 0 && (
            <div className='map-sidebar-row'>
              <div className='space-between'>
                <label>
                  {localization.toLanguageStringF('camera.toggleSidebar')}
                  {camerasLayer.enabled && <span className={styles.itemCount}>{camerasLayer.count}</span>}
                </label>
                <Switch
                  checked={camerasLayer.enabled}
                  onChange={(ev) => {
                    dispatch(camerasLayerActions.setEnabled(ev.value));
                    amplitudeService.trackLayer(LayerName.Cameras, ev.value);
                  }}
                />
              </div>
              {camerasLayer.enabled && (
                <div className='map-sidebar-section'>
                  <Collapsible header={localization.toLanguageStringF('common.status')} expanded={true} className={styles.filterSection}>
                    <div className={styles.tags}>
                      {Object.values(CameraStatus).map((id) => (
                        <button
                          key={id}
                          className={cx('tag', { selected: camerasLayer.statusesFilter.tags[id] })}
                          onClick={() => toogleCameraStatus(id)}
                        >
                          <CameraStatusIcon className={cx('tagIcon', 'tagIconSm')} status={id} />
                          <span>{localization.toLanguageStringF(`camera.status.${CameraStatus[id]}`)}</span>
                          <span className={cx('itemCount', 'itemCountTag')}>{camerasLayer.statusCount[id]}</span>
                          <CloseIcon active={camerasLayer.statusesFilter.tags[id]} />
                        </button>
                      ))}
                    </div>
                  </Collapsible>
                </div>
              )}
            </div>
          )}

          {/* Street objects */}
          {streetObjectsLayer.available && (
            <div className='map-sidebar-row'>
              <div className='space-between'>
                <label>{localization.toLanguageStringF('streetObject.toggleSidebar')}</label>
                <Switch
                  checked={streetObjectsLayer.enabled}
                  onChange={(ev) => {
                    dispatch(streetObjectsLayerActions.setEnabled(ev.value));
                    amplitudeService.trackLayer(LayerName.StreetObjects, ev.value);
                  }}
                />
              </div>
              {streetObjectsLayer.enabled && (
                <div className='map-sidebar-section'>
                  <Collapsible header={localization.toLanguageStringF('common.type')} expanded={true} className={styles.filterSection}>
                    <div className={styles.tags}>
                      {streetObjectsLayer.types.map((type) => (
                        <button
                          key={type}
                          className={cx('tag', {
                            selected: streetObjectsLayer.typesFilter.tags[type],
                          })}
                          onClick={() => toogleStreetObjectType(type)}
                        >
                          <StreetObjectIcon
                            className={cx('tagIcon', 'tagIconStreetObject', {
                              tagIconCurb: type === StreetObjectType.Curb || type === StreetObjectType.CurbCut,
                            })}
                            type={type}
                          />
                          <span>{localization.toLanguageStringF(`streetObject.types.${StreetObjectType[type]}`)}</span>
                          <CloseIcon active={streetObjectsLayer.typesFilter.tags[type]} />
                        </button>
                      ))}
                    </div>
                  </Collapsible>
                </div>
              )}
            </div>
          )}

          {(zonesLayer.count > 0 ||
            (offstreetZonesLayer.garagesCount || 0) > 0 ||
            curbSpacesLayer.count > 0 ||
            (metersLayer.count || 0) > 0 ||
            signsLayer.count > 0 ||
            camerasLayer.count > 0 ||
            streetObjectsLayer.available ||
            suggestedStreetsLayer.available) && <div className='map-sidebar-row-border'></div>}

          {/* Custom layers*/}
          <CustomLayersToggleSidebar />

          {/* Heatmap */}
          {heatmapLayers.length > 0 && (
            <>
              <div className='map-sidebar-row space-between'>
                <label>{localization.toLanguageStringF('toggleSidebar.heatmap.title')}</label>
                <Switch
                  checked={heatmapsFilter.enabled}
                  onChange={(ev) => {
                    dispatch(heatmapsFilterActions.setEnabled(ev.value));
                    amplitudeService.trackLayer(LayerName.Heatmap, ev.value);
                  }}
                />
              </div>
              {heatmapsFilter.enabled && (
                <>
                  <div className='map-sidebar-section'>
                    <div className={`map-sidebar-row ${styles.labelRow}`}>
                      <label className={cx('sublevel-label')}>{localization.toLanguageStringF('toggleSidebar.heatmap.layers')}</label>
                      <DropDownList
                        dataItemKey='id'
                        textField='name'
                        data={heatmapLayers}
                        value={heatmapLayers.find((x) => x.id === heatmapsFilter.layer)}
                        onChange={onHeatmapLayerSelected}
                      />
                    </div>

                    {heatmapsFilter.layer === HeatmapLayerType.StudyArea && (
                      <div className={`map-sidebar-row ${styles.labelRow}`}>
                        <div></div>
                        <MultiSelectCheckBox
                          items={studyAreas}
                          placeholder={localization.toLanguageStringF('toggleSidebar.heatmap.studyAreasPlaceholder')}
                          selected={heatmapsFilter.selectedStudyAreas
                            .filter((x) => studyAreas.some((y) => parseInt(y.id) === x))
                            .map((x) => x.toString())}
                          onChange={(items) => dispatch(heatmapsFilterActions.selectStudyAreas(items.map((x) => parseInt(x))))}
                        />
                      </div>
                    )}

                    <div className={`map-sidebar-row ${styles.labelRow} ${styles.heatmapPeriodSelector}`}>
                      <HeatmapPeriodSelector
                        heatmapPeriod={heatmapsFilter.periodFilter.heatmapPeriod}
                        period={heatmapsFilter.periodFilter.period}
                        onChange={onChangeHeatmapPeriodSelector}
                      />
                    </div>

                    <div className={`map-sidebar-row ${styles.labelRow}`}>
                      <label className={cx('sublevel-label')} htmlFor='weekDays'>
                        {localization.toLanguageStringF('toggleSidebar.weekdays')}
                      </label>
                      <MultiSelectCheckBox
                        items={weekdays}
                        selected={heatmapsFilter.periodFilter.weekDays}
                        onChange={(items) => onWeekDaysSelected(items.map((x) => x as WeekDay))}
                      />
                    </div>
                    <div className={`map-sidebar-row ${styles.labelRow}`}>
                      <label className={cx('sublevel-label')} htmlFor='dayTime'>
                        {localization.toLanguageStringF('toggleSidebar.daytime')}
                      </label>
                      <DayTimeRangeSelector
                        id='dayTime'
                        start={heatmapsFilter.periodFilter.minutesStart}
                        end={heatmapsFilter.periodFilter.minutesEnd}
                        onChange={onDayTimeSelected}
                        className={styles.slider}
                      />
                    </div>

                    {/* Revenue */}
                    <div className='map-sidebar-row space-between'>
                      <Checkbox
                        checked={heatmapsFilter.revenueEnabled}
                        onChange={(ev) => {
                          dispatch(heatmapsFilterActions.setRevenueEnabled(ev.value));
                          amplitudeService.trackLayer(LayerName.Revenue, ev.value);
                        }}
                        label={localization.toLanguageStringF('revenue.toggleSidebar.title')}
                      />
                    </div>
                    {heatmapsFilter.revenueEnabled && (
                      <>
                        {showRevenueAmount() && (
                          <div className={`map-sidebar-row ${styles.labelRow}`}>
                            <label className={cx('sublevel-label')} htmlFor='revenueAmount'>
                              Amount
                            </label>
                            <DropDownList
                              id='revenueAmount'
                              dataItemKey='id'
                              textField='name'
                              data={revenueAmounts}
                              value={revenueAmounts.find((x) => x.id === heatmapsFilter.revenueAmount)}
                              onChange={onRevenueAmountSelected}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <div className='map-sidebar-section'>
                    {/* Enforcements */}
                    {heatmapsFilter.enforcementGroups.length > 0 && (
                      <>
                        <div className='map-sidebar-row space-between'>
                          <Checkbox
                            disabled={heatmapsFilter.layer === HeatmapLayerType.OffstreetZone}
                            checked={heatmapsFilter.enforcementEnabled}
                            onChange={(ev) => {
                              dispatch(heatmapsFilterActions.setEnforcementEnabled(ev.value));
                              amplitudeService.trackLayer(LayerName.Enforcement, ev.value);
                            }}
                            label={localization.toLanguageStringF('enforcement.toggleSidebar.title')}
                          />
                        </div>
                        {heatmapsFilter.enforcementEnabled && (
                          <>
                            <div className={`map-sidebar-row ${styles.labelRow}`}>
                              <label className={cx('sublevel-label')} htmlFor='enforcementsType'>
                                {localization.toLanguageStringF('enforcement.toggleSidebar.type')}
                              </label>
                              <DropDownList
                                id='enforcementsType'
                                dataItemKey='id'
                                textField='name'
                                data={enforcementTypes}
                                value={enforcementTypes.find((x) => x.id === heatmapsFilter.enforcementGroupId)}
                                onChange={onEnforcementsTypeSelected}
                              />
                            </div>

                            <div className={`map-sidebar-row ${styles.labelRow}`}>
                              <label className={cx('sublevel-label')} htmlFor='enforcementsMeasure'>
                                {localization.toLanguageStringF('enforcement.toggleSidebar.measure')}
                              </label>
                              <DropDownList
                                id='enforcementsMeasure'
                                dataItemKey='id'
                                textField='name'
                                data={enforcementMeasures}
                                value={enforcementMeasures.find((x) => x.id === heatmapsFilter.enforcementMeasure)}
                                onChange={onEnforcementsMeasureSelected}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}

                    {/* Parking durations */}
                    <div className='map-sidebar-row space-between'>
                      <Checkbox
                        disabled={
                          heatmapsFilter.layer === HeatmapLayerType.Meter ||
                          heatmapsFilter.layer === HeatmapLayerType.OffstreetZone ||
                          heatmapsFilter.layer === HeatmapLayerType.CurbSpace
                        }
                        checked={heatmapsFilter.durationEnabled}
                        onChange={(ev) => {
                          dispatch(heatmapsFilterActions.setDurationEnabled(ev.value));
                          amplitudeService.trackLayer(LayerName.ParkingDuration, ev.value);
                        }}
                        label={localization.toLanguageStringF('parkingDuration.toggleSidebar.title')}
                      />
                    </div>

                    {/* Occupancy */}
                    <div className='map-sidebar-row space-between'>
                      <Checkbox
                        checked={heatmapsFilter.occupancyEnabled}
                        // disabled={heatmapsFilter.layer === HeatmapLayerType.CurbSpace}
                        onChange={(ev) => {
                          dispatch(heatmapsFilterActions.setOccupancyEnabled(ev.value));
                          amplitudeService.trackLayer(LayerName.Occupancy, ev.value);
                        }}
                        label={localization.toLanguageStringF('toggleSidebar.occupancy')}
                      />
                    </div>
                    {heatmapsFilter.occupancyEnabled && (
                      <div className={`map-sidebar-row ${styles.labelRow}`}>
                        <label className={cx('sublevel-label')}>{localization.toLanguageStringF('occupancy.toggleSidebar.source')}</label>
                        <OccupancySourceSelector
                          value={heatmapsFilter.heatmapOccupancySource}
                          onChange={onHeatmapOccupancySourceSelected}
                          hideLable={true}
                        />
                      </div>
                    )}

                    {/* Traffic */}
                    <div className='map-sidebar-row space-between'>
                      <Checkbox
                        checked={heatmapsFilter.trafficEnabled}
                        onChange={(ev) => {
                          dispatch(heatmapsFilterActions.setTrafficEnabled(ev.value));
                          amplitudeService.trackLayer(LayerName.Traffic, ev.value);
                        }}
                        label={localization.toLanguageStringF('toggleSidebar.traffic')}
                      />
                    </div>
                    {heatmapsFilter.trafficEnabled && heatmapsFilter.trafficSegmentsCount > 0 && (
                      <div className={`map-sidebar-row ${styles.labelRow}`}>
                        <label className={cx('sublevel-label')} htmlFor='trafficLayer'>
                          Layers
                        </label>
                        <DropDownList
                          id='trafficLayer'
                          dataItemKey='id'
                          textField='name'
                          data={trafficLayers}
                          value={trafficLayers.find((x) => x.id === heatmapsFilter.trafficLayerType)}
                          onChange={onTrafficLayerSelected}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
          <div className='map-sidebar-row-border'></div>

          {savedViewEditEnabled && (
            <div className={cx('map-sidebar-row', 'sidebarButtons')}>
              <Button themeColor={'primary'} onClick={onSaveSavedViewClick} className={cx('button', 'primary')}>
                {localization.toLanguageStringF('common.save')}
              </Button>
              <Button className={styles.button} onClick={() => setSavedViewCancelSave((prevState) => !prevState)}>
                {localization.toLanguageStringF('common.cancel')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ToggleLayersSidebar: FunctionComponent = () => {
  return (
    <MapSidebarContainer name={SidebarName.layers}>
      <Sidebar />
    </MapSidebarContainer>
  );
};
