/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { debounceTime, distinctUntilChanged, Subject, switchMap, catchError, of, map, tap } from 'rxjs';

import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteFocusEvent, ListItemProps } from '@progress/kendo-react-dropdowns';

import { FunctionComponent } from 'react';

import { useAppSelector } from '../../../../store/hooks';
import {
  selectAreaEnabled,
  selectBlockfacesLayer,
  selectCamerasLayer,
  selectCurbSpacesLayer,
  selectedAreaActions,
  selectedBlockfacesActions,
  selectedCurbSpacesActions,
  selectedOffstreetZonesActions,
  selectedParkingLotsActions,
  selectedRppAreasActions,
  selectedRppZonesActions,
  selectedStudyAreasActions,
  selectedZonesActions,
  selectMetersLayer,
  selectOffstreetZonesLayer,
  selectRppAreasLayer,
  selectRppZonesLayer,
  selectSignsLayer,
  selectStudyAreasLayer,
  selectZonesLayer,
} from '../../../../features';
import { amplitudeService, NavigationSource, PopupType, search } from '../../../../services';
import { DEFAULT_SEARCH_RESULT, OffstreetZoneLayerType, SensorFilter } from '../../../../model';
import {
  MeterItem,
  CurbSpaceItem,
  CameraItem,
  SignItem,
  ZoneItem,
  OffstreetZoneItem,
  AreaItem,
  NoResultItem,
  MoreResultsItem,
  ParkingLotItem,
  BlockfaceItem,
  SensorItem,
  RppZoneItem,
} from './items';
import { useOpenPopupDispatch } from '../../../../hooks';
import { selectedMetersActions, selectedCamerasActions, selectedSignsActions } from '../../../../features';

import styles from './Search.module.scss';
import { StudyAreaItem } from './items/StudyAreaItem';
import { RppAreaItem } from './items/RppAreaItem';

enum SelectedItemName {
  Meter = 'meter',
  Camera = 'camera',
  Sign = 'sign',
  Zone = 'zone',
  RppZone = 'rppZone',
  RppArea = 'rppArea',
  CurbSpace = 'curbSpace',
  Area = 'area',
  OffstreetZone = 'offstreetZone',
  ParkingLot = 'parkingLot',
  Blockface = 'blockface',
  Sensor = 'sensor',
  StudyArea = 'studyArea',
}

interface ISearchResultItem {
  type: SelectedItemName | string;
  item: any;
  displayName: string;
  enabled: boolean;
}

export interface SearchItemProps {
  model: ISearchResultItem;
  onItemSelected: (item: ISearchResultItem) => void;
}

const NO_RESULT = { type: 'NoResult', item: {}, displayName: '', enabled: true };
const MORE_RESULT = { type: 'MoreResult', item: {}, displayName: '', enabled: true };
const PAGE_SIZE = 5;

const Search: FunctionComponent = () => {
  const curbSpacesLayer = useAppSelector(selectCurbSpacesLayer);
  const metersLayer = useAppSelector(selectMetersLayer);
  const camerasLayer = useAppSelector(selectCamerasLayer);
  const signsLayer = useAppSelector(selectSignsLayer);
  const zonesLayer = useAppSelector(selectZonesLayer);
  const rppZonesLayer = useAppSelector(selectRppZonesLayer);
  const rppAreasLayer = useAppSelector(selectRppAreasLayer);

  const offstreetZonesLayer = useAppSelector(selectOffstreetZonesLayer);
  const areaLevelEnabled = useAppSelector(selectAreaEnabled);
  const blockfacesLayer = useAppSelector(selectBlockfacesLayer);
  const studyAreasLayer = useAppSelector(selectStudyAreasLayer);
  const popupDispatch = useOpenPopupDispatch();

  const layers = useMemo(() => {
    const layers: Array<string> = [];
    if (curbSpacesLayer.enabled) layers.push('Spot');
    if (metersLayer.enabled) layers.push('Meter');
    if (camerasLayer.enabled) layers.push('Camera');
    if (signsLayer.enabled) layers.push('Sign');
    if (zonesLayer.enabled) layers.push('Zone');
    if (offstreetZonesLayer.enabled) layers.push('OffstreetZone', 'ParkingLot');
    if (areaLevelEnabled) layers.push('Area');
    if (blockfacesLayer.enabled) layers.push('Blockface');
    if (rppZonesLayer.enabled) layers.push('RppZone');
    if (rppAreasLayer.enabled) layers.push('RppArea');

    if (
      curbSpacesLayer.enabled &&
      curbSpacesLayer.sensorsCount > 0 &&
      (curbSpacesLayer.sensorsFilter === SensorFilter.All || curbSpacesLayer.sensorsFilter === SensorFilter.WithSensors)
    )
      layers.push('Sensor');
    if (studyAreasLayer.enabled) layers.push('StudyArea');

    return layers;
  }, [
    curbSpacesLayer.enabled,
    curbSpacesLayer.sensorsCount,
    curbSpacesLayer.sensorsFilter,
    metersLayer.enabled,
    camerasLayer.enabled,
    signsLayer.enabled,
    zonesLayer.enabled,
    offstreetZonesLayer.enabled,
    areaLevelEnabled,
    blockfacesLayer.enabled,
    rppZonesLayer.enabled,
    rppAreasLayer.enabled,
    studyAreasLayer.enabled,
  ]);

  const [data, setData] = useState<ISearchResultItem[]>([]);
  const [value, setValue] = useState('');
  const [onSearch$] = useState(() => new Subject<string>());
  const [loading, setLoading] = useState(false);
  const [takeCount, setTakeCount] = useState(PAGE_SIZE);

  const onItemSelected = useCallback(
    (item: ISearchResultItem) => {
      if (!item.enabled) {
        return;
      }

      if (item.type === MORE_RESULT.type) {
        setTakeCount((x) => x + PAGE_SIZE);
        return;
      }

      const payload = { id: item.item.Id, position: null };
      switch (item.type) {
        case SelectedItemName.Meter:
          popupDispatch(selectedMetersActions.loadMeter(payload));
          amplitudeService.trackPopupOpen(PopupType.Meters, NavigationSource.Search);
          break;
        case SelectedItemName.Camera:
          popupDispatch(selectedCamerasActions.loadCamera(payload));
          amplitudeService.trackPopupOpen(PopupType.Cameras, NavigationSource.Search);
          break;
        case SelectedItemName.Sign:
          popupDispatch(selectedSignsActions.loadSign(payload));
          break;
        case SelectedItemName.Zone:
          popupDispatch(selectedZonesActions.loadZone(payload));
          amplitudeService.trackPopupOpen(PopupType.Zones, NavigationSource.Search);
          break;
        case SelectedItemName.RppZone:
          popupDispatch(selectedRppZonesActions.loadRppZone(payload));
          amplitudeService.trackPopupOpen(PopupType.RppZones, NavigationSource.Search);
          break;
        case SelectedItemName.RppArea:
          popupDispatch(selectedRppAreasActions.loadRppArea(payload));
          amplitudeService.trackPopupOpen(PopupType.RppAreas, NavigationSource.Search);
          break;
        case SelectedItemName.CurbSpace:
          popupDispatch(selectedCurbSpacesActions.load(payload));
          amplitudeService.trackPopupOpen(PopupType.CurbSpaces, NavigationSource.Search);
          break;
        case SelectedItemName.Area:
          popupDispatch(selectedAreaActions.loadArea(payload));
          break;
        case SelectedItemName.OffstreetZone:
          popupDispatch(selectedOffstreetZonesActions.loadZone(payload));
          amplitudeService.trackPopupOpen(PopupType.OffstreetParking, NavigationSource.Search);
          break;
        case SelectedItemName.ParkingLot:
          popupDispatch(selectedParkingLotsActions.load(payload));
          amplitudeService.trackPopupOpen(PopupType.ParkingLot, NavigationSource.Search);
          break;
        case SelectedItemName.Blockface:
          popupDispatch(selectedBlockfacesActions.loadBlockface(payload));
          amplitudeService.trackPopupOpen(PopupType.Blockfaces, NavigationSource.Search);
          break;
        case SelectedItemName.Sensor:
          popupDispatch(selectedCurbSpacesActions.load({ id: item.item.SpotId, position: null }));
          amplitudeService.trackPopupOpen(PopupType.CurbSpaces, NavigationSource.Search);
          break;
        case SelectedItemName.StudyArea:
          popupDispatch(selectedStudyAreasActions.loadStudyArea(payload));
          amplitudeService.trackPopupOpen(PopupType.Study, NavigationSource.Search);
          break;
      }

      setValue(item.displayName);
    },
    [popupDispatch],
  );

  const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const dataItem = itemProps.dataItem as ISearchResultItem;
    switch (dataItem.type) {
      case SelectedItemName.Meter:
        return <MeterItem model={dataItem} onItemSelected={onItemSelected} />;
      case SelectedItemName.CurbSpace:
        return <CurbSpaceItem model={dataItem} onItemSelected={onItemSelected} />;
      case SelectedItemName.Camera:
        return <CameraItem model={dataItem} onItemSelected={onItemSelected} />;
      case SelectedItemName.Sign:
        return <SignItem model={dataItem} onItemSelected={onItemSelected} />;
      case SelectedItemName.Zone:
        return <ZoneItem model={dataItem} onItemSelected={onItemSelected} />;
      case SelectedItemName.RppZone:
        return <RppZoneItem model={dataItem} onItemSelected={onItemSelected} />;
      case SelectedItemName.RppArea:
        return <RppAreaItem model={dataItem} onItemSelected={onItemSelected} />;
      case SelectedItemName.OffstreetZone:
        return <OffstreetZoneItem model={dataItem} onItemSelected={onItemSelected} />;
      case SelectedItemName.Area:
        return <AreaItem model={dataItem} onItemSelected={onItemSelected} />;
      case SelectedItemName.ParkingLot:
        return <ParkingLotItem model={dataItem} onItemSelected={onItemSelected} />;
      case SelectedItemName.Blockface:
        return <BlockfaceItem model={dataItem} onItemSelected={onItemSelected} />;
      case SelectedItemName.Sensor:
        return <SensorItem model={dataItem} onItemSelected={onItemSelected} />;
      case SelectedItemName.StudyArea:
        return <StudyAreaItem model={dataItem} onItemSelected={onItemSelected} />;
      case NO_RESULT.type:
        return <NoResultItem />;
      case MORE_RESULT.type:
        return <MoreResultsItem model={MORE_RESULT} onItemSelected={onItemSelected} />;
    }
  };

  const getEnabled = useCallback(
    (type: SelectedItemName | string, item: any) => {
      switch (type) {
        case SelectedItemName.CurbSpace:
          return (
            curbSpacesLayer.statusesFilter.isTagEnabled(item.Status) &&
            (curbSpacesLayer.sensorsFilter === SensorFilter.All ||
              (curbSpacesLayer.sensorsFilter === SensorFilter.WithSensors && !!item.ParkingSensorId) ||
              (curbSpacesLayer.sensorsFilter === SensorFilter.WithoutSensors && !item.ParkingSensorId))
          );
        case SelectedItemName.Meter: {
          return (
            metersLayer.typesFilter.isTagEnabled(item.TypeId) &&
            metersLayer.statusesFilter.isTagEnabled(item.Status) &&
            metersLayer.vendorsFilter.isTagEnabled(item.VendorId) &&
            (!metersLayer.showPerformanceParkingOnly || !!item.PerformanceParking)
          );
        }
        case SelectedItemName.Camera:
          return camerasLayer.statusesFilter.isTagEnabled(item.Status);
        case SelectedItemName.Sign:
          return signsLayer.statusesFilter.isTagEnabled(item.Status);
        case SelectedItemName.OffstreetZone:
          return (
            offstreetZonesLayer.layerTypeFilter.isTagEnabled(OffstreetZoneLayerType.Garages) &&
            offstreetZonesLayer.vendorsFilter.isTagEnabled(item.VendorId)
          );
        case SelectedItemName.ParkingLot:
          return offstreetZonesLayer.layerTypeFilter.isTagEnabled(OffstreetZoneLayerType.Lots);
        case SelectedItemName.StudyArea:
          return studyAreasLayer.selectedStudyAreas.length === 0 || studyAreasLayer.selectedStudyAreas.includes(item.Id);
        case SelectedItemName.Zone:
          return !zonesLayer.showPerformanceParkingOnly || !!item.PerformanceParking;
        case SelectedItemName.Blockface:
          return !blockfacesLayer.showPerformanceParkingOnly || !!item.PerformanceParking;
        default:
          return true;
      }
    },
    [
      camerasLayer.statusesFilter,
      curbSpacesLayer.statusesFilter,
      metersLayer.statusesFilter,
      metersLayer.typesFilter,
      metersLayer.vendorsFilter,
      offstreetZonesLayer.vendorsFilter,
      signsLayer.statusesFilter,
      offstreetZonesLayer.layerTypeFilter,
      metersLayer.showPerformanceParkingOnly,
      curbSpacesLayer.sensorsFilter,
      studyAreasLayer.selectedStudyAreas,
      zonesLayer.showPerformanceParkingOnly,
      blockfacesLayer.showPerformanceParkingOnly,
    ],
  );

  useEffect(() => {
    const createSearchResultItem = (type: SelectedItemName | string, item: any): ISearchResultItem => {
      return {
        type: type,
        item: item,
        displayName: getDisplayName(item.Name),
        enabled: getEnabled(type, item),
      };
    };

    const loadData = (query: string, layers: string[]) => {
      const has2AlphanumericBesides = query && query.match(/^.*\w{2,}.*$/g) !== null;
      if (has2AlphanumericBesides && layers.length > 0) {
        return search.get(query, layers).pipe(
          catchError((err) => of(DEFAULT_SEARCH_RESULT)),
          map((data) => [
            ...data.Spots.map((x) => createSearchResultItem(SelectedItemName.CurbSpace, x)),
            ...data.Meters.map((x) => createSearchResultItem(SelectedItemName.Meter, x)),
            ...data.Cameras.map((x) => createSearchResultItem(SelectedItemName.Camera, x)),
            ...data.Signs.map((x) => createSearchResultItem(SelectedItemName.Sign, x)),
            ...data.Zones.map((x) => createSearchResultItem(SelectedItemName.Zone, x)),
            ...data.OffstreetZones.map((x) => createSearchResultItem(SelectedItemName.OffstreetZone, x)),
            ...data.Areas.map((x) => createSearchResultItem(SelectedItemName.Area, x)),
            ...data.ParkingLots.map((x) => createSearchResultItem(SelectedItemName.ParkingLot, x)),
            ...data.Blockfaces.map((x) => createSearchResultItem(SelectedItemName.Blockface, x)),
            ...data.Sensors.map((x) => createSearchResultItem(SelectedItemName.Sensor, x)),
            ...data.StudyAreas.map((x) => createSearchResultItem(SelectedItemName.StudyArea, x)),
            ...data.RppAreas.map((x) => createSearchResultItem(SelectedItemName.RppArea, x)),
            ...data.RppZones.map((x) => createSearchResultItem(SelectedItemName.RppZone, x)),
          ]),
        );
      }

      return of([]);
    };

    const subscription = onSearch$
      .pipe(
        tap((x) => setLoading(true)),
        debounceTime(40),
        distinctUntilChanged(),
        switchMap((search) => loadData(search, layers)),
      )
      .subscribe({
        next: (data) => {
          setData(data);
          setLoading(false);
        },
        error: () => setLoading(false),
      });

    return () => subscription.unsubscribe();
  }, [onSearch$, layers, getEnabled]);

  //change 'enabled' when toggle filters
  useEffect(() => {
    setData((prevState) =>
      prevState.map((x) => ({
        ...x,
        enabled: getEnabled(x.type, x.item),
      })),
    );
  }, [getEnabled]);

  const onChange = (event: AutoCompleteChangeEvent) => {
    if (typeof event.value === 'string') {
      setValue(event.value);
      setTakeCount(PAGE_SIZE);
      onSearch$.next(event.value);
    } else {
      //enter was pressed
      const item = event.value as ISearchResultItem;
      onItemSelected(item);
    }
  };

  const onFocus = (event: AutoCompleteFocusEvent) => {
    if (event.target.value) {
      event.target.setState({ opened: true });
    }
  };

  const getSuggestions = () => {
    if (data.length === 0) {
      return [NO_RESULT];
    } else {
      const items = data.slice(0, takeCount + 1);
      if (items.length > takeCount) {
        items[items.length - 1] = MORE_RESULT;
      }
      return items;
    }
  };

  return (
    <AutoComplete
      className={styles.autocomplete}
      data={getSuggestions()}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      itemRender={itemRender}
      loading={loading}
      style={{
        height: '28px',
      }}
      popupSettings={{ width: 300 }}
      rounded={null}
    />
  );
};

export default Search;

function getDisplayName(value: string): string {
  return value.replace(/<b>|<\/b>/gi, '');
}
