import { FunctionComponent, useCallback } from 'react';
import { Layer, MapLayerMouseEvent, Source } from 'react-map-gl';

import { AnyLayout } from 'mapbox-gl';
import { MIN_ZOOM } from '../../../../constants';
import { selectedStreetSignsActions, selectStreetObjectsGeo, selectStreetObjectsLayer } from '../../../../features';
import { useMapLayerHover, useMapLayerPopup, useMapToolTip, useOpenPopupDispatch } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { amplitudeService, NavigationSource, PopupType } from '../../../../services';
import { useAppSelector } from '../../../../store/hooks';
import { MapImage } from '../../controls';
import { StreetSignPopup } from './StreetSignPopup';

const curbsPaint = {
  'line-width': {
    base: 1.5,
    stops: [
      [14, 2.5],
      [20, 3],
    ],
  },
  'line-color': '#000000',
};

const curbCutsPaint = {
  'line-width': {
    base: 3,
    stops: [
      [14, 7],
      [20, 9],
    ],
  },
  'line-color': '#A020F0',
};

const iconLayerPaint = {
  'text-color': 'black',
};

const iconLayout = {
  'icon-allow-overlap': true,
  'icon-size': {
    base: 0.4,
    stops: [
      [16, 0.6],
      [18, 0.8],
      [20, 1.2],
      [21, 1.6],
    ],
  },
};

export const StreetObjectsLayer: FunctionComponent = () => {
  const popupDispatch = useOpenPopupDispatch();
  const streetObjectsGeo = useAppSelector(selectStreetObjectsGeo);
  const streetObjectsLayer = useAppSelector(selectStreetObjectsLayer);

  const defaultLayout: AnyLayout = {
    visibility: streetObjectsLayer.enabled ? 'visible' : 'none',
  };
  const fireHydrantsLayout: AnyLayout = {
    visibility: streetObjectsLayer.enabled ? 'visible' : 'none',
    'icon-image': 'fire-hydrant',
    ...iconLayout,
  };
  const streetSignsLayout: AnyLayout = {
    visibility: streetObjectsLayer.enabled ? 'visible' : 'none',
    'icon-image': ['match', ['get', 'signCode'], 'R7-108', 'sign-parking', 'R7-8', 'sign-handicap', 'sign-no-parking'],
    ...iconLayout,
  };
  const polesLayout: AnyLayout = {
    visibility: streetObjectsLayer.enabled ? 'visible' : 'none',
    'icon-image': 'pole',
    ...iconLayout,
  };

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      const feature = evt.features ? evt.features[0] : null;
      if (!feature || !feature.properties) return;

      const properties = feature.properties;
      const position = [evt.lngLat.lng, evt.lngLat.lat];
      popupDispatch(
        selectedStreetSignsActions.selectSign({
          sign: {
            Id: properties.id,
            Code: properties.signCode,
            Position: [evt.lngLat.lng, evt.lngLat.lat],
            Direction: properties.direction,
            Description: properties.signCodeDesc,
            CollectionDate: new Date(properties.collectionDate),
            ImageUrl: properties.imageURL,
          },
          position,
        }),
      );
      amplitudeService.trackPopupOpen(PopupType.StreetObjects, NavigationSource.Map);
    },
    [popupDispatch],
  );

  useMapLayerPopup(handleLayerClick, 'street-signs-layer');
  const localization = useExtendedLocalization();

  const streetSignsLayer = 'street-signs-layer';
  const curbsLayer = 'curbs-layer';
  const curbCutsLayer = 'curb-cuts-layer';
  const fireHydrantsLayer = 'fire-hydrants-layer';
  const polesLayer = 'poles-layer';

  useMapToolTip(
    (e) => {
      const description: string = e.features?.[0].properties?.signCodeDesc;
      return localization.toLanguageStringF('streetObject.toolTip.sign', [description]);
    },
    () => streetObjectsLayer.enabled,
    streetSignsLayer,
  );
  useMapToolTip(
    () => localization.toLanguageStringF('streetObject.toolTip.curb'),
    () => streetObjectsLayer.enabled,
    curbsLayer,
  );
  useMapToolTip(
    () => localization.toLanguageStringF('streetObject.toolTip.curbCut'),
    () => streetObjectsLayer.enabled,
    curbCutsLayer,
  );
  useMapToolTip(
    () => localization.toLanguageStringF('streetObject.toolTip.fireHydrant'),
    () => streetObjectsLayer.enabled,
    fireHydrantsLayer,
  );
  useMapToolTip(
    (e) => {
      const description: string = e.features?.[0].properties?.ID;
      return localization.toLanguageStringF('streetObject.toolTip.pole', [description]);
    },
    () => streetObjectsLayer.enabled,
    polesLayer,
  );

  useMapLayerHover([streetSignsLayer, curbsLayer, curbCutsLayer, fireHydrantsLayer, polesLayer]);

  return (
    <>
      <Source id='curbs-source' type='geojson' data={streetObjectsGeo.curbs} generateId={true}>
        <Layer
          {...{
            id: curbsLayer,
            type: 'line',
            layout: defaultLayout,
            paint: curbsPaint,
            before: 'building-extrusion',
            minzoom: MIN_ZOOM,
          }}
        />
      </Source>

      <Source id='curb-cuts-source' type='geojson' data={streetObjectsGeo.curbCuts} generateId={true}>
        <Layer
          {...{
            id: curbCutsLayer,
            type: 'line',
            layout: defaultLayout,
            paint: curbCutsPaint,
            before: 'building-extrusion',
            minzoom: MIN_ZOOM,
          }}
        />
      </Source>

      <Source id='fire-hydrants-source' type='geojson' data={streetObjectsGeo.fireHydrants} generateId={true}>
        <Layer
          {...{
            id: fireHydrantsLayer,
            type: 'symbol',
            layout: fireHydrantsLayout,
            paint: iconLayerPaint,
            before: 'building-extrusion',
            minzoom: MIN_ZOOM,
          }}
        />
      </Source>

      <Source id='street-signs-source' type='geojson' data={streetObjectsGeo.signs} generateId={true}>
        <Layer
          {...{
            id: streetSignsLayer,
            type: 'symbol',
            layout: streetSignsLayout,
            before: 'building-extrusion',
            minzoom: MIN_ZOOM,
          }}
        />
      </Source>

      <Source id='poles-source' type='geojson' data={streetObjectsGeo.poles} generateId={true}>
        <Layer
          {...{
            id: polesLayer,
            type: 'symbol',
            layout: polesLayout,
            paint: iconLayerPaint,
            before: 'building-extrusion',
            minzoom: MIN_ZOOM,
          }}
        />
      </Source>

      <StreetSignPopup />

      <MapImage name='fire-hydrant' url='/street-objects/fire-hydrant.png' />
      <MapImage name='sign-no-parking' url='/street-objects/signs/sign-no-parking.png' />
      <MapImage name='sign-handicap' url='/street-objects/signs/sign-handicap.png' />
      <MapImage name='sign-parking' url='/street-objects/signs/sign-parking.png' />
      <MapImage name='pole' url='/street-objects/pole.png' />
    </>
  );
};
