import { FunctionComponent, useEffect, useState } from 'react';

import { useAppSelector } from '../../../../store/hooks';

import { getAreaTypes, IAreaTypeNameById, selectAreaTypes } from '../../../../features';

import { IArea } from '../../../../model';

type Props = {
  area: IArea;
};

export const AreaTypeName: FunctionComponent<Props> = ({ area }) => {
  const types = useAppSelector(selectAreaTypes);
  const [typeNames, setTypeNames] = useState<IAreaTypeNameById>({});

  useEffect(() => {
    setTypeNames(getAreaTypes(types));
  }, [types]);

  const typeName = typeNames[area.TypeId];
  if (!typeName) return null;

  return <span>{typeName.name}</span>;
};
