import { FC, useMemo } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { DayOfWeek, TimeSpans } from '../../../../model';
import { dateUtils, numberUtils } from '../../../../utils';
import React from 'react';

const formatDaysOfWeek = (daysOfWeek: DayOfWeek[], localizationFn: (i: number) => string): string =>
  formatSegments(
    daysOfWeek.map((x) => Object.values(DayOfWeek).indexOf(x) + 1),
    localizationFn,
  );

const formatSegments = (arr: number[], localizationFn: (i: number) => string): string => {
  const segments = numberUtils.splitIntoSegments(arr);

  const strArray = segments.map((x) => {
    return Array.isArray(x) ? `${localizationFn(x[0])} - ${localizationFn(x[1])}` : localizationFn(x);
  });

  return strArray.join(', ');
};

export const PolicyTimeSpans: FC<{ timeSpans: TimeSpans[] | null; className: string }> = ({ timeSpans, className }) => {
  const localization = useExtendedLocalization();

  const content = useMemo(
    () => (
      <>
        {timeSpans?.map((x, index) => (
          <React.Fragment key={`timeSpan${index}`}>
            {(x.StartDate || x.EndDate) && (
              <span className={className}>
                {(x.StartDate && dateUtils.toLocalUiString(x.StartDate)) || '\u223E'} -
                {(x.EndDate && dateUtils.toLocalUiString(x.EndDate)) || '\u223E'}
              </span>
            )}
            {!!x.DaysOfWeek?.length && (
              <span className={className}>
                {formatDaysOfWeek(x.DaysOfWeek, (x) => localization.toLanguageStringF(`common.weekDaysNum.${x}`))}
              </span>
            )}
            {!!x.Months?.length && (
              <span className={className}>{formatSegments(x.Months, (x) => localization.toLanguageStringF(`common.month.${x}`))}</span>
            )}
            {(x.TimeOfDayStart || x.TimeOfDayEnd) && (
              <span className={className}>
                {x.TimeOfDayStart || '00:00'} - {x.TimeOfDayEnd || '00:00'}
              </span>
            )}

            {x.DesignatedPeriod && (
              <span className={className}>
                {x.DesignatedPeriodExcept && localization.toLanguageStringF('common.no')}
                {x.DesignatedPeriod}
              </span>
            )}
          </React.Fragment>
        ))}
      </>
    ),
    [className, localization, timeSpans],
  );

  return content;
};
