/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';

import { useAppDispatch } from '../../../../../../store/hooks';

import { metersEditingActions } from '../../../../../../features';
import { GeoCoordinatesEditor, GeoCoordinatesText } from '../../../../../common';
import { IMeterState } from '../../../../../../model';
import { MeterTypeIcon } from '../../../../icons';
import { MeterTypeText } from '../../MeterTypeText';
import { MeterStatusText } from '../../MeterStatusText';

import styles from './MeterEditingTile.module.scss';

interface Props {
  meter: IMeterState;
  className?: string;
}

export const MeterEditingTile: FunctionComponent<Props> = ({ meter, className }) => {
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);

  const toggleSelected = (enabled: boolean | 'mixed') => {
    const selected = enabled === 'mixed' ? false : enabled;
    dispatch(metersEditingActions.setMeterSelected({ id: meter.id, selected }));
  };

  const applyNewPosition = useCallback(
    (position: [number, number]) => {
      dispatch(metersEditingActions.moveMeter({ meterFeature: meter.feature, position }));
      setEditMode(false);
    },
    [dispatch, meter.feature],
  );

  const cancelEditing = useCallback(() => {
    setEditMode(false);
  }, []);

  const close = useCallback(() => {
    dispatch(metersEditingActions.removeMeter(meter.id));
  }, [dispatch, meter.id]);

  return (
    <div className={`${styles.tile} ${className || ''}`}>
      <div className={styles.meter}>
        <Checkbox checked={meter.isSelected} onChange={(ev: CheckboxChangeEvent) => toggleSelected(ev.value)} />

        <div>
          <div className={styles.tileInfo}>
            <MeterTypeIcon className={styles.itemIcon} typeId={meter.typeId} />
            <div>
              <div className={styles.tileInfoRow}>
                <label>Meter: </label>#<span className='value'>{meter.name}</span>
              </div>
              <div className={styles.tileInfoRow}>
                <MeterTypeText className={styles.meterTypeIcon} typeId={meter.typeId} />
                {meter.status ? (
                  <>
                    &nbsp;| <MeterStatusText status={meter.status} />
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.coordinates}>
            <div className={styles.coordsRow}>
              <span className={styles.coordsLabel}>Current:</span>
              <GeoCoordinatesText position={meter.originalPosition} className='coordsValue' />
            </div>
            <div className={styles.coordsRow}>
              <span className={styles.coordsLabel}>Target:</span>
              {!editMode ? (
                <div className={styles.coordsValue}>
                  <GeoCoordinatesText position={meter.position} />
                  <button className={styles.btnEdit} onClick={() => setEditMode(true)}>
                    <i className='k-icon k-i-pencil'></i>
                  </button>
                </div>
              ) : null}
              {editMode ? (
                <GeoCoordinatesEditor
                  position={meter.position}
                  onApply={applyNewPosition}
                  onCancel={cancelEditing}
                  className={styles.coordsValue}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <button className={styles.btnUndo} onClick={close}>
        <i className='k-icon k-i-reload-sm'></i>
      </button>
    </div>
  );
};
