import { FunctionComponent } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';

import { IViolationMetric, Period } from '../../../../model';
import { MetricsTable, MetricValue } from '../metrics-table-table/MetricsTable';

interface Props {
  metrics: IViolationMetric[];
  className?: string;
}

export const EnforcementTable: FunctionComponent<Props> = ({ metrics, className }) => {
  const localization = useExtendedLocalization();
  const periods = [Period.Yesterday, Period.Week, Period.Month, Period.Quarter];

  const createMetricValues = (
    valueProp: (x: IViolationMetric) => number,
    changeProp: (x: IViolationMetric) => number,
    isMoney: boolean,
  ) => {
    const values: { [key in Period]?: MetricValue | undefined } = {};
    periods.forEach((x) => {
      const violationMetric = metrics.find((y) => y.Period === x);
      values[x] = violationMetric
        ? { value: valueProp(violationMetric), change: changeProp(violationMetric), isMoney: isMoney }
        : undefined;
    });
    return values;
  };

  const metricItems = [
    {
      name: localization.toLanguageStringF(`enforcement.metricReportType.issued`),
      values: createMetricValues(
        (y) => y.Total,
        (y) => y.TotalChange,
        true,
      ),
    },
    {
      name: localization.toLanguageStringF(`enforcement.metricReportType.violations`),
      values: createMetricValues(
        (y) => y.Count,
        (y) => y.CountChange,
        false,
      ),
    },
    {
      name: localization.toLanguageStringF(`enforcement.metricReportType.averageTicket`),
      values: createMetricValues(
        (y) => y.Average,
        (y) => y.AverageChange,
        true,
      ),
    },
    {
      name: localization.toLanguageStringF(`enforcement.metricReportType.dailyAverage`),
      values: createMetricValues(
        (y) => y.DailyAverage,
        (y) => y.DailyAverageChange,
        true,
      ),
    },
  ];

  return <MetricsTable periods={periods} metricItems={metricItems} className={className}></MetricsTable>;
};
