import { Button } from '@progress/kendo-react-buttons';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { FC, useCallback, useState } from 'react';

import {
  currentStudyArea,
  heatmapsFilterActions,
  selectedBlockfacesActions,
  selectedCurbSpacesActions,
  selectedMetersActions,
  selectedStudyAreasActions,
  selectedZonesActions,
  selectHeatmapFilters,
  sidebarActions,
} from '../../../../features';
import { useOccupancyTrafficEnabled, useOpenPopupDispatch, useReportBuilder } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ISelectedStudyArea, OccupancySource, SidebarName, SpotOccupancyCalculator } from '../../../../model';
import { FilterName, ISelectedEntity, NavigationSource, PopupType, ReportName, amplitudeService } from '../../../../services';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Link } from '../../../common';
import { OccupancyTable, SidebarSectionLazyLoader } from '../../common';
import { TrafficTable } from '../../common/traffic-table/TrafficTable';
import { ZoneKpiTable } from '../../common/zone-kpi-table/ZoneKpiTable';
import { MapSidebarContainer } from '../../controls';
import { CameraThumbnail } from '../cameras/camera-thumbnail/CameraThumbnail';

import { RevenueTable } from '../../../revenue';
import styles from './StudyAreaSidebar.module.scss';
import { OccupancySourceSelector } from '../../common/occupancy-source-selector/OccupancySourceSelector';

const SidebarRow: FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
  <div className='map-sidebar-row'>
    <label>{title}</label>
    <div>{children}</div>
  </div>
);

const OccupancyMoreInfoLink: FC = () => {
  const localization = useExtendedLocalization();
  const reportBuilder = useReportBuilder();
  const occupancyReportUrl = reportBuilder.getOverviewStudyAreaOccupancy();

  if (!occupancyReportUrl) return null;

  const occupancyMoreInfo = (ev: React.FormEvent) => {
    ev.preventDefault();
    window.open(occupancyReportUrl, '_blank');
    amplitudeService.trackReport(ReportName.StudyAreaOccupancy, NavigationSource.Sidebar);
  };

  return (
    <div className='map-sidebar-row more-info'>
      <Link onClick={occupancyMoreInfo} className='more-info-button'>
        {localization.toLanguageStringF('common.moreInfo')}
      </Link>
    </div>
  );
};

interface Props {
  studyAreaData: ISelectedEntity<ISelectedStudyArea>;
}

const Sidebar: FC<Props> = ({ studyAreaData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const localization = useExtendedLocalization();
  const reportsBuilder = useReportBuilder();
  const studyArea = studyAreaData.entity;
  const occupancyTrafficEnabled = useOccupancyTrafficEnabled();
  const heatmapsFilter = useAppSelector(selectHeatmapFilters);
  const [occupancySource, setOccupancySource] = useState(heatmapsFilter.reportOccupancySource ?? heatmapsFilter.heatmapOccupancySource);

  const onReportOccupancySourceChanged = useCallback(
    (occupancySource: OccupancySource) => {
      dispatch(heatmapsFilterActions.setReportOccupancySource(occupancySource));
      setOccupancySource(occupancySource);
      amplitudeService.trackFilter(FilterName.ReportOccupancySource, occupancySource);
    },
    [dispatch],
  );

  const handleCloseClick = (ev: React.FormEvent) => {
    ev.preventDefault();
    dispatch(sidebarActions.clearSelected());
  };

  const mapViewClick = () => {
    studyArea && popupDispatch(selectedStudyAreasActions.openPopup(studyArea.Id));
  };

  const openMeter = (meterId: number) => {
    popupDispatch(selectedMetersActions.loadMeter({ id: meterId, position: null }));
    amplitudeService.trackPopupOpen(PopupType.Meters, NavigationSource.Navigation);
  };

  const openSpot = (spotId: number) => {
    popupDispatch(selectedCurbSpacesActions.load({ id: spotId, position: null }));
    amplitudeService.trackPopupOpen(PopupType.CurbSpaces, NavigationSource.Navigation);
  };

  const openZone = (zoneId: number) => {
    popupDispatch(selectedZonesActions.loadZone({ id: zoneId, position: null }));
    amplitudeService.trackPopupOpen(PopupType.Zones, NavigationSource.Navigation);
  };

  const openBlockface = (blockfaceId: number) => {
    popupDispatch(selectedBlockfacesActions.loadBlockface({ id: blockfaceId, position: null }));
    amplitudeService.trackPopupOpen(PopupType.Blockfaces, NavigationSource.Navigation);
  };

  if (!studyArea) {
    return null;
  }

  const analysisReportUrl = reportsBuilder.getOverviewStudyAreaAnalysis();
  const revenueReportUrl = reportsBuilder.getStudyAreaRevenue(studyArea.Id);

  const kpiMoreInfo = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (analysisReportUrl) {
      window.open(analysisReportUrl, '_blank');
      amplitudeService.trackReport(ReportName.StudyAreaAnalysis, NavigationSource.Sidebar);
    }
  };

  const revenueMoreInfo = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (revenueReportUrl) {
      window.open(revenueReportUrl, '_blank');
      amplitudeService.trackReport(ReportName.StudyAreaRevenue, NavigationSource.Sidebar);
    }
  };

  const spotsCount = SpotOccupancyCalculator.getCountSpotStatuses(
    studyArea.spotsStates.map((x) => x.Status),
    studyArea.SpotsCount,
  );

  return (
    <div className='map-sidebar map-sidebar map-sidebar-info'>
      <div className='map-sidebar-header'>
        <h3>Study Area: #{studyArea?.Name}</h3>
        <Button size='small' className='close' icon='close' onClick={handleCloseClick}></Button>
      </div>

      <div className='map-sidebar-body'>
        <PanelBar keepItemsMounted={true}>
          <PanelBarItem title={localization.toLanguageStringF('common.generalInfo')} expanded={true}>
            <div className={styles.generalInfo}>
              {studyArea.zones.length > 0 && (
                <SidebarRow title={`${localization.toLanguageStringF('studyArea.zones')}:`}>
                  {studyArea.zones.map((x, idx) => (
                    <Link key={x.Id} onClick={() => openZone(x.Id)} className={styles.listItem}>
                      <span>{x.Name}</span>
                      {idx < studyArea.zones.length - 1 && ','}
                    </Link>
                  ))}
                </SidebarRow>
              )}
              {studyArea.blockfaces.length > 0 && (
                <SidebarRow title={`${localization.toLanguageStringF('studyArea.blockfaces')}:`}>
                  {studyArea.blockfaces.map((x, idx) => (
                    <Link key={x.Id} onClick={() => openBlockface(x.Id)} className={styles.listItem}>
                      <span>{x.Name}</span>
                      {idx < studyArea.blockfaces.length - 1 && ','}
                    </Link>
                  ))}
                </SidebarRow>
              )}
              <SidebarRow title={`${localization.toLanguageStringF('studyArea.sidebar.address')}:`}>{studyArea.Address}</SidebarRow>
              <SidebarRow title='Vacant:'>{spotsCount.vacant}</SidebarRow>
              <SidebarRow title='Booked:'>{spotsCount.booked}</SidebarRow>
              <SidebarRow title='Occupied:'>{spotsCount.occupied}</SidebarRow>
              <SidebarRow title='Not Monitored:'>{spotsCount.notMonitored}</SidebarRow>
              <SidebarRow title={`${localization.toLanguageStringF('common.parkingSpacesCount')}:`}>{studyArea.SpotsCount}</SidebarRow>
            </div>
            <div className='map-sidebar-row more-info'>
              <Link onClick={mapViewClick} className='more-info-button' disabled={studyAreaData.openPopup}>
                {localization.toLanguageStringF('common.mapView')}
              </Link>
            </div>
          </PanelBarItem>

          <PanelBarItem title={localization.toLanguageStringF('studyArea.revenue')} expanded={false}>
            <SidebarSectionLazyLoader
              hasData={(studyArea.revenue?.length || 0) > 0}
              shouldLoadData={studyArea.revenue === undefined}
              loadData={() => dispatch(selectedStudyAreasActions.fetchRevenue(studyArea.Id))}
            >
              <RevenueTable revenue={studyArea.revenue || []} />
              {revenueReportUrl !== null && (
                <div className='map-sidebar-row more-info'>
                  <Link onClick={revenueMoreInfo} className='more-info-button'>
                    {localization.toLanguageStringF('common.moreInfo')}
                  </Link>
                </div>
              )}
            </SidebarSectionLazyLoader>
          </PanelBarItem>

          <PanelBarItem title={localization.toLanguageStringF('common.report.kpi')} expanded={false}>
            <SidebarSectionLazyLoader
              hasData={(studyArea.kpiReport?.length || 0) > 0}
              shouldLoadData={studyArea.kpiReport === undefined}
              loadData={() => dispatch(selectedStudyAreasActions.fetchKpiReport(studyArea.Id))}
            >
              <ZoneKpiTable kpiReport={studyArea.kpiReport || []} className={styles.panelMargin} />
              {analysisReportUrl !== null && (
                <div className='map-sidebar-row more-info'>
                  <Link onClick={kpiMoreInfo} className='more-info-button'>
                    {localization.toLanguageStringF('common.moreInfo')}
                  </Link>
                </div>
              )}
            </SidebarSectionLazyLoader>
          </PanelBarItem>

          {occupancyTrafficEnabled && (
            <PanelBarItem title={localization.toLanguageStringF('common.report.traffic')} expanded={false}>
              <SidebarSectionLazyLoader
                hasData={(studyArea.trafficReport?.length || 0) > 0}
                shouldLoadData={studyArea.trafficReport === undefined}
                loadData={() => dispatch(selectedStudyAreasActions.fetchTrafficReport(studyArea.Id))}
              >
                <TrafficTable report={studyArea.trafficReport || []} className={styles.panelMargin} />
                <OccupancyMoreInfoLink />
              </SidebarSectionLazyLoader>
            </PanelBarItem>
          )}

          <PanelBarItem title={localization.toLanguageStringF('common.report.occupancy')} expanded={false}>
            <div className={styles.wrapContainer}>
              <OccupancySourceSelector value={occupancySource} onChange={onReportOccupancySourceChanged} />
            </div>

            <SidebarSectionLazyLoader
              hasData={(studyArea.occupancyReport?.length || 0) > 0}
              shouldLoadData={studyArea.occupancyReport === undefined}
              reloadDependencies={[occupancySource]}
              loadData={() =>
                dispatch(
                  selectedStudyAreasActions.fetchOccupancyReport({
                    studyAreaId: studyArea.Id,
                    occupancySource: occupancySource,
                  }),
                )
              }
            >
              <OccupancyTable occupancyReport={studyArea.occupancyReport || []} />
              <OccupancyMoreInfoLink />
            </SidebarSectionLazyLoader>
          </PanelBarItem>

          {studyArea.camerasStates.length > 0 && (
            <PanelBarItem title={localization.toLanguageStringF('studyArea.sidebar.cameras')} expanded={false}>
              <div className={styles.wrapContainer}>
                {studyArea.camerasStates.map((x) => {
                  return (
                    <div key={x.CameraId} className={styles.thumbnail}>
                      <CameraThumbnail state={x} />
                    </div>
                  );
                })}
              </div>
            </PanelBarItem>
          )}

          {(studyArea.metersNames.length > 0 && (
            <PanelBarItem
              title={localization.toLanguageStringF('studyArea.sidebar.meters')}
              expanded={false}
              className='panelbar-scrollable'
            >
              <div className={styles.wrapContainer}>
                {studyArea.metersNames.map((x, idx) => (
                  <Link key={x.Id} onClick={() => openMeter(x.Id)} className={styles.linkButton}>
                    <span>{x.Name}</span>
                    {idx < studyArea.metersNames.length - 1 && ','}
                  </Link>
                ))}
              </div>
            </PanelBarItem>
          )) ||
            null}

          {studyArea.sensors.length > 0 && (
            <PanelBarItem
              title={localization.toLanguageStringF('studyArea.sidebar.sensors')}
              expanded={false}
              className='panelbar-scrollable'
            >
              <div className={styles.wrapContainer}>
                {studyArea.sensors.map((x, idx) => (
                  <Link key={x.SensorId} onClick={() => openSpot(x.SpotId)} className={styles.linkButton}>
                    <span>{x.SensorName}</span>
                    {idx < studyArea.sensors.length - 1 && ','}
                  </Link>
                ))}
              </div>
            </PanelBarItem>
          )}
        </PanelBar>
      </div>
    </div>
  );
};

export const StudyAreaSidebar: FC = () => {
  const current = useAppSelector(currentStudyArea);

  return current ? (
    <MapSidebarContainer name={SidebarName.studyArea}>
      <Sidebar studyAreaData={current} />
    </MapSidebarContainer>
  ) : null;
};
