import { FunctionComponent } from 'react';
import { IViolationInfo } from '../../../../../model';
import { PeriodViolationsAmountTextInfo } from './PeriodViolationsAmountTextInfo';
import { PeriodViolationsChartInfo } from './PeriodViolationsChartInfo';
import { PeriodViolationsNumberTextInfo } from './PeriodViolationsNumberTextInfo';

import { IPeriodFilter } from '../../../../../features';
import styles from './ViolationsPeriodInfo.module.scss';

interface Props {
  filter: IPeriodFilter;
  violation: IViolationInfo;
  selectedGroupId?: number;
  className?: string;
}

export const ViolationsPeriodInfo: FunctionComponent<Props> = ({ filter, violation, className, selectedGroupId }) => {
  return (
    <div className={className || ''}>
      <div className={styles.popupRow}>
        <PeriodViolationsAmountTextInfo period={filter.heatmapPeriod} violation={violation} className={styles.textInfo} />
        <PeriodViolationsChartInfo
          period={filter.heatmapPeriod}
          violation={violation}
          valueSelector={(x) => x.CurrentTotal}
          className={styles.chartInfo}
          selectedGroupId={selectedGroupId}
        />
      </div>
      <div className={styles.popupRow}>
        <PeriodViolationsNumberTextInfo period={filter.heatmapPeriod} violation={violation} className={styles.textInfo} />
        <PeriodViolationsChartInfo
          period={filter.heatmapPeriod}
          violation={violation}
          valueSelector={(x) => x.CurrentCount}
          className={styles.chartInfo}
          selectedGroupId={selectedGroupId}
        />
      </div>
    </div>
  );
};
