import { FunctionComponent } from 'react';
import classNames from 'classnames/bind';
import { useExtendedLocalization } from '../../../../../hooks/use-extended-localization-service';
import { CameraStatus } from '../../../../../model';

import styles from './CameraStatusText.module.scss';

const cx = classNames.bind(styles);

interface Props {
  status?: CameraStatus;
  className?: string;
}

export const CameraStatusText: FunctionComponent<Props> = ({ status = CameraStatus.Inactive, className }) => {
  const localization = useExtendedLocalization();
  return <span className={cx('cameraStatus', status, className)}>{localization.toLanguageStringF(`camera.status.${status}`)}</span>;
};
