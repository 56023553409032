import { AnyLayout, AnyPaint, Expression, LinePaint, MapLayerMouseEvent } from 'mapbox-gl';
import { FunctionComponent, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';

import { selectHeatmapFilters, selectTrafficGeo, selectTrafficGeoSpeedData } from '../../../../features';
import { useAppSelector } from '../../../../store/hooks';
import { TrafficLayerType } from '../../../../model';
import { useMapLayerHover, useMapToolTip } from '../../../../hooks';
import { palette } from './palette';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import styles from './TrafficLayer.module.scss';

const trafficSpeedLayer = 'traffic-speed-layer';

const trafficPaint: AnyPaint = {
  'line-width': {
    base: 1.5,
    stops: [
      [14, 2.5],
      [20, 3],
    ],
  },
  'line-color': {
    base: 1,
    type: 'categorical',
    property: 'congestion',
    stops: [
      ['low', 'hsl(145, 95%, 30%)'],
      ['moderate', 'hsl(30, 100%, 42%)'],
      ['heavy', 'hsl(355, 100%, 37%)'],
      ['severe', 'hsl(355, 70%, 22%)'],
    ],
  },
};

const defColor = 'rgba(124, 124, 124, 0.5)';
const getHeatMapPalette = (value: Expression | number): Expression => ['interpolate-lab', ['linear'], ['number', value], ...palette];

export const TrafficLayer: FunctionComponent = () => {
  const heatmapFilter = useAppSelector(selectHeatmapFilters);
  const trafficSpeedGeo = useAppSelector(selectTrafficGeo);
  const trafficSpeedData = useAppSelector(selectTrafficGeoSpeedData);
  const localization = useExtendedLocalization();

  useMapLayerHover([trafficSpeedLayer]);
  useMapToolTip(
    (event: MapLayerMouseEvent) => {
      const feature = event.features && event.features.length > 0 ? event.features[0] : null;
      const id = event.features && event.features.length > 0 ? event.features[0].properties?.id : null;
      const speedData = trafficSpeedData.find((x) => x.Id === id);

      return (
        <div className={styles.toolTip}>
          <div>
            {feature?.properties?.code
              ? localization.toLanguageStringF(`traffic.tooltip.segment`, [feature?.properties?.code])
              : localization.toLanguageStringF(`traffic.tooltip.segmentNoData`)}
          </div>
          <div>
            {speedData
              ? localization.toLanguageStringF(`traffic.tooltip.speed`, [speedData.Speed])
              : localization.toLanguageStringF(`traffic.tooltip.speedNoData`)}
          </div>
        </div>
      );
    },
    () => heatmapFilter.trafficLayerType === TrafficLayerType.Speed,
    trafficSpeedLayer,
  );

  const trafficLayout: AnyLayout = useMemo(() => {
    return {
      visibility:
        heatmapFilter.enabled && heatmapFilter.trafficEnabled && heatmapFilter.trafficLayerType === TrafficLayerType.Traffic
          ? 'visible'
          : 'none',
    };
  }, [heatmapFilter.enabled, heatmapFilter.trafficEnabled, heatmapFilter.trafficLayerType]);

  const trafficSpeedLayout: AnyLayout = useMemo(() => {
    return {
      visibility:
        heatmapFilter.enabled && heatmapFilter.trafficEnabled && heatmapFilter.trafficLayerType === TrafficLayerType.Speed
          ? 'visible'
          : 'none',
    };
  }, [heatmapFilter.enabled, heatmapFilter.trafficEnabled, heatmapFilter.trafficLayerType]);

  const trafficSpeedPaint = useMemo((): LinePaint => {
    const exp: Expression | undefined = trafficSpeedData.length
      ? ['match', ['get', 'id'], ...trafficSpeedData.map((x) => [x.Id, x.NormalizedSpeed]).reduce((x, y) => x.concat(y), []), -1]
      : undefined;
    return {
      'line-color': exp ? getHeatMapPalette(exp) : defColor,
      'line-width': {
        base: 1.5,
        stops: [
          [14, 2.5],
          [20, 3],
        ],
      },
    };
  }, [trafficSpeedData]);

  const content = useMemo(() => {
    return (
      <>
        <Source id='traffic-source' type='vector' url='mapbox://mapbox.mapbox-traffic-v1'>
          <Layer
            {...{
              id: 'traffic-layer',
              'source-layer': 'traffic',
              type: 'line',
              layout: trafficLayout,
              paint: trafficPaint,
            }}
          />
        </Source>

        <Source id='traffic-speed-source' type='geojson' data={trafficSpeedGeo} generateId={true}>
          <Layer
            {...{
              id: trafficSpeedLayer,
              type: 'line',
              layout: trafficSpeedLayout,
              paint: trafficSpeedPaint,
            }}
          />
        </Source>
      </>
    );
  }, [trafficSpeedLayout, trafficLayout, trafficSpeedGeo, trafficSpeedPaint]);

  return content;
};
