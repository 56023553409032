import mapboxgl, { AnyPaint, CirclePaint, MapLayerMouseEvent, SymbolPaint } from 'mapbox-gl';
import { FunctionComponent, useCallback } from 'react';
import { Layer, Source } from 'react-map-gl';
import { Point, Feature, GeoJsonProperties } from 'geojson';
import { LngLat } from 'mapbox-gl';

import {
  metersEditingActions,
  selectedMetersActions,
  selectMetersEditing,
  selectMetersEnabled,
  selectMetersGeo,
  selectMetersShowSpotsCount,
  selectMetersShowVendorLogo,
  selectMeterVendors,
} from '../../../../features';
import { useMapLayerEditing, useMapLayerHover, useMapLayerPopup, useMapToolTip } from '../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { MapImage } from '../../controls/MapImage';
import { ILayouts, IPaints } from '../common';
import { MeterPopup } from './MeterPopup';
import { MetersEditingLayer } from './meters-editing/MetersEditingLayer';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { amplitudeService, NavigationSource, PopupType } from '../../../../services';

const COLORS: { [key: number]: string } = {
  1: 'grey',
  2: 'yellow',
  3: 'green',
  4: 'black',
  5: 'brown',
  6: 'red',
  7: 'purple',
  8: 'blue',
  9: 'white',
};

const getMetersPaint = (direction: string | null = null): AnyPaint => {
  return {
    'circle-radius': {
      base: 1.2,
      stops: [
        [12, 1.5],
        [22, 8],
      ],
    },
    'circle-color': [
      'match',
      ['get', 'policy'],
      1,
      COLORS[1],
      2,
      COLORS[2],
      3,
      COLORS[3],
      4,
      COLORS[4],
      5,
      COLORS[5],
      6,
      COLORS[6],
      7,
      COLORS[7],
      8,
      COLORS[8],
      9,
      COLORS[9],
      /* other */ '#08304f',
    ],
    'circle-translate': getOffsetLiteral(direction),
  };
};

const getOffsetLiteral = (direction: string | null = null, offset = 4): mapboxgl.Expression => {
  let base: [number, number] = [0, 0];
  switch (direction) {
    case 'left':
      base = [-offset, 0];
      break;
    case 'right':
      base = [offset, 0];
      break;
    case 'top':
      base = [0, -offset];
      break;
    case 'bottom':
      base = [0, offset];
      break;
  }

  return ['interpolate', ['linear'], ['zoom'], 14, ['literal', [base[0] / 4, base[1] / 4]], 18, ['literal', [base[0], base[1]]]];
};

const paints: IPaints = {
  payStations: {
    'text-color': 'black',
  },

  payStationSpots: {
    'text-color': '#000',
    'text-translate': ['interpolate', ['linear'], ['zoom'], 15, ['literal', [6, -6]], 16, ['literal', [9, -9]], 17, ['literal', [11, -11]]],
    'icon-translate': ['interpolate', ['linear'], ['zoom'], 15, ['literal', [6, -6]], 16, ['literal', [9, -9]], 17, ['literal', [11, -11]]],
  },

  meters: getMetersPaint(),
  metersLeft: getMetersPaint('left'),
  metersRight: getMetersPaint('right'),
  metersTop: getMetersPaint('top'),
  metersBottom: getMetersPaint('bottom'),

  meterVendors: {
    'text-color': '#fff',
    'text-halo-color': '#106EBE',
    'text-halo-width': 20,

    // 'text-translate': this.getOffsetExpression('offset')
  },
  meterStatuses: {
    'text-color': 'black',
    // 'text-translate': this.getOffsetExpression('offset')
  },
};

const metersLayer = 'meters-layer';
const metersLayerLeft = 'meters-layer-left';
const metersLayerRight = 'meters-layer-right';
const metersLayerTop = 'meters-layer-top';
const metersLayerBottom = 'meters-layer-bottom';
const meterVendorsLayer = 'meter-vendors-layer';

const payStationsLayer = 'pay-stations-layer';
const payStationVendorsLayer = 'pay-station-vendors-layer';
const payStationsLayerSpots = 'pay-stations-layer-spots';

const layers = [
  metersLayer,
  metersLayerLeft,
  metersLayerRight,
  metersLayerTop,
  metersLayerBottom,
  meterVendorsLayer,

  payStationsLayer,
  payStationVendorsLayer,
  payStationsLayerSpots,
];
const editingLayerId = 'meters-editing-layer';
const allowedLayers = layers.concat(['pay-stations-layer-spots', editingLayerId]);

export const MetersLayer: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const geojson = useAppSelector(selectMetersGeo);
  const layerEnabled = useAppSelector(selectMetersEnabled);
  const showVendorLogo = useAppSelector(selectMetersShowVendorLogo);
  const showSpotsCount = useAppSelector(selectMetersShowSpotsCount);
  const editing = useAppSelector(selectMetersEditing);
  const meterVendors = useAppSelector(selectMeterVendors);

  const localization = useExtendedLocalization();
  useMapToolTip(
    () => localization.toLanguageStringF('meter.toolTip.singleSpaceMeter'),
    () => layerEnabled,
    metersLayer,
  );
  useMapToolTip(
    () => localization.toLanguageStringF('meter.toolTip.doubleSpaceMeter'),
    () => layerEnabled,
    metersLayerLeft,
    metersLayerRight,
    metersLayerTop,
    metersLayerBottom,
  );
  useMapToolTip(
    () => localization.toLanguageStringF('meter.toolTip.payStation'),
    () => layerEnabled,
    payStationsLayer,
  );
  useMapToolTip(
    () => localization.toLanguageStringF('common.parkingSpacesCount'),
    () => layerEnabled,
    payStationsLayerSpots,
  );
  useMapToolTip(
    (e) => {
      const vendorId = e.features?.length ? e.features[0].properties?.vendorId : undefined;
      return vendorId !== undefined && vendorId != null ? meterVendors?.find((x) => x.Id === vendorId)?.Name : undefined;
    },
    () => layerEnabled,
    meterVendorsLayer,
    payStationVendorsLayer,
  );

  const layouts: ILayouts = {
    meters: {
      visibility: layerEnabled ? 'visible' : 'none',
    },

    payStations: {
      visibility: layerEnabled ? 'visible' : 'none',
      'icon-image': [
        'match',
        ['get', 'policy'],
        'General',
        'pay-station-general',
        'Commercial',
        'pay-station-commercial',
        'ShortTerm',
        'pay-station-short-term',
        'Motorcycle',
        'pay-station-motorcycle',
        'TourBus',
        'pay-station-tour-bus',
        'SixWheeled',
        'pay-station-six-wheeled',
        'BoatTrailer',
        'pay-station-boat-trailer',
        'DisabledPeople',
        'pay-station-disabled-people',
        'PassengerLoading',
        'pay-station-passenger-loading',
        'pay-station',
      ],
      'icon-allow-overlap': true,
      'icon-size': {
        base: 0.3,
        stops: [
          [16, 0.5],
          [18, 0.7],
          [20, 1],
          [21, 1.4],
        ],
      },
    },

    payStationSpots: {
      visibility: layerEnabled && showSpotsCount ? 'visible' : 'none',
      'text-field': ['get', 'spotsCount'],
      'text-size': {
        base: 14,
        stops: [
          [15, 8],
          [16, 10],
          [17, 14],
        ],
      },

      'icon-image': 'circle-spot-count',
      'icon-size': {
        base: 1,
        stops: [
          [15, 0.6],
          [16, 0.8],
          [17, 1],
        ],
      },
    },

    vendorsLayout: {
      visibility: layerEnabled && showVendorLogo ? 'visible' : 'none',
      'icon-offset': [12, -12],
      'icon-image': [
        'match',
        ['get', 'vendorId'],
        1,
        'vendor-ips',
        2,
        'vendor-flowbird',
        3,
        'vendor-mac-kay',
        4,
        'vendor-gps',
        5,
        'vendor-pom',
        6,
        'vendor-cale',
        7,
        'vendor-duncan',
        'no-image',
      ],
      'icon-allow-overlap': true,
      'icon-size': {
        base: 0.4,
        stops: [
          [16, 0.6],
          [18, 0.8],
          [20, 1.2],
          [21, 1.6],
        ],
      },
    },
  };

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      dispatch(selectedMetersActions.closePopup());
      const feature = evt.features ? evt.features[0] : null;
      if (!feature || !feature.properties) return;

      const meterId = feature.properties.id;
      const position = [evt.lngLat.lng, evt.lngLat.lat];
      setTimeout(() => {
        dispatch(
          selectedMetersActions.loadMeter({
            id: meterId,
            feature: feature as Feature<Point, GeoJsonProperties>,
            position: position,
          }),
        );
        amplitudeService.trackPopupOpen(PopupType.Meters, NavigationSource.Map);
      }, 10);
    },
    [dispatch],
  );

  useMapLayerHover(layers);

  useMapLayerPopup(handleLayerClick, ...layers);

  const handleMeterMove = useCallback(
    (feature: Feature<Point, GeoJsonProperties>, position: LngLat) => {
      dispatch(metersEditingActions.moveMeter({ meterFeature: feature, position }));
    },
    [dispatch],
  );
  const handleMeterMoveFinish = useCallback(() => {
    dispatch(metersEditingActions.finishEditing());
  }, [dispatch]);
  useMapLayerEditing('meters-layer', editing.isEditing, handleMeterMove, handleMeterMoveFinish);

  return (
    <>
      <Source id='meters-source' type='geojson' data={geojson} generateId={true}>
        <Layer
          {...{
            id: metersLayer,
            type: 'circle',
            layout: layouts.meters,
            paint: paints.meters as CirclePaint,
            before: 'building-extrusion',
            filter: ['==', 'offset', 'center'],
          }}
        />

        <Layer
          {...{
            id: metersLayerLeft,
            type: 'circle',
            layout: layouts.meters,
            paint: paints.metersLeft as CirclePaint,
            before: 'building-extrusion',
            filter: ['==', 'offset', 'left'],
          }}
        />

        <Layer
          {...{
            id: metersLayerRight,
            type: 'circle',
            layout: layouts.meters,
            paint: paints.metersRight as CirclePaint,
            before: 'building-extrusion',
            filter: ['==', 'offset', 'right'],
          }}
        />

        <Layer
          {...{
            id: metersLayerTop,
            type: 'circle',
            layout: layouts.meters,
            paint: paints.metersTop as CirclePaint,
            before: 'building-extrusion',
            filter: ['==', 'offset', 'top'],
          }}
        />

        <Layer
          {...{
            id: metersLayerBottom,
            type: 'circle',
            layout: layouts.meters,
            paint: paints.metersBottom as CirclePaint,
            before: 'building-extrusion',
            filter: ['==', 'offset', 'bottom'],
          }}
        />

        <Layer
          {...{
            id: meterVendorsLayer,
            type: 'symbol',
            layout: layouts.vendorsLayout,
            paint: paints.meterVendors as SymbolPaint,
            before: 'building-extrusion',
            filter: showSpotsCount
              ? [
                  'any',
                  ['==', 'offset', 'center'],
                  ['==', 'offset', 'top'],
                  ['==', 'offset', 'bottom'],
                  ['==', 'offset', 'left'],
                  ['==', 'offset', 'right'],
                ]
              : ['all'], //fixed rarely reproducible error 'mapbox layer filter undefined'
          }}
        />

        <Layer
          {...{
            id: payStationsLayer,
            type: 'symbol',
            layout: layouts.payStations,
            paint: paints.payStations as SymbolPaint,
            before: 'building-extrusion',
            filter: ['==', 'offset', 'MultiSpot'],
          }}
        />

        <Layer
          {...{
            id: payStationVendorsLayer,
            type: 'symbol',
            layout: {
              ...layouts.vendorsLayout,
              visibility: layerEnabled && showSpotsCount && showVendorLogo ? 'visible' : 'none',
              'icon-offset': [-12, 12],
            },
            paint: paints.meterVendors as SymbolPaint,
            before: 'building-extrusion',
            filter: ['==', 'offset', 'MultiSpot'],
          }}
        />

        <Layer
          {...{
            id: payStationsLayerSpots,
            type: 'symbol',
            layout: layouts.payStationSpots,
            paint: paints.payStationSpots as SymbolPaint,
            before: 'building-extrusion',
            filter: ['==', 'offset', 'MultiSpot'],
          }}
        />

        <MapImage name='no-image' url='/icons/placeholder-image-transparent.png' />
        <MapImage name='vendor-ips' url='/icons/vendors/ips.png' />
        <MapImage name='vendor-flowbird' url='/icons/vendors/flowbird.png' />
        <MapImage name='vendor-mac-kay' url='/icons/vendors/mac-kay.png' />
        <MapImage name='vendor-gps' url='/icons/vendors/gps.png' />
        <MapImage name='vendor-pom' url='/icons/vendors/pom.png' />
        <MapImage name='vendor-cale' url='/icons/vendors/cale.png' />
        <MapImage name='vendor-duncan' url='/icons/vendors/duncan.png' />

        <MapImage name='pay-station' url='/icons/meters/types/paystation.png' />
        <MapImage name='pay-station-boat-trailer' url='/icons/meters/types/paystation-boat-trailer.png' />
        <MapImage name='pay-station-commercial' url='/icons/meters/types/paystation-commercial.png' />
        <MapImage name='pay-station-disabled-people' url='/icons/meters/types/paystation-disabled-people.png' />
        <MapImage name='pay-station-general' url='/icons/meters/types/paystation-general.png' />
        <MapImage name='pay-station-motorcycle' url='/icons/meters/types/paystation-motorcycle.png' />
        <MapImage name='pay-station-passenger-loading' url='/icons/meters/types/paystation-passenger-loading.png' />
        <MapImage name='pay-station-short-term' url='/icons/meters/types/paystation-short-term.png' />
        <MapImage name='pay-station-six-wheeled' url='/icons/meters/types/paystation-six-wheeled.png' />
        <MapImage name='pay-station-tour-bus' url='/icons/meters/types/paystation-tour-bus.png' />

        <MapImage name='circle-spot-count' url='/icons/circle-spot-count.png' />
        <MeterPopup />
      </Source>

      <MetersEditingLayer
        layerId={editingLayerId}
        enabled={layerEnabled}
        paint={paints.meters as CirclePaint}
        onLayerClick={handleLayerClick}
      />
    </>
  );
};
