/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FunctionComponent } from 'react';
import classNames from 'classnames/bind';

import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { Period } from '../../../../model';

import styles from './MetricsTable.module.scss';
import { numberUtils } from '../../../../utils';

const cx = classNames.bind(styles);

interface Props {
  metricItems: MetricItem[];
  periods: Period[];
  className?: string;
}

export interface MetricValue {
  value: number | string;
  change: number;
  isMoney: boolean;
}

export interface MetricItem {
  name: string;
  values: { [key in Period]?: MetricValue | undefined };
}

const Cell: FunctionComponent<{ value?: MetricValue }> = ({ value }) => {
  if (!value) {
    return <>-</>;
  }

  const isNumber = typeof value.value === 'number';
  const prefix = value.isMoney ? '$' : '';
  const changePrefix = value.change > 0 ? '+' : '';
  return (
    <>
      <div>{`${prefix}${isNumber ? numberUtils.toUiString(value.value as number, value.isMoney ? 2 : 0) : value.value}`}</div>
      <div className={cx(value.change < 0 ? 'red' : 'green')}>{`${changePrefix}${value.change.toFixed(2)}%`}</div>
    </>
  );
};

export const MetricsTable: FunctionComponent<Props> = ({ metricItems, periods, className }) => {
  const localization = useExtendedLocalization();

  return (
    <table className={cx('table table-borderless table-striped table-sm', 'enforcementTable', className)}>
      <thead>
        <tr>
          <th scope='col'></th>
          {periods.map((x, idx) => (
            <th key={idx} scope='col'>
              {localization.toLanguageStringF(`common.period.${x.toString().toLowerCase()}`)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {metricItems.map((x, idx) => (
          <tr key={idx}>
            <td>{x.name}</td>
            {periods.map((p, idx) => (
              <td key={idx}>
                <Cell value={x.values[p]} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
